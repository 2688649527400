import { Select, Textarea } from '@keoworld/gbl-ui-kit'
import Form from 'containers/form'
import { useEffect } from 'react'
import { getIndustries } from 'services/customer'
import styled from 'styled-components'
import { useDebounce } from 'utils/hooks/debounce'
import {
  CUSTOMER_AGENT,
  CUSTOMER_FORM,
  CUSTOMER_PROFILE,
  CUSTOMER_USER,
  SH_AGENT,
  SH_BUSINESS,
  SH_LOCATION,
  SH_PERSON,
} from 'utils/schemas/customer'
import { INPUT_TYPES } from 'containers/form'
import { useState } from 'react'
import { validateValue } from 'utils/functions/validators'

const initialSchemas = {
  CUSTOMER_AGENT,
  CUSTOMER_FORM,
  CUSTOMER_PROFILE,
  CUSTOMER_USER,
  SH_AGENT,
  SH_BUSINESS,
  SH_LOCATION,
  SH_PERSON,
}

const CustomerForm = ({
  formValues,
  setFormValues,
  formErrors,
  setFormErrors,
  isValidating,
  createProspect = true,
  isBlocked = false,
}) => {
  const [industriesOption, setIndustriesOption] = useState([])
  const [firstRender, setFirstRender] = useState(false)

  const formSchemas = Object.entries(initialSchemas).reduce(
    (acc, [key, value]) => {
      const newSchema = value.map((itm) => ({ ...itm, disabled: isBlocked }))
      return { ...acc, [key]: newSchema }
    },
    {}
  )

  const debounce = useDebounce(
    formValues?.customer['satIndustriesFilter'] ?? '',
    700
  )

  const handleFormValue = (formType) => (newFormValues) => {
    setFormValues((prev) => ({ ...prev, [formType]: { ...newFormValues } }))
  }

  const handleFormErrors = (formType) => (newFormErrors) => {
    setFormErrors((prev) => ({
      ...prev,
      [formType]: { ...prev[formType], ...newFormErrors },
    }))
  }

  const handleDescription = (e) => {
    const value = e.target.value
    const hasError = !validateValue(value, {
      params: { min: 50, max: 1000 },
    })
    setFormValues({
      ...formValues,
      customer: { ...formValues.customer, 'description:customer': value },
    })
    setFormErrors({
      ...formErrors,
      customer: { ...formErrors.customer, 'description:customer': hasError },
    })
  }

  useEffect(() => {
    const description = formValues?.customer?.['description:customer'] ?? ''

    setFormErrors({
      ...formErrors,
      customer: {
        ...formErrors.customer,
        'description:customer': description.length < 50,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchIndustries = async () => {
      if (firstRender)
        setFormValues((formValues) => ({
          ...formValues,
          customer: { ...formValues.customer, 'satIndustries:customer': '' },
        }))
      const { data: industryList } = await getIndustries(debounce)
      const industries = industryList.map((industry) => {
        const { id: value, economicActivity: label } = industry
        return { value, label }
      })
      setFirstRender(true)
      setIndustriesOption(industries)
    }
    if (debounce && debounce.length >= 2) fetchIndustries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounce])

  return (
    <CustomerInfoStyled>
      <div className='form-section'>
        <h5 className=''>Perfil</h5>
        <div className='form'>
          <Form
            schema={formSchemas.CUSTOMER_PROFILE}
            formValues={formValues?.customer}
            setFormValues={handleFormValue('customer')}
            formErrors={formErrors?.customer}
            setFormErrors={handleFormErrors('customer')}
            isValidating={isValidating}
          />
          <Form
            schema={[
              {
                inputType: INPUT_TYPES.SELECT,
                name: 'satIndustries:customer',
                label: 'Industria',
                options: industriesOption ?? [],
                disabled: isBlocked,
              },
            ]}
            formValues={formValues?.customer}
            setFormValues={handleFormValue('customer')}
            formErrors={formErrors?.customer}
            setFormErrors={handleFormErrors('customer')}
            isValidating={isValidating}
          />
        </div>
      </div>
      <div className='form-section'>
        <h5 className=''>Contacto de la empresa</h5>
        <div className='form'>
          <Form
            schema={formSchemas.CUSTOMER_USER}
            formValues={formValues?.customer}
            setFormValues={handleFormValue('customer')}
            formErrors={formErrors?.customer}
            setFormErrors={handleFormErrors('customer')}
            isValidating={isValidating}
          />
        </div>
      </div>
      <div className='form-section'>
        <h5>Datos de la empresa</h5>
        <div className='form'>
          <Form
            schema={formSchemas.CUSTOMER_FORM}
            formValues={formValues?.customer}
            setFormValues={handleFormValue('customer')}
            formErrors={formErrors?.customer}
            setFormErrors={handleFormErrors('customer')}
            isValidating={isValidating}
          />
        </div>
        <h5 className='title-legal-form'>Datos de Representante Legal</h5>
        <div className='form'>
          <Form
            schema={formSchemas.CUSTOMER_AGENT}
            formValues={formValues?.customer}
            setFormValues={handleFormValue('customer')}
            formErrors={formErrors?.customer}
            setFormErrors={handleFormErrors('customer')}
            isValidating={isValidating}
          />
        </div>
      </div>
      <div className='form-section'>
        <h5>Principal accionista/ obligado solidario</h5>
        <div className='form'>
          <Select
            name='personType'
            label='Tipo de persona Principal accionista'
            disabled={!createProspect || isBlocked}
            value={formValues?.customer?.personType}
            onChange={({ target }) => {
              setFormValues({
                ...formValues,
                personType: target.value,
                customer: {
                  ...formValues.customer,
                  personType: target.value,
                },
              })
            }}
          >
            <option value='naturalPerson'>Persona Física</option>
            <option value='moralPerson'>Persona Moral</option>
          </Select>
        </div>
        <br />
        <div className='form'>
          {formValues?.customer.personType === 'naturalPerson' && (
            <Form
              schema={formSchemas.SH_PERSON}
              formValues={formValues?.sh_person}
              setFormValues={handleFormValue('sh_person')}
              formErrors={formErrors?.sh_person}
              setFormErrors={handleFormErrors('sh_person')}
              isValidating={isValidating}
            />
          )}
          {formValues?.customer.personType !== 'naturalPerson' && (
            <>
              <Form
                schema={formSchemas.SH_BUSINESS}
                formValues={formValues?.sh_business}
                setFormValues={handleFormValue('sh_business')}
                formErrors={formErrors?.sh_business}
                setFormErrors={handleFormErrors('sh_business')}
                isValidating={isValidating}
              />
              <Form
                schema={formSchemas.SH_AGENT}
                formValues={formValues?.sh_agent}
                setFormValues={handleFormValue('sh_agent')}
                formErrors={formErrors?.sh_agent}
                setFormErrors={handleFormErrors('sh_agent')}
                isValidating={isValidating}
              />
            </>
          )}
          <Form
            schema={formSchemas.SH_LOCATION}
            formValues={formValues?.sh_location}
            setFormValues={handleFormValue('sh_location')}
            formErrors={formErrors?.sh_location}
            setFormErrors={handleFormErrors('sh_location')}
            isValidating={isValidating}
          />
        </div>
      </div>
      <div className='form-section'>
        <h5>Describe el perfil del prospecto</h5>
        <div className='form'>
          <Textarea
            className='textarea'
            name='comments'
            label='Comentarios'
            rows={5}
            maxLength={1000}
            value={formValues.customer['description:customer']}
            placeholder='Comentarios sobre el prospecto'
            onChange={handleDescription}
            error={isValidating && formErrors?.customer['description:customer']}
            disabled={isBlocked}
          />
        </div>
      </div>
    </CustomerInfoStyled>
  )
}

const CustomerInfoStyled = styled.div`
  .form-section {
    margin-top: 2rem;

    .form {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      & > div {
        padding: 0 1rem;
        &:nth-child(3n + 1) {
          padding-left: 0;
        }
        &:nth-child(3n) {
          padding-right: 0;
        }
      }

      .textarea {
        grid-column: span 2;
      }
    }
    .title-legal-form {
      margin-top: 2rem;
    }
  }
`

export default CustomerForm
