import { Button, Icon, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import { useCallback, useEffect, useState } from 'react'
import { commentCreate, commentFind } from 'services/documents'
import styled from 'styled-components'
import { dateFormatFromIso } from 'utils/functions/date'
import { getUserRoles } from 'utils/functions/role-manager'
import { useAuth } from 'utils/hooks/auth'
import CreateLine from './lateral-bar/create-line'
import LineDetail from './lateral-bar/line-detail'
import EditLine from './lateral-bar/line-edit'
import Portfolio from './lateral-bar/portfolio'

const LateralBar = ({
  setlateralBar,
  setLateralBar,
  type,
  formValues,
  fileId,
  documentName,
  loan,
  callback,
  onClose,
  getLines,
}) => {
  setLateralBar ??= setlateralBar
  return (
    <LateralBarStyled type={type}>
      {type === 'CREATE_LINE' && (
        <>
          <Icon name='close' onClick={() => setLateralBar(false)} />
          <CreateLine getLines={getLines} setLateralBar={setLateralBar} />
        </>
      )}
      {type === 'DETAIL' && (
        <>
          <Icon name='close' onClick={() => setLateralBar(false)} />
          <LineDetail line={formValues} setLateralBar={setLateralBar} />
        </>
      )}
      {type === 'EDIT' && (
        <>
          <Icon name='close' onClick={() => setLateralBar(false)} />
          <EditLine
            line={formValues}
            setLateralBar={setLateralBar}
            callback={getLines}
          />
        </>
      )}
      {type === 'PORTFOLIO' && (
        <>
          <Icon name='close' onClick={() => onClose && onClose()} />
          <Portfolio
            loan={loan}
            onClose={() => onClose && onClose()}
            setViewAction={(...args) => callback && callback(...args)}
          />
        </>
      )}
      {!type && (
        <>
          <Icon name='close' onClick={() => setLateralBar(false)} />
          <Comment fileId={fileId} documentName={documentName} />
        </>
      )}
    </LateralBarStyled>
  )
}

const Comment = ({ fileId, documentName }) => {
  const [comment, setComment] = useState('')
  const [loading, setLoading] = useState(true)
  const [commentList, setCommentList] = useState([])
  const [action, setAction] = useState(false)
  const { user } = useAuth()

  const getComments = useCallback(async () => {
    try {
      if (!fileId) return
      setLoading(true)
      setAction(true)
      const comments = await commentFind(fileId)
      setCommentList(comments)
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
      setAction(false)
    }
  }, [fileId])

  const handleCreate = async () => {
    try {
      if (!comment) return
      setAction(true)
      await commentCreate({
        comment,
        uuid: user.uid,
        role: getUserRoles()[0],
        fileId,
      })
      setComment('')
      getComments()
    } catch (error) {
      console.log('error', error)
    } finally {
      setAction(false)
    }
  }

  useEffect(() => {
    getComments()
  }, [getComments])

  return (
    <div>
      <div className='content-lateral'>
        <h4>Comentarios {fileId}</h4>
        <p>{documentName}</p>
        <div className='input-content'>
          <textarea
            className='text-area'
            placeholder='Agrega un comentario'
            value={comment}
            onChange={(e) => {
              setComment(e.target.value)
            }}
          />
          <Button
            disabled={action}
            className='button'
            onClick={() => handleCreate()}
          >
            Agregar
          </Button>
        </div>

        {loading && (
          <div className='center-loading'>
            <LoadingAnimation />
          </div>
        )}

        <ul>
          {!loading &&
            commentList.map((item, index) => {
              const date = item?.createdAt
                ? dateFormatFromIso(item?.createdAt)
                : ''
              return (
                <li className='card' key={`comment-${index}`}>
                  <h4>
                    <b>{item?.nameUser} </b> &nbsp; {date}
                  </h4>
                  <p>{item?.comment}</p>
                  <br />
                </li>
              )
            })}
        </ul>
        <br />
      </div>
    </div>
  )
}

const LateralBarStyled = styled.aside`
  background-color: #f6f8fc;
  width: auto;
  max-width: ${({ type }) => (!type || type === 'DETAIL' ? '30vw' : '60vw')};
  min-width: 457px;
  border-radius: 24px 0px 0px;
  position: absolute;
  height: calc(100% - 70px);
  top: 70px;
  right: 0%;
  z-index: 9999;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);

  .switch-div {
    display: flex;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin: 0.5rem 0rem 0.5rem 0.5rem;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 8px;
    left: 0px;
    right: 14px;
    bottom: 11px;
    background-color: #939191;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 22px;
    width: 22px;
    left: -1px;
    bottom: -3.5px;
    background-color: #bab9b9;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #939191;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .create-line {
    padding: 0rem 2rem;
    color: black;
    overflow-y: hidden;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    .scroll {
      overflow-y: scroll;
      /* height: 550px; */
      height: 100%;
    }
    #scroll-create {
      /* height: auto; */
    }
    .scroll::-webkit-scrollbar {
      display: none;
    }
    Select {
      background-color: #efefef;
    }
    h3 {
      font-size: 20px;
    }
    .p-title {
      margin: 0rem auto;
    }
    .form {
      display: grid;
      grid-template-columns: 50% 50%;
      div {
        min-width: auto;
      }
      Input {
        background-color: #efefef;
        width: 150px;
      }
      span {
        display: block;
        margin: 0rem 2rem;
      }
      & > div {
        padding: 0;
        margin: 0.5rem 0rem;
        &:nth-child(2n + 1) {
          padding-left: 0;
        }
        &:nth-child(2n) {
          padding-right: 0;
        }
      }
      .sections {
        margin: 0rem;
      }
    }
    .buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2rem;
      Button {
        margin: 0.2rem;
        width: 155px;
        height: 34px;
      }
    }
  }

  .icon {
    margin: 1rem;
    cursor: pointer;
  }
  .content-lateral {
    margin: auto;
    width: 360px;
    h4 {
      font-size: 20px;
      font-weight: bold;
    }
    .input-content {
      display: flex;
      justify-content: space-around;
      align-items: center;
      background-color: white;
      border-radius: 7px;
      width: 360px;
      min-height: 54px;
      .text-area {
        border-radius: 7px;
        min-height: 54px;
        border: none;
        font-family: 'Poppins';
        width: 200px;
        padding: 10px;
        resize: none;
      }
      .button {
        width: 82px;
        height: 30px;
      }
    }
    .card {
      background-color: white;
      border-radius: 7px;
      margin: 1rem auto 1rem auto;
      h4 {
        font-weight: lighter;
        font-size: 12px;
        padding: 1rem;
      }
      p {
        margin: auto 1rem;
        overflow-wrap: break-word;
        white-space: pre-line;
      }
    }
    ul {
      padding: 0;
      list-style: none;

      overflow-y: auto;
      max-height: calc(100vh - 300px);
    }
  }

  .center-loading {
    margin-top: 50px;
  }
  Button {
    div {
      span {
        color: white !important;
        background-color: white;
      }
    }
  }
`

export default LateralBar
