import { Button, Input } from '@keoworld/gbl-ui-kit'
import LineConditionsView from 'containers/line/line-conditions-view'
import { AlertContext } from 'providers/alert'
import PaginatedItems from 'components/pagination'
import { CustomerContext } from 'providers/customer'
import { LateralBarContext, LATERAL_MENU_OPTIONS } from 'providers/lateral-bar'
import { useContext, useEffect, useState, useCallback } from 'react'
import { getLineConditions } from 'services/lines'
import { editRelation } from 'services/relations'
import { instanceTransition } from 'services/workflow'
import { getLineRelations } from 'services/relations'
import styled from 'styled-components'
import { validateValue } from 'utils/functions/validators'
import { useFetchLines } from 'utils/hooks/fetch-lines'
import { CUSTOMER_STATES } from 'utils/schemas/customer'
import { LINE_STATES } from 'utils/schemas/line'
import { RELATION_STATES } from 'utils/schemas/relations'

const MAX_ITEMS_PER_PAGE = 5

const RedistributeRelationScores = () => {
  const { customer } = useContext(CustomerContext)
  const [form, setForm] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingConditions, setIsLoadingConditions] = useState(true)
  const [lineConditions, setLineConditions] = useState()
  const [line] = useFetchLines(customer?.id, LINE_STATES.REASSESSMENT)
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: MAX_ITEMS_PER_PAGE,
  })
  const [relations, setRelations] = useState([])

  const fetchRelations = useCallback(async () => {
    if (line?.lineId) {
      const { relations } = await getLineRelations({
        lineId: line?.lineId,
        size: pagination.pageSize,
        page: pagination.pageIndex + 1,
        columnFilters: [{ id: 'status', value: 'Reassessment' }],
      })
      setRelations(relations)

      const relationScores = Object.values(relations).reduce(
        (previousValue, currentValue) => {
          const { id, score } = currentValue
          return { ...previousValue, [id]: score }
        },
        {}
      )
      setForm(relationScores)
    }
  }, [pagination.pageSize, pagination.pageIndex, line?.lineId])

  const { setSelectedOption, setAction } = useContext(LateralBarContext)
  const { setAlert } = useContext(AlertContext)

  const onHandleChange = (id, value) => setForm({ ...form, [id]: value })
  const closeAction = () => {
    setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
    setAction(undefined)
  }

  const validateScores = () => {
    const scores = Object.values(relations).map(({ id }) => form[id] ?? null)
    if (scores.find((score) => score === null)) return true
    const newScoreTotal = sumScoresForm(relations, form)
    return line?.score < newScoreTotal
  }

  const onHandleSubmit = async () => {
    setIsLoading(true)
    const { event, nextStage, nextState } =
      CUSTOMER_STATES.line_amountReassigment.transition.SEND_REASSIGNMENT

    if (validateScores()) {
      setAlert({
        title: 'Error',
        label: 'Por favor verifique el monto de los score',
        type: 'error',
      })
      setIsLoading(false)
      return
    }

    try {
      await Promise.all(
        relations.map(async (itm) => {
          const updateBody = {
            id: itm?.id,
            score: Number(form[itm?.id]),
          }
          if (
            [
              RELATION_STATES.ACTIVE,
              RELATION_STATES.INACTIVE,
              RELATION_STATES.REASSESSMENT,
            ].includes(itm.status)
          ) {
            return await editRelation(updateBody)
          }
          return null
        })
      )

      const body = {
        'id:status': customer?.id,
        'wfState:status': nextState,
        'wfStage:status': nextStage,
        'status:lineStatus': LINE_STATES.ACTIVE,
        'id:lineStatus': line.lineId,
      }

      await instanceTransition(
        customer?.lineInstance?.workflowId,
        `${customer?.customerInstance?.instanceId}.${line.lineId}`,
        event,
        body
      )
      closeAction()
    } catch (error) {
      setAlert({
        title: 'Error',
        label: 'Ocurrió un error reasignando las relaciones',
        type: 'error',
      })
    }

    setIsLoading(false)
  }

  useEffect(() => {
    const fetchLineConditions = async () => {
      if (customer?.lineId) {
        setIsLoadingConditions(true)
        const lineConditions = await getLineConditions(customer.lineId)
        setLineConditions(lineConditions)
        setIsLoadingConditions(false)
      }
    }
    fetchLineConditions()
    fetchRelations()
  }, [customer, fetchRelations])

  return (
    <Styles>
      <div className='line-info'>
        <LineConditionsView
          isLoading={isLoadingConditions}
          lineData={lineConditions}
        />
      </div>
      <div className='relations'>
        <h3>Redistribución de relaciones en la linea</h3>
        <table>
          <thead>
            <tr>
              <th>Nombre del Proveedor</th>
              <th>Monto mensual autorizado</th>
              <th>Estado</th>
              <th>Merchant ID</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {relations.map((relation) => {
              const { id, providerName, score, status, providerId } = relation
              return (
                <tr key={`relation-${id}`}>
                  <td>{providerName}</td>
                  <td>{score}</td>
                  <td>{status}</td>
                  <td>{providerId}</td>
                  <td>
                    <Input
                      className='input'
                      placeholder='Ingrese un nuevo score'
                      name={`relation-${id}`}
                      value={form[id] ?? ''}
                      onChange={({ target }) =>
                        onHandleChange(id, target.value)
                      }
                      error={
                        !validateValue(form[id], 'numbers')
                          ? 'Ingrese un valor'
                          : false
                      }
                      disabled={status !== RELATION_STATES.REASSESSMENT}
                    />
                  </td>
                </tr>
              )
            })}
            <tr>
              <td colSpan='4' />
              <TdWithValidation isValid={!validateScores()}>
                Total: {sumScoresForm(relations, form)}
              </TdWithValidation>
            </tr>
          </tbody>
        </table>
        {pagination.pageSize > 5 && (
          <div className='pagination'>
            <PaginatedItems
              currentPage={pagination.pageIndex}
              handlePageClick={(event) => setPagination(event.selected)}
              pageCount={Math.ceil(pagination.pageSize / MAX_ITEMS_PER_PAGE)}
            />
          </div>
        )}
        <div className='actions'>
          <Button
            disabled={isLoading}
            buttonType='grayButton'
            onClick={closeAction}
          >
            Cancelar
          </Button>
          <Button disabled={isLoading} onClick={onHandleSubmit}>
            Enviar
          </Button>
        </div>
      </div>
    </Styles>
  )
}

const sumScoresForm = (relations, form) =>
  relations.reduce(
    (acc, itm) =>
      acc +
      (itm?.status === RELATION_STATES.REASSESSMENT
        ? Number(form[itm?.id])
        : 0),
    0
  )

const Styles = styled.section`
  padding: 2rem 3rem;
  .line-info {
    width: 70%;
  }

  .relations {
    margin-top: 2rem;
    table {
      margin-top: 1rem;
      width: 100%;
      text-align: left;
    }
  }

  .actions {
    margin-top: 2rem;
    button + button {
      margin-left: 28px;
    }
  }
`
const TdWithValidation = styled.td`
  color: ${({ isValid, theme }) => (isValid ? 'auto' : 'red')};
`

export default RedistributeRelationScores
