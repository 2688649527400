import InfoSummary from 'containers/buyer/detail/header/info-summary'
import { CustomerContext } from 'providers/customer'
import { ONBOARDING_STATES } from 'utils/schemas/workflows/onboarding'

import { useContext } from 'react'
import styled from 'styled-components'

const OnboardingActions = () => {
  const { customer } = useContext(CustomerContext)

  const customerStatus = customer?.wfState
  const onboardingStates = ONBOARDING_STATES[customerStatus]

  const { component: Component, label } = onboardingStates

  return (
    <StyledAction>
      <header>
        <InfoSummary currentRoute={[label]} />
      </header>
      {customerStatus && <Component />}
    </StyledAction>
  )
}

const StyledAction = styled.div`
  overflow-y: scroll;
  height: calc(100vh - 70px);
  header {
    padding: 2rem 2rem 0px;
  }
`

export default OnboardingActions
