import ModalUpload from 'containers/Modal/modal-documents-upload'
import LateralBar from 'containers/lateral-bar'
import { CustomerContext } from 'providers/customer'
import { LATERAL_MENU_OPTIONS, LateralBarContext } from 'providers/lateral-bar'
import { getDocumentList } from 'services/documents'
import { BuildSection } from 'templates/actions/document-actions/build-section'
import { SHAREHOLDER_TYPES } from 'utils/schemas/customer'
import { ASSESSMENT, FILE_STAGES, FILE_STATES } from 'utils/schemas/documents'
import { ONBOARDING_STATES } from 'utils/schemas/workflows/onboarding'

import { Button, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiBuyerRepository } from 'services/buyer-suppcards/buyer.repository'
import styled from 'styled-components'
import Router from 'utils/router'
import { useAuth } from 'utils/hooks/auth'
import { getUserRoles } from 'utils/functions/role-manager'

const ReviewDocumentsReassessment = () => {
  const [documents, setDocuments] = useState({})
  const [openModal, setOpenModal] = useState(false)
  const [lateralBar, setLateralBar] = useState(false)
  const [message, setMessage] = useState('')
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)

  const { user } = useAuth()
  const { customer } = useContext(CustomerContext)
  const { setAction, setSelectedOption } = useContext(LateralBarContext)

  const { customerId } = useParams()
  const navigate = useNavigate()

  const AssessmentDocuments = { ...ASSESSMENT }

  const customerType =
    customer?.type === SHAREHOLDER_TYPES.PERSON
      ? 'naturalPerson'
      : 'moralPerson'

  const { actions } = ONBOARDING_STATES.REASSESSMENT_DOCUMENTS_REVIEW
  const [requestCorrections, approveDocuments] = actions

  const validateDocs = () => {
    const reviewSchema = [
      ...AssessmentDocuments.generalInformation[customerType],
      ...AssessmentDocuments.financialInformation[customerType],
      ...AssessmentDocuments.mainAuctioneer[customerType],
    ]

    let arrayDocuments = reviewSchema
      .reduce((prevArray, documentsScheme) => {
        const documentsToReview = documents[documentsScheme.typeId] ?? []
        return [...prevArray, ...documentsToReview]
      }, [])
      .map((document) => {
        const allowStates = [FILE_STATES.ACCEPTED, FILE_STATES.REFUSED]

        return {
          ...document,
          status: allowStates.includes(document.status)
            ? document.status
            : FILE_STATES.REFUSED,
        }
      })

    const isAccepted = arrayDocuments.every(
      (itm) => itm.status === FILE_STATES.ACCEPTED
    )

    return { arrayDocuments, isAccepted }
  }

  const updateDocuments = async () => {
    setOpenModal(true)
    const { isAccepted } = validateDocs()
    let message = ''
    if (isAccepted) {
      message = `Todos los documentos fueron aceptados,
        el prospecto será enviado a "${ONBOARDING_STATES.BACKGROUND_REVIEW_REASSESSMENT.label}"`
    } else {
      message = `Algunos documentos NO fueron aceptados,
        el prospecto será enviado a "${ONBOARDING_STATES.REASSESSMENT.label}"`
    }
    setMessage(message)
  }

  const executeActionEvent = async () => {
    setIsLoadingSubmit(true)
    try {
      const { arrayDocuments, isAccepted } = validateDocs()
      let event

      const fileList = arrayDocuments.map((document) => ({
        id: document.id,
        status: document.status,
      }))

      const [userRole] = getUserRoles()

      const transitionData = {
        'fileList:multiUpdate': fileList,
        'id:status': customer.id,
        'id:assignation': customer.assignationId,
        'uuid:assignation': user.uid,
        'role:assignation': userRole,
        'customerId:assignation': customer.id,
      }

      if (isAccepted) {
        event = approveDocuments.event
        transitionData['wfState:status'] = approveDocuments.nextState
      } else {
        event = requestCorrections.event
        transitionData['wfState:status'] = requestCorrections.nextState
      }

      await ApiBuyerRepository.doReassessmentEvent(
        customer.externalId,
        event,
        transitionData
      )

      setAction(undefined)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      navigate(Router.Root)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  const onCancel = () => {
    setAction(undefined)
    navigate(-1)
  }

  /* Fetch saved files Effect */
  useEffect(() => {
    const fetchFiles = async () => {
      const { data: savedFilesData } = await getDocumentList(customerId, {
        status: `${FILE_STATES.PENDING},${FILE_STATES.ACCEPTED},${FILE_STATES.REFUSED},${FILE_STATES.DRAFT}`,
        stage: FILE_STAGES.ASSESSMENT,
      })
      let savedFiles = {}
      savedFilesData.forEach((itm) => {
        const extension = itm.fileExtension ? `.${itm.fileExtension}` : ''
        if (!savedFiles[itm.typeId]) savedFiles[itm.typeId] = []
        savedFiles[itm.typeId].push({
          name: `${itm.fileName}${extension}`,
          size: itm.fileSize,
          status: itm.fileStatus,
          id: itm.fileId,
        })
      })
      setDocuments(savedFiles)
    }
    if (customerId) fetchFiles()
  }, [customerId])

  if (!documents) {
    return <LoadingAnimation />
  }

  return (
    <ReviewDocumentsStyled>
      <h2 className='page-title'>Revision de documentos</h2>
      <BuildSection
        allowUpload={false}
        allowReview={true}
        isValidating={isLoadingSubmit}
        schema={AssessmentDocuments.generalInformation[customerType]}
        files={documents}
        setFiles={setDocuments}
        allowDelete={false}
        allowUpdate={false}
        title={AssessmentDocuments.generalInformation.title}
        setCommentBarInfo={setLateralBar}
      />

      <BuildSection
        allowUpload={false}
        isValidating={isLoadingSubmit}
        allowReview={true}
        schema={AssessmentDocuments.mainAuctioneer[customerType]}
        files={documents}
        setFiles={setDocuments}
        allowDelete={false}
        allowUpdate={false}
        title={AssessmentDocuments.mainAuctioneer.title}
        setCommentBarInfo={setLateralBar}
      />

      <BuildSection
        allowUpload={false}
        allowReview={true}
        allowDelete={false}
        allowUpdate={false}
        isValidating={isLoadingSubmit}
        schema={AssessmentDocuments.financialInformation[customerType]}
        files={documents}
        setFiles={setDocuments}
        title={AssessmentDocuments.financialInformation.title}
        setCommentBarInfo={setLateralBar}
      />
      <div className='btn-actions'>
        <Button buttonType='outline' onClick={onCancel}>
          Cancelar
        </Button>
        <Button
          buttonType='blueDark'
          disabled={isLoadingSubmit}
          onClick={updateDocuments}
        >
          {isLoadingSubmit && <LoadingAnimation className='loading' />}
          {!isLoadingSubmit && 'Enviar'}
        </Button>
      </div>
      {lateralBar && (
        <LateralBar
          setLateralBar={setLateralBar}
          fileId={lateralBar.fileId}
          documentName={lateralBar.fileName}
        />
      )}
      {openModal && (
        <ModalUpload
          setOpenModalUpload={setOpenModal}
          messages={{
            msg: message,
            msgButton: 'Confirmar',
          }}
          handleSave={executeActionEvent}
          isLoadingSubmit={isLoadingSubmit}
        />
      )}
    </ReviewDocumentsStyled>
  )
}

const ReviewDocumentsStyled = styled.div`
  padding: 2rem 3rem;

  .page-title {
    font-size: 24pt;
    margin-bottom: 10px;
  }
  .loading span {
    background-color: white;
  }

  .row {
    display: flex;
    align-items: center;

    .button-section {
      display: flex;
      margin-left: 5%;
      button {
        width: 50px;
        border-radius: 6px;
        &.checked {
          ${({ theme }) => theme.buttons.outline.hover}
        }
      }
      button + button {
        margin-left: 15px;
      }
    }
  }

  .btn-actions {
    position: relative;
    margin-top: 3rem;

    button:nth-child(2) {
      margin-left: 15px;
    }

    button:last-child {
      position: absolute;
      right: 1rem;
    }
  }
`

export default ReviewDocumentsReassessment
