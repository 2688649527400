import axios from 'axios'
import fileDownload from 'js-file-download'

/**
 * @param {string} url Link or URL where the document is hosted
 * @param {string} filename Final name with extension of the document after downloading
 * @param {boolean} newTab Open file in a new tab?
 */
export const downloadFile = async (url, filename) => {
  if (!filename) {
    window.open(url, '_blank')
  } else {
    const { data } = await axios.get(url, { responseType: 'blob' })
    fileDownload(data, filename)
  }
}

/**
 * @param {number} fileId ID of the file to download
 * @returns {Blob} File as a blob
 */
export const fetchFileBinary = async (fileId) => {
  const url = `/file.download?id=${fileId}`
  const { data } = await axios.get(url, { responseType: 'blob' })
  return data
}
