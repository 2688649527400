import { Button, Select } from '@keoworld/gbl-ui-kit'
import { useCallback, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'

import MaterialTable from 'components/react-table'
import RelationActions, {
  OPTIONS,
} from 'containers/customer-detail/relations/relation-actions'
import { CustomerContext } from 'providers/customer'
import { getCustomerLines } from 'services/lines'
import { getLineRelations } from 'services/relations'
import { formatCurrency } from 'utils/functions/handlers-currency'
import { LINE_STATES } from 'utils/schemas/line'
import { getCustomerInfo } from 'services/customer'

const MAX_ITEMS_PER_PAGE = 10

const COLUMNS_TABLE = [
  {
    accessorKey: 'providerName',
    header: 'Nombre Proveedor',
  },
  {
    accessorKey: 'score',
    header: 'Monto mensual autorizado',
    size: 300,
    enableColumnFilter: false,
  },
  {
    accessorKey: 'status',
    header: 'Estado de la relación',
    filterVariant: 'select',
    filterSelectOptions: [
      { text: 'Pendiente', value: 'Pending' },
      { text: 'Activo', value: 'Active' },
      { text: 'Rechazado', value: 'Rejected' },
      { text: 'Cancelado', value: 'Cancelled' },
      { text: 'Inactivo', value: 'Inactive' },
      { text: 'Error', value: 'Error' },
      { text: 'Reassessment', value: 'Reassessment' },
    ],
    size: 250,
  },
]

const COLUMNS_TABLE_SUPPLEMENTARY = [
  {
    accessorKey: 'providerName',
    header: 'Nombre Proveedor',
  },
  {
    accessorKey: 'providerNid',
    header: 'RFC',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'status',
    header: 'Estado de la relación',
    filterVariant: 'select',
    filterSelectOptions: [
      { text: 'Pendiente', value: 'Pending' },
      { text: 'Activo', value: 'Active' },
      { text: 'Rechazado', value: 'Rejected' },
      { text: 'Cancelado', value: 'Cancelled' },
      { text: 'Inactivo', value: 'Inactive' },
      { text: 'Error', value: 'Error' },
      { text: 'Reassessment', value: 'Reassessment' },
    ],
  },
]

const RelationManager = () => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: MAX_ITEMS_PER_PAGE,
  })
  const [columnFilters, setColumnFilters] = useState([
    { id: 'status', value: '' },
  ])
  const [lines, setLines] = useState({
    isFetching: true,
    data: [],
  })
  const [relations, setRelations] = useState({
    isFetching: true,
    data: [],
    fullCount: 0,
  })
  const [lineSelected, setLineSelected] = useState(undefined)
  const [relation, setRelation] = useState()

  const { customer, setCustomer } = useContext(CustomerContext)

  const fetchRelations = useCallback(
    async (lineId) => {
      setRelations((prev) => ({ ...prev, isFetching: true }))

      const timeoutId = setTimeout(async () => {
        const filteredColumnFilters = columnFilters.filter(
          (options) => options.value !== ''
        )

        const { relations, fullCount } = await getLineRelations({
          lineId,
          size: pagination.pageSize,
          page: pagination.pageIndex + 1,
          columnFilters: filteredColumnFilters,
        })

        setRelations({ isFetching: false, data: relations, fullCount })
      }, 4000)

      return () => clearTimeout(timeoutId)
    },
    [pagination.pageSize, pagination.pageIndex, columnFilters]
  )

  useEffect(() => {
    const obtainLines = async () => {
      setLines({ isFetching: true, data: [] })

      const { data: lineStatements } = await getCustomerLines(customer.id)

      const customerLines = lineStatements.filter((line) =>
        [LINE_STATES.ACTIVE, LINE_STATES.FROZEN].includes(line.status)
      )

      setLines({ isFetching: false, data: customerLines })
    }

    if (customer.id) {
      obtainLines()
    }
  }, [customer])

  useEffect(() => {
    if (lines.data.length > 0 && lineSelected) {
      fetchRelations(lineSelected.lineId)
    }
  }, [fetchRelations, lineSelected, lines.data])

  /* Fetch customer info effect*/
  useEffect(() => {
    const fetchBuyer = async () => {
      const { data: buyer } = await getCustomerInfo(customer?.id)
      setCustomer((prev) => ({ ...prev, ...buyer }))
    }
    if (customer?.id) fetchBuyer()
  }, [customer?.id, setCustomer])

  return (
    <Styles>
      {relation && (
        <RelationActions
          line={lineSelected}
          action={relation.action}
          closeLateral={() => {
            setRelation(undefined)
          }}
          relation={relation.data}
          setAction={(action) => {
            setRelation({ ...relation, action })
          }}
          fetchRelations={fetchRelations}
        />
      )}

      <Select
        className='lines'
        label='Lineas'
        value={lineSelected?.lineId ?? ''}
        onChange={(e) => {
          const selected = e.target.value
          const line = lines.data.find(
            ({ lineId }) => lineId === Number(selected)
          )
          setLineSelected(line)
          setRelation(undefined)
        }}
      >
        <option value='' disabled>
          Seleccione una linea
        </option>
        {lines.data.map(({ lineId, score }, index) => (
          <option key={`line-${lineId}-${index}`} value={lineId}>
            {lineId} - Monto: {formatCurrency(score)}
          </option>
        ))}
      </Select>

      {/* {lineSelected && (
        <section className='amounts'>
          <article className='amount'>
            <div bold='true'>Monto autorizado</div>
            <div>{formatCurrency(customer?.authorizedAmount)}</div>
          </article>
          <article className='amount'>
            <div bold='true'>Monto utilizado</div>
            <div>{formatCurrency(customer?.usedAmount)}</div>
          </article>
          <article className='amount'>
            <div bold='true'>Monto disponible</div>
            <div>{formatCurrency(customer?.availableAmount)}</div>
          </article>
        </section>
      )} */}

      {lineSelected && (
        <section>
          <section className='head-relations'>
            <h3>Relaciones asociadas</h3>
            <section className='actions'>
              <Button
                device='mobileLight'
                buttonType='outlineBlueDark'
                onClick={() =>
                  setRelation({
                    action: OPTIONS.CREATE_PROVIDER,
                    data: undefined,
                  })
                }
              >
                Crear Proveedor
              </Button>
              <Button
                buttonType='outline'
                device='mobileLight'
                onClick={() => {
                  const isSupplementaryCard = lineSelected.hasPrimaryCard
                  let createAction = OPTIONS.CREATE_RELATION

                  if (isSupplementaryCard)
                    createAction = OPTIONS.CREATE_RELATION_SUPPLEMENTARY_CARD

                  setRelation({
                    action: createAction,
                    data: undefined,
                  })
                }}
              >
                Crear Relación
              </Button>
            </section>
          </section>

          <MaterialTable
            columns={
              lineSelected.hasPrimaryCard
                ? COLUMNS_TABLE_SUPPLEMENTARY
                : COLUMNS_TABLE
            }
            dataRows={relations.data}
            actions={[
              {
                key: 'ver',
                label: 'ver',
                exec: (relation) => {
                  let action = OPTIONS.VIEW
                  if (lineSelected.hasPrimaryCard) {
                    relation.isSupplementaryCard = true
                    action = OPTIONS.VIEW_SUPPLEMENTARY_CARD
                  }
                  setRelation({ action, data: relation })
                },
              },
            ]}
            pagination={pagination}
            setPagination={setPagination}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            rowCount={relations.fullCount}
            isLoading={relations.isFetching}
          />
        </section>
      )}
    </Styles>
  )
}

const Styles = styled.main`
  padding: 30px 70px;

  .lines {
    margin-bottom: 20px;
    width: 350px;
  }

  .amounts {
    display: flex;
    margin-bottom: 30px;
    .amount {
      width: 200px;
    }
  }
  .head-relations {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2em;
    .actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }
  }
`

export default RelationManager
