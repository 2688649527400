import styled from 'styled-components'
import { CUSTOMER_STAGES } from 'utils/schemas/customer'

const buttons = Object.entries(CUSTOMER_STAGES).map(([value, { label }]) => {
  return { label, value }
})

const StageFilter = ({ stage, setStage }) => {
  return (
    <StageFilterStyled>
      <button
        className={`filter ${!stage ? 'selected' : ''}`}
        onClick={() => setStage(undefined)}
      >
        Todos
      </button>
      {buttons.slice(0, buttons.length - 1).map((object, index) => {
        const { label, value } = object

        const onHandleClick = () => setStage(value)

        return (
          <button
            key={`btn-${index}`}
            className={`filter ${stage === value ? 'selected' : ''}`}
            onClick={onHandleClick}
          >
            {label}
          </button>
        )
      })}
    </StageFilterStyled>
  )
}

const StageFilterStyled = styled.div`
  width: 100%;
  height: 50px;

  display: flex;
  box-shadow: 0px 4px 10px rgba(21, 50, 101, 0.11);
  ${({ theme }) => theme.colors.grayShades[100]};

  button {
    border: none;
    position: relative;
    background-color: transparent;
    cursor: pointer;
    padding: 0 0.8rem;
    padding-top: 1rem;

    &.filter.selected::after {
      content: '';
      position: absolute;
      left: 11px;
      bottom: 0.1rem;

      background-color: ${({ theme }) =>
        theme.colors.grayShades[700].backgroundColor};

      width: 30%;
      height: 3px;
    }
  }
`

export default StageFilter
