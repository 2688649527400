import { Button, LoadingAnimation, Select } from '@keoworld/gbl-ui-kit'
import LateralBar from 'containers/lateral-bar'
import { CustomerContext } from 'providers/customer'
import { LateralBarContext, LATERAL_MENU_OPTIONS } from 'providers/lateral-bar'
import { ModalContext } from 'providers/modal'
import { useContext, useEffect, useState } from 'react'
import { getDocumentList } from 'services/documents'
import { instanceTransition } from 'services/workflow'
import styled from 'styled-components'
import {
  CUSTOMER_STATES,
  CUSTOMER_SUPPLIER_SOURCES,
  CUSTOMER_SUPPLIER_SOURCE_OPTIONS,
} from 'utils/schemas/customer'
import {
  CONTRACT_DOCUMENTS,
  CONTRACT_STATES,
  FILE_STAGES,
  FILE_STATES,
} from 'utils/schemas/documents'
import { LINE_STATES } from 'utils/schemas/line'
import { useFetchContract } from 'utils/hooks/fetch-contract'
import { useAuth } from 'utils/hooks/auth'
import { BuildSection } from '../document-actions/build-section'
import ModalUpload from 'containers/Modal/modal-documents-upload'
import { AlertContext } from 'providers/alert'

const configSupplierSource = {
  [CUSTOMER_SUPPLIER_SOURCES.CALLAO]: 'SEND',
  [CUSTOMER_SUPPLIER_SOURCES.KEO]: 'SEND_KEO',
}

const ContractReview = () => {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [files, setFiles] = useState({})
  const [commentInfo, setCommentInfo] = useState(false)
  const [isValidating, setIsValidating] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [messageModal, setMessageModal] = useState('')
  const [supplierSourceInput, setSupplierSourceInput] = useState(
    CUSTOMER_SUPPLIER_SOURCES.KEO
  )
  const { setAction, setSelectedOption } = useContext(LateralBarContext)
  const { customer } = useContext(CustomerContext)
  const { setActions } = useContext(ModalContext)
  const { user } = useAuth()
  const { setAlert } = useContext(AlertContext)
  const contractInfo = useFetchContract({
    customerId: customer?.id,
    state: CONTRACT_STATES.Instrumentalization,
  })

  const handleSupplierInputSwitch = ({ target }) =>
    setSupplierSourceInput(target?.value)

  const validateDocs = () => {
    const arrayDocuments = Object.values(files).flat()
    arrayDocuments.forEach((document) => {
      document.status =
        document.status !== FILE_STATES.ACCEPTED
          ? FILE_STATES.REFUSED
          : FILE_STATES.ACCEPTED
    })
    const allAccepted = arrayDocuments.every(
      (itm) => itm.status === FILE_STATES.ACCEPTED
    )
    return { arrayDocuments, allAccepted }
  }

  const handleShowModal = async () => {
    const msgs = {
      ACCEPTED: `Todos los documentos fueron aceptados, el prospecto será enviado a ${CUSTOMER_STATES.line_approved.label}`,
      NOT_ACCEPTED: `Algunos documentos NO fueron aceptados, el prospecto será enviado a ${CUSTOMER_STATES.line_contractCorrectionRequested.label}`,
    }
    const { allAccepted } = validateDocs()
    setOpenModal(true)
    if (allAccepted) setMessageModal(msgs.ACCEPTED)
    else setMessageModal(msgs.NOT_ACCEPTED)
  }

  const handleSubmit = async () => {
    setIsLoadingSubmit(true)
    setIsValidating(true)
    const transition = CUSTOMER_STATES.line_signedDocsProvided.transition
    try {
      const { arrayDocuments, allAccepted } = validateDocs()
      const multiUpdateIds = arrayDocuments.map((file) => ({
        id: file.id,
        status: file.status,
        typeId: file.typeId,
      }))

      let transitionObject = {}
      const acceptanceConfig = configSupplierSource[supplierSourceInput]
      if (allAccepted)
        transitionObject = {
          EVENT: transition[acceptanceConfig].event,
          nextState: transition[acceptanceConfig].nextState,
          nextStage: FILE_STAGES.READY,
          contractNewState: CONTRACT_STATES.Active,
          notificationReceiverRoles:
            transition[acceptanceConfig]?.notificationReceiverRoles,
          notificationReceiverRoles2:
            transition[acceptanceConfig]?.notificationReceiverRoles2 ?? [],
        }
      else
        transitionObject = {
          EVENT: transition.REJECT.event,
          nextState: transition.REJECT.nextState,
          nextStage: FILE_STAGES.INSTRUMENTATION,
          contractNewState: CONTRACT_STATES.Instrumentalization,
          notificationReceiverRoles:
            transition?.REJECT?.notificationReceiverRoles,
          notificationReceiverRoles2: [],
        }

      await instanceTransition(
        customer?.lineInstance?.workflowId,
        `${customer?.customerInstance?.instanceId}.${customer?.lineInstance?.instanceId}`,
        transitionObject.EVENT,
        {
          'contractId:contractUpdate': contractInfo?.id,
          'status:contractUpdate': transitionObject.contractNewState,
          'fileList:multiUpdate': multiUpdateIds,
          id: customer?.id,
          'wfState:status': transitionObject.nextState,
          'wfStage:status': transitionObject.nextStage,
          'tradePlatform:status': supplierSourceInput,
          'status:status': LINE_STATES.ACTIVE,
          'id:lineStatus': customer?.lineId,
          'id:lineRollback': customer?.lineId,
          'status:lineStatus': LINE_STATES.ACTIVE,
          'status:lineRollback': LINE_STATES.OFFER,
          'customerId:financeFind': customer?.id,
          'lineId:lineConditionInfo': customer?.lineId,
          senderUidFed: user.uid,
          'receiverUidIam:notificationOne': customer?.assignations?.find(
            (itm) =>
              transitionObject.notificationReceiverRoles.includes(itm?.role)
          )?.iamUid,
          'customerId:notificationOne': customer.id,
          'receiverUidIam:notificationOne2': customer?.assignations?.find(
            (itm) =>
              transitionObject.notificationReceiverRoles2.includes(itm?.role)
          )?.iamUid,
          'customerId:notificationOne2': customer.id,
          'customerId:notificationMany': customer.id,
        }
      )
      setAction(false)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
    } catch (err) {
      console.error('SubmitError', err, err?.status)
      setAlert({
        title: 'Error',
        label:
          'Ocurrió un error al activar el prospecto. Por favor, ingrese al dashboard de clientes y verifique el alta del mismo. De no ver el cambio, comuniquese con el Area de Soporte',
        type: 'error',
      })
      setAction(false)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  /* Fetch files effect */
  useEffect(() => {
    const fetchFiles = async () => {
      const { data: filesData } = await getDocumentList(customer.id, {
        stage: FILE_STAGES.INSTRUMENTATION,
      })
      let contractFiles = {}
      filesData.forEach((itm) => {
        const extension = itm.fileExtension ? `.${itm.fileExtension}` : ''
        if (!contractFiles[itm.typeId]) contractFiles[itm.typeId] = []
        contractFiles[itm.typeId].push({
          name: `${itm.fileName}${extension}`,
          size: itm.fileSize,
          status: itm.fileStatus,
          id: itm.fileId,
          typeId: itm.typeId,
        })
      })
      setFiles(contractFiles)
    }
    if (customer?.id) fetchFiles()
  }, [customer?.id])

  /* Setup modal effect */
  useEffect(() => {
    setActions([
      undefined,
      () => {
        setAction(false)
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      },
    ])
  }, [setActions, setAction, setSelectedOption])

  return (
    <ContractContainerStyled>
      <div>
        <h5>Documentos de contrato</h5>
        <BuildSection
          allowUpload={false}
          allowReview={true}
          allowDelete={false}
          allowUpdate={false}
          schema={CONTRACT_DOCUMENTS.generalInformation.documents}
          files={files}
          setFiles={setFiles}
          isValidating={isValidating}
          setCommentBarInfo={setCommentInfo}
        />
      </div>
      <div className='container'>
        <h4>Seleccionar flujo de customer:</h4>
        <label className='source-selector'>
          <Select
            name='supplierSource'
            label='Flujo de customer'
            value={supplierSourceInput}
            onChange={(e) => handleSupplierInputSwitch(e)}
          >
            {CUSTOMER_SUPPLIER_SOURCE_OPTIONS.map((itm, idx) => (
              <option value={itm.value} key={`opt-src-${idx}`}>
                {itm.label}
              </option>
            ))}
          </Select>
        </label>
      </div>
      <div className='action-group'>
        <Button buttonType='grayButton' onClick={() => setAction(false)}>
          Cancelar
        </Button>
        <Button
          buttonType='green'
          className='send'
          disabled={isLoadingSubmit}
          onClick={() => handleShowModal()}
        >
          {isLoadingSubmit ? (
            <LoadingAnimation className='loading' />
          ) : (
            'Enviar'
          )}
        </Button>
      </div>
      {commentInfo && (
        <LateralBar
          setlateralBar={setCommentInfo}
          fileId={commentInfo.fileId}
          documentName={commentInfo.fileName}
        />
      )}
      {openModal && (
        <ModalUpload
          setOpenModalUpload={setOpenModal}
          messages={{
            msg: messageModal,
            msgButton: 'Confirmar',
          }}
          handleSave={handleSubmit}
          isLoadingSubmit={isLoadingSubmit}
        />
      )}
    </ContractContainerStyled>
  )
}

const ContractContainerStyled = styled.div`
  padding: 2rem;
  padding-bottom: 1rem;
  .loading span {
    background-color: white;
  }
  h3 {
    margin-top: 1rem;
  }
  h4 {
    font-weight: bold;
    margin-top: 1rem;
  }
  .container {
    margin: 0 0.5rem;
  }
  .proposal {
    display: flex;
    justify-content: space-between;
    p {
      font-weight: bold;
    }
    .input-section {
      display: flex;
      justify-content: center;
      align-items: center;
      .grey {
        ${({ theme }) => theme.colors.grayShades[300]};
        border-radius: 6px;
        height: 39px;
        margin-right: 1rem;
        text-align: center;
        width: 50px;
        p {
          margin: 0.5rem auto;
        }
      }
      .input {
        max-width: 200px;
      }
    }
  }
  .action-group {
    margin: 2rem 0.5rem 0;

    button + button {
      margin-left: 28px;
    }
    .send {
      float: right;
    }
  }
  .file-row {
    display: flex;
    align-items: center;

    .button-section {
      display: flex;
      margin-left: 5%;
      button {
        width: 50px;
        border-radius: 6px;
        &.checked {
          ${({ theme }) => theme.buttons.outline.hover}
        }
      }
      button + button {
        margin-left: 15px;
      }
    }
  }
  .source-selector {
    display: flex;
  }
`

export default ContractReview
