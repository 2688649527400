/* eslint-disable react-hooks/exhaustive-deps */
import { Skeleton } from '@mui/material'
import MaterialTable from 'components/react-table'
import LateralBar from 'containers/lateral-bar'
import CancelPayments from 'containers/payments/cancel-payments'
import PaymentPopup from 'containers/payments/payment-popup'
import PaymentRegister from 'containers/payments/payment-register'
import { AlertContext } from 'providers/alert'
import { CustomerContext } from 'providers/customer'
import { useCallback, useContext, useEffect, useState } from 'react'
import { getLoans } from 'services/loan'
import styled from 'styled-components'
import { dateFormatFromIso } from 'utils/functions/date'
import { currency } from 'utils/functions/formatters'
import { useDebounce } from 'utils/hooks/debounce'
import { useFetchBuyerAmountsAssigned } from 'utils/hooks/fetch/useFetchBuyerAmountsAssigned'
import { ApiError } from 'utils/schemas/errors'
import { PORTFOLIO_ACTIONS } from 'utils/schemas/portfolio'

const MAX_ITEMS_PER_PAGE = 5

const productName = {
  GROSS_PAYMENT: 'Gross payment',
  TDC: 'TDC',
  INPAY: 'In-Pay',
}

const loanStatus = {
  VIG: 'Vigente',
  VNC: 'Vencido',
  LIQ: 'Liquidado',
  FRZ: 'Congelado',
  WRO: 'Write Off',
  PND: 'Pendiente',
}

const relationStatus = {
  Pending: 'Pendiente',
  Active: 'Activo',
  Rejected: 'Rechazado',
  Cancelled: 'Cancelado',
  Inactive: 'Inactivo',
  Error: 'Error',
  Reassessment: 'Reassessment',
}

const LOAN_DETAILS = [
  {
    accessorKey: 'relationId',
    header: 'Id Relación',
  },
  {
    accessorKey: 'lineId',
    header: 'Id Linea',
  },
  {
    accessorKey: 'id',
    header: 'Id Préstamo',
  },
  {
    accessorKey: 'products',
    header: 'Productos',
    filterVariant: 'select',
    filterSelectOptions: [
      { text: 'Gross payment', value: 1 },
      { text: 'TDC', value: 2 },
      { text: 'In-Pay', value: 3 },
    ],
    Cell: ({ cell }) => {
      const products = cell.getValue()
      return products.map((product) => (
        <li key={`product-${product.id}`}>{productName[product.name]}</li>
      ))
    },
  },
  {
    accessorKey: 'relationStatus',
    header: 'Estado de la relación',
    filterVariant: 'select',
    filterSelectOptions: [
      { text: 'Pendiente', value: 'Pending' },
      { text: 'Activo', value: 'Active' },
      { text: 'Rechazado', value: 'Rejected' },
      { text: 'Cancelado', value: 'Cancelled' },
      { text: 'Inactivo', value: 'Inactive' },
      { text: 'Error', value: 'Error' },
      { text: 'Reassessment', value: 'Reassessment' },
    ],
    size: 250,
    Cell: ({ cell }) => {
      const value = cell.getValue()
      return relationStatus[value] ?? 'N/A'
    },
  },
  {
    accessorKey: 'status',
    header: 'Estado',
    filterVariant: 'select',
    filterSelectOptions: [
      { text: 'Vigente', value: 'VIG' },
      { text: 'Vencido', value: 'VNC' },
      { text: 'Congelado', value: 'FRZ' },
      { text: 'Write Off', value: 'WRO' },
      { text: 'Pendiente', value: 'PND' },
    ],
    Cell: ({ cell }) => {
      const value = cell.getValue()
      return loanStatus[value] ?? 'N/A'
    },
  },
  {
    accessorFn: (rowData) => new Date(rowData.createdAt),
    accessorKey: 'createdAt',
    header: 'Fecha',
    filterVariant: 'date-range',
    Cell: ({ cell }) => dateFormatFromIso(cell.getValue(), 'dd/MM/yyyy'),
  },
  {
    accessorKey: 'debt',
    header: 'Deuda',
    accessorFn: (rowData) => currency(rowData.debt),
  },
  {
    accessorKey: 'amount',
    header: 'Monto prestado',
    accessorFn: (rowData) => currency(rowData.amount),
  },
]

const RecordPayment = () => {
  const { setAlert } = useContext(AlertContext)
  const { customer } = useContext(CustomerContext)
  const [loanSelected, setLoanSelected] = useState()
  const [action, setAction] = useState(PORTFOLIO_ACTIONS.loanList)
  const [popUpConfig, setPopUpConfig] = useState({})
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: MAX_ITEMS_PER_PAGE,
  })
  const [columnFilters, setColumnFilters] = useState([])
  const [data, setData] = useState([])
  const [rowCount, setRowCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [isRefetching, setIsRefetching] = useState(false)
  const pageIndex = useDebounce(pagination.pageIndex, 400)

  const [assignedAmount, isFetchingAmountsAssigned] =
    useFetchBuyerAmountsAssigned(customer?.id)

  const fetchLoans = useCallback(async () => {
    try {
      if (!customer?.id || isRefetching) return

      setIsRefetching(true)

      const filters = columnFilters.reduce((acc, curr) => {
        const { id, value } = curr
        if (id === 'createdAt' && Array.isArray(curr.value)) {
          const [from, to] = value
          return { ...acc, from, to }
        }
        return { ...acc, [id]: value }
      }, {})

      const { count, loans } = await getLoans({
        customerId: customer.id,
        numberOfItems: pagination.pageSize,
        page: pageIndex + 1,
        ...filters,
      })

      setRowCount(count)
      setData(loans)
    } catch (error) {
      let alert = {
        type: 'error',
        title: '¡Ups, algo salió mal!',
        label:
          'Ocurrió un error al obtener la información. Por favor contacte con el soporte',
      }
      if (error instanceof ApiError) {
        alert.type = error.statusCode === 400 ? 'warning' : 'error'
        alert.title = error.title
        alert.label = error.message
      }
      setAlert(alert)
    } finally {
      setIsLoading(false)
      setIsRefetching(false)
    }
  }, [columnFilters, customer?.id, pagination.pageSize, pageIndex])

  const showLoanList = async () => {
    setLoanSelected(undefined)
    setPopUpConfig({})
    setAction(PORTFOLIO_ACTIONS.loanList)
    await fetchLoans()
  }

  useEffect(() => {
    fetchLoans()
  }, [fetchLoans])

  if (loanSelected && action === PORTFOLIO_ACTIONS.paymentRegister) {
    return (
      <PaymentRegister
        loan={loanSelected}
        onHandleClose={() => showLoanList()}
        showCondonationModal={(payment) => {
          setPopUpConfig({
            payment,
            action: PORTFOLIO_ACTIONS.paymentCondonation,
          })
          setAction(PORTFOLIO_ACTIONS.showPopUp)
        }}
      />
    )
  }

  if (loanSelected && action === PORTFOLIO_ACTIONS.cancelPayments) {
    return (
      <CancelPayments
        loan={loanSelected}
        callback={() => fetchLoans()}
        onClose={() => showLoanList()}
        setAction={(payments) => {
          setPopUpConfig({ payments, action: PORTFOLIO_ACTIONS.cancelPayments })
          setAction(PORTFOLIO_ACTIONS.showPopUp)
        }}
      />
    )
  }

  return (
    <RecordPaymentStyled>
      <h3>Resumen de cliente</h3>

      {action === PORTFOLIO_ACTIONS.showPopUp && loanSelected && (
        <PaymentPopup
          loan={loanSelected}
          config={popUpConfig}
          onClose={showLoanList}
        />
      )}

      {isFetchingAmountsAssigned && (
        <Skeleton variant='rounded' height={85} width={700} />
      )}
      {!isFetchingAmountsAssigned && (
        <div className='balance'>
          <div className='content'>
            <div className='dot c-green' />
            <div>
              <div className='b-title'>Monto autorizado</div>
              <div>MXN {currency(assignedAmount.requestedAmount)}</div>
            </div>
          </div>
          <div className='content'>
            <div className='dot c-red' />
            <div>
              <div className='b-title'>Monto disponible</div>
              <div>MXN {currency(assignedAmount.availableAmount)}</div>
            </div>
          </div>
          <div className='content'>
            <div className='dot c-blue-d' />
            <div>
              <div className='b-title'>Monto utilizado</div>
              <div>MXN {currency(assignedAmount.usedAmount)}</div>
            </div>
          </div>
        </div>
      )}

      <h3>Préstamos activos</h3>
      <MaterialTable
        columns={LOAN_DETAILS}
        dataRows={data}
        actions={[
          {
            key: 'ver',
            label: 'Pagar',
            disabled: (loan) => loan.status === 'PND',
            exec: (loan) => {
              if (loan.status !== 'PND') {
                setIsLoading(false)
                setLoanSelected(loan)
                setAction(PORTFOLIO_ACTIONS.paymentRegister)
              }
            },
          },
          {
            key: 'cartera',
            label: 'Cartera',
            display: (loan) => loan.status !== 'WRO' && loan.status !== 'PND',
            exec: (loan) => {
              if (loan.status !== 'WRO' && loan.status !== 'PND') {
                setIsLoading(false)
                setLoanSelected(loan)
                setAction(PORTFOLIO_ACTIONS.openLateralBar)
              }
            },
          },
        ]}
        pagination={pagination}
        setPagination={setPagination}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        rowCount={rowCount}
        isLoading={isLoading}
        isRefetching={isRefetching}
      />
      {action === PORTFOLIO_ACTIONS.openLateralBar && (
        <LateralBar
          type={'PORTFOLIO'}
          loan={loanSelected}
          callback={(action, config) => {
            setAction(action)
            if (config) setPopUpConfig(config)
          }}
          onClose={() => showLoanList()}
        />
      )}
    </RecordPaymentStyled>
  )
}

const RecordPaymentStyled = styled.div`
  padding: 2rem 70px;

  .title-data {
    position: absolute;
  }

  .c-green {
    background-color: #29ca41;
  }
  .c-red {
    background-color: #ff0045;
  }
  .c-blue-d {
    background-color: #00172d;
  }

  .balance {
    display: flex;
    width: 700px;
    align-items: center;
    justify-content: space-around;
    background-color: #f6f8fc;
    border-radius: 10px;
    margin-top: 1rem;
    padding: 1rem 0;

    .dot {
      content: '';
      display: block;
      width: 15px;
      height: 15px;
      border-radius: 50%;

      position: absolute;
      top: 2px;
      left: -1.5rem;
    }

    .content {
      position: relative;
    }

    .b-title {
      font-weight: bold;
    }
  }

  table {
    text-align: left;
    thead tr {
      border-color: transparent;

      th {
        min-width: 7rem;
        padding: 0 0.5rem;
      }
    }

    tbody {
      tr {
        height: 3rem;
        border: solid;
        border-width: 1px 0;
        border-color: #e5e5e5;
      }

      td {
        padding: 0 0.5rem;

        &.products ul {
          padding: 0 0.5rem;
          margin: 0;
        }
      }

      .skeleton {
        height: 2rem;
      }
    }

    .td-actions {
      display: flex;
      gap: 5px;
      margin-top: 5px;
      border-color: transparent;

      button {
        height: 2rem;
        width: 7rem;
      }
    }
  }
  h3 {
    margin: 1rem auto;
  }
`

export default RecordPayment
