import { INPUT_TYPES, VALIDATOR_TYPES } from 'containers/form'

export const RELATION_STATES = {
  PENDING: 'Pending',
  ACTIVE: 'Active',
  REJECTED: 'Rejected',
  DEBT: 'Debt',
  CANCELLED: 'Cancelled',
  REASSESSMENT: 'Reassessment',
  INACTIVE: 'Inactive',
}

export const PROVIDER_SCHEMA = [
  {
    inputType: INPUT_TYPES.SELECT,
    name: 'nidType',
    label: 'Tipo de documento',
    options: [{ value: 'RFC', label: 'RFC' }],
  },
  {
    name: 'nid',
    label: 'Número de documento',
    validatorType: VALIDATOR_TYPES.RFC,
  },
  {
    name: 'name',
    label: 'Nombre',
    validatorType: VALIDATOR_TYPES.TEXT,
  },
  {
    name: 'externalId',
    label: 'External ID',
    validatorType: VALIDATOR_TYPES.ANY,
    required: false,
  },
  {
    inputType: INPUT_TYPES.SELECT,
    name: 'source',
    label: 'Fuente',
    options: [{ value: 'Keo', label: 'Keo' }],
  },
]

export const RELATION_SCHEMA = [
  {
    inputType: INPUT_TYPES.SELECT,
    name: 'source',
    label: 'Fuente',
    options: [
      { label: 'Keo', value: 'Keo' },
      { label: 'Callao', value: 'Callao' },
    ],
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'lineId',
    label: 'Line ID',
    disabled: true,
  },
  {
    inputType: INPUT_TYPES.CURRENCY,
    name: 'score',
    label: 'Monto autorizado mensual',
    placeholder: 'Escriba el monto autorizado mensual',
    validatorType: VALIDATOR_TYPES.CURRENCY,
    errorMessage: 'Ingrese el monto autorizado mensual',
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'days',
    label: 'Días',
    placeholder: 'Escriba los días',
    validatorType: VALIDATOR_TYPES.NUMBER_VALIDATION,
    validatorOptions: { min: 0, max: 100 },
    errorMessage: 'Ingrese los días',
  },
  {
    inputType: INPUT_TYPES.SELECT,
    name: 'dueFromApproval',
    label: 'Días pactados desde',
    options: [
      { label: 'Días pactados desde la emisión', value: '0' },
      { label: 'Días pactados desde la aprobación', value: '1' },
    ],
  },
  {
    inputType: INPUT_TYPES.PERCENTAGE,
    name: 'percentageReceiver',
    label: 'Porcentaje de cobro',
    placeholder: 'Escriba el porcentaje de cobro',
    validatorType: VALIDATOR_TYPES.PERCENTAGE,
    errorMessage: 'Ingrese el porcentaje de cobro',
  },
]

export const RELATION_TS_SCHEMA = [
  {
    inputType: INPUT_TYPES.SELECT,
    name: 'source',
    label: 'Fuente',
    options: [
      { label: 'Keo', value: 'Keo' },
      { label: 'Callao', value: 'Callao' },
    ],
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'lineId',
    label: 'Line ID',
    disabled: true,
  },
]

export const RELATION_TS_CALLAO_SCHEMA = [
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'days',
    label: 'Días',
    placeholder: 'Escriba los días',
    //validatorType: VALIDATOR_TYPES.OPTIONAL_NUMBER,
    errorMessage: 'Ingrese los días',
  },
  {
    inputType: INPUT_TYPES.SELECT,
    name: 'dueFromApproval',
    label: 'Días pactados desde',
    //validatorType: VALIDATOR_TYPES.OPTIONAL_NUMBER,
    options: [
      { label: 'Días pactados desde la emisión', value: '0' },
      { label: 'Días pactados desde la aprobación', value: '1' },
    ],
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'percentageReceiver',
    label: 'Porcentaje de cobro',
    placeholder: 'Escriba el porcentaje de cobro',
    //validatorType: VALIDATOR_TYPES.OPTIONAL_NUMBER,
    errorMessage: 'Ingrese el porcentaje de cobro',
  },
]

export const PAYMENT_COMMENT_CATEGORIES = {
  Condonation: { label: 'Condonación', color: '#751644' },
  Annulled: { label: 'Anulación', color: '#00172d' },
}
