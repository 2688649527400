import { Button, Icon, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import LateralBar from 'containers/lateral-bar'
import { CustomerContext } from 'providers/customer'
import axios from 'axios'
import { LateralBarContext, LATERAL_MENU_OPTIONS } from 'providers/lateral-bar'
import { useContext, useEffect, useState } from 'react'
import {
  downloadDocument,
  getDocumentList,
  uploadDocuments,
} from 'services/documents'
import { instanceTransition } from 'services/workflow'
import styled from 'styled-components'
import { downloadFile } from 'utils/functions/download-file'
import { getUserRoles } from 'utils/functions/role-manager'
import { useAuth } from 'utils/hooks/auth'
import { CUSTOMER_STATES } from 'utils/schemas/customer'
import {
  CONTRACT_DOCUMENTS,
  FILE_STAGES,
  FILE_STATES,
  CONTRACT_STATES,
} from 'utils/schemas/documents'
import { GeneratedFilesStyled } from './generated-files-styles'
import { useFetchContract } from 'utils/hooks/fetch-contract'
import { AlertContext } from 'providers/alert'
import { BuildSection } from '../document-actions/build-section'

const CONTRACT_TYPE_ID = 30

const ContractUpload = () => {
  const [files, setFiles] = useState({})
  const [generatedFiles, setGeneratedFiles] = useState([])
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [commentInfo, setCommentInfo] = useState(false)
  const [isValidating, setIsValidating] = useState(false)
  const { setAlert } = useContext(AlertContext)
  const { customer } = useContext(CustomerContext)
  const { setAction, setSelectedOption } = useContext(LateralBarContext)
  const { user } = useAuth()
  const contractInfo = useFetchContract({
    customerId: customer?.id,
    state: CONTRACT_STATES.Instrumentalization,
  })

  const handleUpload = async () => {
    const userRole = getUserRoles()[0]
    const response = await uploadDocuments(
      files,
      customer.id,
      user.uid,
      userRole
    )
    if (response.some((request) => request.status !== 'fulfilled')) {
      throw new Error('error saving files')
    }
    return response.map((request) => request.value)
  }

  const handleSave = async () => {
    try {
      setIsLoadingSubmit(true)
      await handleUpload()
      setAction(false)
   } catch (error) {
      console.error(error.message)
      const alert = {
        label:
          'Ha ocurrido un error al guardar los archivos, por favor intenta de nuevo',
        title: 'Error',
        type: 'error',
      }
       if(error.message === 'error saving files'){
        setAction(false)
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
       }
       setAlert(alert)
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  const handleSubmit = async () => {
    try {
      setIsLoadingSubmit(true)
      setIsValidating(true)

      const transition =
        CUSTOMER_STATES[customer?.lineInstance?.currentState].transition

      const hasErrors = CONTRACT_DOCUMENTS.generalInformation.documents.some(
        ({ typeId }) => {
          const documents = files[typeId] ?? []
          if (typeId === 35 && documents.length === 0) return false

          if (documents.length === 0) return true

          return documents.some(
            (document) => document.status === FILE_STATES.REFUSED
          )
        }
      )

      if (hasErrors) {
        throw new Error('file-list-error')
      }

      const uploadedIds = await handleUpload()

      const multiUploadIds = uploadedIds.map(({ id, status }) => ({
        id,
        status: [FILE_STATES.DELETED, FILE_STATES.ACCEPTED].includes(status)
          ? status
          : FILE_STATES.PENDING,
      }))

      let body = {
        'contractId:contractUpdate': contractInfo?.id,
        'status:contractUpdate': CONTRACT_STATES.Instrumentalization,
        'fileList:multiUpdate': multiUploadIds,
        id: customer.id,
        'wfState:status': transition.SEND.nextState,
      }

      if (transition.SEND.nextState === 'cust_customerReady') {
        body = {
          ...body,
          principalId: `${customer.customerInstance.instanceId}`,
        }
      }

      await instanceTransition(
        customer.lineInstance.workflowId,
        `${customer.id}.${customer.lineId}`,
        transition.SEND.event,
        body
      )
      setAction(false)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
    } catch (error) {
      let closeAction = false
      let label = 'Ha ocurrido un error al enviar. por favor recarga la pagina'

      if (error.message === 'file-list-error') {
        label = 'Por favor revisa la lista de archivos y corrige los errores'
      } else if (error.message === 'error saving files') {
        label =
          'Ha ocurrido un error al guardar los archivos, por favor ingresa al prospecto e intenta subir los documentos de nuevo'
        closeAction = true
      } else if (axios.isAxiosError(error)) {
        const { data } = error.response
        console.error('workflow error:', data.body?.message)
        label =
          'Los documentos se han guardado, pero ha ocurrido un error al enviar. Por favor ingresa al prospecto e intenta nuevamente'
        closeAction = true
      }

      if (closeAction) {
        setAction(false)
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      }

      setAlert({ label, title: 'Error', type: 'error' })
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  const onClickDownload = (id, name) => {
    try {
      if (id && name) downloadFile(downloadDocument(id), name)
    } catch (err) {
      console.error('error', err, err?.message)
    }
  }

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const { data: savedFilesData } = await getDocumentList(customer?.id, {
          stage: FILE_STAGES.INSTRUMENTATION,
        })
        const { data: generatedFilesData } = await getDocumentList(
          customer?.id,
          { status: FILE_STATES.DRAFT }
        )

        let savedFiles = {}
        savedFilesData.forEach((itm) => {
          const extension = itm.fileExtension ? `.${itm.fileExtension}` : ''
          if (!savedFiles[itm.typeId]) savedFiles[itm.typeId] = []
          savedFiles[itm.typeId].push({
            name: `${itm.fileName}${extension}`,
            size: itm.fileSize,
            status: itm.fileStatus,
            id: itm.fileId,
            typeId: itm.typeId,
          })
        })
        const generatedFiles = generatedFilesData.reduce((acc, itm) => {
          if (itm.typeId === CONTRACT_TYPE_ID)
            acc.push({
              name: `${itm.fileName}${'.pdf'}`,
              size: itm.fileSize,
              status: itm.fileStatus,
              id: itm.fileId,
              typeName: itm.typeName,
            })
          return acc
        }, [])
        setFiles(savedFiles)
        setGeneratedFiles(generatedFiles)
      } catch (err) {
        console.error('FetchError', err)
      }
    }
    if (customer?.id) fetchFiles()
  }, [customer?.id])

  return (
    <ContractUploadStyled>
      <div>
        <h5>Contratos Generados</h5>
        <GeneratedFilesStyled>
          {generatedFiles.map((itm, idx) => (
            <div
              key={`file-${idx}`}
              className='file-download'
              onClick={() => onClickDownload(itm.id, itm.name)}
            >
              <Icon name='file_download' type='outlined' />
              <p>{itm.name}</p>
            </div>
          ))}
        </GeneratedFilesStyled>
      </div>
      <CustomerDocumentsStyled>
        <BuildSection
          allowUpload={true}
          allowReview={false}
          allowDelete={true}
          allowUpdate={true}
          allowDownload={true}
          schema={CONTRACT_DOCUMENTS.generalInformation.documents}
          title={CONTRACT_DOCUMENTS.generalInformation.title}
          files={files}
          setFiles={setFiles}
          isValidating={isValidating}
          setCommentBarInfo={setCommentInfo}
        />
      </CustomerDocumentsStyled>
      <div className='action-group'>
        <Button buttonType='grayButton' onClick={() => setAction(false)}>
          Cancelar
        </Button>
        <Button disabled={isLoadingSubmit} onClick={() => handleSave()}>
          {isLoadingSubmit ? (
            <LoadingAnimation className='loading' />
          ) : (
            'Guardar'
          )}
        </Button>
        <Button
          buttonType='green'
          className='send'
          disabled={isLoadingSubmit}
          onClick={() => handleSubmit()}
        >
          {isLoadingSubmit ? (
            <LoadingAnimation className='loading' />
          ) : (
            'Enviar'
          )}
        </Button>
      </div>
      {commentInfo && (
        <LateralBar
          setlateralBar={setCommentInfo}
          fileId={commentInfo?.fileId}
          documentName={commentInfo?.fileId}
        />
      )}
    </ContractUploadStyled>
  )
}

const ContractUploadStyled = styled.div`
  padding: 2rem;
  padding-bottom: 1rem;
  .loading span {
    background-color: white;
  }

  h3 {
    margin-top: 1rem;
  }
  h4 {
    font-weight: bold;
    margin-top: 1rem;
  }
  .proposal {
    display: flex;
    justify-content: space-between;
    p {
      font-weight: bold;
    }
    .input-section {
      display: flex;
      justify-content: center;
      align-items: center;
      .grey {
        ${({ theme }) => theme.colors.grayShades[300]};
        border-radius: 6px;
        height: 39px;
        margin-right: 1rem;
        text-align: center;
        width: 50px;
        p {
          margin: 0.5rem auto;
        }
      }
      .input {
        max-width: 200px;
      }
    }
  }
  .action-group {
    margin: 2rem 5px 0;

    button + button {
      margin-left: 28px;
    }
    .send {
      float: right;
    }
  }
`

const CustomerDocumentsStyled = styled.section`
  .center-loading {
    height: 50vh;
    display: flex;
    align-items: center;
    div {
      width: 150px;
    }
  }

  h5 {
    margin-top: 1rem;
  }

  section {
    width: 65%;
  }
`

export default ContractUpload
