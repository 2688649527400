import { createContext, useState } from 'react'

export const LATERAL_MENU_OPTIONS = {
  DASHBOARD_ONBOARDING: 'dashboard',
  ONBOARDING: 'onBoarding',
  CUSTOMER_DETAIL: 'customerDetail',
  PRECUSTOMER_DETAIL: 'precustomerDetail',
  ONBOARDING_BUYER_DETAIL: 'OnboardingBuyerDetail',
  DASHBOARD_CUSTOMERS: 'providers',
  SETTINGS: 'settings',
  MASSIVE_PAYMENT: 'massivePayment',
  HELP: 'help',
}

const context = {
  selectedOption: LATERAL_MENU_OPTIONS.DASHBOARD_CUSTOMERS,
  action: '',
  setSelectedOption: () => {},
  setAction: () => {},
}

export const LateralBarContext = createContext(context)

export const LateralBarProvider = ({ children }) => {
  const [selectedOption, setSelectedOption] = useState(context.selectedOption)
  const [action, setAction] = useState(context.action)

  return (
    <LateralBarContext.Provider
      value={{ selectedOption, setSelectedOption, action, setAction }}
    >
      {children}
    </LateralBarContext.Provider>
  )
}
