import { Button } from '@keoworld/gbl-ui-kit'
import ProspectModal from 'containers/dashboard/onboarding/prospect-modal'
import { CustomerContext } from 'providers/customer'
import { LateralBarContext, LATERAL_MENU_OPTIONS } from 'providers/lateral-bar'
import { useContext } from 'react'
import styled from 'styled-components'
import { CUSTOMER_STAGES, CUSTOMER_STATES } from 'utils/schemas/customer'

const profileColors = [
  { backgroundColor: '#35C7C6' },
  { backgroundColor: '#FF9600' },
  { backgroundColor: '#00575B' },
  { backgroundColor: '#FF0045' },
]

const CustomerDashboard = ({ customer, refreshList, index }) => {
  const { setSelectedOption } = useContext(LateralBarContext)
  const { setCustomer } = useContext(CustomerContext)

  const color = customer.stateTimeConfig?.color?.replace('.', ' ')

  return (
    <CustomerDashboardRowStyled
      profileColor={profileColors[index % profileColors.length]}
    >
      <td>
        <div className={`profile-picture`}>
          <ProspectModal
            customer={customer}
            refreshList={refreshList}
          />
        </div>
      </td>
      <td title={customer.name}>{customer.name}</td>
      <td title={CUSTOMER_STAGES[customer.wfStage]?.label}>
        {CUSTOMER_STAGES[customer.wfStage]?.label}
      </td>
      <td
        className='large-text'
        title={CUSTOMER_STATES[customer.wfState]?.label}
      >
        {CUSTOMER_STATES[customer.wfState]?.label}
      </td>
      <td className='traffic-light'>
        <div className={`circle ${color}`}>
          {customer.stateTimeConfig?.name}
        </div>
      </td>
      <td>
        <Button
          onClick={() => {
            setCustomer(customer)
            setSelectedOption(LATERAL_MENU_OPTIONS.PRECUSTOMER_DETAIL)
          }}
          buttonType='blueDark'
        >
          Continuar
        </Button>
      </td>
    </CustomerDashboardRowStyled>
  )
}

const CustomerDashboardRowStyled = styled.tr`
  .profile-picture {
    position: relative;

    border-radius: 50px;
    height: 50px;
    width: 50px;
    line-height: 50px;

    ${(props) => props.profileColor}
  }

  .icon {
    opacity: 1;
    &.material-icons-outlined {
      opacity: 0.5;
    }
  }

  td + td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    padding: 0 20px;
    text-transform: capitalize;
  }

  .traffic-light {
    .circle {
      border-radius: 5px;
      height: 30px;
      line-height: 30px;
      max-width: 120px;
      margin: auto;

      padding: 0 10px;

      text-align: center;

      &.color.disabled {
        ${({ theme }) => theme.colors.grayShades[400]}
        background: color-mix(in srgb, currentColor 20%, transparent);
        position: relative;
        border-radius: 4px;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          width: 4px;
          height: 100%;
          background-color: gray;
          border-radius: 4px 0px 0px 4px;
        }
      }
      &.color.green {
        color: #1d9c50;
        background: color-mix(in srgb, currentColor 20%, transparent);
        position: relative;
        border-radius: 4px;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          width: 4px;
          height: 100%;
          background-color: #1d9c50;
          border-radius: 4px 0px 0px 4px;
        }
      }
      &.color.warning {
        color: #ff9600;
        background: color-mix(in srgb, currentColor 20%, transparent);
        position: relative;
        border-radius: 4px;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          width: 4px;
          height: 100%;
          background-color: #ff9600;
          border-radius: 4px 0px 0px 4px;
        }
      }
      &.color.gray {
        color: #00172d;
        background: color-mix(in srgb, currentColor 20%, transparent);
        position: relative;
        border-radius: 4px;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          width: 4px;
          height: 100%;
          background-color: #00172d;
          border-radius: 4px 0px 0px 4px;
        }
      }
      &.color.alert {
        color: #ff0045;
        background: color-mix(in srgb, currentColor 20%, transparent);
        position: relative;
        border-radius: 4px;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          width: 4px;
          height: 100%;
          background-color: #ff0045;
          border-radius: 4px 0px 0px 4px;
        }
      }
    }
  }
`

export default CustomerDashboard
