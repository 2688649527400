import { Button, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import { CustomerContext } from 'providers/customer'
import { LateralBarContext, LATERAL_MENU_OPTIONS } from 'providers/lateral-bar'
import { useContext, useEffect, useState } from 'react'
import { evaluationFind, getCustomerLines } from 'services/lines'
import { instanceTransition } from 'services/workflow'
import styled from 'styled-components'
import { dateFormatFromIso } from 'utils/functions/date'
import { CUSTOMER_STATES } from 'utils/schemas/customer'
import { useAuth } from 'utils/hooks/auth'

const CommitteeResult = () => {
  const [evaluationResults, setEvaluationResults] = useState([])
  const [loading, setLoading] = useState(false)
  const { customer } = useContext(CustomerContext)
  const { setAction, setSelectedOption } = useContext(LateralBarContext)
  const { user } = useAuth()

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const transition =
        CUSTOMER_STATES[customer?.lineInstance?.currentState].transition
      const notificationReceiver = customer?.assignations
        ?.find( itm => 
          (transition?.SEND?.notificationReceiverRoles ?? [])?.includes(itm?.role) 
        )
      const notificationReceiver2 = customer?.assignations
        ?.find( itm => 
          (transition?.SEND?.notificationReceiverRoles2 ?? [])?.includes(itm?.role) 
        )
      
      await instanceTransition(
        customer?.lineInstance?.workflowId,
        `${customer?.customerInstance?.instanceId}.${customer?.lineInstance?.instanceId}`,
        transition.SEND.event,
        {
          id: customer.id,
          'wfStage:status': transition.SEND.nextStage,
          'wfState:status': transition.SEND.nextState,
          'senderUidFed': user.uid,
          'receiverUidIam:notificationOne': notificationReceiver?.iamUid ,
          'customerId:notificationOne': customer.id,
          'receiverUidIam:notificationOne2': notificationReceiver2?.iamUid ,
          'customerId:notificationOne2': customer.id
        }
      )
      setAction(false)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  useEffect(() => {
    const getLineInformation = async () => {
      try {
        setLoading(true)
        const { data } = await getCustomerLines(customer.id)
        if (data[0]?.lineId) {
          const results = await evaluationFind(data[0].lineId)
          setEvaluationResults(results)
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }
    if (customer?.id) getLineInformation()
  }, [customer?.id])

  return (
    <CommitteeResultStyled>
      <h3>Resultado de Comité</h3>
      {loading && (
        <div className='center-loading'>
          <LoadingAnimation />
        </div>
      )}
      {!loading && (
        <table>
          <thead>
            <tr>
              <th>Revisor</th>
              <th>Fecha</th>
              <th>Resultado</th>
              <th>Comentario</th>
            </tr>
          </thead>
          <tbody>
            {evaluationResults.length === 0 && (
              <tr>
                <td colSpan={4}>No hay resultados</td>
              </tr>
            )}
            {evaluationResults?.map((result) => (
              <tr key={result.id}>
                <td>{result.nameUser}</td>
                <td>
                  {result.createdAt &&
                    dateFormatFromIso(result.createdAt, 'dd/MM/yyyy')}
                </td>
                <td>{result.result}</td>
                <td>{result.comment}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <div className='actions'>
        <Button buttonType='grayButton' onClick={() => setAction(false)}>
          Cancelar
        </Button>
        <Button onClick={() => handleSubmit()}>Continuar</Button>
      </div>
    </CommitteeResultStyled>
  )
}

const CommitteeResultStyled = styled.div`
  padding: 3rem 2rem;

  table {
    margin-top: 30px;
    width: 80%;
    text-align: left;
    tbody {
      tr > td {
        padding-top: 15px;
      }
    }
  }

  .actions {
    margin-top: 30px;
    button + button {
      margin-left: 28px;
    }
  }
`

export default CommitteeResult
