import { CustomerContext } from 'providers/customer'
import { useContext } from 'react'
import BuyerHistory from 'templates/buyer-detail/tabs/customer-history'
import CustomerHistory from 'templates/precustomer-detail/tabs/customer-history'

const CustomerManager = () => {
  const { customer } = useContext(CustomerContext)

  if (customer.service === 'supplementaryCards') {
    return <BuyerHistory />
  }

  return <CustomerHistory />
}

export default CustomerManager
