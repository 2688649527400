import { Button } from '@keoworld/gbl-ui-kit'
import Form from 'containers/form'
import LateralBar from 'containers/lateral-bar'
import { AlertContext } from 'providers/alert'
import { CustomerContext } from 'providers/customer'
import { LATERAL_MENU_OPTIONS, LateralBarContext } from 'providers/lateral-bar'
import { useContext, useEffect, useMemo, useState } from 'react'
import { financeFind } from 'services/lines'
import styled from 'styled-components'
import { currency } from 'utils/functions/formatters'
import { useAuth } from 'utils/hooks/auth'
import { useFetchDocuments } from 'utils/hooks/fetch-documents'
import { CREDIT_STUDY, CREDIT_STUDY_HEAD } from 'utils/schemas/customer'
import {
  COMMITTEE_SEND,
  FILE_STAGES,
  FINANCIAL_ANALYSIS,
} from 'utils/schemas/documents'
import DebtCapacity from '../../../containers/debt-capacity/debt-capacity'
import { ApiBuyerRepository } from 'services/buyer-suppcards/buyer.repository'
import RejectReviewModal from 'templates/actions/committee-actions/reject-review-modal'
import { BuildSection } from 'templates/actions/document-actions/build-section'
import { ONBOARDING_STATES } from 'utils/schemas/workflows/onboarding'
import { ApiDebtCapacityRepository } from 'services/debt-capacity-suppcards/debtCapacity.repository'
import { useNavigate } from 'react-router-dom'
import Router from 'utils/router'
import { getUserRoles } from 'utils/functions/role-manager'

const COMMITTEE_STATUS = {
  BEFORE: 'REVIEW_BEFORE_COMMITTEE',
  MAJOR: 'MAJOR_COMMITTEE',
  MINOR: 'MENOR_COMMITTEE',
}

const FILE_STAGE_RISK = { stage: FILE_STAGES.RISK }
const FILE_STAGE_CREDIT = { stage: FILE_STAGES.CREDIT }

const disableSchema = (schema) => {
  return schema.map((itm) => ({ ...itm, disabled: true }))
}

const FORM_SCHEMAS = {
  SOURCE: disableSchema(CREDIT_STUDY_HEAD),
  FINANCIAL: disableSchema(CREDIT_STUDY),
}

const CommitteeReview = () => {
  const { setAlert } = useContext(AlertContext)
  const { setAction, setSelectedOption } = useContext(LateralBarContext)
  const { customer } = useContext(CustomerContext)
  const { user } = useAuth()
  const navigate = useNavigate()

  const legalRiskDocuments = useFetchDocuments(customer?.id, FILE_STAGE_RISK)
  const creditReviewDocuments = useFetchDocuments(
    customer?.id,
    FILE_STAGE_CREDIT
  )

  const [isLoading, setIsLoading] = useState(false)
  const [commentBarConfiguration, setCommentBarConfiguration] = useState()
  const [financeForm, setFinanceForm] = useState({})
  const [financialDocuments, setFinancialDocuments] = useState([])
  const [debtCapacities, setDebtCapacities] = useState({
    debtCapacities: [],
    proposalId: undefined,
    evaluations: [],
  })
  const [modalConfiguration, setModalConfiguration] = useState()

  const currentWorkflowState = customer?.wfState

  const showFinancialActions = useMemo(() => {
    const userFirstRole = user?.iam?.roles[0]
    const isPending = debtCapacities?.evaluations.some(
      (itm) => itm?.role === userFirstRole && itm?.result === 'Pending'
    )
    const isBeforeCommittee = currentWorkflowState === COMMITTEE_STATUS.BEFORE
    return isPending || isBeforeCommittee
  }, [user?.iam, currentWorkflowState, debtCapacities?.evaluations])

  const openRejectModal = () => {
    let modalConfiguration = {
      title: 'Rechazar propuesta de negocio',
      message:
        '¿Estás seguro de rechazar la propuesta de negocio? ' +
        'Después de esta acción no podrá retomar el proceso',
    }

    if (currentWorkflowState !== COMMITTEE_STATUS.BEFORE) {
      modalConfiguration = {
        title: 'Rechazar propuesta de negocio',
        message: '¿Estás seguro de rechazar la propuesta de negocio?',
        requireReason: true,
      }
    }

    setModalConfiguration(modalConfiguration)
  }

  const rejectDebtCapacity = async (reasonMessage) => {
    try {
      setIsLoading(true)
      const [userRole] = getUserRoles()

      if (currentWorkflowState !== COMMITTEE_STATUS.BEFORE && !reasonMessage) {
        throw new Error('Debes ingresar un motivo para rechazar')
      } else if (currentWorkflowState === COMMITTEE_STATUS.BEFORE) {
        const { rejectBuyer } = ONBOARDING_STATES.REVIEW_BEFORE_COMMITTEE.events

        await ApiBuyerRepository.doEvent(customer.id, rejectBuyer, {
          'customerId:status': customer.id,
          'wfState:status': 'BUYER_REJECTED',
          'status:status': 'Rejected',
          'uuid:assignation': user.uid,
          'customerId:assignation': customer.id,
          'id:assignation': customer.assignationId,
          'role:assignation': userRole,
          'id:proposal': debtCapacities?.proposalId,
        })
      } else {
        const { uploadReview, rejectBuyer } =
          ONBOARDING_STATES[currentWorkflowState].events
        const eventResults = await ApiBuyerRepository.doEvent(
          customer.id,
          uploadReview,
          {
            proposalId: debtCapacities?.proposalId,
            uuid: user?.uid,
            decision: 'Rejected',
            comment: reasonMessage,
          }
        )
        const { decision } = eventResults[0]?.response
        if (decision === 'votePending') {
          setAlert({
            type: 'info',
            title: 'Pendiente de votos',
            label: 'Pendiente de votos de otros miembros del comité',
          })
        } else if (decision === 'proposalRejected') {
          await ApiBuyerRepository.doEvent(customer.id, rejectBuyer, {
            'id:proposal': debtCapacities?.proposalId,
          })
        }
      }
      navigate(Router.Root)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      setAction(undefined)
    } catch (error) {
      setAlert({ type: 'error', title: 'Error', label: error.message })
    } finally {
      setIsLoading(false)
    }
  }

  const approveDebtCapacity = async () => {
    try {
      setIsLoading(true)
      if (currentWorkflowState === COMMITTEE_STATUS.BEFORE) {
        const { assign, sendToMajorCommittee, sendToMenorCommittee } =
          ONBOARDING_STATES.REVIEW_BEFORE_COMMITTEE.events

        const [eventResult] = await ApiBuyerRepository.doEvent(
          customer.id,
          assign,
          {
            proposalId: debtCapacities?.proposalId,
          }
        )

        const { response } = eventResult

        const resultEvent = mapCommitteeKeys(response?.committee)

        if (![sendToMajorCommittee, sendToMenorCommittee].includes(resultEvent))
          throw new Error('Error obteniendo decision de comité mayor/menor')

        await ApiBuyerRepository.doEvent(customer.id, resultEvent, {})
      } else {
        const { uploadReview, completeReview, rejectBuyer } =
          ONBOARDING_STATES[currentWorkflowState].events

        const [eventResult] = await ApiBuyerRepository.doEvent(
          customer.id,
          uploadReview,
          {
            proposalId: debtCapacities?.proposalId,
            uuid: user?.uid,
            decision: 'Approved',
            comment: '',
          }
        )

        const { decision } = eventResult?.response ?? {}

        if (!decision) throw new Error('Error obteniendo decision de comité')

        if (decision === 'votePending') {
          setAlert({
            type: 'info',
            title: 'Pendiente de votos',
            label: 'Pendiente de votos de otros miembros del comité',
          })
        } else if (decision === 'proposalApproved') {
          await ApiBuyerRepository.doEvent(customer.id, completeReview, {
            'id:proposal': debtCapacities?.proposalId,
          })
        } else if (decision === 'proposalRejected') {
          await ApiBuyerRepository.doEvent(customer.id, rejectBuyer, {
            'id:proposal': debtCapacities?.proposalId,
          })
        }
      }
      navigate(Router.Root)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      setAction(undefined)
    } catch (error) {
      setAlert({ type: 'error', title: 'Error', label: error.message })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const documents = [...legalRiskDocuments, ...creditReviewDocuments]
    const savedFiles = documents.reduce((acc, itm) => {
      const fileTypeDocs = acc[itm.typeId] || []
      const extension = itm.fileExtension ? `.${itm.fileExtension}` : ''
      fileTypeDocs.push({
        name: `${itm.fileName}${extension}`,
        size: itm.fileSize,
        status: itm.fileStatus,
        id: itm.fileId,
        fileExtension: itm.fileExtension,
      })
      return { ...acc, [itm.typeId]: fileTypeDocs }
    }, {})

    setFinancialDocuments(savedFiles)
  }, [legalRiskDocuments, creditReviewDocuments])

  useEffect(() => {
    const fetchDebtCapacities = async () => {
      const { debtCapacities, proposalId } =
        await ApiDebtCapacityRepository.getByBuyerId(customer?.id)
      let evaluations = []
      if (
        [COMMITTEE_STATUS.MAJOR, COMMITTEE_STATUS.MINOR].includes(
          currentWorkflowState
        )
      )
        evaluations = await ApiDebtCapacityRepository.getEvaluationsByBuyerId(
          customer?.id
        )
      setDebtCapacities({ debtCapacities, proposalId, evaluations })
    }
    fetchDebtCapacities()
  }, [customer?.id, currentWorkflowState])

  useEffect(() => {
    const getFinanceOptions = async () => {
      const schemas = [...CREDIT_STUDY_HEAD, ...CREDIT_STUDY]
      const [finance] = await financeFind(customer.id)

      const formattedObject = schemas.reduce((acc, curr) => {
        const { name, validatorType } = curr
        let value = finance[name]
        if (validatorType === 'currency') {
          value = currency(value)
        }
        return { ...acc, [name]: value }
      }, {})

      setFinanceForm(formattedObject)
    }

    if (customer?.id) getFinanceOptions()
  }, [customer?.id])

  return (
    <Styles>
      <section className='introduction'>
        <DebtCapacity debtCapacities={debtCapacities.debtCapacities} />

        <BuildSection
          allowComment
          allowUpload={false}
          files={financialDocuments}
          title={COMMITTEE_SEND.title}
          schema={COMMITTEE_SEND.documents}
          setCommentBarInfo={setCommentBarConfiguration}
        />
      </section>

      <h5>Indicadores financieros</h5>
      <section className='source-form'>
        <Form
          schema={FORM_SCHEMAS.SOURCE}
          formValues={financeForm}
          isValidating={false}
        />
      </section>

      <section className='financial-form'>
        <Form
          schema={FORM_SCHEMAS.FINANCIAL}
          formValues={financeForm}
          isValidating={false}
        />
      </section>

      <BuildSection
        className='financial-documents'
        allowComment
        allowUpload={false}
        files={financialDocuments}
        isValidating={false}
        schema={FINANCIAL_ANALYSIS.generalInformation.documents}
        setCommentBarInfo={setCommentBarConfiguration}
        title={FINANCIAL_ANALYSIS.generalInformation.title}
      />

      <section className='financial-actions'>
        <Button
          buttonType='outlineBlueDark'
          onClick={() => {
            navigate(-1)
            setAction(undefined)
          }}
        >
          Regresar
        </Button>
        {showFinancialActions && (
          <div>
            <Button
              disabled={isLoading}
              onClick={() => !isLoading && openRejectModal()}
            >
              Rechazar
            </Button>
            <Button
              buttonType='blueDark'
              disabled={isLoading}
              onClick={() => !isLoading && approveDebtCapacity()}
            >
              Aprobar
            </Button>
          </div>
        )}
      </section>

      {commentBarConfiguration && (
        <LateralBar
          fileId={commentBarConfiguration.fileId}
          documentName={commentBarConfiguration.fileName}
          setLateralBar={setCommentBarConfiguration}
        />
      )}
      {modalConfiguration && (
        <RejectReviewModal
          handleCloseModal={() => setModalConfiguration(undefined)}
          modalConfiguration={modalConfiguration}
          handleSubmit={rejectDebtCapacity}
        />
      )}
    </Styles>
  )
}

const mapCommitteeKeys = (committeeKey) => {
  const committeeMap = {
    minorCommittee: 'send-to-menor-committee',
    majorCommitte: 'send-to-major-committee',
  }
  return committeeMap[committeeKey]
}

const Styles = styled.main`
  margin-block: 20px;
  padding-inline: 30px;
  .introduction {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
    flex-wrap: wrap;
    gap: 2rem;

    margin-block: 20px;
  }

  .source-form,
  .financial-form {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 10px;

    margin-block: 10px;
  }

  .financial-documents {
    max-width: 800px;
    margin-block: 30px;
  }

  .financial-actions {
    margin-block: 30px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    button + button {
      margin-inline-start: 10px;
    }
  }
`

export default CommitteeReview
