import { CustomerContext } from 'providers/customer'
import { useContext, useEffect, useState } from 'react'

const WORKFLOW_PREFIX = {
  customer: 'cust',
  line: 'line'
}

export const useWorkflowStateValidation = () => {
  const { customer } = useContext(CustomerContext)
  const [isRight, setIsRight] = useState(false)

  useEffect(() => {
    if (customer?.wfState) {
      const workflow = customer?.wfState.slice(0, 4)
      const instance =
        workflow === WORKFLOW_PREFIX.customer
          ? customer?.customerInstance
          : customer?.lineInstance

      setIsRight(
        Boolean(instance) && customer.wfState === instance?.currentState
      )
    }
  }, [customer])

  return isRight
}
