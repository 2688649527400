import { CUSTOMER_STATES } from './customer'
import { LOAN_ACTION_CATEGORIES } from './portfolio'
import { PAYMENT_COMMENT_CATEGORIES } from './relations'

/**
 * @class Comment
 * @classdesc Comment schema
 * @property {number} id - Comment id
 * @property {string} createdAt - Comment creation date
 * @property {string} message - Comment message
 * @property {string} user - Comment user
 * @property {Object} category - Comment category
 * @property {string} category.label - Category label
 * @property {string} category.color - Category color
 */
export class Comment {
  /**
   * @constructor
   * @param {Object} comment
   * @param {number} comment.id - Comment id
   * @param {string} comment.createdAt - Comment creation date
   * @param {string} comment.message - Comment message
   * @param {string} comment.user - Comment user
   * @param {Object} comment.category - Category
   * @param {string} comment.category.label - Category label
   * @param {string} comment.category.color - Category color
   */
  constructor(comment) {
    this.id = comment.id
    this.createdAt = comment.createdAt
    this.message = comment.message
    this.user = comment.user
    this.category = comment.category
  }
}

/**
 * Function to transform data to Comment schema
 * @param {Object} response
 * @param {string} request
 * @returns {{ comments: Comment[], total: number }}
 */
export const commentAdapter = (response, request) => {
  let comments = []
  let total = 0

  if (request === 'onboarding') {
    const { commentsMod, fullCount } = response
    total = fullCount
    comments = commentsMod.map((comment) => {
      const { id, createdAt, comment: message, nameUser, wfState } = comment
      const defaultCategory = { label: wfState, color: '#00172d' }
      const category = CUSTOMER_STATES[wfState] ?? defaultCategory
      return new Comment({ id, createdAt, message, user: nameUser, category })
    })
  }

  if (request === 'portfolio') {
    const { data, count } = response
    total = count
    comments = data.map((comment) => {
      const { id, createdAt, comment: message, nameUser, action } = comment
      const defaultCategory = { label: action, color: '#00172d' }
      const category = LOAN_ACTION_CATEGORIES[action] ?? defaultCategory
      return new Comment({ id, createdAt, message, user: nameUser, category })
    })
  }

  if (request === 'payments') {
    const { data, count } = response
    total = count
    comments = data.map((comment) => {
      const { id, createdAt, commentType, comment: message, nameUser } = comment
      const defaultCategory = { label: commentType, color: '#00172d' }
      const category =
        PAYMENT_COMMENT_CATEGORIES[commentType] ?? defaultCategory
      return new Comment({ id, createdAt, message, user: nameUser, category })
    })
  }

  return { comments, total }
}
