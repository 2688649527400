import { Button, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import axios from 'axios'
import ModalUpload from 'containers/Modal/modal-documents-upload'
import Form from 'containers/form'
import GeneralComment from 'containers/general-comment'
import LateralBar from 'containers/lateral-bar'
import LineConditions, {
  initialLineConditionsForm,
} from 'containers/line-conditions/line-conditions'
import LineConditionsView from 'containers/line/line-conditions-view'
import { AlertContext } from 'providers/alert'
import { CustomerContext } from 'providers/customer'
import { LATERAL_MENU_OPTIONS, LateralBarContext } from 'providers/lateral-bar'
import { useContext, useEffect, useRef, useState } from 'react'
import { uploadDocuments } from 'services/documents'
import { getLineConditions } from 'services/lines'
import { instanceTransition } from 'services/workflow'
import styled from 'styled-components'
import { BuildSection } from 'templates/actions/document-actions/build-section'
import { validateForm } from 'utils/functions/form-validate'
import * as Percentage from 'utils/functions/handle-percentage'
import * as Currency from 'utils/functions/handlers-currency'
import { getUserRoles } from 'utils/functions/role-manager'
import { useAuth } from 'utils/hooks/auth'
import { useFetchDocuments } from 'utils/hooks/fetch-documents'
import { useFetchFinance } from 'utils/hooks/fetch-finance'
import {
  CREDIT_STUDY,
  CREDIT_STUDY_HEAD,
  CUSTOMER_STAGES,
  CUSTOMER_STATES,
} from 'utils/schemas/customer'
import {
  CREDIT_ANALYSIS,
  FILE_STAGES,
  FILE_STATES,
  FINANCIAL_ANALYSIS,
} from 'utils/schemas/documents'
import {
  COMMISSION_TERM_FORM,
  MORATORIUM_TERM_FORM,
  ORDINARY_TERM_FORM,
} from 'utils/schemas/line'

const [userRole] = getUserRoles()

const FILE_STAGE_RISK = { stage: FILE_STAGES.RISK }
const FILE_STAGE_CREDIT = { stage: FILE_STAGES.CREDIT }

const TRANSITION = CUSTOMER_STATES.line_creditReview.transition
const STAGES = {
  ASSESSMENT: CUSTOMER_STAGES.assessment.id,
  //PRE_ASSESSMENT: CUSTOMER_STAGES.preassessment.id,
  REASSESSMENT: CUSTOMER_STAGES.reassessment.id,
}
const TRANSITION_SUCCESS = {
  [STAGES.REASSESSMENT]: TRANSITION.SEND_REASSESSMENT,
  [STAGES.ASSESSMENT]: TRANSITION.SEND_ASSESSMENT,
  // [STAGES.PRE_ASSESSMENT]: TRANSITION.SEND_PREASSESSMENT,
  // hasData: TRANSITION.SEND_ASSESSMENT_BY_PREASSESSMENT,
}
const TRANSITION_REJECT = {
  [STAGES.REASSESSMENT]: TRANSITION.REJECT_REASSESSMENT,
  [STAGES.ASSESSMENT]: TRANSITION.REJECT_ASSESSMENT,
  // [STAGES.PRE_ASSESSMENT]: TRANSITION.REJECT_PREASSESSMENT,
  // hasData: TRANSITION.REJECT,
}

const ERRORS = {
  'missing-documents': {
    close: false,
    label: 'Por favor sube todos los documentos solicitados',
  },
  'missing-form-fields': {
    close: false,
    label: 'Completa todos los campos antes de enviar',
  },
  'missing-comment': {
    close: false,
    label: 'Por favor agrega un comentario',
  },
  'error-uploading-documents': {
    close: true,
    label:
      'Ha ocurrido un error al guardar los archivos, por favor ingresa al prospecto e intenta subir los documentos de nuevo',
  },
  workflow: {
    close: true,
    label:
      'Los documentos se han guardado, pero ha ocurrido un error al enviar. Por favor contacta a soporte',
  },
  default: {
    close: true,
    label: 'Ha ocurrido un error al enviar. Por favor recarga la pagina',
  },
}

const CustomerCreditStudy = () => {
  const [line, setLine] = useState()
  const [files, setFiles] = useState({})
  const [openCommentBar, setOpenCommentBar] = useState()
  const [financeFormValues, setFinanceFormValues] = useState({})
  const [lineFormValues, setLineFormValues] = useState(
    initialLineConditionsForm
  )
  const [formErrorsProposal, setFormErrorsProposal] = useState(
    initialLineConditionsForm
  )
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [formErrorsIndicators, setFormErrorsIndicators] = useState({})
  const [comment, setComment] = useState('')
  const [formErrors, setFormErrors] = useState({})
  const [isPublic, setIsPublic] = useState(false)
  const [isValidating, setIsValidating] = useState(false)
  const [hasFinancialData, setHasFinancialData] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showModalLine, setShowModalLine] = useState(false)
  const { setAction, setSelectedOption } = useContext(LateralBarContext)
  const { customer } = useContext(CustomerContext)
  const toResetErrorRef = useRef(null)
  const { user } = useAuth()
  const { setAlert } = useContext(AlertContext)
  const financeInformation = useFetchFinance()
  const riskDocuments = useFetchDocuments(customer?.id, FILE_STAGE_RISK)
  const creditDocuments = useFetchDocuments(customer?.id, FILE_STAGE_CREDIT)

  const validateFormsAndDocuments = () => {
    const validateForms = (formErrorsObj) =>
      Object.values(formErrorsObj).some((itm) => itm)

    const documentValidation =
      FINANCIAL_ANALYSIS.generalInformation.documents.some((itm) => {
        const docs = files[itm.typeId] ?? []
        return docs.length === 0
      })

    const source = validateForms(formErrorsIndicators)
    const financial = validateForms(formErrors)

    const { line, ordinary, moratorium, commission } = formErrorsProposal

    const proposal =
      validateForms(line) ||
      ordinary.some((period) => validateForms(period)) ||
      moratorium.some((period) => validateForms(period)) ||
      commission.some((item) => validateForms(item))

    if (source) throw new Error('missing-form-fields')
    if (financial) throw new Error('missing-form-fields')
    if (documentValidation) throw new Error('missing-documents')
    if (proposal) throw new Error('missing-form-fields')
    if (!comment) throw new Error('missing-comment')
  }

  const saveDocs = async () => {
    const documentsToUpload =
      FINANCIAL_ANALYSIS.generalInformation.documents.reduce((acc, itm) => {
        const docs = files[itm.typeId] ?? []
        return {
          ...acc,
          [itm.typeId]: docs,
        }
      }, {})

    /* Upload files */
    const documents = await uploadDocuments(
      documentsToUpload,
      customer?.id,
      user.uid,
      userRole
    )

    if (documents.some((document) => document.status !== 'fulfilled')) {
      throw new Error('error-uploading-documents')
    }

    return documents.map((document) => {
      const doc = document.value
      doc.status = FILE_STATES.ACCEPTED
      return doc
    })
  }

  const acceptCreditAnalysis = async () => {
    try {
      setIsLoadingSubmit(true)
      setIsValidating(true)
      setShowModal(false)

      /* Check form errors */
      validateFormsAndDocuments()

      /* Get files to upload */

      const documents = await saveDocs()

      /* Map Form Values */
      const {
        line: lineConditions,
        ordinary,
        moratorium,
        commission,
      } = lineFormValues

      const lineConditionsCreate = {
        score: Currency.extractNumber(lineConditions.score),
        maxTerm: Number(lineConditions.maxTerm),
        terms: [
          ...ordinary.map((period) => {
            Object.keys(period).forEach((key) => {
              if (key === 'rate') {
                period[key] = Percentage.extractNumberPercentage(period[key])
              } else if (key !== 'name') {
                period[key] = Number(period[key])
              }
            })
            period.delinquency = false
            return period
          }),
          ...moratorium.map((period) => {
            Object.keys(period).forEach((key) => {
              if (key === 'rate') {
                period[key] = Percentage.extractNumberPercentage(period[key])
              } else if (key !== 'name') {
                period[key] = Number(period[key])
              }
            })
            if (period.term === 0) period.term = undefined
            period.delinquency = true
            return period
          }),
        ],
        commissions: commission.map((commission) => {
          commission.amount = Currency.extractNumber(commission.amount)
          commission.days = commission.days.split(',').map(Number)
          return commission
        }),
      }

      const lineForm = Object.entries(lineConditionsCreate).reduce(
        (acc, [key, value]) => {
          acc[`${key}:lineConditions`] = value
          return acc
        },
        {}
      )

      const financeForm = Object.entries(financeFormValues).reduce(
        (acc, [key, value]) => {
          const head = ['cutoffDate', 'source']
          if (Currency.canBeFormatted(value) && !head.includes(key)) {
            acc[`${key}:finance`] = Currency.extractNumber(value)
          } else {
            acc[`${key}:finance`] = value
          }

          return acc
        },
        {}
      )
      /* workflow config */
      const workflowStage = customer?.wfStage
      const transitionConfig =
        [STAGES.PRE_ASSESSMENT, STAGES.ASSESSMENT].includes(workflowStage) &&
        hasFinancialData
          ? TRANSITION_SUCCESS.hasData
          : TRANSITION_SUCCESS[workflowStage]

      const {
        event,
        nextState,
        lineStatus,
        notificationReceiverRoles,
        notificationReceiverRoles2,
      } = transitionConfig

      /* Get notification receivers */
      const notificationReceiver = customer?.assignations?.find((itm) =>
        (notificationReceiverRoles ?? []).includes(itm?.role)
      )
      const notificationReceiver2 = customer?.assignations?.find((itm) =>
        (notificationReceiverRoles2 ?? []).includes(itm?.role)
      )

      /* Execute workflow transition */
      await instanceTransition(
        customer?.lineInstance?.workflowId,
        `${customer?.id}.${customer?.lineInstance?.instanceId}`,
        event,
        {
          ...lineForm,
          ...financeForm,
          'fileList:multiUpdate': documents,
          'cutoffDate:finance': financeFormValues.cutoffDate,
          'id:lineStatus': line?.lineId,
          'status:lineStatus': lineStatus,
          'lineId:lineConditions': line?.lineId,
          'comment:comment': comment,
          'isPublic:comment': isPublic,
          'id:status': customer?.id,
          'wfState:status': nextState,
          'customerId:finance': customer?.id,
          'customerId:comment': customer?.id,
          'uuid:comment': user?.uid,
          'role:comment': userRole,
          'customerId:notificationOne': customer.id,
          'receiverUidIam:notificationOne': notificationReceiver?.iamUid,
          'customerId:notificationOne2': customer.id,
          'receiverUidIam:notificationOne2': notificationReceiver2?.iamUid,
        }
      )

      /* Close action */
      setAction(false)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        error.message = 'workflow'
      }

      const { close, label } = ERRORS[error.message] || ERRORS.default

      if (close) {
        setAction(false)
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      }

      setAlert({ type: 'error', title: 'Error', label })
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  const handleEvent = () => {
    if (line.score !== Currency.extractNumber(lineFormValues.line.score)) {
      setShowModalLine(true)
    } else {
      acceptCreditAnalysis()
    }
  }

  const rejectCreditAnalysis = async () => {
    try {
      setIsLoadingSubmit(true)
      setIsValidating(true)
      setShowModal(false)

      /* Check form errors */
      validateFormsAndDocuments()

      /* Upload files */
      await saveDocs()

      /* Transform form values */
      const financeForm = Object.entries(financeFormValues).reduce(
        (acc, [key, value]) => {
          const head = ['cutoffDate', 'source']
          if (Currency.canBeFormatted(value) && !head.includes(key)) {
            acc[`${key}:finance`] = Currency.extractNumber(value)
          } else {
            acc[`${key}:finance`] = value
          }
          return acc
        },
        {}
      )

      /* workflow config */
      const workflowStage = customer?.wfStage
      const transitionConfig =
        [STAGES.PRE_ASSESSMENT, STAGES.ASSESSMENT].includes(workflowStage) &&
        hasFinancialData
          ? TRANSITION_REJECT.hasData
          : TRANSITION_REJECT[workflowStage]

      const {
        event,
        nextState,
        notificationReceiverRoles,
        notificationReceiverRoles2,
      } = transitionConfig

      /* Get notification receivers */
      const notificationReceiver = customer?.assignations?.find((itm) =>
        (notificationReceiverRoles ?? []).includes(itm?.role)
      )
      const notificationReceiver2 = customer?.assignations?.find((itm) =>
        (notificationReceiverRoles2 ?? []).includes(itm?.role)
      )

      /* Execute workflow transition */

      await instanceTransition(
        customer?.lineInstance?.workflowId,
        `${customer?.id}.${customer?.lineInstance?.instanceId}`,
        event,
        {
          ...financeForm,
          'cutoffDate:finance': financeFormValues.cutoffDate,
          'id:lineStatus': line?.lineId,
          'status:lineStatus': 'Cancelled',
          'comment:comment': comment,
          'isPublic:comment': isPublic,
          'id:status': customer?.id,
          'wfState:status': nextState,
          'customerId:finance': customer?.id,
          'customerId:comment': customer?.id,
          'uuid:comment': user?.uid,
          'role:comment': userRole,
          'customerId:notificationOne': customer.id,
          'receiverUidIam:notificationOne': notificationReceiver?.iamUid,
          'customerId:notificationOne2': customer.id,
          'receiverUidIam:notificationOne2': notificationReceiver2?.iamUid,
        }
      )

      /* Close action */
      setAction(false)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const { body } = error.response.data
        console.error('workflow error:', body?.message)
        error.message = 'workflow'
      }
      const { close, label } = ERRORS[error.message] || ERRORS.default

      if (close) {
        setAction(false)
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      }
      setAlert({
        label,
        title: 'Error',
        type: 'error',
      })
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  /*Fetch financial info effect */
  useEffect(() => {
    if (financeInformation?.id) {
      setHasFinancialData(true)
      setFinanceFormValues({
        ...financeInformation,
        cutoffDate: financeInformation.cutoffDate,
        financeId: financeInformation?.id,
      })
    }
  }, [financeInformation])

  /* Fetch files effect */
  useEffect(() => {
    const documents = [...riskDocuments, ...creditDocuments]
    const savedFiles = documents.reduce((acc, itm) => {
      const fileTypeDocs = acc[itm.typeId] || []
      const extension = itm.fileExtension ? `.${itm.fileExtension}` : ''
      fileTypeDocs.push({
        name: `${itm.fileName}${extension}`,
        size: itm.fileSize,
        status: itm.fileStatus,
        id: itm.fileId,
        fileExtension: itm.fileExtension,
      })
      return { ...acc, [itm.typeId]: fileTypeDocs }
    }, {})
    setFiles(savedFiles)
  }, [riskDocuments, creditDocuments])

  /* Fetch line */
  useEffect(() => {
    const fetchLineConditions = async () => {
      if (!customer.lineId) return
      setIsLoadingData(true)
      const lineConditions = await getLineConditions(customer.lineId)
      const { terms, commissions } = lineConditions
      const line = {
        score: Currency.formatCurrency(lineConditions.score ?? 0),
        maxTerm: `${lineConditions.maxTerm}`,
        currency: lineConditions.currency ?? 'MXN',
      }

      const lineConditionsForm = {
        line,
        ordinary: terms
          .filter((period) => !period.delinquency)
          .map((item) => {
            const { rate, term, to, typeId } = item ?? {}
            return {
              ...item,
              rate: Percentage.formatPercentage(rate),
              term: term.toString(),
              to: to.toString(),
              typeId: typeId.toString(),
            }
          }),
        moratorium: terms
          .filter((period) => period.delinquency)
          .map((item) => {
            const { rate, term, to, typeId } = item ?? {}
            return {
              ...item,
              rate: Percentage.formatPercentage(rate),
              term: term.toString(),
              to: to.toString(),
              typeId: typeId.toString(),
            }
          }),
        commission: commissions.map((commission) => {
          return {
            ...commission,
            amount: Currency.formatCurrency(commission.amount),
            days: commission.days?.join(',') ?? '',
          }
        }),
      }

      setLine(lineConditions)
      setLineFormValues(lineConditionsForm)
      setIsLoadingData(false)
    }
    fetchLineConditions()
  }, [customer])

  useEffect(() => {
    if (line) {
      const verifyValue = (schema) => (object) => {
        const values = Object.entries(object).reduce(
          (acc, [key, value]) => ({ ...acc, [key]: `${value}` }),
          {}
        )
        return validateForm(schema, values)
      }

      const setErrors = () => {
        const { commissions, maxTerm, score, terms } = line

        const errorFormat = {
          line: {
            score: !score,
            maxTerm: !maxTerm,
            currency: false,
          },
          ordinary: terms
            .filter((period) => !period.delinquency)
            .map(verifyValue(ORDINARY_TERM_FORM)),
          moratorium: terms
            .filter((period) => period.delinquency)
            .map(verifyValue(MORATORIUM_TERM_FORM)),
          commission: commissions.map((commission) =>
            verifyValue(COMMISSION_TERM_FORM)({
              ...commission,
              days: commission.days?.join(',') ?? '',
            })
          ),
        }
        setFormErrorsProposal(errorFormat)
      }

      const id = setTimeout(() => {
        setErrors()
      }, 100)
      return () => clearTimeout(id)
    }
  }, [line])

  return (
    <>
      <CustomerCreditStudyStyled ref={toResetErrorRef}>
        <section className='header'>
          <LineConditionsView isLoading={isLoadingData} lineData={line} />
          <div className='header-section'>
            <BuildSection
              allowComment
              allowUpload={false}
              files={files}
              schema={CREDIT_ANALYSIS}
              setCommentBarInfo={setOpenCommentBar}
            />
          </div>
        </section>
        <h3>Indicadores financieros</h3>
        <div className='form grey-section'>
          <Form
            schema={CREDIT_STUDY_HEAD}
            formValues={financeFormValues}
            setFormValues={setFinanceFormValues}
            formErrors={formErrorsIndicators}
            setFormErrors={setFormErrorsIndicators}
            isValidating={isValidating}
          />
        </div>
        <div className='form'>
          <Form
            schema={CREDIT_STUDY}
            formValues={financeFormValues}
            setFormValues={setFinanceFormValues}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
            isValidating={isValidating}
          />
        </div>
        <div className='buildSection-two'>
          <BuildSection
            allowComment
            allowDelete
            allowUpdate
            files={files}
            setFiles={setFiles}
            isValidating={isValidating}
            schema={FINANCIAL_ANALYSIS.generalInformation.documents}
            setCommentBarInfo={setOpenCommentBar}
            title={FINANCIAL_ANALYSIS.generalInformation.title}
          />
        </div>
      </CustomerCreditStudyStyled>
      <FooterStyled>
        <div className='grey-footer'>
          <LineConditions
            isValidating={isValidating}
            lineForm={lineFormValues}
            setLineForm={setLineFormValues}
            formErrors={formErrorsProposal}
            setFormErrors={setFormErrorsProposal}
          />
        </div>
      </FooterStyled>
      <CreditStudyActionsStyled>
        <GeneralComment
          className='comment'
          comment={comment}
          setComment={setComment}
          setIsPublic={setIsPublic}
          error={isValidating && !comment}
        />
        <div className='buttons'>
          <Button onClick={() => setAction(undefined)}>Cancelar</Button>
          <div className='actions'>
            <Button buttonType='outline' onClick={() => setShowModal(true)}>
              {isLoadingSubmit && <LoadingAnimation className='loading' />}
              {!isLoadingSubmit && 'Rechazar'}
            </Button>
            <Button
              buttonType='blueDark'
              disabled={isLoadingSubmit}
              onClick={() => handleEvent()}
            >
              {isLoadingSubmit && <LoadingAnimation className='loading' />}
              {!isLoadingSubmit && 'Enviar'}
            </Button>
          </div>
        </div>
      </CreditStudyActionsStyled>
      {openCommentBar && (
        <LateralBar
          fileId={openCommentBar.fileId}
          documentName={openCommentBar.fileName}
          setlateralBar={setOpenCommentBar}
        />
      )}
      {showModal && (
        <ModalUpload
          setOpenModalUpload={setShowModal}
          messages={{
            msg: '¿Esta seguro que desea rechazar al prospecto? Si rechaza al prospecto ya no podrá retomar el proceso.',
            msgButton: 'Confirmar',
          }}
          handleSave={() => rejectCreditAnalysis()}
          isLoadingSubmit={isLoadingSubmit}
        />
      )}
      {showModalLine && (
        <ModalUpload
          setOpenModalUpload={setShowModalLine}
          messages={{
            msg: '¿Estas seguro que deseas modificar este monto de línea',
            msgButton: 'Confirmar',
          }}
          handleSave={() => acceptCreditAnalysis()}
          isLoadingSubmit={isLoadingSubmit}
        />
      )}
    </>
  )
}

const CreditStudyActionsStyled = styled.div`
  padding: 2em 5%;

  .loading span {
    background-color: white;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    .actions {
      display: flex;
      gap: 1rem;
    }
  }
`

const CustomerCreditStudyStyled = styled.div`
  padding: 5rem 5%;
  .header {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 1rem;

    .header-section {
      max-width: 50%;
      .file-section {
        min-width: min-content;
      }
    }
  }

  .buildSection {
    margin: -0.5rem 1rem;
  }
  .buildSection-two {
    width: 60%;
  }
  .square {
    width: 406px;
    border: 1px solid
      ${({ theme }) => theme.colors.grayShades[300].backgroundColor};
    border-radius: 11px;
    padding: 1.5rem;
    h4 {
      font-size: 18px;
      font-weight: bold;
    }
    .amount {
      font-size: 14px;
      font-weight: bold;
      margin-top: 2rem;
    }
    .flex-section {
      display: flex;
      align-items: center;
      .grey {
        background-color: ${({ theme }) =>
          theme.colors.grayShades[500].backgroundColor};
        font-size: 13px;
        border-radius: 6px;
        width: 46px;
        height: 36px;
        text-align: center;
        font-weight: bold;
        padding: 0px;
        line-height: 10px;
      }
      .money {
        font-size: 18px;
        margin-left: 0.5rem;
      }
    }
    table {
      thead {
        text-align: left;
        th {
          text-align: left;
          padding: 1rem 1rem 0rem 0rem;
        }
      }
      tbody {
        tr,
        td {
          text-align: left;
          padding: 1rem 3rem 0rem 0rem;
        }
      }
    }
  }
  h3 {
    margin: 3rem auto 2rem auto;
  }
  .grey-section {
    background-color: ${({ theme }) =>
      theme.colors.grayShades[300].backgroundColor};
    border-radius: 9px;
    width: 745px;
    display: flex;
    padding: 0 1em;
    .content {
      margin-left: 1rem;
      .light {
        color: ${({ theme }) => theme.colors.grayShades[800].backgroundColor};
      }
    }
    input {
      background-color: ${({ theme }) =>
        theme.colors.grayShades[300].backgroundColor};
    }
  }
  .form {
    display: grid;
    margin-top: 2rem;
    grid-template-columns: 1fr 1fr 1fr;
    & > div {
      padding: 1rem 1rem;
      &:nth-child(3n + 1) {
        padding-left: 0;
      }
      &:nth-child(3n) {
        padding-right: 0;
      }
    }
  }
`

const FooterStyled = styled.div`
  Input {
    background-color: ${({ theme }) =>
      theme.colors.grayShades[300].backgroundColor};
  }

  .grey-footer {
    margin-top: -1rem;
    padding: 0 2em 2em 2em;
    background-color: ${({ theme }) =>
      theme.colors.grayShades[300].backgroundColor};
    h4 {
      font-size: 18px;
      font-weight: bold;
      padding: 2rem 5% 1rem 5%;
    }
  }

  .proposal {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    justify-content: space-between;
    margin: auto;
    p {
      font-weight: bold;
    }
    .input-section {
      display: flex;
      justify-content: center;
      align-items: center;
      .grey {
        background-color: white;
        border-radius: 6px;
        height: 39px;
        margin-right: 1rem;
        text-align: center;
        width: 50px;
        p {
          margin: 0.5rem auto;
        }
      }
      .input {
        max-width: 150px;
        min-width: 200px;
      }
    }
    .time {
      p {
        margin-top: 1.2rem;
      }
    }
  }
`
export default CustomerCreditStudy
