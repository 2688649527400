export const FILE_STATES = {
  DRAFT: 'Draft',
  PENDING: 'Pending',
  ACCEPTED: 'Accepted',
  REFUSED: 'Refused',
  DELETED: 'Deleted',
  EXPIRED: 'EXPIRED',
}

export const FILE_STAGES = {
  ASSESSMENT: 'Assesment/preassesment',
  RISK: 'Riesgo legal',
  VISIT: 'Visita',
  CREDIT: 'Revision de credito',
  CONTRACT: 'Carga de firmantes',
  INSTRUMENTATION: 'Instrumentalización',
  READY: 'setup',
}

export const CONTRACT_STATES = {
  Draft: 'Draft',
  Instrumentalization: 'Instrumentalization',
  Review: 'Review',
  Correction: 'Correction',
  Active: 'Active',
  Inactive: 'Inactive',
}

/* FILE FORMS */
export const ASSESSMENT = {
  generalInformation: {
    title: 'Información general del cliente',
    moralPerson: [
      {
        label: 'Solicitud crédito completa y firmada',
        documentType: ['.pdf'],
        name: 'creditApplication',
        typeId: 1,
      },
      {
        label: 'Constancia de situación fiscal (RFC)',
        documentType: ['.pdf'],
        name: 'rfc',
        typeId: 2,
      },
      {
        label: 'Comprobante de domicilio del acreditado',
        documentType: ['.pdf'],
        name: 'addressProof',
        typeId: 3,
      },
      {
        label: 'Identificación Oficial del Acreditado o Representante Legal',
        documentType: ['.pdf'],
        name: 'identificationDocument',
        typeId: 4,
      },
      {
        label: 'Acta constitutiva y últimas actas de asamblea o cambios',
        documentType: ['.pdf'],
        name: 'incorporationArticles',
        typeId: 5,
      },
    ],
    naturalPerson: [
      {
        label: 'Solicitud crédito completa y firmada',
        documentType: ['.pdf'],
        name: 'creditApplication',
        typeId: 1,
      },
      {
        label: 'Constancia de situación fiscal (RFC)',
        documentType: ['.pdf'],
        name: 'rfc',
        typeId: 2,
      },
      {
        label: 'Comprobante de domicilio del acreditado',
        documentType: ['.pdf'],
        name: 'addressProof',
        typeId: 3,
      },
      {
        label: 'Identificación Oficial del Acreditado o Representante Legal',
        documentType: ['.pdf'],
        name: 'identificationDocument',
        typeId: 4,
      },
      {
        label: 'Acta constitutiva y últimas actas de asamblea o cambios',
        documentType: ['.pdf'],
        name: 'incorporationArticles',
        typeId: 5,
      },
    ],
  },
  mainAuctioneer: {
    title: 'Principal Accionista',
    moralPerson: [
      {
        label: 'Acta constitutiva',
        documentType: ['.pdf'],
        name: 'incorporationArticlesMainAuctioneer',
        typeId: 11,
      },
      {
        label: 'Comprobante de domicilio',
        documentType: ['.pdf'],
        name: 'addressProofMainAuctioneer',
        typeId: 8,
      },
      {
        label: 'Constancia de Situación Fiscal',
        documentType: ['.pdf'],
        name: 'taxStatusProof',
        typeId: 12,
      },
      {
        label: 'Identificación Oficial del Representante Legal',
        documentType: ['.pdf'],
        name: 'officialIdentification',
        typeId: 13,
      },
    ],
    naturalPerson: [
      {
        label: 'Identificación Oficial',
        documentType: ['.pdf'],
        name: 'identificationDocumentMainAuctioneer',
        typeId: 7,
      },
      {
        label: 'Comprobante de Domicilio',
        documentType: ['.pdf'],
        name: 'addressProofMainAuctioneer',
        typeId: 8,
      },
      {
        label: 'Constancia de Situación Fiscal',
        documentType: ['.pdf'],
        name: 'taxStatusCertificate',
        typeId: 9,
      },
      {
        label: 'Acta de matrimonio',
        documentType: ['.pdf'],
        name: 'marriageCertificate',
        typeId: 10,
      },
    ],
  },
  financialInformation: {
    title: 'Información Financiera',
    moralPerson: [
      {
        label: 'Estados financieros internos del cierre del último mes',
        documentType: ['.pdf'],
        name: 'internalFinancialStatement',
        typeId: 16,
      },
      {
        label: 'Declaración de impuestos del último mes',
        documentType: ['.pdf'],
        name: 'lastMonthTaxReturns',
        typeId: 17,
      },
      {
        label: 'Acuse de declaración de impuestos del último mes',
        documentType: ['.pdf'],
        name: 'lastMonthTax',
        typeId: 37,
      },
      {
        label:
          'Estados financieros anuales auditados firmados de los últimos 3 últimos años',
        documentType: ['.pdf'],
        name: 'annualFinancialStatements',
        typeId: 18,
      },
      {
        label: 'Declaración anual de  impuestos de los últimos 3 años',
        documentType: ['.pdf'],
        name: 'annualTaxReturn',
        typeId: 19,
      },
      {
        label: 'Acuse de declaración anual de impuestos de los últimos 3 años',
        documentType: ['.pdf'],
        name: 'lastThreeYear',
        typeId: 38,
      },
      {
        label: 'Estados de Cuenta de los últimos 12 meses',
        documentType: ['.pdf'],
        name: 'countStatements',
        typeId: 20,
      },
    ],
    naturalPerson: [
      {
        label: 'Estados financieros internos del cierre del último mes',
        documentType: ['.pdf'],
        name: 'internalFinancialStatement',
        typeId: 16,
      },
      {
        label: 'Declaración de impuestos del último mes',
        documentType: ['.pdf'],
        name: 'lastMonthTaxReturns',
        typeId: 17,
      },
      {
        label: 'Acuse de declaración de impuestos del último mes',
        documentType: ['.pdf'],
        name: 'lastMonthTax',
        typeId: 37,
      },
      {
        label:
          'Estados financieros anuales auditados firmados de los últimos 3 últimos años',
        documentType: ['.pdf'],
        name: 'annualFinancialStatements',
        typeId: 18,
      },
      {
        label: 'Declaración anual de  impuestos de los últimos 3 años',
        documentType: ['.pdf'],
        name: 'annualTaxReturn',
        typeId: 19,
      },
      {
        label: 'Acuse de declaración anual de impuestos de los últimos 3 años',
        documentType: ['.pdf'],
        name: 'lastThreeYear',
        typeId: 38,
      },
      {
        label: 'Estados de Cuenta de los últimos 12 meses',
        documentType: ['.pdf'],
        name: 'countStatements',
        typeId: 20,
      },
    ],
  },
}

export const PRE_ASSESSMENT = {
  generalInformation: {
    title: 'Información general del cliente',
    moralPerson: [
      {
        label: 'Solicitud crédito completa y firmada',
        documentType: ['.pdf'],
        name: 'creditApplication',
        typeId: 1,
      },
      {
        label: 'Constancia de situación fiscal (RFC)',
        documentType: ['.pdf'],
        name: 'rfc',
        typeId: 2,
      },
    ],
    naturalPerson: [
      {
        label: 'Solicitud crédito completa y firmada',
        documentType: ['.pdf'],
        name: 'creditApplication',
        typeId: 1,
      },
      {
        label: 'Constancia de situación fiscal (RFC)',
        documentType: ['.pdf'],
        name: 'rfc',
        typeId: 2,
      },
    ],
  },
  mainAuctioneer: {
    title: 'Principal Accionista',
    moralPerson: [],
    naturalPerson: [],
  },
  financialInformation: {
    title: 'Información Financiera',
    moralPerson: [
      {
        label: 'Estados financieros auditados o firmados del último año',
        documentType: ['.pdf'],
        name: 'financialStatements',
        typeId: 14,
      },
      {
        label: 'Declaración Anual de  Impuestos  del último año',
        documentType: ['.pdf'],
        name: 'annualTaxReturn',
        typeId: 15,
      },
      {
        label: 'Acuse de declaración anual de impuestos del último año',
        documentType: ['.pdf'],
        name: 'annualTaxYear',
        typeId: 36,
      },
      {
        label: 'Estados financieros internos del cierre del último mes',
        documentType: ['.pdf'],
        name: 'internalFinancialStatement',
        typeId: 16,
      },
      {
        label: 'Declaración de impuestos del último mes',
        documentType: ['.pdf'],
        name: 'lastMonthTaxReturns',
        typeId: 17,
      },
    ],
    naturalPerson: [
      {
        label: 'Estados financieros auditados o firmados del último año',
        documentType: ['.pdf'],
        name: 'financialStatements',
        typeId: 14,
      },
      {
        label: 'Declaración Anual de  Impuestos  del último año',
        documentType: ['.pdf'],
        name: 'annualTaxReturn',
        typeId: 15,
      },
      {
        label: 'Acuse de declaración anual de impuestos del último año',
        documentType: ['.pdf'],
        name: 'annualTaxYear',
        typeId: 36,
      },
      {
        label: 'Estados financieros internos del cierre del último mes',
        documentType: ['.pdf'],
        name: 'internalFinancialStatement',
        typeId: 16,
      },
      {
        label: 'Declaración de impuestos del último mes',
        documentType: ['.pdf'],
        name: 'lastMonthTaxReturns',
        typeId: 17,
      },
    ],
  },
}

export const CREDIT_ANALYSIS = [
  {
    label: 'PLD',
    name: 'pld',
    documentType: ['.pdf'],
    typeId: 22,
  },
  {
    label: 'Buró de crédito',
    name: 'buro',
    documentType: ['.pdf'],
    typeId: 23,
  },
]

export const COMMITTEE_SEND = {
  title: 'Análisis de riesgo',
  documents: [
    {
      label: 'PLD',
      name: 'pld',
      documentType: ['.pdf'],
      typeId: 22,
    },
    {
      label: 'Buró de crédito',
      name: 'buro',
      documentType: ['.pdf'],
      typeId: 23,
    },
    {
      label: 'RPP',
      name: 'rpp',
      documentType: ['.pdf'],
      typeId: 24,
    },
    {
      label: 'Dictamen',
      name: 'dictamen',
      documentType: ['.pdf'],
      typeId: 25,
    },
  ],
}

export const FINANCIAL_ANALYSIS = {
  generalInformation: {
    title: 'Documento de análisis financiero',
    titleTwo: 'Generación de contrato',
    documents: [
      {
        label: 'Documento Análisis financiero',
        name: 'financialAnalisys',
        documentType: [
          '.xls',
          '.xlsx',
          '.csv',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/vnd.ms-excel',
        ],
        typeId: 29,
      },
    ],
  },
}

export const CONTRACT_DOCUMENTS = {
  generalInformation: {
    title: 'Carga de contratos firmados',
    documents: [
      {
        label: 'Contrato firmado',
        name: 'signedContract',
        documentType: ['.pdf', 'image/*'],
        typeId: 31,
      },
      {
        label: 'Anexo A',
        name: 'annexA',
        documentType: ['.pdf', 'image/*'],
        typeId: 32,
      },
      {
        label: 'Pagaré',
        name: 'promissoryNote',
        documentType: ['.pdf', 'image/*'],
        typeId: 33,
      },
      {
        label: 'Formato Proveedores',
        name: 'providers',
        documentType: ['.pdf', 'image/*'],
        typeId: 34,
      },
      {
        label: 'Contrato de garantías (opcional)',
        name: 'guarantee',
        documentType: ['.pdf', 'image/*'],
        typeId: 35,
        isMandatory: false,
      },
    ],
  },
}

export const LEGAL_RISK = [
  {
    label: 'Revisión RPP',
    name: 'rpp',
    documentType: ['.pdf', 'image/*'],
    typeId: 24,
  },
  {
    label: 'Dictamen',
    name: 'report',
    documentType: ['.pdf', 'image/*'],
    typeId: 25,
  },
]

export const LEGAL_VALIDATION = [
  {
    label: 'documento 1',
    name: 'document1',
    documentType: ['.pdf', 'image/*'],
    typeId: 22,
  },
  {
    label: 'documento 2',
    name: 'document2',
    documentType: ['.pdf', 'image/*'],
    typeId: 23,
  },
]

export const VISIT_REGISTER = [
  {
    label: 'Documento Visita',
    name: 'visitDocument',
    documentType: ['.pdf'],
    typeId: 28,
  },
]

export const PAID = [
  {
    label: 'Archivos de pago',
    name: 'paymentFile',
    documentType: ['.xls', '.xlsx', '.csv'],
    typeId: 0,
  },
]
