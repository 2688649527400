import {
  Button,
  Icon,
  Input,
  LoadingAnimation,
  Textarea,
} from '@keoworld/gbl-ui-kit'
import Form from 'containers/form'
import LateralBar from 'containers/lateral-bar'
import LineConditionsView from 'containers/line/line-conditions-view'
import ModalUpload from 'containers/Modal/modal-documents-upload'
import { AlertContext } from 'providers/alert'
import { CustomerContext } from 'providers/customer'
import { LateralBarContext, LATERAL_MENU_OPTIONS } from 'providers/lateral-bar'
import { useContext, useEffect, useState } from 'react'
import {
  assignLine,
  evaluateLine,
  financeFind,
  getCustomerLines,
  getLineConditions,
} from 'services/lines'
import { instanceTransition } from 'services/workflow'
import styled from 'styled-components'
import {
  canBeFormatted,
  extractNumber,
} from 'utils/functions/handlers-currency'
import { useAuth } from 'utils/hooks/auth'
import { useFetchDocuments } from 'utils/hooks/fetch-documents'
import { CUSTOMER_STATES } from 'utils/schemas/customer'
import {
  CREDIT_ANALYSIS,
  FILE_STAGES,
  FINANCIAL_ANALYSIS,
} from 'utils/schemas/documents'
import { FINANCIAL_INDICATORS } from 'utils/schemas/line'
import { BuildSection } from '../document-actions/build-section'

const REVIEW_DOCUMENTS_FILTER = { state: FILE_STAGES.CREDIT }

const LineInformation = ({ line }) => {
  const { customer } = useContext(CustomerContext)
  const [lineInformation, setLineInformation] = useState()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      if (!customer?.lineId) return
      setIsLoading(true)
      const lineConditions = await getLineConditions(customer.lineId)
      setLineInformation(lineConditions)
      setIsLoading(false)
    }
    fetchData()
  }, [customer?.lineId])

  return (
    <div className='line-information'>
      <h3>Propuesta de negocio</h3>
      <LineConditionsView isLoading={isLoading} lineData={lineInformation} />
    </div>
  )
}

const RejectModal = ({ onClose, onConfirm }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [reason, setReason] = useState('')

  const onHandleChange = (e) => {
    setReason(e.target.value)
  }

  const confirm = async () => {
    if (!reason) return
    setIsLoading(true)
    await onConfirm(reason)
    await onClose()
    setIsLoading(false)
  }

  return (
    <div className='modal-bg'>
      <div className='modal'>
        <h3>
          <Icon name='cancel' type='outlined' />
          Rechazado
        </h3>
        <p>Comenta las diferentes razones por las cuales lo rechazas.</p>
        <Textarea
          name='reason'
          value={reason}
          onChange={onHandleChange}
          error={!reason}
        />
        <div className='actions'>
          <Button
            buttonType='grayButton'
            onClick={onClose}
            disabled={isLoading}
          >
            Cancelar
          </Button>
          <Button onClick={confirm} disabled={isLoading}>
            Aceptar
          </Button>
        </div>
      </div>
    </div>
  )
}

const formatToCurrency = (value) => {
  if (!canBeFormatted(value)) return value
  const number = extractNumber(value)

  return Intl.NumberFormat('es-MX', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number)
}

const CommitteeSend = () => {
  const [documents, setDocuments] = useState({})
  const [lineInformation, setLineInformation] = useState({})
  const [financialForm, setFinancialForm] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [isRejectedProspect, setIsRejectedProspect] = useState(false)
  const [openCommentBar, setOpenCommentBar] = useState()
  const { setAction, setSelectedOption } = useContext(LateralBarContext)
  const { customer } = useContext(CustomerContext)
  const { user } = useAuth()
  const { setAlert } = useContext(AlertContext)
  const files = useFetchDocuments(customer?.id, REVIEW_DOCUMENTS_FILTER)

  const isOnCommittee =
    customer?.lineInstance?.currentState === 'line_minorCommittee' ||
    customer?.lineInstance?.currentState === 'line_majorCommittee'

  const onClickRejectButton = () => {
    setIsRejectedProspect(true)
    setOpenModal(true)
  }

  const rejectAction = async () => {
    setIsLoading(true)
    try {
      if (!customer) throw new Error('No customer found - committee')

      const { REJECT } = CUSTOMER_STATES[customer.wfState].transition
      const { event, nextState } = REJECT

      await instanceTransition(
        customer.lineInstance.workflowId,
        `${customer.customerInstance.instanceId}.${customer.lineInstance.instanceId}`,
        event,
        {
          'wfState:status': nextState,
          'customerId:status': customer.id,
        }
      )
    } catch (error) {
      setAlert({
        title: 'Error',
        label: 'Ha ocurrido un error.',
        type: 'error',
      })
      console.error('Error:', error)
    } finally {
      setIsLoading(false)
      setIsRejectedProspect(false)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      setAction('')
    }
  }

  const rejectModal = async (comment) => {
    try {
      await evaluateLine(
        lineInformation.lineId,
        customer.id,
        customer.lineInstance.workflowId,
        user.uid,
        'Rejected',
        comment
      )
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      setAction('')
    } catch (error) {
      console.log(error)
    }
  }

  const approve = async () => {
    try {
      setIsLoading(true)
      if (!lineInformation || !customer) {
        throw new Error("Can't approve")
      }
      if (!isOnCommittee) {
        await assignLine(
          lineInformation.lineId,
          Number(lineInformation?.score),
          customer.id,
          customer.lineInstance.workflowId
        )
      } else {
        await evaluateLine(
          lineInformation.lineId,
          customer.id,
          customer.lineInstance.workflowId,
          user.uid,
          'Approved'
        )
      }
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      setAction('')
    } catch (error) {
      setAlert({
        title: 'Error',
        label: 'Ha ocurrido un error.',
        type: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const getDocs = async () => {
      const schemas = [
        ...CREDIT_ANALYSIS,
        ...FINANCIAL_ANALYSIS.generalInformation.documents,
      ]

      const docs = schemas.reduce((acc, schema) => {
        const { typeId } = schema
        const documentsByType = files
          .filter((doc) => doc.typeId === typeId)
          .map((file) => ({
            id: file.fileId,
            typeId,
            name: `${file.fileName}.${file.fileExtension}`,
            size: file.fileSize,
            status: file.fileStatus,
          }))
        return {
          ...acc,
          [typeId]: documentsByType,
        }
      }, {})

      setDocuments(docs)
    }
    getDocs()
  }, [files])

  useEffect(() => {
    const getLine = async () => {
      const { data } = await getCustomerLines(customer.id)
      setLineInformation(data[0])
    }

    const getFinanceOptions = async () => {
      const [finance] = await financeFind(customer.id)

      const formattedObject = FINANCIAL_INDICATORS.reduce((acc, curr) => {
        const { name, validatorType } = curr
        let value = finance[name]
        if (validatorType === 'currency') {
          value = formatToCurrency(value)
        }
        return { ...acc, [name]: value }
      }, {})

      setFinancialForm({
        ...formattedObject,
        cutoffDate: finance.cutoffDate,
        source: finance.source,
      })
    }
    if (customer?.id) {
      getLine()
      getFinanceOptions()
    }
  }, [customer?.id])

  return (
    <CommitteeSendStyled>
      <section className='credit'>
        <LineInformation line={lineInformation} />
        <div className='documents'>
          <BuildSection
            allowComment
            allowUpload={false}
            files={documents}
            schema={CREDIT_ANALYSIS}
            setCommentBarInfo={setOpenCommentBar}
            title='Análisis de crédito'
          />
        </div>
      </section>
      <section className='financial'>
        <h3>Indicadores financieros</h3>
        <div className='financial-card'>
          <Input
            label='Fecha corte información financiera'
            type='date'
            value={financialForm?.cutoffDate}
            disabled
          />
          <Input
            label='Fuente de datos'
            placeholder='Ejemplo: Texto'
            value={financialForm?.source}
            disabled
          />
        </div>
        <div className='financial-form'>
          <Form
            schema={FINANCIAL_INDICATORS}
            formValues={financialForm}
            isValidating={false}
          />
        </div>
      </section>
      <section className='financial-analysis'>
        <BuildSection
          allowComment
          allowUpload={false}
          files={documents}
          schema={FINANCIAL_ANALYSIS.generalInformation.documents}
          setCommentBarInfo={setOpenCommentBar}
          title='Documentos análisis financiero'
        />
      </section>
      <div className='actions'>
        <Button
          buttonType='outline'
          onClick={() => onClickRejectButton()}
          disabled={isLoading}
        >
          {isLoading ? <LoadingAnimation /> : 'Rechazar'}
        </Button>
        <Button buttonType='blueDark' onClick={approve} disabled={isLoading}>
          {isLoading ? <LoadingAnimation /> : 'Aprobar'}
        </Button>
      </div>
      {isOnCommittee && openModal && (
        <RejectModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          onConfirm={rejectModal}
        />
      )}
      {openCommentBar && (
        <LateralBar
          fileId={openCommentBar.fileId}
          documentName={openCommentBar.fileName}
          setlateralBar={setOpenCommentBar}
        />
      )}
      {!isOnCommittee && isRejectedProspect && (
        <ModalUpload
          setOpenModalUpload={setIsRejectedProspect}
          messages={{
            msg: '¿Esta seguro que desea rechazar al prospecto? Si rechaza al prospecto ya no podrá retomar el proceso.',
            msgButton: 'Aceptar',
          }}
          isLoadingSubmit={isLoading}
          handleSave={rejectAction}
        />
      )}
    </CommitteeSendStyled>
  )
}

const CommitteeSendStyled = styled.main`
  padding: 2rem;
  padding-bottom: 1rem;

  section.credit {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: start;
    gap: 10px;

    .documents {
      padding: 10px 15px;
      border: 1px solid #e6e6e6;
      border-radius: 9px;

      h5 {
        margin: auto !important;
        margin-bottom: 1rem;
      }
    }

    .line-information {
      padding: 10px 15px;

      border: 1px solid #e6e6e6;
      border-radius: 9px;

      min-width: 650px;

      .requested-amount {
        padding: 15px 0;
        .currency {
          padding: 5px;
          margin: 0 5px;
          background-color: #f5f5f5;
        }
      }
      .table {
        padding-top: 15px;
        border-top: 1px solid #000;

        display: grid;
        grid-template-columns: 1fr 0.5fr 1fr 0.5fr;
        gap: 10px;
      }
    }
    .document-list {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      width: 40%;

      .document {
        width: 100%;
        min-width: auto;
      }
    }
  }
  section.financial {
    margin-top: 15px;
    .financial-card {
      display: flex;
      gap: 15px;

      max-width: 700px;
      padding: 15px;
      margin-top: 15px;

      border-radius: 9px;

      background-color: #efefef;

      & > div {
        flex: 1;
      }

      input {
        background-color: transparent;
        width: 100%;
      }
    }
    .financial-form {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 15px;

      margin-top: 10px;
    }
  }
  section.financial-analysis {
    margin-top: 20px;
  }
  .actions {
    margin-top: 25px;
    button + button {
      margin-left: 28px;
    }
  }
  .modal-bg {
    position: absolute;
    z-index: 6;

    height: 100%;
    width: 100%;

    top: 0;
    left: 0;

    background-color: rgb(0, 0, 0, 0.4);

    .modal {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      padding: 30px 40px;
      border-radius: 9px;

      text-align: center;

      background: white;

      h3 {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
      }

      textarea {
        background: transparent;
      }
    }
  }
`

export default CommitteeSend
