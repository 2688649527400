import {
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  updateDoc
} from 'firebase/firestore'
import { firestore } from 'services/firebase.controller'

/**
 * function to subscribe from notifications
 * @param {ObserverCallback} callback - callback to set notifications
 * @param {number} userIdIam - user id iam
 * @returns {function} unsubscribe
 */
export const subscriptionToNotifications = (callback, userIdIam) => {
  if (typeof callback !== 'function') {
    throw new Error('Observer must be a function')
  }

  if (!userIdIam) {
    throw new Error('User must be signed in')
  }
  const ref = collection(firestore, 'users', `${userIdIam}`, 'notifications')
  const observer = (snapshot) =>
    callback(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))

  return onSnapshot(
    query(ref, orderBy('createdAt', 'desc'), limit(15)),
    observer
  )
}

/**
 * 
 * @param {function} callback 
 * @param {number} userIdIam - user id iam
 * @returns 
 */
export const getPendingNotifications = (callback, userIdIam) => {
  if (typeof callback !== 'function') {
    throw new Error('Observer must be a function')
  }

  if (!userIdIam) {
    throw new Error('User must be signed in')
  }

  const ref = collection(firestore, 'users', `${userIdIam}`, 'notifications')
  const observer = (snapshot) =>
    callback(snapshot.docs.reduce((acc,doc) => ( !(doc.data().read) ? acc + 1 : acc ), 0) )
  return onSnapshot(ref, observer)
}

/**
 * function to mark notification as read
 * @param {string} id - notification id
 * @param {number} userIdIam - user id iam
 * @param {boolean} read - notification read status
 */
export const markAsRead = async (id, userIdIam, read) => {
  if (!userIdIam) {
    throw new Error('User must be signed in')
  }

  const userRef = doc(firestore, 'users', `${userIdIam}`)
  const notificationRef = doc(userRef, 'notifications', id)
  
  await updateDoc(notificationRef, { read })
}

/**
 * @callback ObserverCallback
 * @param {Object[]} notifications
 */
