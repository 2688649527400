import { Button, Icon, Textarea } from '@keoworld/gbl-ui-kit'
import PropTypes from 'prop-types'
import { AlertContext } from 'providers/alert'
import { SessionContext } from 'providers/session-manager'
import { useContext, useState } from 'react'
import { collectionCreate } from 'services/lines'
import {
  executeFreeze,
  executeUnFreeze,
  executeWriteOff,
  restructureLoan,
} from 'services/loan'
import { cancelPayments } from 'services/payments'
import styled from 'styled-components'
import { dateFormatFromIso, getUTCDateTime } from 'utils/functions/date'
import { currency } from 'utils/functions/formatters'
import { ApiError } from 'utils/schemas/errors'
import { POPUP_CONFIG, PORTFOLIO_ACTIONS } from 'utils/schemas/portfolio'

const PaymentPopup = (props) => {
  const { loan, config, onClose } = props
  const { setAlert } = useContext(AlertContext)
  const { userAuth } = useContext(SessionContext)
  const [text, setText] = useState('')
  const [loading, setLoading] = useState(false)
  const configuration = POPUP_CONFIG[config.action] || POPUP_CONFIG.default

  const handleReason = (e) => {
    const value = e.target.value
    setText(value)
  }

  const onContinue = async () => {
    setLoading(true)
    if (config.action === PORTFOLIO_ACTIONS.freezeLoan) {
      await freezeLoan({ loanId: loan.id, reason: text })
    }
    if (config.action === PORTFOLIO_ACTIONS.unfreezeLoan) {
      await unfreezeLoan({ loanId: loan.id, reason: text })
    }
    if (config.action === PORTFOLIO_ACTIONS.writeOff) {
      await cancelLoan(loan.id, userAuth.iam.id, text)
    }
    if (config.action === PORTFOLIO_ACTIONS.restructureLoan) {
      await executeRestructureLoan(loan.id, userAuth.iam.id, text)
    }
    if (config.action === PORTFOLIO_ACTIONS.cancelPayments) {
      await executeCancelPayments(loan.id, userAuth.iam.id, text)
    }
    if (config.action === PORTFOLIO_ACTIONS.paymentCondonation) {
      await executePaymentCondonation(loan.id, userAuth.iam.id, text)
    }
    onClose()
    setLoading(false)
  }

  const freezeLoan = async ({ loanId, reason }) => {
    const now = new Date()

    let freezeAt = new Date(
      `${config.formValues.freezingDate}T${now.toTimeString().split(' ')[0]}`
    )

    freezeAt = getUTCDateTime(freezeAt)

    let autoUnfreezeAt = config.formValues.unfreezingDate
    if (autoUnfreezeAt) {
      autoUnfreezeAt = new Date(
        `${autoUnfreezeAt}T${now.toTimeString().split(' ')[0]}`
      )
      autoUnfreezeAt.setHours(now.getHours())
      autoUnfreezeAt = getUTCDateTime(autoUnfreezeAt)
    }

    const response = await executeFreeze({
      loanId,
      reason,
      freezeAt,
      autoUnfreezeAt,
      iamId: userAuth.iam.id,
    })

    if (response.type === 'success') {
      setAlert({
        type: 'success',
        title: '¡Listo!:',
        label: configuration.successMessage.replace('{loanId}', loan.id),
      })
    } else if (response.title === 'freeze-already-scheduled') {
      setAlert({
        type: 'error',
        title: 'Ha ocurrido un error:',
        label: 'El préstamo ya está programado para ser congelado',
      })
    } else {
      setAlert({
        type: 'error',
        title: 'Ha ocurrido un error:',
        label: configuration.errorMessage.replace('{loanId}', loan.id),
      })
    }
  }

  const unfreezeLoan = async ({ loanId, reason }) => {
    const now = new Date()

    let unfreezeAt = new Date(
      `${config.formValues.unfreezingDate}T${now.toTimeString().split(' ')[0]}`
    )

    unfreezeAt = getUTCDateTime(unfreezeAt)

    const response = await executeUnFreeze({
      loanId,
      reason,
      unfreezeAt,
      iamId: userAuth.iam.id,
    })

    if (response.type === 'success') {
      setAlert({
        type: 'success',
        title: '¡Listo!:',
        label: configuration.successMessage.replace('{loanId}', loan.id),
      })
    } else if (response.title === 'unfreeze-already-scheduled') {
      setAlert({
        type: 'error',
        title: 'Ha ocurrido un error:',
        label: 'El préstamo ya está programado para ser descongelado',
      })
    } else {
      setAlert({
        type: 'error',
        title: 'Ha ocurrido un error:',
        label: configuration.errorMessage.replace('{loanId}', loan.id),
      })
    }
  }

  /**
   * Function to cancel a loan
   * @param {number} loanId
   * @param {number} iamId
   * @param {string} reason
   */
  const cancelLoan = async (loanId, iamId, reason) => {
    try {
      await executeWriteOff({ loanId, reason, iamId })
      setAlert({
        type: 'success',
        title: '¡Listo!:',
        label: configuration.successMessage.replace('{loanId}', loan.id),
      })
    } catch (error) {
      setAlert({
        type: 'error',
        title: 'Ha ocurrido un error:',
        label: configuration.errorMessage.replace('{loanId}', loan.id),
      })
    }
  }

  /**
   * Function to execute the restructure loan action
   * @param {number} loanId
   * @param {number} iamId
   * @param {string} reason
   */
  const executeRestructureLoan = async (loanId, iamId, reason) => {
    try {
      const response = await restructureLoan({ loanId, iamId, reason })
      setAlert({
        type: 'success',
        title: '¡Listo!:',
        label: configuration.successMessage.replace(
          '{loanId}',
          response.data.loanId
        ),
      })
    } catch (error) {
      if (error instanceof ApiError) {
        setAlert({
          type: 'error',
          title: 'Ha ocurrido un error:',
          label: error.message,
        })
      } else {
        setAlert({
          type: 'error',
          title: 'Ha ocurrido un error:',
          label: configuration.errorMessage,
        })
      }
    }
  }

  /**
   * Function to execute the cancel payments action
   * @param {number} loanId
   * @param {number} iamId
   * @param {string} reason
   */
  const executeCancelPayments = async (loanId, iamId, reason) => {
    try {
      const payments = config.payments.map(({ id }) => id)
      await cancelPayments({ loanId, iamId, reason, payments })
      setAlert({
        type: 'success',
        title: '¡Listo!',
        label: configuration.successMessage.replace('{loanId}', loanId),
      })
    } catch (error) {
      setAlert({
        type: 'error',
        title: 'Ha ocurrido un error:',
        label: configuration.errorMessage,
      })
    }
  }

  /**
   * Function to execute the payment condonation action
   * @param {number} loanId
   * @param {number} iamId
   * @param {string} reason
   */
  const executePaymentCondonation = async (loanId, iamId, reason) => {
    try {
      const payment = { ...config.payment, reason, iamId }
      await collectionCreate(payment)
      setAlert({
        type: 'success',
        title: '¡Listo!',
        label: configuration.successMessage.replace('{loanId}', loanId),
      })
    } catch (error) {
      setAlert({
        type: 'error',
        title: 'Ha ocurrido un error:',
        label: configuration.errorMessage,
      })
    }
  }

  return (
    <Styles>
      <section className='content'>
        <Button
          className='close-action'
          size='action'
          onClick={() => onClose()}
        >
          <Icon name='close' />
        </Button>

        <div className='action-icon' data-mark={`${configuration.mark}`}>
          <Icon name={configuration.icon} type='outlined' />
        </div>

        <h3>{configuration.title}</h3>

        <div>
          <div>
            <span className='bold'>ID préstamo: </span>
            <span>{loan.id}</span>
          </div>
          <div>
            {config.action === PORTFOLIO_ACTIONS.cancelPayments && (
              <>
                <span className='bold'>Monto total que se anulará: </span>
                {config.payments && (
                  <span>
                    {currency(
                      config.payments.reduce(
                        (acc, { amount }) => acc + amount,
                        0
                      )
                    )}
                  </span>
                )}
              </>
            )}
            {config.action === PORTFOLIO_ACTIONS.paymentCondonation && (
              <>
                <span className='bold'>Monto a condonar: </span>
                <span>{currency(config.payment.amount)}</span>
              </>
            )}
            {config.action !== PORTFOLIO_ACTIONS.cancelPayments &&
              config.action !== PORTFOLIO_ACTIONS.paymentCondonation && (
                <>
                  <span className='bold'>Monto: </span>
                  <span>{currency(loan.amount)}</span>
                </>
              )}
          </div>
          <div>
            <span className='bold'>Fecha de solicitud: </span>
            <span>{dateFormatFromIso(loan.createdAt, 'dd/MM/yyyy')}</span>
          </div>
        </div>

        <Textarea
          className='input'
          label={configuration.inputLabel}
          name='explain'
          maxLength={1000}
          rows={1}
          value={text}
          onChange={handleReason}
        />

        {configuration.showMessage && (
          <div className='message-info'>
            <Icon name='info' type='outlined' />
            <span>
              {config.action === PORTFOLIO_ACTIONS.restructureLoan && (
                <>
                  Recuerda que al reestructurar una disposición{' '}
                  <span className='bold'>
                    NO se podrá anular esta acción ni podrán realizarse más
                    acciones
                  </span>{' '}
                  sobre la disposición.
                  <br />
                  <br />
                  La nueva disposición se creará con las condiciones que se
                  encuentren activas en línea.
                </>
              )}
              {config.action === PORTFOLIO_ACTIONS.writeOff && (
                <>
                  Recuerda que al enviar a Write off una disposición{' '}
                  <span className='bold'>
                    NO se podrá anular esta acción ni podrán realizarse más
                    acciones
                  </span>{' '}
                  sobre la disposición.
                </>
              )}
            </span>
          </div>
        )}

        <div className='actions'>
          <Button
            buttonType='outlineBlueDark'
            onClick={() => onClose()}
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button
            buttonType='blueDark'
            onClick={() => onContinue()}
            disabled={loading}
          >
            Continuar
          </Button>
        </div>
      </section>
    </Styles>
  )
}

const Styles = styled.div`
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;

  background-color: rgba(0, 0, 0, 0.5);

  section.content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;

    width: 500px;
    padding: 3rem;

    background: #ffffff;
    border-radius: 10px;

    &::before {
      content: '';

      position: absolute;
      top: 3px;
      left: 50%;
      transform: translate(-50%, -50%);

      width: 228px;
      height: 7px;

      background: #00172d;
      border-radius: 10px;
    }

    .bold {
      font-weight: bold;
    }

    .close-action {
      position: absolute;
      top: 0;
      right: 0;

      background-color: transparent;
      color: #00172d;
    }

    .action-icon {
      height: 75px;
      width: 75px;
      border-radius: 50%;

      margin: auto;

      background-color: rgba(12, 53, 92, 0.2);

      display: grid;
      place-items: center;

      .icon {
        font-size: 48px;
      }
      &[data-mark='true']::after {
        content: '';
        position: absolute;
        width: 50px;
        border: 2px solid #ff0045;
        transform: rotate(-45deg);
      }
    }

    h3 {
      text-align: center;
      color: #00172d;
    }

    .input {
      margin-top: 1rem;
    }

    .message-info {
      display: flex;
      gap: 1rem;
      margin-top: 1rem;

      .icon {
        font-size: 30px;
        color: #ff0045;
      }
    }

    .actions {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }
  }
`

PaymentPopup.propTypes = {
  action: PropTypes.string,
  loan: PropTypes.object,
  config: PropTypes.object,
  onClose: PropTypes.func,
}

export default PaymentPopup
