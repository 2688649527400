import { Button, Icon, LoadingAnimation, Modal } from '@keoworld/gbl-ui-kit'
import styled from 'styled-components'

const DEFAULT_MESSAGE =
  'Estás a punto de guardar los documentos cargados, ' +
  'está acción no cambiara el estado del cliente. ' +
  '¿Estás seguro de continuar?'

const ModalUpload = (props) => {
  const {
    setOpenModalUpload,
    isLoadingSubmit,
    handleSave,
    messages,
    handleSubmit,
  } = props

  return (
    <ModalUploadStyled>
      <Icon
        name='close'
        onClick={() => {
          setOpenModalUpload(false)
        }}
      />
      <div className='modal-content-one'>
        <h4>Confirmar</h4>
        {messages?.mdu && <p>{messages.mdu}</p>}
        {!messages?.mdu && <p>{messages?.msg ?? DEFAULT_MESSAGE}</p>}
        <div className='button-section'>
          <Button
            disabled={isLoadingSubmit}
            onClick={() => {
              setOpenModalUpload(false)
            }}
            buttonType='outlineBlueDark'
          >
            Cancelar
          </Button>
          {!messages?.mdu && (
            <Button
              buttonType='blueDark'
              disabled={isLoadingSubmit}
              onClick={handleSave}
            >
              {isLoadingSubmit && <LoadingAnimation className='loading' />}
              {!isLoadingSubmit && (
                <span>{messages?.msgButton || 'Guardar'}</span>
              )}
            </Button>
          )}
          {messages?.moratorium && (
            <Button
              buttonType='blueDark'
              disabled={isLoadingSubmit}
              onClick={handleSubmit}
            >
              {isLoadingSubmit && <LoadingAnimation className='loading' />}
              {!isLoadingSubmit && (messages?.msgButton || 'Guardar')}
            </Button>
          )}
        </div>
      </div>
    </ModalUploadStyled>
  )
}

const ModalUploadStyled = styled(Modal)`
  .content_modal_document {
    position: relative;
  }
  .icon {
    cursor: pointer;
    float: right;
    margin: 1rem;
  }
  .loading span {
    background-color: white;
  }

  h4 {
    font-size: 20px;
    font-weight: bold;
  }
  p {
    width: 339px;
    margin: 1rem auto;
  }
  .button-section {
    margin-top: 2.5rem;
    button {
      margin: auto 1rem;
    }
  }
  .card-modal {
    width: auto;
    height: auto;
    min-height: auto;
    border-radius: 21px;
    .modal-content-one {
      width: 500px;
      height: 15rem;
      text-align: center;
      margin-top: 4rem;
      &:before {
        content: '';
        position: absolute;
        width: 228px;
        height: 7px;
        left: 140px;
        bottom: 223pt;
        border-radius: 10px;
        flex-shrink: 0;
        background-color: #00172d;
      }
    }
  }
  Button {
    div {
      span {
        color: white !important;
        background-color: white;
      }
    }
  }
`

export default ModalUpload
