import ModalUpload from 'containers/Modal/modal-documents-upload'
import DebtCapacity from 'containers/debt-capacity/debt-capacity'
import Form, { validateFormErrors } from 'containers/form'
import GeneralComment from 'containers/general-comment'
import LateralBar from 'containers/lateral-bar'
import { AlertContext } from 'providers/alert'
import { CustomerContext } from 'providers/customer'
import { LATERAL_MENU_OPTIONS, LateralBarContext } from 'providers/lateral-bar'
import { uploadDocuments } from 'services/documents'
import { instanceTransition } from 'services/workflow'
import { BuildSection } from 'templates/actions/document-actions/build-section'
import {
  canBeFormatted,
  extractNumber,
} from 'utils/functions/handlers-currency'
import { getUserRoles } from 'utils/functions/role-manager'
import { useAuth } from 'utils/hooks/auth'
import { useFetchDocuments } from 'utils/hooks/fetch-documents'
import { CREDIT_STUDY, CREDIT_STUDY_HEAD } from 'utils/schemas/customer'
import {
  CREDIT_ANALYSIS,
  FILE_STAGES,
  FILE_STATES,
  FINANCIAL_ANALYSIS,
} from 'utils/schemas/documents'
import { ONBOARDING_STATES } from 'utils/schemas/workflows/onboarding'

import { Button, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiDebtCapacityRepository } from 'services/debt-capacity-suppcards/debtCapacity.repository'
import styled from 'styled-components'
import { v4 as UUIDv4 } from 'uuid'

const FILE_STAGE_RISK = { stage: FILE_STAGES.RISK }
const FILE_STAGE_CREDIT = { stage: FILE_STAGES.CREDIT }

const ERRORS = {
  'missing-documents': {
    close: false,
    label: 'Por favor sube todos los documentos solicitados',
  },
  'missing-form-fields': {
    close: false,
    label: 'Completa todos los campos antes de enviar',
  },
  'missing-comment': {
    close: false,
    label: 'Por favor agrega un comentario',
  },
  'error-uploading-documents': {
    close: true,
    label:
      'Ha ocurrido un error al guardar los archivos, por favor ingresa al prospecto e intenta subir los documentos de nuevo',
  },
  workflow: {
    close: true,
    label:
      'Los documentos se han guardado, pero ha ocurrido un error al enviar. Por favor contacta a soporte',
  },
  default: {
    close: true,
    label: 'Ha ocurrido un error al enviar. Por favor recarga la pagina',
  },
}

const FinancialAnalysis = () => {
  const { setAction, setSelectedOption } = useContext(LateralBarContext)
  const { setAlert } = useContext(AlertContext)
  const { customer } = useContext(CustomerContext)

  const [proposal, setProposal] = useState()
  const [debtCapacities, setDebtCapacities] = useState([])
  const [debtCapacitiesError, setDebtCapacitiesError] = useState([])
  const [financialFiles, setFinancialFiles] = useState([])
  const [sourceForm, setSourceForm] = useState({})
  const [sourceFormErrors, setSourceFormErrors] = useState({})
  const [financeFormValues, setFinanceFormValues] = useState({})
  const [financeFormErrors, setFinanceFormErrors] = useState({})
  const [commentBar, setCommentBar] = useState(undefined)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [isValidating, setIsValidating] = useState(false)
  const [isShowingConfirmationModal, setIsShowingConfirmationModal] =
    useState(false)
  const [generalComment, setGeneralComment] = useState({
    comment: '',
    isPublic: true,
  })

  const { user } = useAuth()
  const [userRole] = getUserRoles()
  const { customerId } = useParams()
  const navigate = useNavigate()

  const riskDocuments = useFetchDocuments(customerId, FILE_STAGE_RISK)
  const creditDocuments = useFetchDocuments(customerId, FILE_STAGE_CREDIT)

  const {
    rejectFinance,
    approveFinance,
    addDebtCapacity,
    deleteDebtCapacity,
    updateDebtCapacity,
  } = ONBOARDING_STATES.FINANCE_ANALYSIS.transition

  const userUID = user?.uid

  useEffect(() => {
    const documents = [...riskDocuments, ...creditDocuments]
    const savedFiles = documents.reduce((acc, itm) => {
      const fileTypeDocs = acc[itm.typeId] || []
      const extension = itm.fileExtension ? `.${itm.fileExtension}` : ''
      fileTypeDocs.push({
        name: `${itm.fileName}${extension}`,
        size: itm.fileSize,
        status: itm.fileStatus,
        id: itm.fileId,
        fileExtension: itm.fileExtension,
      })
      return { ...acc, [itm.typeId]: fileTypeDocs }
    }, {})

    setFinancialFiles(savedFiles)
  }, [riskDocuments, creditDocuments])

  useEffect(() => {
    const fetchProposal = async () => {
      const numberFormat = Intl.NumberFormat('es-MX', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })

      if (!customerId) return
      const proposal = await ApiDebtCapacityRepository.getByBuyerId(customerId)

      setProposal(proposal)
      setDebtCapacities(
        proposal.debtCapacities.map((capacity) => ({
          ...capacity,
          amount: numberFormat.format(capacity.amount),
          isApproved: true,
        }))
      )
      setDebtCapacitiesError(
        proposal.debtCapacities.map(() => ({
          currency: false,
          amount: false,
        }))
      )
    }

    fetchProposal()
  }, [customerId])

  const saveDocuments = async () => {
    const documentsToUpload =
      FINANCIAL_ANALYSIS.generalInformation.documents.reduce((acc, itm) => {
        const docs = financialFiles[itm.typeId] ?? []
        return {
          ...acc,
          [itm.typeId]: docs,
        }
      }, {})

    /* Upload files */
    const documents = await uploadDocuments(
      documentsToUpload,
      customerId,
      user.uid,
      userRole
    )

    if (documents.some((document) => document.status !== 'fulfilled')) {
      throw new Error('error-uploading-documents')
    }

    return documents.map((document) => {
      const doc = document.value
      doc.status = FILE_STATES.ACCEPTED
      return doc
    })
  }

  const validateFormsAndDocuments = () => {
    const documentValidation =
      FINANCIAL_ANALYSIS.generalInformation.documents.some((itm) => {
        const docs = financialFiles[itm.typeId] ?? []
        return docs.length === 0
      })

    const source = validateFormErrors(sourceFormErrors)
    const financial = validateFormErrors(financeFormErrors)

    if (source) throw new Error('missing-form-fields')
    if (financial) throw new Error('missing-form-fields')
    if (documentValidation) throw new Error('missing-documents')
    if (generalComment.comment.length <= 0) throw new Error('missing-comment')
  }

  const createDebtCapacities = async () => {
    const createdCapacities = debtCapacities.filter(
      (debtCapacity) => !debtCapacity.id
    )

    await Promise.all(
      createdCapacities.map(async (debtCapacity) => {
        await instanceTransition(
          customer?.customerInstance?.workflowId,
          customerId,
          addDebtCapacity.event,
          {
            'id:debtCapacity': UUIDv4(),
            'proposalId:debtCapacity': proposal.id,
            'currency:debtCapacity': debtCapacity.currency,
            'amount:debtCapacity': extractNumber(debtCapacity.amount),
          }
        )
      })
    )
  }

  const deleteDebtCapacities = async () => {
    const deletedCapacities = proposal.debtCapacities.filter(
      (proposalCapacity) => {
        const isNotDeleted = debtCapacities.some(
          (debtCapacity) => proposalCapacity.id === debtCapacity.id
        )
        return !isNotDeleted
      }
    )

    await Promise.all(
      deletedCapacities.map(async (debtCapacity) => {
        await instanceTransition(
          customer?.customerInstance?.workflowId,
          customerId,
          deleteDebtCapacity.event,
          {
            'id:debtCapacity': debtCapacity.id,
            'proposalId:debtCapacity': proposal.id,
          }
        )
      })
    )
  }

  const updateDebtCapacities = async () => {
    const updatedCapacities = debtCapacities.filter((debtCapacity) => {
      if (!debtCapacity.id) return false
      const proposalCapacity = proposal.debtCapacities.find(
        (proposalCapacity) => proposalCapacity.id === debtCapacity.id
      )

      if (!proposalCapacity) return false

      return (
        proposalCapacity.currency !== debtCapacity.currency ||
        proposalCapacity.amount !== extractNumber(debtCapacity.amount)
      )
    })

    await Promise.all(
      updatedCapacities.map(async (debtCapacity) => {
        await instanceTransition(
          customer?.customerInstance?.workflowId,
          customerId,
          updateDebtCapacity.event,
          {
            'id:debtCapacity': debtCapacity.id,
            'amount:debtCapacity': extractNumber(debtCapacity.amount),
          }
        )
      })
    )
  }

  const makeTransition = async (isApproved) => {
    try {
      // Set loading state
      setIsLoadingSubmit(true)
      setIsValidating(true)
      setIsShowingConfirmationModal(false)

      // Check form errors
      validateFormsAndDocuments()

      // Upload files and catch file IDs
      const fileList = await saveDocuments()

      // Prepare payload for Workflow
      const financeInformation = Object.entries({
        ...financeFormValues,
        ...sourceForm,
      }).reduce((acc, [key, value]) => {
        if (canBeFormatted(value)) {
          return { ...acc, [`${key}:finance`]: extractNumber(value) }
        }
        return { ...acc, [`${key}:finance`]: value }
      }, {})

      const event = {
        name: approveFinance.event,
        data: {
          ...financeInformation,
          'fileList:multiUpdate': fileList,
          'wfState:status': approveFinance.nextState,
          'customerId:finance': Number(customerId),
          'comment:comment': generalComment.comment,
          'isPublic:comment': generalComment.isPublic,
          'customerId:comment': Number(customerId),
          'uuid:comment': userUID,
          'role:comment': userRole,
        },
      }

      if (!isApproved) {
        event.name = rejectFinance.event
        event.data['wfState:status'] = rejectFinance.nextState
        event.data['status:status'] = rejectFinance.status
      }

      // execute transitions

      await deleteDebtCapacities()
      await updateDebtCapacities()
      await createDebtCapacities()

      await instanceTransition(
        customer?.customerInstance?.workflowId,
        customerId,
        event.name,
        event.data
      )

      // Close action
      navigate('/')
      setAction(false)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        error.message = 'workflow'
      }

      const { close, label } = ERRORS[error.message] || ERRORS.default

      if (close) {
        setAction(false)
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      }

      setAlert({ type: 'error', title: 'Error', label })
    } finally {
      // Set loading state
      setIsLoadingSubmit(false)
    }
  }

  return (
    <Styles>
      <section className='introduction'>
        <DebtCapacity
          debtCapacities={debtCapacities}
          setDebtCapacities={setDebtCapacities}
          debtCapacitiesError={debtCapacitiesError}
          setDebtCapacitiesError={setDebtCapacitiesError}
          allowEdit
        />

        <BuildSection
          allowComment
          allowUpload={false}
          files={financialFiles}
          schema={CREDIT_ANALYSIS}
          setCommentBarInfo={setCommentBar}
        />
      </section>

      <h5>Indicadores financieros</h5>
      <section className='source-form'>
        <Form
          schema={CREDIT_STUDY_HEAD}
          formValues={sourceForm}
          setFormValues={setSourceForm}
          formErrors={sourceFormErrors}
          setFormErrors={setSourceFormErrors}
          isValidating={isValidating}
        />
      </section>

      <section className='financial-form'>
        <Form
          schema={CREDIT_STUDY}
          formValues={financeFormValues}
          setFormValues={setFinanceFormValues}
          formErrors={financeFormErrors}
          setFormErrors={setFinanceFormErrors}
          isValidating={isValidating}
        />
      </section>

      <section className='financial-documents'>
        <BuildSection
          allowComment
          allowDelete
          allowUpdate
          files={financialFiles}
          setFiles={setFinancialFiles}
          isValidating={isValidating}
          schema={FINANCIAL_ANALYSIS.generalInformation.documents}
          setCommentBarInfo={setCommentBar}
          title={FINANCIAL_ANALYSIS.generalInformation.title}
        />
      </section>

      <GeneralComment
        className='comment'
        comment={generalComment.comment}
        isPublic={generalComment.isPublic}
        setComment={(comment) =>
          setGeneralComment({ ...generalComment, comment })
        }
        setIsPublic={(isPublic) =>
          setGeneralComment({ ...generalComment, isPublic })
        }
        error={isValidating && !generalComment.comment}
      />

      <section className='financial-actions'>
        <Button
          buttonType='outlineBlueDark'
          onClick={() => {
            setAction(undefined)
            navigate(-1)
          }}
        >
          Cancelar
        </Button>
        <div>
          <Button onClick={() => setIsShowingConfirmationModal(true)}>
            {isLoadingSubmit && <LoadingAnimation className='loading' />}
            {!isLoadingSubmit && 'Rechazar'}
          </Button>
          <Button
            buttonType='blueDark'
            disabled={isLoadingSubmit}
            onClick={() => makeTransition(true)}
          >
            {isLoadingSubmit && <LoadingAnimation className='loading' />}
            {!isLoadingSubmit && <span>Aprobar</span>}
          </Button>
        </div>
      </section>

      {commentBar && (
        <LateralBar
          fileId={commentBar.fileId}
          documentName={commentBar.fileName}
          setLateralBar={setCommentBar}
        />
      )}
      {isShowingConfirmationModal && (
        <ModalUpload
          setOpenModalUpload={setIsShowingConfirmationModal}
          messages={{
            msg: '¿Esta seguro que desea rechazar al prospecto? Si rechaza al prospecto ya no podrá retomar el proceso.',
            msgButton: 'Confirmar',
          }}
          handleSave={() => makeTransition(false)}
          isLoadingSubmit={isLoadingSubmit}
        />
      )}
    </Styles>
  )
}

const Styles = styled.main`
  padding-inline: 30px;
  .introduction {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
    flex-wrap: wrap;
    gap: 2rem;

    margin-block: 20px;
  }

  .source-form,
  .financial-form {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 10px;

    margin-block: 10px;
  }

  .financial-documents {
    max-width: 800px;
  }

  .comment {
    margin-block: 30px;
  }

  .financial-actions {
    margin-block: 30px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    button + button {
      margin-inline-start: 10px;
    }
  }
`

export default FinancialAnalysis
