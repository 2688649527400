import { Icon } from '@keoworld/gbl-ui-kit'
import { useEffect, useState } from 'react'
import { markAsRead, subscriptionToNotifications } from 'services/notifications'
import styled from 'styled-components'
import { timeAgoToNow } from 'utils/functions/date'
import { useAuth } from 'utils/hooks/auth'

const NOTIFICATION_ICONS = {
  group: 'group',
  warning: 'warning',
  approve: 'mood',
  reject: 'cancel',
  send: 'send',
}

const NotificationBar = ({ onClose }) => {
  const [notifications, setNotifications] = useState([])
  const { user } = useAuth()

  const onCloseNotificationBar = () => {
    if (typeof onClose === 'function') {
      onClose()
    }
  }

  useEffect(() => {
    if (user?.iam?.id) {
      const unsubscribe = subscriptionToNotifications(
        setNotifications,
        user?.iam?.id
      )
      return () => unsubscribe()
    }
  }, [user?.iam?.id])

  return (
    <NotificationsBarStyled>
      <button onClick={onCloseNotificationBar}>
        <Icon name='close' />
      </button>

      <div className='head-title'>
        <h3>Notificaciones</h3>
      </div>

      <ul className='notifications'>
        {notifications.map((notification, index) => {
          const {
            action,
            createdAt,
            customerId,
            id,
            message,
            read,
            senderFullName,
            stage,
            type,
          } = notification

          const date = createdAt?.toDate()

          const setRead = async () => {
            await markAsRead(id, user?.iam?.id, !read)
          }

          return (
            <li key={`notification-${index}`}>
              <input
                type='checkbox'
                checked={read}
                onChange={(e) => e.target.checked}
                onClick={setRead}
              />
              <div className='notification' onClick={setRead}>
                <div className='profile-circle'>
                  <span className='initials'>
                    {senderFullName?.substring(0, 2)}
                  </span>
                </div>
                <div className='information'>
                  <div className='creator'>
                    <h3>
                      {senderFullName}{' '}
                      <span className='time' regular='true'>
                        {timeAgoToNow(date)}
                      </span>
                    </h3>
                  </div>
                  <div className='content'>
                    <Icon
                      name={
                        NOTIFICATION_ICONS[type] ?? NOTIFICATION_ICONS['group']
                      }
                      type='outlined'
                    />
                    <span className='message'>{message}</span>
                  </div>
                  <div className='path'>
                    {customerId ? `Prospect ${customerId}` : stage} {'>'}{' '}
                    {action}
                  </div>
                </div>
              </div>
            </li>
          )
        })}
      </ul>
    </NotificationsBarStyled>
  )
}

const NotificationsBarStyled = styled.div`
  overflow: auto;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 6;

  width: 450px;
  height: 100vh;

  padding: 60px 30px 20px;

  ${({ theme }) => theme.colors.grayShades[800]};

  border-radius: 9px 0 0 9px;

  ul {
    list-style-type: none;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      input[type='checkbox'] {
        height: 20px;
        width: 20px;
        border-radius: 6px;
        cursor: pointer;
      }
      .notification {
        display: grid;
        grid-template-columns: 60px 1fr;

        width: 90%;

        padding: 10px 15px;
        cursor: pointer;

        .profile-circle {
          width: 50px;
          height: 50px;
          border-radius: 50%;

          margin: auto;

          display: grid;
          place-items: center;

          ${({ theme }) => theme.colors.grayShades[700]};
          span {
            font-size: 18pt;
            font-weight: 500;
            text-transform: uppercase;
          }
        }
        .information {
          margin-left: 20px;
          text-transform: capitalize;

          .time {
            font-size: 8pt;
            text-transform: lowercase;
            display: inline-block;
            &::first-letter {
              text-transform: uppercase;
            }
          }
          .content {
            display: flex;
            align-items: center;
            .message {
              margin-left: 10px;
            }
            & > * {
              font-size: 10pt;
            }
          }
          .path {
            font-size: 8pt;
          }
        }
        &:hover {
          border-radius: 9px;
          background-color: #323232;
        }
      }
    }
  }

  button {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: inherit;
    border: none;
    background-color: transparent;
    color: white;
  }
`

export default NotificationBar
