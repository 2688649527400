import { Button, Input, LoadingAnimation, Switch } from '@keoworld/gbl-ui-kit'
import ModalUpload from 'containers/Modal/modal-documents-upload'
import { AlertContext } from 'providers/alert'
import { useContext, useEffect, useState } from 'react'
import { editRelation, getRelationInfo } from 'services/relations'
import styled from 'styled-components'
import * as CurrencyFormat from 'utils/functions/handlers-currency'
import { RELATION_STATES } from 'utils/schemas/relations'
import { OPTIONS } from './relation-actions'
import { CUSTOMER_SUPPLIER_SOURCES } from 'utils/schemas/customer'

const RelationDetail = ({
  isSupplementaryCard,
  relationId,
  editing,
  setAction,
  closeLateral,
  fetchRelations,
}) => {
  const [amount, setAmount] = useState('')
  const [relationInfo, setRelationInfo] = useState({})
  const [bppSelector, setBppSelector] = useState(false)
  const { setAlert } = useContext(AlertContext)
  const [openModalUpload, setOpenModalUpload] = useState(false)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)

  const {
    identifier,
    lineId,
    providerId,
    cardProgram,
    status,
    days,
    dueFromApproval,
    percentageReceiver,
    providerNid,
    providerSource,
    commerceCode,
  } = relationInfo

  const onHandleSubmit = async () => {
    setIsLoadingSubmit(true)
    try {
      if (!CurrencyFormat.canBeFormatted(amount) || amount <= 0) {
        throw new Error('balance 0 or empty')
      }

      await editRelation({
        id: relationId,
        score: CurrencyFormat.extractNumber(amount),
        bpp: bppSelector,
      })
      fetchRelations(lineId)
      closeLateral && closeLateral()
    } catch (error) {
      const errorLabels = {
        'The card is not found': 'Error al encontrar la tarjeta',
        'There are pending relationships': 'Hay relaciones pendientes',
        'The balance is wrong':
          'El balance no puede ser mayor al monto de la relación',
        'The line score is full': 'El score de la línea está lleno',
        'balance 0 or empty': 'El balance no puede ser 0 o estar vacío',
        default: 'Error al editar la relación',
      }
      setAlert({
        type: 'error',
        title: 'Error',
        label:
          error.message === 'balance 0 or empty'
            ? errorLabels[error.message]
            : errorLabels[error.title] || error.title || error.default,
      })
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  const onCancelRelation = async () => {
    setIsLoadingSubmit(true)
    try {
      await editRelation({ id: relationId, status: RELATION_STATES.CANCELLED })
      fetchRelations(lineId)
      closeLateral && closeLateral()
    } catch (error) {
      setAlert({
        type: 'error',
        title: 'Error',
        label: 'Error al cancelar relación',
      })
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  useEffect(() => {
    const getProvider = async () => {
      const data = await getRelationInfo(relationId)
      setRelationInfo(data)
      setAmount(CurrencyFormat.formatCurrency(data.score))
      setBppSelector(data.bpp)
    }
    if (relationId) {
      getProvider()
    }
  }, [relationId, setAmount])

  return (
    <RelationDetailStyled>
      {openModalUpload && (
        <ModalUpload
          setOpenModalUpload={setOpenModalUpload}
          messages={{
            msg: '¿Estás seguro que deseas dar de baja al proveedor?',
            msgButton: 'Confirmar',
          }}
          handleSave={onCancelRelation}
          isLoadingSubmit={isLoadingSubmit}
        />
      )}
      <section className='content'>
        <h3>{identifier}</h3>
        {!isSupplementaryCard && (
          <article>
            <div>Monto Autorizado:</div>
            <h2>
              {editing && (
                <Input
                  name='authorizedAmount'
                  value={amount}
                  onBlur={(e) => {
                    const { target } = CurrencyFormat.handleBlurCurrency(e)
                    setAmount(target.value)
                  }}
                  onFocus={(e) => CurrencyFormat.handleFocusCurrency(e)}
                  onChange={(e) => setAmount(e.target.value)}
                />
              )}
              {!editing && CurrencyFormat.formatCurrency(amount ?? 0)}
            </h2>
          </article>
        )}
        <section className='relation-information'>
          <article className='item-inline'>
            <p bold='true'>ID Línea:</p>
            <p>{lineId}</p>
          </article>
          <article className='item-inline'>
            <p bold='true'>ID Proveedor:</p>
            <p>{providerId}</p>
          </article>
          <article className='item-inline'>
            <p bold='true'>ID Relación:</p>
            <p>{relationId}</p>
          </article>
          <article className='item-inline'>
            <p bold='true'>Card Program:</p>
            <p>{cardProgram}</p>
          </article>
          <article className='item-inline'>
            <p bold='true'>Estado:</p>
            <p>{status}</p>
          </article>
          <article className='item-inline'>
            <p bold='true'>Días:</p>
            <p>{days}</p>
          </article>
          <article className='item-inline'>
            <p bold='true'>Días pactados desde:</p>
            <p>
              {dueFromApproval
                ? 'Días pactados desde la aprobación'
                : 'Días pactados desde la emisión'}
            </p>
          </article>
          <article className='item-inline'>
            <p bold='true'>Porcentaje de cobro de cliente:</p>
            <p>{percentageReceiver}%</p>
          </article>
          <article className='item-inline'>
            <p bold='true'>RFC del proveedor vinculado a la relación:</p>
            <p>{providerNid}</p>
          </article>
          <article className='item-inline'>
            <p bold='true'>Código de comercio vinculado a la relación:</p>
            <p>{commerceCode}</p>
          </article>

          {providerSource === CUSTOMER_SUPPLIER_SOURCES.CALLAO && (
            <article className='item-inline'>
              <label bold='true'>BPP</label>
              <Switch
                checked={bppSelector}
                onChange={() => {
                    if (editing) setBppSelector((prev) => !prev)
                  }}
                firstOption='No'
                secondOption='Sí'
                />
            </article>
          )}
        </section>
      </section>
      <section className='btn-group'>
        <Button
          className='cancel-action'
          buttonType='outline'
          onClick={() => setOpenModalUpload(true)}
          disabled={status !== 'Active' || isLoadingSubmit || !editing}
        >
          {isLoadingSubmit ? <LoadingAnimation /> : 'Dar de baja'}
        </Button>
        <Button
          buttonType='blueDark'
          onClick={() => {
            if (!editing) setAction(OPTIONS.EDIT)
            else onHandleSubmit()
          }}
          disabled={status !== 'Active' || isLoadingSubmit}
        >
          {isLoadingSubmit ? <LoadingAnimation /> : getActionLabel(editing)}
        </Button>
      </section>
    </RelationDetailStyled>
  )
}

const getActionLabel = (editing) => (editing ? 'Guardar' : 'Editar')

const RelationDetailStyled = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .relation-information {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .item-inline {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: 5px;

    p {
      margin: 0;
      max-width: 50%;
      text-wrap: balance;
    }

    p + * {
      text-align: end;
    }
  }

  .btn-group {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    button {
      height: 40px;
    }
    .cancel-action:not(:hover),
    .cancel-action:disabled {
      background-color: transparent;
    }
  }
`

export default RelationDetail
