import { createContext, useEffect, useState } from 'react'
import { auth } from 'services/firebase.controller'
import { getUsers } from 'services/user'

const initialContext = {
  userAuth: undefined,
  setUserAuth: undefined
}

export const SessionContext = createContext(initialContext)

export const SessionProvider = ({ children }) => {
  const [userAuth, setUserAuth] = useState()
  const [attempts, setAttempts] = useState(3)

  useEffect(() => {
    let timeout

    const fetchUser = async () => {
      if (attempts <= 0) return
      const [information] = await getUsers({ uuid: userAuth.uid })
      if (information) {
        setAttempts(0)
        setUserAuth({ ...userAuth, iam: information })
      } else {
        timeout = setTimeout(() => {
          setAttempts(attempts - 1)
        }, 3000)
      }
    }

    if (userAuth && !userAuth.iam) fetchUser()

    return () => {
      clearTimeout(timeout)
    }
  }, [attempts, userAuth])

  useEffect(() => {
    const onAuthChange = async (user) => setUserAuth(user)
    const unsubscribe = auth.onAuthStateChanged(onAuthChange)

    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <SessionContext.Provider value={{ userAuth, setUserAuth }}>
      {children}
    </SessionContext.Provider>
  )
}
