import { Button, Icon } from '@keoworld/gbl-ui-kit'
import Form from 'containers/form'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { calculateLineConditions } from 'utils/functions/calculate-line-conditions'
import { percentage } from 'utils/functions/formatters'
import { extractNumberPercentage } from 'utils/functions/handle-percentage'
import {
  canBeFormatted,
  extractNumber,
} from 'utils/functions/handlers-currency'
import {
  COMMISSION_TERM_FORM,
  LINE_DETAILS_FORM,
  MORATORIUM_TERM_FORM,
  ORDINARY_TERM_FORM,
} from 'utils/schemas/line'

export const initialLineConditionsForm = {
  line: {
    cardProgram: '',
  },
  ordinary: [{ name: 'Periodo 1' }],
  moratorium: [],
  commission: [],
}

export const label = {
  score:
    'El campo de Monto asignado a la linea debe ser llenado con un valor superior a cero, por favor revise la configuración y vuelva a intentar ',
  maxTerm:
    ' El campo plazo máximo debe ser llenado con un valor superior a cero, por favor revise la configuración y vuelva a intentar ',
  ordinary:
    ' Tenga en cuenta que la suma de los  periodos ordinarios es mayor al plazo máximo configurado en el sistema. ¿Desea continuar con el proceso?',
  moratorium:
    ' No se ha configurado ningún periodo moratorio ¿Desea continuar con el proceso?',
}

export const verifyErrors = (formError = {}) => {
  const { line, ordinary, moratorium, commission } = formError

  const verifyPeriodErrors = (schema, data) => {
    return schema.some(({ name }) => data[name])
  }

  const lineErrors = verifyPeriodErrors(LINE_DETAILS_FORM, line)
  const ordinaryErrors = ordinary.some((period) =>
    verifyPeriodErrors(ORDINARY_TERM_FORM, period)
  )
  const moratoriumErrors = moratorium.some((period) =>
    verifyPeriodErrors(MORATORIUM_TERM_FORM, period)
  )
  const commissionErrors = commission.some((period) =>
    verifyPeriodErrors(COMMISSION_TERM_FORM, period)
  )

  return lineErrors || ordinaryErrors || moratoriumErrors || commissionErrors
}

const LineConditions = (props) => {
  const {
    isValidating = false,
    lineForm,
    setLineForm,
    formErrors,
    setFormErrors,
  } = props
  const [showActions, setShowActions] = useState(false)
  const [lineOptions, setLineOptions] = useState({
    dailyReturn: 0,
    annualReturn: 0,
    ordinaryConfig: [],
    moratoriumConfig: [],
    totalOrdinaryTerm: 0,
  })

  const onAddScheme = (type) => {
    const values = lineForm[type] || []
    const errors = formErrors[type] || []

    let name

    if (type === 'ordinary') {
      name = `Periodo ${values.length + 1}`
    } else if (type === 'moratorium') {
      name = `Moratoria`
    } else {
      name = `Comisión ${values.length + 1}`
    }

    setLineForm({
      ...lineForm,
      [type]: [...values, { name }],
    })

    setFormErrors({
      ...formErrors,
      [type]: [...errors, { name: false }],
    })
  }

  const onRemoveScheme = (type, index) => {
    const dataByType = lineForm[type]
    const dataToSet = dataByType.filter((_, i) => i !== index)

    setLineForm({
      ...lineForm,
      [type]: dataToSet,
    })

    const errorsByType = formErrors[type]
    const errorsToSet = errorsByType.filter((_, i) => i !== index)

    setFormErrors({
      ...formErrors,
      [type]: errorsToSet,
    })
  }

  const onHandleChange = (type, values, index) => {
    let data = {}
    if (type === 'line') {
      data = {
        ...lineForm,
        line: values,
      }
    } else {
      const dataByType = [...lineForm[type]]
      dataByType[index] = values
      data = {
        ...lineForm,
        [type]: dataByType,
      }
    }

    setLineForm(data)
  }

  const onHandleError = (type, values, index) => {
    let data = {}
    if (type === 'line') {
      data = { line: values }
    } else {
      const errorsByType = [...formErrors[type]]
      errorsByType[index] = values
      data = {
        [type]: errorsByType,
      }
    }
    setFormErrors((prev) => ({ ...prev, ...data }))
  }

  const getConditionScheme = (schema = [], typeId) => {
    let newSchema = schema.map((item) => ({ ...item }))
    if (newSchema && typeId) {
      let type = typeId === '3' ? '(Diaria)' : '(Única)'
      newSchema[2].label = `Tasa ${type}`
    }
    return newSchema
  }

  useEffect(() => {
    const { line, ordinary, moratorium } = lineForm
    const { score, maxTerm } = line

    if (!score || !maxTerm || !canBeFormatted(score)) return

    const periods = [
      ...ordinary.map((period) => ({ ...period, delinquency: false })),
      ...moratorium.map((period) => ({ ...period, delinquency: true })),
    ]

    const terms = periods.map((period) => {
      return {
        ...period,
        term: Number(period.term),
        rate: extractNumberPercentage(period.rate),
        typeId: Number(period.typeId),
      }
    })

    const { annualReturn, dailyReturn, termsConfig, totalOrdinaryTerm } =
      calculateLineConditions({ score: extractNumber(score), maxTerm, terms })

    let ordinaryConfig = termsConfig.filter((period) => !period.delinquency)
    let moratoriumConfig = termsConfig.filter((period) => period.delinquency)

    setLineOptions({
      dailyReturn,
      annualReturn,
      ordinaryConfig,
      moratoriumConfig,
      totalOrdinaryTerm,
    })
  }, [lineForm])

  return (
    <Styles>
      <h5 className='title'>Propuesta de negocio</h5>
      <div className='offer-content'>
        <section>
          <div className='form'>
            <Form
              schema={LINE_DETAILS_FORM}
              formValues={lineForm?.line ?? {}}
              setFormValues={(values) => onHandleChange('line', values)}
              formErrors={formErrors.line}
              setFormErrors={(errors) => onHandleError('line', errors)}
              isValidating={isValidating}
            />
          </div>
        </section>

        {lineForm.ordinary.map((period = {}, index) => {
          const { annualRate, range } = lineOptions.ordinaryConfig[index] ?? {}
          const [start, to] = range ?? []
          const schema = getConditionScheme(ORDINARY_TERM_FORM, period.typeId)

          return (
            <section key={`ordinary-${index}`}>
              <div className='head-info'>
                <div className='bold'>Periodo {index + 1}</div>
                <div>
                  <span className='bold'>Tasa anualizada: </span>
                  <span
                    className={
                      (!period.rate && period.rate !== 0) ||
                      !period.term ||
                      isNaN(annualRate) ||
                      !lineForm?.line?.currency
                        ? 'skeleton'
                        : ''
                    }
                  >
                    {percentage(annualRate)}
                  </span>
                </div>
                <div>
                  <span className='bold'>Rango: </span>
                  <span className={isNaN(start) || isNaN(to) ? 'skeleton' : ''}>
                    Día {start} a {to}
                  </span>
                </div>
                {lineForm.ordinary.length > 1 && (
                  <Button
                    className='action'
                    buttonType='outlineDark'
                    onClick={() => onRemoveScheme('ordinary', index)}
                  >
                    <Icon name='delete' />
                  </Button>
                )}
              </div>
              <div className='form form-large'>
                <Form
                  schema={schema}
                  formValues={period}
                  setFormValues={(values) =>
                    onHandleChange('ordinary', values, index)
                  }
                  formErrors={formErrors.ordinary[index] ?? {}}
                  setFormErrors={(errors) =>
                    onHandleError('ordinary', errors, index)
                  }
                  isValidating={isValidating}
                />
              </div>
            </section>
          )
        })}
        {lineForm.moratorium.map((period = {}, index) => {
          const { annualRate, range } =
            lineOptions.moratoriumConfig[index] ?? {}
          const [start, to] = range ?? []
          const schema = getConditionScheme(MORATORIUM_TERM_FORM, period.typeId)

          return (
            <section key={`moratorium-${index}`}>
              <div className='head-info'>
                <div className='bold'>Moratoria</div>
                <div>
                  <span className='bold'>Tasa anualizada: </span>
                  <span
                    className={
                      (!period.rate && period.rate !== 0) ||
                      isNaN(annualRate) ||
                      !lineForm?.line?.currency
                        ? 'skeleton'
                        : ''
                    }
                  >
                    {percentage(annualRate)}
                  </span>
                </div>
                <div>
                  <span className='bold'>Rango: </span>
                  <span>
                    Día {start} en {isFinite(to) ? to : 'adelante'}
                  </span>
                </div>
                <Button
                  className='action'
                  size='action'
                  buttonType='outlineDark'
                  onClick={() => onRemoveScheme('moratorium', index)}
                >
                  <Icon name='delete' />
                </Button>
              </div>
              <div className='form'>
                <Form
                  schema={schema}
                  formValues={period}
                  setFormValues={(values) =>
                    onHandleChange('moratorium', values, index)
                  }
                  formErrors={formErrors.moratorium[index] ?? {}}
                  setFormErrors={(errors) =>
                    onHandleError('moratorium', errors, index)
                  }
                  isValidating={isValidating}
                />
              </div>
            </section>
          )
        })}
        {lineForm.commission.map((period = {}, index) => {
          return (
            <section key={`commission-${index}`}>
              <div className='head-info'>
                <div className='bold'>Comisión</div>
                <Button
                  className='action'
                  size='action'
                  buttonType='outlineDark'
                  onClick={() => onRemoveScheme('commission', index)}
                >
                  <Icon name='delete' />
                </Button>
              </div>
              <div className='form'>
                <Form
                  schema={COMMISSION_TERM_FORM}
                  formValues={period}
                  setFormValues={(values) =>
                    onHandleChange('commission', values, index)
                  }
                  formErrors={formErrors.commission[index] ?? {}}
                  setFormErrors={(errors) =>
                    onHandleError('commission', errors, index)
                  }
                  isValidating={isValidating}
                />
              </div>
            </section>
          )
        })}

        <div
          className='annual-return'
          data-error={lineOptions.annualReturn < 0.18}
        >
          <span className='bold'>Rentabilidad anual estimada: </span>
          <span
            className={
              !lineOptions.annualReturn || !lineForm.line.currency
                ? 'skeleton'
                : ''
            }
          >
            {percentage(lineOptions.annualReturn)}
          </span>
          <span className='tool-message'>
            Recuerde que se espera que la rentabilidad estimada sea{' '}
            <span>18% o mayor</span>
          </span>
        </div>

        <div className='actions'>
          {!showActions && (
            <Button
              className='action'
              size='action'
              onClick={() => setShowActions(true)}
            >
              <Icon name='add' />
            </Button>
          )}

          {showActions && (
            <>
              <Button onClick={() => onAddScheme('ordinary')}>
                Añadir periodo ordinario
              </Button>
              {lineForm.moratorium.length === 0 && (
                <Button onClick={() => onAddScheme('moratorium')}>
                  Añadir periodo moratorio
                </Button>
              )}
              <Button onClick={() => onAddScheme('commission')}>
                Añadir comisión
              </Button>
            </>
          )}
        </div>
      </div>
    </Styles>
  )
}

const Styles = styled.div`
  border-radius: 1rem;
  padding: 1rem;
  margin-top: 1rem;
  background-color: rgba(117, 22, 68, 0.04);

  h5.title {
    margin-bottom: 1rem;
  }

  .offer-content {
    background-color: white;
    padding: 1rem 2rem;
    border-radius: 9px;
  }

  section + section {
    margin-top: 0.8rem;
  }

  .head-info {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    column-gap: 1rem;
    font-size: 1rem;

    & > div {
      display: flex;
      align-items: baseline;
      gap: 0.5rem;
    }

    button.action {
      width: 30px;
      height: 30px;
      display: grid;
      place-items: center;

      span {
        font-size: 0.9rem;
      }
    }
  }

  .bold {
    font-weight: bold;
  }

  .form {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.8rem;

    &.form-large {
      grid-template-columns: repeat(4, 1fr);
    }

    & > div {
      min-width: min-content;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    /* Firefox */
    input[type='number'] {
      appearance: textfield;
      -moz-appearance: textfield;
      appearance: textfield;
    }
  }

  .annual-return {
    margin-top: 2rem;
    .tool-message {
      display: none;
    }
    &[data-error='true'] {
      color: #ff0045;
      .tool-message {
        display: block;
      }
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
    width: min-content;
    margin-left: auto;

    button.action {
      width: 35pt;
      height: 35pt;
    }
  }

  .skeleton {
    color: transparent;
    display: inline-block;
    width: min-content;
    height: max-content;
    min-width: 7rem;
    max-height: 1.5em;
  }

  @media (max-width: 768px) {
    .form {
      grid-template-columns: repeat(3, 1fr);

      &.form-large {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
`

LineConditions.propTypes = {
  lineForm: PropTypes.object.isRequired,
  setLineForm: PropTypes.func.isRequired,
  formErrors: PropTypes.object.isRequired,
  setFormErrors: PropTypes.func.isRequired,
  isValidating: PropTypes.bool,
}

export default LineConditions
