import { THEME } from '@keoworld/gbl-ui-kit'
import '@keoworld/gbl-ui-kit/styles/index.css'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import App from 'App'
import 'index.css'
import { AlertProvider } from 'providers/alert'
import { SessionProvider } from 'providers/session-manager'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import reportWebVitals from 'reportWebVitals'
import { ThemeProvider } from 'styled-components'
import { setUpAxios } from './services/config'

setUpAxios()

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <SessionProvider>
        <ThemeProvider theme={THEME}>
          <AlertProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </AlertProvider>
        </ThemeProvider>
      </SessionProvider>
    </LocalizationProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
