import { useEffect, useState } from 'react'
import { getLineConditionChanges } from 'services/lines'

export const useFetchLineConditionChanges = (lineId) => {
  const [lineConditions, setLineConditions] = useState()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const lineChanges = await getLineConditionChanges(lineId)
        setLineConditions(lineChanges)
      } catch (error) {
        console.log(error)
      }
    }

    if (lineId) fetchData()
  }, [lineId])

  return lineConditions
}
