export const WORKFLOW_PROJECT_ID = process.env.REACT_APP_WORKFLOW_PROJECT_ID
export const WORKFLOW_ALIAS = {
  customer: process.env.REACT_APP_WORKFLOW_CUSTOMER_ALIAS,
  line: process.env.REACT_APP_WORKFLOW_LINE_ALIAS,
}
export const API_HOST = process.env.REACT_APP_API_HOST
export const MEX_STATE = [
  {
    label: 'Aguascalientes',
    value: 'Aguascalientes',
  },
  {
    label: 'Baja California',
    value: 'Baja California',
  },
  {
    label: 'Baja California Sur',
    value: 'Baja California Sur',
  },
  {
    label: 'Campeche',
    value: 'Campeche',
  },
  {
    label: 'Chiapas',
    value: 'Chiapas',
  },
  {
    label: 'Chihuahua',
    value: 'Chihuahua',
  },
  {
    label: 'Ciudad de México',
    value: 'Ciudad de México',
  },
  {
    label: 'Coahuila',
    value: 'Coahuila',
  },
  {
    label: 'Colima',
    value: 'Colima',
  },
  {
    label: 'Durango',
    value: 'Durango',
  },
  {
    label: 'Estado de México',
    value: 'Estado de México',
  },
  {
    label: 'Guanajuato',
    value: 'Guanajuato',
  },
  {
    label: 'Guerrero',
    value: 'Guerrero',
  },
  {
    label: 'Hidalgo',
    value: 'Hidalgo',
  },
  {
    label: 'Jalisco',
    value: 'Jalisco',
  },
  {
    label: 'Michoacán',
    value: 'Michoacán',
  },
  {
    label: 'Morelos',
    value: 'Morelos',
  },
  {
    label: 'Nayarit',
    value: 'Nayarit',
  },
  {
    label: 'Nuevo León',
    value: 'Nuevo León',
  },
  {
    label: 'Oaxaca',
    value: 'Oaxaca',
  },
  {
    label: 'Puebla',
    value: 'Puebla',
  },
  {
    label: 'Querétaro',
    value: 'Querétaro',
  },
  {
    label: 'Quintana Roo',
    value: 'Quintana Roo',
  },
  {
    label: 'San Luis Potosí',
    value: 'San Luis Potosí',
  },
  {
    label: 'Sinaloa',
    value: 'Sinaloa',
  },
  {
    label: 'Sonora',
    value: 'Sonora',
  },
  {
    label: 'Tabasco',
    value: 'Tabasco',
  },
  {
    label: 'Tamaulipas',
    value: 'Tamaulipas',
  },
  {
    label: 'Tlaxcala',
    value: 'Tlaxcala',
  },
  {
    label: 'Veracruz',
    value: 'Veracruz',
  },
  {
    label: 'Yucatán',
    value: 'Yucatán',
  },
  {
    label: 'Zacatecas',
    value: 'Zacatecas',
  },
]

export const FILE_MAX_SIZE = 10000000

export const PROVIDER_STATUS = {
  active: 'Active',
  deleted: 'Deleted',
}

export const INTEREST_TYPES = {
  1: 'Fija de aplicación única sobre disposición',
  2: 'Fija de aplicación única sobre saldo insoluto',
  3: 'Fija de aplicación diaria sobre saldo insoluto',
}
