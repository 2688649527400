import AppBar from 'containers/app-bar'
import LateralBarMenu from 'containers/lateral-bar-menu'
import { CustomerProvider } from 'providers/customer'
import { LateralBarProvider } from 'providers/lateral-bar'
import { Navigate, Outlet } from 'react-router-dom'
import styled from 'styled-components'
import { getUserRoles } from 'utils/functions/role-manager'
import { useAuth } from 'utils/hooks/auth'
import Router from 'utils/router'

const ProtectedRoute = () => {
  const { isAuthenticated } = useAuth()
  const [role] = getUserRoles()

  if (!isAuthenticated) return <Navigate to={Router.SignIn} />
  if (!role) return <Navigate to={Router.unauthorized} />
  return (
    <LateralBarProvider>
      <CustomerProvider>
        <AppRouteStyles>
          <AppBar />
          <div className='content-app'>
            <LateralBarMenu />
            <Outlet />
          </div>
        </AppRouteStyles>
      </CustomerProvider>
    </LateralBarProvider>
  )
}

const AppRouteStyles = styled.div`
  height: 100vh;
  .content-app {
    height: calc(100vh - 70px);

    display: grid;
    grid-template-columns: min-content 1fr;
  }
`

export default ProtectedRoute
