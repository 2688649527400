import { AlertContext } from 'providers/alert'
import { useContext, useEffect, useState } from 'react'
import { ApiBuyerRepository } from 'services/buyer-suppcards/buyer.repository'
import { ApiError } from 'utils/schemas/errors'

const INITIAL_RESPONSE = {
  authorizedAmount: 0,
  requestedAmount: 0,
  availableAmount: 0,
}

/**
 * Fetch customer amounts assigned.
 * @param {Number} customerId The customer identifier
 * @returns {[{ authorizedAmount: Number, requestedAmount: Number, availableAmount: Number }, Boolean]} The amounts assigned and the loading state
 * @example
 * const [amounts, isLoading] = useFetchBuyerAmountsAssigned(customerId)
 */
export const useFetchBuyerAmountsAssigned = (customerId) => {
  const [isLoading, setIsLoading] = useState(false)
  const [amounts, setAmounts] = useState(INITIAL_RESPONSE)
  const { setAlert } = useContext(AlertContext)

  useEffect(() => {
    const fetchCustomerCapacities = async () => {
      try {
        setIsLoading(true)

        if (!customerId) return

        const amounts =
          await ApiBuyerRepository.obtainAssignedAmountsByCustomer(customerId)

        setAmounts(amounts)
      } catch (error) {
        if (error instanceof ApiError) {
          return setAlert({
            title: '¡Ups! ha ocurrido un error',
            message: error.message,
            type: 'error',
          })
        }
        setAlert({
          title: '¡Ups! ha ocurrido un error',
          message: 'Error al obtener la información del cliente',
          type: 'error',
        })
      } finally {
        setIsLoading(false)
      }
    }

    fetchCustomerCapacities()
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [customerId])

  return [amounts, isLoading]
}
