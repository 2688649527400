import axios from 'axios'
import { ApiError, ErrorPrototype } from 'utils/schemas/errors'
import { ENDPOINTS } from './config'

/**
 * Service to get all payments of a loan
 * @param {Object} query - The query object
 * @param {number} query.loanId - The loan id
 * @param {number} query.paymentId - The payment id
 * @param {string} query.dateFrom - The date from
 * @param {string} query.dateTo - The date to
 * @param {number} query.limit - The limit of payments
 * @param {number} query.offset - The offset of payments
 * @returns {{ payments: Object[], total: number } }}
 */
export const fetchPayments = async (query) => {
  const { loanId, paymentId, dateTo, dateFrom, limit, offset } = query

  const { data } = await axios.get(ENDPOINTS.payment.find, {
    params: { loanId, paymentId, dateTo, dateFrom, size: limit, page: offset },
  })

  return { payments: data.payments, total: data.fullCount }
}

/**
 * Service to cancel payments
 * @param {Object} body - The request body
 * @param {string} body.reason - The reason of cancelation
 * @param {number[]} body.payments - The payments to cancel
 * @param {number} body.loanId - The loan id
 * @param {number} body.iamId - The iam id
 */
export const cancelPayments = async (body) => {
  try {
    await axios.post(ENDPOINTS.payment.annulment, {
      loanId: body.loanId,
      uidIam: body.iamId,
      paymentsIds: body.payments,
      reason: body.reason,
    })
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const { data } = error.response
      throw new ApiError(data.title, data.detail, data.status)
    }
    throw new ErrorPrototype(error.message)
  }
}
