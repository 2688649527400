import { Button, LoadingAnimation, Select } from '@keoworld/gbl-ui-kit'
import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { v4 as UUIDv4 } from 'uuid'

import LineConditions, {
  initialLineConditionsForm as initialForm,
  verifyErrors,
} from 'containers/line-conditions/line-conditions'
import { AlertContext } from 'providers/alert'
import { CustomerContext } from 'providers/customer'
import { ApiLineRepository } from 'services/line.repository'
import { getContract } from 'services/lines'
import { extractNumberPercentage } from 'utils/functions/handle-percentage'
import { extractNumber } from 'utils/functions/handlers-currency'
import { useReassessmentValidation } from 'utils/hooks/reassessment-validation'
import { getCategoryList } from 'services/relations'
import { getCustomerInfo } from 'services/customer'

const CreateLine = (props) => {
  const { getLines, setLateralBar } = props

  const [primaryCardPrograms, setPrimaryCardPrograms] = useState([])
  const [lineForm, setLineForm] = useState(initialForm)
  const [lineFormErrors, setLineFormErrors] = useState(initialForm)
  const [isValidating, setIsValidating] = useState(false)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [contracts, setContracts] = useState([])

  const { customer, setCustomer } = useContext(CustomerContext)
  const { setAlert } = useContext(AlertContext)

  const hasReassessmentInProgress = useReassessmentValidation()

  const validateLineCreation = () => {
    const isThereError = verifyErrors(lineFormErrors)

    if (isThereError || !lineForm.line.cardProgram) throw new Error('formError')
    if (hasReassessmentInProgress) throw new Error('reassessment')
  }

  const onHandleCreate = async () => {
    try {
      setIsValidating(true)
      setIsLoadingSubmit(true)

      validateLineCreation()

      const {
        line: lineConditions,
        ordinary,
        moratorium,
        commission,
      } = lineForm

      const [contract] = contracts

      const lineConditionsCreate = {
        id: UUIDv4(),
        lineConditionId: UUIDv4(),
        primaryCardId: UUIDv4(),
        contractId: contract.externalId,
        buyerId: customer.externalId,
        amount: extractNumber(lineConditions.score),
        maxTerm: Number(lineConditions.maxTerm),
        cardProgram: lineConditions.cardProgram,
        productId: lineConditions.product,
        terms: [
          ...ordinary.map((period) => {
            return {
              id: UUIDv4(),
              name: period.name,
              rate: extractNumberPercentage(period.rate),
              term: Number(period.term),
              typeId: Number(period.typeId),
              isDelinquency: false,
            }
          }),
          ...moratorium.map((period) => {
            return {
              id: UUIDv4(),
              name: period.name,
              rate: extractNumberPercentage(period.rate),
              typeId: Number(period.typeId),
              isDelinquency: true,
            }
          }),
        ],
        commissions: commission.map((commission) => {
          return {
            id: UUIDv4(),
            name: commission.name,
            amount: extractNumber(commission.amount),
            days: commission.days.split(',').map(Number),
          }
        }),
      }

      await ApiLineRepository.create(lineConditionsCreate)

      await getLines()
      setLateralBar(false)
    } catch (error) {
      const errorMessages = {
        formError: 'Complete el formulario',
        scoreError:
          'El monto ingresado no está disponible, el valor supera la capacidad de endeudamiento disponible',
        reassessment:
          'El cliente se encuentra en reassessment. Finalicé el proceso antes de crear una linea',
        serviceError: 'line score is greater than the customer score',
        alertMessage:
          'El monto de la linea es mayor que el score del customer.',
        default: 'Ocurrió en error en la creación de la linea',
      }

      const hasErrorMessage = error?.message && errorMessages[error.message]

      const alertMessage = hasErrorMessage
        ? errorMessages[error.message]
        : errorMessages.default

      setAlert({
        title: 'Error',
        label: alertMessage,
        type: hasErrorMessage ? 'warning' : 'error',
      })
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  useEffect(() => {
    const fetchBuyer = async () => {
      const { data: buyer } = await getCustomerInfo(customer?.id)
      setCustomer((prev) => ({ ...prev, ...buyer }))
    }

    if (customer?.id) fetchBuyer()
  }, [customer?.id, setCustomer])

  useEffect(() => {
    const obtainCardPrograms = async () => {
      const categories = await getCategoryList('PRIMARY')
      setPrimaryCardPrograms(categories)
    }

    if (customer?.id) {
      obtainCardPrograms()
    }
  }, [customer?.id])

  useEffect(() => {
    const fetchContract = async () => {
      const { contracts } = await getContract(customer.id, 'Active')
      setContracts(contracts)
    }

    if (customer?.id) fetchContract()
  }, [customer?.id])

  return (
    <Styles>
      <div className='scroll' id='scroll-create'>
        <h3>Crear Linea</h3>
        <section className='line-form-group'>
          <header className='input-group'>
            <Select
              label='Seleccione un card program'
              name='cardProgram'
              value={lineForm.line.cardProgram}
              defaultValue=''
              onChange={(e) => {
                setLineForm({
                  ...lineForm,
                  line: {
                    ...lineForm.line,
                    cardProgram: e.target.value,
                  },
                })
              }}
              error={
                isValidating && !lineForm.line.cardProgram && 'Campo Requerido'
              }
            >
              <option value='' disabled>
                Seleccione un card program
              </option>
              {primaryCardPrograms.map(({ id, cardProgram }, i) => {
                return (
                  <option key={`cardProgram-${id} ${i}`} value={cardProgram}>
                    {cardProgram}
                  </option>
                )
              })}
            </Select>
          </header>
          <LineConditions
            formErrors={lineFormErrors}
            isValidating={isValidating}
            lineForm={lineForm}
            setFormErrors={setLineFormErrors}
            setLineForm={setLineForm}
          />
        </section>

        <section className='buttons'>
          <Button
            className='button-cancel'
            disabled={isLoadingSubmit}
            buttonType='outline'
            onClick={() => setLateralBar(false)}
          >
            Cancelar
          </Button>
          <Button
            buttonType='blueDark'
            disabled={isLoadingSubmit}
            onClick={onHandleCreate}
          >
            {isLoadingSubmit ? <LoadingAnimation /> : 'Crear'}
          </Button>
        </section>
      </div>
    </Styles>
  )
}

const Styles = styled.article`
  height: 90%;
  overflow-y: hidden;
  padding: 0rem 2rem;

  .scroll {
    overflow-y: scroll;
    height: 100%;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .offer-content {
    button.action span.icon {
      margin: inherit;
    }
  }

  .line-form-group {
    margin-block: 2rem;
  }

  section .input-group {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-inline: 10px;

    .button-cancel:not(:hover) {
      background-color: transparent;
    }
  }
`

export default CreateLine
