import { Icon } from '@keoworld/gbl-ui-kit'
import { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import { INTEREST_TYPES } from 'utils/constants'
import { calculateLineConditions } from 'utils/functions/calculate-line-conditions'
import { currency, percentage } from 'utils/functions/formatters'

const LineConditionsView = (props) => {
  const { lineData = {}, isLoading } = props
  const [lineOptions, setLineOptions] = useState({
    dailyReturn: 0,
    annualReturn: 0,
    termsConfig: [],
    totalOrdinaryTerm: 0,
  })

  useEffect(() => {
    if (!lineData) return
    const { score, maxTerm, terms = [] } = lineData

    const { annualReturn, dailyReturn, termsConfig, totalOrdinaryTerm } =
      calculateLineConditions({ score, maxTerm, terms })

    setLineOptions({
      dailyReturn,
      annualReturn,
      termsConfig,
      totalOrdinaryTerm,
    })
  }, [lineData])

  return (
    <LineViewStyled>
      <h4>
        <Icon name='credit_card' />
        <span className='view-title'>
          {!lineData?.status && <span className='skeleton' />}
          {lineData?.status === 'proposal' && viewTypes.proposal}
          {lineData?.status !== 'approved' && viewTypes.default}
        </span>
      </h4>
      <div className='line-overview'>
        <div className='line-ov-div'>
          <p>
            <span>Monto</span>
          </p>
          <div
            className={`line-score ${
              isLoading || !lineData?.score ? 'skeleton' : ''
            }`}
          >
            {!isLoading && lineData?.score && (
              <>
                <span>MXN </span> {currency(lineData?.score)}
              </>
            )}
          </div>
        </div>
        <div className='line-ov-div'>
          <p>
            {!isLoading &&
              lineData?.maxTerm &&
              `${lineData?.maxTerm} días de plazo máximo`}
          </p>
          <p className={`${isLoading && 'skeleton'}`}>
            {!isLoading && percentage(lineOptions.annualReturn)} Rentabilidad
            Anual Estimada
          </p>
        </div>
      </div>
      <div className='line-details'>
        <div className='terms'>
          <table>
            <tbody>
              {(isLoading || !lineData?.terms) && (
                <tr>
                  <td>
                    <p className='skeleton'>...</p>
                  </td>
                  <td>
                    <p className='skeleton'>...</p>
                  </td>
                  <td>
                    <p className='skeleton'>...</p>
                  </td>
                </tr>
              )}
              {!isLoading &&
                lineData?.terms?.map((itm, idx) => {
                  const condition = lineOptions.termsConfig[idx] ?? {}
                  const { annualRate = 0 } = condition
                  return (
                    <Fragment key={`terms-${idx}`}>
                      <tr>
                        <td>
                          <span>{`Periodo ${idx + 1}`}</span>
                        </td>
                        <td>
                          <span>Tasa anualizada: </span>
                          {percentage(annualRate)}
                        </td>
                        <td>
                          <span>Rango: </span>
                          {!itm.delinquency
                            ? `Día ${itm.from} a ${itm.to}`
                            : `Día ${itm.from} en adelante`}
                        </td>
                      </tr>
                      <BorderedRow
                        hasBorder={lineData?.terms?.length !== idx + 1}
                      >
                        <td>{itm?.name}</td>
                        <td>Tasa {itm?.rate} %</td>
                        <td>
                          {Boolean(itm?.typeId) && INTEREST_TYPES[itm.typeId]}
                        </td>
                      </BorderedRow>
                    </Fragment>
                  )
                })}
            </tbody>
          </table>
        </div>
        <div className='commissions'>
          <table>
            <tbody>
              {isLoading && (
                <tr>
                  <td>
                    <p className='skeleton' />
                  </td>
                </tr>
              )}
              {!isLoading && (
                <>
                  {lineData?.commissions?.length === 0 && (
                    <tr className='commissions-empty'>
                      <td>La línea no cuenta con comisiones</td>
                    </tr>
                  )}
                  {lineData?.commissions?.map((itm, idx) => (
                    <Fragment key={`commissions-${idx}`}>
                      <tr>
                        <td>
                          <span>{`Comisión ${idx + 1}`}</span>
                        </td>
                      </tr>
                      <BorderedRow
                        hasBorder={lineData?.commissions.length !== idx + 1}
                      >
                        <td>{itm?.name}</td>
                        <td>
                          {`${itm.days?.length > 1 ? 'Días' : 'Dia'}`}{' '}
                          {itm.days?.join(',')}
                        </td>
                        <td>MXN ${itm?.amount}</td>
                      </BorderedRow>
                    </Fragment>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </LineViewStyled>
  )
}

const viewTypes = {
  proposal: 'Propuesta de negocio',
  default: 'Linea',
}

const LineViewStyled = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fbf7f7;
  border-radius: 1em;
  padding: 3em 2em 2em 2em;
  width: fit-content;

  margin: 1em 0;

  span {
    font-weight: bold;
  }

  table {
    width: -webkit-fill-available;
    border-collapse: collapse;

    td {
      padding: 1px 1em;
    }
  }
  h4 {
    display: flex;
    font-weight: bold;
    font-size: 1.2em;

    .icon {
      margin: 0;
      font-weight: normal;
      margin-right: 0.5em;
    }
  }

  .line-overview {
    display: flex;
    align-items: last baseline;
    margin: 1em 0 0.5em 0;

    p {
      margin: 0;
    }

    .line-ov-div {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-right: 1em;
    }
  }
  .line-score {
    font-size: 2em;
    font-weight: bold;

    span {
      color: ${({ theme }) => theme.colors.secondary.dark.backgroundColor};
    }
  }
  .line-details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1em;

    .terms,
    .commissions {
      background-color: white;
      padding: 1em;
    }
  }
  .skeleton {
    height: 25px;
    min-width: 185px;
    max-width: 285px;
    color: rgba(234, 240, 246, 0);
  }

  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
`

const BorderedRow = styled.tr`
  border-bottom: ${({ hasBorder }) =>
    hasBorder ? '1pt #EFEFEF solid' : 'none'};
`

export default LineConditionsView
