import { CustomerContext } from 'providers/customer'
import { useContext, useEffect, useState } from 'react'
import { financeFind } from 'services/lines'

export const useFetchFinance = () => {
  const [financeInformation, setFinanceInformation] = useState({})
  const { customer } = useContext(CustomerContext)

  useEffect(() => {
    const fetchFinance = async () => {
      const [data] = await financeFind(customer.id)
      setFinanceInformation(data)
    }

    if (customer?.id) fetchFinance()
  }, [customer?.id])

  return financeInformation
}
