import { Button, Icon, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import DragDropFile from 'components/dragDropFile'
import GeneralComment from 'containers/general-comment'
import LateralBar from 'containers/lateral-bar'
import ModalUpload from 'containers/Modal/modal-documents-upload'
import axios from 'axios'
import { AlertContext } from 'providers/alert'
import { CustomerContext } from 'providers/customer'
import { LateralBarContext, LATERAL_MENU_OPTIONS } from 'providers/lateral-bar'
import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { downloadDocument, uploadDocuments } from 'services/documents'
import { instanceTransition } from 'services/workflow'
import styled from 'styled-components'
import { FILE_MAX_SIZE } from 'utils/constants'
import { downloadFile, fetchFileBinary } from 'utils/functions/download-file'
import { getUserRoles } from 'utils/functions/role-manager'
import { useAuth } from 'utils/hooks/auth'
import { useFetchDocuments } from 'utils/hooks/fetch-documents'
import { CUSTOMER_STATES } from 'utils/schemas/customer'
import {
  FILE_STAGES,
  FILE_STATES,
  VISIT_REGISTER,
} from 'utils/schemas/documents'
import { BuildSection } from '../document-actions/build-section'

const FILES_FILTER = { stage: FILE_STAGES.VISIT }
const IMAGE_FILE_TYPE = 27
const DOCUMENT_FILE_TYPE = 28

const VisitRegister = () => {
  const [showErrorImages, setShowErrorImages] = useState(false)
  const [files, setFiles] = useState({})
  const [imagePreview, setImagePreview] = useState([])
  const [imagesDropHandler, setImagesDropHandler] = useState([])
  const [imageURLs, setImageURLs] = useState([])
  const [openCommentBar, setOpenCommentBar] = useState(false)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [comment, setComment] = useState('')
  const [isPublic, setIsPublic] = useState(false)
  const [openModalUpload, setOpenModalUpload] = useState(false)
  const [isValidating, setIsValidating] = useState(false)
  const { customer } = useContext(CustomerContext)
  const { setAction, setSelectedOption } = useContext(LateralBarContext)
  const { user } = useAuth()
  const { setAlert } = useContext(AlertContext)
  const toResetErrorRef = useRef(null)
  const filesUploaded = useFetchDocuments(customer?.id, FILES_FILTER)

  const handleDrop = (inputFiles) => {
    // Get only image files
    const filesFiltered = Array.from(inputFiles)
      ?.filter(
        (file) =>
          new RegExp('image/*').test(file?.type) && file.size < FILE_MAX_SIZE
      )
      ?.map((file) => {
        file.typeId = IMAGE_FILE_TYPE
        return file
      })
    const imageHandleDrop = [...filesFiltered, ...imagesDropHandler]
    setImagesDropHandler(imageHandleDrop)
    const urlImages = imageHandleDrop.map((file) => URL.createObjectURL(file))
    setImageURLs(urlImages)
  }

  const saveFiles = async () => {
    const userRole = getUserRoles()[0]
    const imagesUploaded = files[IMAGE_FILE_TYPE] || []

    const imageHandleDrop = [
      ...imagesUploaded,
      ...imagePreview,
      ...imagesDropHandler,
    ]

    const response = await uploadDocuments(
      { ...files, [IMAGE_FILE_TYPE]: imageHandleDrop },
      customer.id,
      user.uid,
      userRole
    )
    if (response.some((request) => request.status !== 'fulfilled')) {
      throw new Error('error saving files')
    }
    return response.map((request) => request.value)
  }

  const validate = () => {
    const imagesUploaded = files[IMAGE_FILE_TYPE] || []
    const filesUploaded = files[DOCUMENT_FILE_TYPE] || []
    const imageHandleDrop = [...imagesUploaded, ...imagesDropHandler]

    if (filesUploaded.length === 0) {
      throw new Error('form-files')
    }
    if (filesUploaded.some((file) => file.status === FILE_STATES.REFUSED)) {
      throw new Error('form-files-status')
    }
    if (imageHandleDrop.length === 0) {
      throw new Error('images')
    }
    if (!comment) {
      throw new Error('comment')
    }
  }

  const onSaveDocuments = async () => {
    try {
      setIsLoadingSubmit(true)
      await saveFiles()
      setAction(undefined)
      setIsLoadingSubmit(false)
      setOpenModalUpload(false)
    } catch (err) {
      setAlert({
        title: 'Error',
        label: 'Error al subir los archivos.',
        type: 'error',
      })
    }
  }

  const onHandleRemove = (file) => {
    setImagePreview(
      imagePreview.map((prevFile) => {
        if (prevFile.name === file.name) {
          prevFile.status = FILE_STATES.DELETED
        }
        return prevFile
      })
    )
  }

  const onSubmitTransition = async () => {
    try {
      setIsLoadingSubmit(true)
      validate()
      const documents = await saveFiles()
      const fileList = documents.map(({ id, status = FILE_STATES.DRAFT }) => ({
        id,
        status: [FILE_STATES.DRAFT, FILE_STATES.REFUSED].includes(status)
          ? FILE_STATES.PENDING
          : status,
      }))
      const transition = CUSTOMER_STATES.cust_legalReviewCompleted.transition
      const [role] = getUserRoles()
      const notificationReceiver = customer?.assignations?.find((itm) =>
        transition?.SEND?.notificationReceiverRoles.includes(itm?.role)
      )

      await instanceTransition(
        customer?.customerInstance?.workflowId,
        customer?.customerInstance?.instanceId,
        transition.SEND.event,
        {
          'fileList:multiUpdate': fileList,
          'wfState:status': transition.SEND.nextState,
          customerId: customer.id,
          'wfState:comment': customer?.customerInstance?.currentState,
          'uuid:comment': user?.uid,
          'role:comment': role,
          'comment:comment': comment,
          'isPublic:comment': isPublic,
          senderUidFed: user.uid,
          'receiverUidIam:notificationOne': notificationReceiver?.iamUid,
          'customerId:notificationOne': customer.id,
        }
      )
      setAction('')
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
    } catch (err) {
      const alert = {
        label: 'Ha ocurrido un error al enviar. por favor recarga la pagina',
        title: 'Error',
        type: 'error',
      }
      if (err.message === 'error saving files') {
        alert.label =
          'Ha ocurrido un error al guardar los archivos, por favor ingresa al prospecto e intenta subir los documentos de nuevo'
      } else if (axios.isAxiosError(err)) {
        const { data } = err.response
        console.error('workflow error:', data.body?.message)
        alert.label =
          'Los documentos se han guardado, pero ha ocurrido un error al enviar. por favor ingresa al prospecto e intenta  nuevamente'
      }
      const errors = {
        'form-files': 'Debe subir al menos un archivo',
        'form-files-status': 'Por favor corrige los archivos rechazados',
        images: 'Debe subir al menos una imagen',
        comment: 'Debe ingresar un comentario',
        default: 'Error al subir los archivos.',
      }
      setAlert({
        title: 'Error',
        label: errors[err.message] || errors.default,
        type: 'error',
      })
      setIsValidating(true)
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  useEffect(() => {
    const array = imagePreview.filter(
      ({ status }) => status !== FILE_STATES.DELETED
    )
    setShowErrorImages(array.length === 0)
  }, [imagePreview])

  useEffect(() => {
    /* Fetch saved files */
    const fetchImages = async (files) => {
      const images = files[IMAGE_FILE_TYPE] ?? []
      if (!images.length) return
      const response = await Promise.allSettled(
        images.map(async ({ id, name, status }) => {
          const file = await fetchFileBinary(id)
          const url = URL.createObjectURL(file)
          return { id, name, status, url }
        })
      )
      setImagePreview(
        response
          .filter((item) => item.status === 'fulfilled')
          .map((item) => item.value)
      )
    }

    /* Fetch saved files */
    const fetchFiles = async () => {
      const filesSet = filesUploaded.reduce((acc, file) => {
        const {
          fileExtension,
          typeId,
          fileName,
          fileSize,
          fileId,
          fileStatus,
        } = file
        const extension = fileExtension ? `.${fileExtension}` : ''
        const filesArray = acc[typeId] || []
        const fileObject = {
          extension: fileExtension,
          id: fileId,
          name: `${fileName}${extension}`,
          size: Number(fileSize),
          status: fileStatus || FILE_STATES.DRAFT,
          typeId,
        }
        return {
          ...acc,
          [typeId]: [...filesArray, fileObject],
        }
      }, {})
      setFiles(filesSet)
      fetchImages(filesSet)
    }
    fetchFiles()
  }, [filesUploaded])

  return (
    <VisitRegisterStyled ref={toResetErrorRef}>
      <section>
        <BuildSection
          allowComment
          allowDelete
          allowUpdate
          isValidating={isValidating}
          files={files}
          setFiles={setFiles}
          title='Formulario de visita'
          schema={VISIT_REGISTER}
          setCommentBarInfo={setOpenCommentBar}
        />
      </section>
      {imagePreview.length > 0 && (
        <section>
          <h3>Fotos subidas previamente</h3>
          <div className='image-container'>
            {imagePreview
              .filter((image) => image.status !== FILE_STATES.DELETED)
              .map((image, index) => {
                return (
                  <div>
                    <div
                      key={`image-${index}`}
                      className='image-preview'
                    >
                      <img
                        alt={image.name}
                        src={image.url}
                      />
                      <Icon
                        name='file_download'
                        type='outlined'
                        onClick={() =>
                          downloadFile(downloadDocument(image.id), image.name)
                        }
                      />
                      <label
                        className='span_icon_deleted'
                        onClick={() => onHandleRemove(image)}
                      >
                        {image.status !== FILE_STATES.ACCEPTED && (
                          <Icon
                            name='delete'
                            type='outlined'
                          />
                        )}
                      </label>
                    </div>
                  </div>
                )
              })}
          </div>
        </section>
      )}
      <section>
        <h3>Fotografías de la visita </h3>
        <div className='image-upload'>
          <DragDropFile
            handleFiles={handleDrop}
            error={
              isValidating && showErrorImages && imagesDropHandler.length === 0
            }
          />
          <div className='image-list'>
            {imageURLs.slice(0, 7).map((itm, idx) => (
              <Fragment key={`img-${idx}`}>
                <img
                  className='image-prev'
                  alt='tile'
                  src={itm}
                />
                <Icon
                  name='delete'
                  type='outlined'
                  onClick={() => {
                    setImageURLs(imageURLs.filter((_, i) => idx !== i))
                    setImagesDropHandler(
                      imagesDropHandler.filter((_, i) => idx !== i)
                    )
                  }}
                />
              </Fragment>
            ))}
            {imageURLs?.length > 7 && (
              <div className='image-prev'>{`+${imageURLs?.length - 7}`}</div>
            )}
          </div>
        </div>
      </section>
      <section>
        <GeneralComment
          comment={comment}
          setComment={setComment}
          setIsPublic={setIsPublic}
          error={!comment && isValidating}
        />
      </section>
      <div className='action-group'>
        <Button onClick={() => setAction(false)}>Cancelar</Button>
        <Button
          buttonType='outline'
          disabled={isLoadingSubmit}
          onClick={() => setOpenModalUpload(true)}
        >
          {isLoadingSubmit ? <LoadingAnimation /> : 'Guardar'}
        </Button>
        <Button
          buttonType='blueDark'
          className='send'
          disabled={isLoadingSubmit}
          onClick={() => onSubmitTransition()}
        >
          {isLoadingSubmit ? <LoadingAnimation /> : 'Enviar'}
        </Button>
      </div>
      {openCommentBar && (
        <LateralBar
          fileId={openCommentBar.fileId}
          documentName={openCommentBar.fileName}
          setlateralBar={setOpenCommentBar}
        />
      )}
      {openModalUpload && (
        <ModalUpload
          setOpenModalUpload={setOpenModalUpload}
          isLoadingSubmit={isLoadingSubmit}
          handleSave={onSaveDocuments}
        />
      )}
    </VisitRegisterStyled>
  )
}

const VisitRegisterStyled = styled.div`
  padding: 2rem;
  section > h3 {
    margin-bottom: 1rem;
  }

  section + section {
    margin-top: 2rem;
  }
  .image-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    .image-preview {
      max-width: 200px;

      display: grid;
      place-items: center;

      position: relative;

      img {
        height: 120px;
        max-height: 100%;
        margin: auto;
      }

      span.icon {
        position: absolute;
      }

      &:not(:hover) {
        span.icon {
          display: none;
        }
      }

      &:hover {
        cursor: pointer;

        img {
          filter: brightness(65%);
        }

        span.icon {
          display: block;

          background-color: white;
          border-radius: 50%;
        }
      }
    }
    .span_icon_deleted {
      position: absolute;
      left: 90pt;
      top: 36pt;
    }
  }

  .image-upload {
    display: flex;
    gap: 1rem;

    .image-list {
      display: flex;
      flex-wrap: wrap;
      flex-basis: 55%;

      .image-prev {
        width: 100px;
        height: 100px;
        background-color: grey;
        border-radius: 10px;
        margin: 10px;

        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2em;
      }

      span.icon {
        cursor: pointer;
      }
    }
  }

  .action-group {
    margin-top: 2rem;

    button + button {
      margin-left: 28px;
    }

    .send {
      float: right;
    }
  }
`

export default VisitRegister
