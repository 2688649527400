import styled from 'styled-components'
import { BuildSection } from 'templates/actions/document-actions/build-section'

const DocumentsRequested = (props) => {
  const {
    documentsRequested,
    setDocumentsRequested,
    documentsRequestedSchema,
    setOpenCommentBar,
  } = props

  return (
    <DocumentRequestedStyled>
      <BuildSection
        allowComment
        allowReview
        allowUpload={false}
        files={documentsRequested}
        setFiles={setDocumentsRequested}
        schema={documentsRequestedSchema}
        setCommentBarInfo={setOpenCommentBar}
        title='Documentos solicitados'
      />
    </DocumentRequestedStyled>
  )
}

const DocumentRequestedStyled = styled.div`
  margin-top: 25px;

  .row {
    display: flex;
    align-items: center;
  }
`

export default DocumentsRequested
