import { Button, Icon, LoadingAnimation, Select } from '@keoworld/gbl-ui-kit'
import Dropdown from 'containers/dropdown'
import Form from 'containers/form'
import MoralPersonJointly from 'containers/moral-person'
import NaturePersonJointly from 'containers/nature-person'
import { CustomerContext } from 'providers/customer'
import { LateralBarContext, LATERAL_MENU_OPTIONS } from 'providers/lateral-bar'
import { useCallback, useContext, useEffect, useState } from 'react'
import {
  downloadDocument,
  generateContractFiles,
  getDocumentList,
} from 'services/documents'
import { getCustomerLines, getLineInfo } from 'services/lines'
import { instanceTransition } from 'services/workflow'
import styled from 'styled-components'
import { downloadFile } from 'utils/functions/download-file'
import { getUserRoles } from 'utils/functions/role-manager'
import { useAuth } from 'utils/hooks/auth'
import { useFetchContract } from 'utils/hooks/fetch-contract'
import {
  LEGAL_REPRESENTATIVE,
  LEGAL_REPRESENTATIVE_KEO_FORM,
  MORAL_PERSON,
  PHYSICAL_PERSON,
} from 'utils/schemas/commercial'
import { CUSTOMER_STATES } from 'utils/schemas/customer'
import { CONTRACT_STATES } from 'utils/schemas/documents'
import { GeneratedFilesStyled } from './generated-files-styles'

const CONTRACT_TYPE_ID = 30

const options = {
  accredited: [
    { label: 'Persona Moral', value: 'moralPerson' },
    { label: 'Persona Física', value: 'physicalPerson' },
  ],
  jointlyBound: [
    { label: 'Persona Moral', value: 'moralPersonJointly' },
    { label: 'Persona Física', value: 'physicalPersonJointly' },
  ],
}

const LoadSignatories = () => {
  const [select, setSelect] = useState({})

  /** finalForm IS THE FINAL OBJECT WITH EVERYTHING */
  const [isLoading, setIsLoading] = useState(false)
  const [finalForm, setFinalForm] = useState({})
  const [representativeForm, setRepresentativeForm] = useState({})
  const [legalRepresentativeArray, setLegalRepresentativeArray] = useState([])
  const [legalRepresentative, setLegalRepresentative] = useState({})
  const [moralPerson, setMoralPerson] = useState({})
  const [physicPerson, setPhysicPerson] = useState({})
  const [listPerson, setListPerson] = useState([])
  const [documents, setDocuments] = useState([])
  const [lineInfo, setLineInfo] = useState({})
  const [loading, setLoading] = useState(false)
  const { customer } = useContext(CustomerContext)
  const { user } = useAuth()
  const { setAction, setSelectedOption } = useContext(LateralBarContext)
  const contractInfo = useFetchContract({
    customerId: customer?.id,
    state: CONTRACT_STATES.Draft,
  })

  const fetchFiles = useCallback(async () => {
    try {
      setLoading(true)
      const { data: filesData } = await getDocumentList(customer?.id, {
        stage: 'Carga de firmantes',
      })
      const savedFiles = filesData.reduce((acc, itm) => {
        if (itm.typeId === CONTRACT_TYPE_ID)
          return {
            ...acc,
            [itm.fileId]: {
              name: `${itm.fileName}${'.pdf'}`,
              size: itm.fileSize,
              status: itm.fileStatus,
              id: itm.fileId,
              typeId: itm.typeId,
            },
          }
        return acc
      }, {})
      setDocuments(savedFiles)
    } catch (err) {
      console.error('FetchFilesError', err)
    } finally {
      setLoading(false)
    }
  }, [customer?.id])

  const finalHandleChange = async (person) => {
    try {
      /* send signers */
      setLoading(true)
      if (person === 'moral') {
        setFinalForm({
          representativeForm,
          moralPerson,
          legalRepresentativeArray,
          listPerson,
        })
      } else {
        const physicPersonForm = physicPerson
        physicPersonForm.spouse ??= false
        setFinalForm({
          representativeForm,
          physicPersonForm,
          listPerson,
        })
      }
      /* generate contract files */

      await generateContractFiles({
        id: lineInfo?.contractId,
        uuid: user.uid,
        role: getUserRoles()[0],
        typeFileId: CONTRACT_TYPE_ID,
      })
      /* fetch signatories */
      await fetchFiles()
    } catch (err) {
      console.error('FileGenerationError', err)
    } finally {
      setLoading(false)
    }
  }

  const representativeLegal = () => {
    setLegalRepresentativeArray((array) => [
      ...array,
      {
        legalRepresentative,
      },
    ])
    setLegalRepresentative({})
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setSelect({ ...select, [name]: value })
  }

  const handleChangeNaturePerson = (e) => {
    const { name, checked } = e.target
    setPhysicPerson({ ...physicPerson, [name]: checked })
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    const transition = CUSTOMER_STATES.line_instrumentation.transition
    const notificationReceiver = customer?.assignations?.find((itm) =>
      transition?.SEND?.notificationReceiverRoles.includes(itm?.role)
    )

    try {
      await instanceTransition(
        customer?.lineInstance?.workflowId,
        `${customer?.customerInstance?.instanceId}.${customer?.lineInstance?.instanceId}`,
        transition.SEND.event,
        {
          signersForm: finalForm,
          'contractId:contractUpdate': contractInfo?.id,
          'status:contractUpdate': CONTRACT_STATES.Instrumentalization,
          'id:status': customer?.id,
          'wfState:status': transition.SEND.nextState,
          senderUidFed: user.uid,
          'receiverUidIam:notificationOne': notificationReceiver?.iamUid,
          'customerId:notificationOne': customer.id,
        }
      )
      setAction(false)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
    } catch (err) {
      console.error('SubmitError', err)
    }
    setIsLoading(false)
  }

  const onClickDownload = (id, name) => {
    try {
      if (id && name) downloadFile(downloadDocument(id), name)
    } catch (err) {
      console.error('error', err, err?.message)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: lines } = await getCustomerLines(customer?.id)
        const { data: lineData } = await getLineInfo(lines[0]?.lineId)
        setLineInfo(lineData)
      } catch (err) {
        console.log('fetchError', err)
      }
    }
    if (customer?.id) fetchData()
  }, [customer?.id])

  useEffect(() => {
    fetchFiles()
  }, [fetchFiles])

  const moralPersonComp = (
    <>
      <br />
      <Dropdown label='Nombre del Acreditado Persona Moral / Obligado Solidario Persona Moral'>
        <div className='form'>
          <Form
            schema={MORAL_PERSON.FORM_ONE}
            formValues={moralPerson}
            setFormValues={setMoralPerson}
          />
        </div>
      </Dropdown>
      <Dropdown label='Acta Constitutiva'>
        <div className='form'>
          <Form
            schema={MORAL_PERSON.FORM_TWO}
            formValues={moralPerson}
            setFormValues={setMoralPerson}
          />
        </div>
      </Dropdown>
      {legalRepresentativeArray?.map((person, index) => {
        return (
          <div className='list-person' key={`relative-${index}`}>
            <div className='icon'>
              <Icon
                name='delete'
                type='outlined'
                onClick={() =>
                  setLegalRepresentativeArray(
                    legalRepresentativeArray.filter((_, i) => index !== i)
                  )
                }
              />
            </div>
            <div>
              <p className='title'>
                <b>Nombre:</b>
              </p>
              <p>{person.legalRepresentative.name}</p>
            </div>
            <div>
              <p className='title'>
                <b>Escritura Numero:</b>
              </p>
              <p>{person.legalRepresentative.writingNumber}</p>
            </div>
          </div>
        )
      })}
      <Dropdown label='Datos del Representante Legal del Acreditado o del Principal accionista persona moral'>
        <div className='form'>
          <Form
            schema={LEGAL_REPRESENTATIVE.FORM_ONE}
            formValues={legalRepresentative}
            setFormValues={setLegalRepresentative}
          />
        </div>
      </Dropdown>
      <Dropdown label='Poderes y Facultades para suscribir el contrato'>
        <div className='form'>
          <Form
            schema={LEGAL_REPRESENTATIVE.FORM_TWO}
            formValues={legalRepresentative}
            setFormValues={setLegalRepresentative}
          />
        </div>
        <Button
          className='button-add'
          buttonType='grayButton'
          onClick={() => representativeLegal()}
        >
          + Agregar Representante Legal
        </Button>
      </Dropdown>
      <hr />
      <div className='select-div'>
        <p>Obligado solidario</p>
        <Select
          name='jointlyBound'
          className='select-input'
          value={select.jointlyBound ?? ''}
          onChange={handleChange}
        >
          <option disabled value=''>
            Selecciona una opción
          </option>
          {options.jointlyBound.map(({ label, value }, index) => (
            <option key={`option-${index}`} value={value}>
              {label}
            </option>
          ))}
        </Select>
      </div>
      {listPerson?.map((person, index) => {
        return (
          <div className='list-person' key={`relative-${index}`}>
            <div className='icon'>
              <Icon
                name='delete'
                type='outlined'
                onClick={() =>
                  setListPerson(listPerson.filter((_, i) => index !== i))
                }
              />
            </div>
            <div>
              <p className='title'>
                <b>
                  {person.naturePersonJointly
                    ? 'Persona Física'
                    : 'Persona Moral'}
                </b>
              </p>
              <p className='title'>
                <b>{person.naturePersonJointly ? 'Nombre' : 'Razón Social'}</b>
              </p>
              <p>
                {person.naturePersonJointly
                  ? person.naturePersonJointly?.name
                  : person.moralPersonForm?.businessName}
              </p>
              <p className='title'>
                <b>RFC (CON HOMOCLAVE)</b>
              </p>
              <p>
                {person.naturePersonJointly
                  ? person.naturePersonJointly?.rfc
                  : person.moralPersonForm?.rfc}
              </p>
            </div>
          </div>
        )
      })}
      {select.jointlyBound === 'moralPersonJointly' && (
        <MoralPersonJointly
          listPerson={listPerson}
          setListPerson={setListPerson}
        />
      )}
      {select.jointlyBound === 'physicalPersonJointly' && (
        <NaturePersonJointly
          listPerson={listPerson}
          setListPerson={setListPerson}
        />
      )}
      <Button
        className='final-button'
        buttonType='green'
        onClick={() => finalHandleChange('moral')}
        disabled={loading}
      >
        {loading ? <LoadingAnimation className='loading' /> : '  Generar PDF'}
      </Button>
    </>
  )

  const physicalPersonComp = (
    <>
      <br />
      <Dropdown label='Datos del Acreditado Persona Física/ Principal accionista persona física / Cónyuge del acreditado o del Principal accionista persona física'>
        <div className='form'>
          <Form
            schema={PHYSICAL_PERSON}
            formValues={physicPerson}
            setFormValues={setPhysicPerson}
          />
        </div>
        <div>
          <p>Cónyuge</p>
          <label className='switch-div'>
            <p>No</p>
            <label className='switch'>
              <input
                type='checkbox'
                name='spouse'
                onChange={handleChangeNaturePerson}
              />
              <span className='slider round' />
            </label>
            <p> Sí</p>
          </label>
        </div>
      </Dropdown>
      <hr />
      <div className='select-div'>
        <p>Obligado solidario</p>
        <Select
          name='jointlyBound'
          className='select-input'
          value={select.jointlyBound ?? ''}
          onChange={(e) => {
            setListPerson([])
            handleChange(e)
          }}
        >
          <option disabled value=''>
            Selecciona una opción
          </option>
          {options.jointlyBound.map(({ label, value }, index) => (
            <option key={`option-${index}`} value={value}>
              {label}
            </option>
          ))}
        </Select>
      </div>
      {select.jointlyBound === 'moralPersonJointly' && (
        <MoralPersonJointly
          listPerson={listPerson}
          setListPerson={setListPerson}
        />
      )}
      {select.jointlyBound === 'physicalPersonJointly' && (
        <NaturePersonJointly
          listPerson={listPerson}
          setListPerson={setListPerson}
        />
      )}
      <Button
        className='final-button'
        onClick={() => finalHandleChange('physic')}
        buttonType='green'
        disabled={loading}
      >
        {loading ? <LoadingAnimation className='loading' /> : '  Generar PDF'}
      </Button>
    </>
  )

  const accredited = {
    moralPerson: moralPersonComp,
    physicalPerson: physicalPersonComp,
  }

  return (
    <LoadSignatoriesStyled>
      <h3>Creación de contrato y pagaré</h3>
      <Dropdown label={'Datos Rep. Legal KEO'}>
        <div className='form'>
          <Form
            schema={LEGAL_REPRESENTATIVE_KEO_FORM}
            formValues={representativeForm}
            setFormValues={setRepresentativeForm}
          />
        </div>
      </Dropdown>
      <hr />
      <div className='select-div'>
        <p>Acreditado</p>
        <Select
          name='accredited'
          className='select-input'
          value={select.accredited ?? ''}
          onChange={(e) => {
            delete select.jointlyBound
            setFinalForm({})
            setLegalRepresentativeArray([])
            setListPerson([])
            setMoralPerson({})
            setPhysicPerson({})
            handleChange(e)
          }}
        >
          <option disabled value=''>
            Selecciona una opción
          </option>
          {options.accredited.map(({ label, value }, index) => (
            <option key={`option-${index}`} value={value}>
              {label}
            </option>
          ))}
        </Select>
      </div>
      <section className='space-section'>
        {accredited[select.accredited]}
      </section>
      <div>
        <GeneratedFilesStyled>
          {Object.values(documents).map((itm, index) => (
            <div
              key={`file-${index}`}
              className='file-download'
              onClick={() => onClickDownload(itm.id, itm.name)}
            >
              <Icon name='file_download' type='outlined' />
              <p>{itm.name}</p>
            </div>
          ))}
        </GeneratedFilesStyled>
      </div>
      <Button onClick={handleSubmit} disabled={isLoading}>
        Enviar
      </Button>
    </LoadSignatoriesStyled>
  )
}

const LoadSignatoriesStyled = styled.div`
  padding: 2rem;
  padding-bottom: 1rem;

  .loading span {
    background-color: white;
  }
  .final-button {
    margin: 1rem auto;
  }
  .div-checkbox {
    display: flex;
    margin: -1rem auto 1rem 0rem;
    align-items: center;
    width: 100px;
    div.input {
      width: 30%;
    }
  }

  .switch-div {
    display: flex;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin: 0.5rem 0rem 0.5rem 0.5rem;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 8px;
    left: 0px;
    right: 14px;
    bottom: 11px;
    background-color: #939191;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 22px;
    width: 22px;
    left: -1px;
    bottom: -3.5px;
    background-color: #bab9b9;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #939191;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
  .button-add {
    width: auto;
    background-color: #9b9b9b;
  }
  .list-person {
    align-items: center;
    margin: 2rem auto 2rem 0rem;
    padding: 10px;
    background-color: #f0f0f0;
    width: 300px;
    p {
      white-space: nowrap;
      width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0.5rem;
    }
    .icon {
      float: right;
    }
  }
  .space-section {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 1rem auto;
    Input {
      max-width: 280px;
    }
    Select {
      max-width: 280px;
    }
    & > div {
      padding: 0;
      max-width: 300px;
      margin: 0.5rem 0rem;
      &:nth-child(2n + 1) {
        padding-left: 0;
      }
      &:nth-child(2n) {
        padding-right: 0;
      }
    }
  }
  .select-div {
    font-size: 20px;
    p {
      font-weight: bold;
      font-size: 16px;
    }
  }
  .select-input {
    width: 200px;
  }
`

export default LoadSignatories
