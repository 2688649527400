import { Button, Icon } from '@keoworld/gbl-ui-kit'
import { useState } from 'react'
import styled from 'styled-components'
import { LEGAL_REPRESENTATIVE, MORAL_PERSON } from 'utils/schemas/commercial'
import Dropdown from './dropdown'
import Form from './form'

const MoralPersonJointly = ({ listPerson, setListPerson }) => {
  const [moralPersonForm, setMoralPersonForm] = useState({})

  const [legalRepresentativeArray, setLegalRepresentativeArray] = useState([])
  const [legalRepresentativeForm, setLegalRepresentativeForm] = useState({})

  const addMoralPerson = () => {
    setListPerson((moral) => [
      ...moral,
      { moralPersonForm, legalRepresentativeArray }
    ])
    setMoralPersonForm({})
    setLegalRepresentativeForm({})
    setLegalRepresentativeArray([])
  }

  const addLegalRepresentative = () => {
    setLegalRepresentativeArray((legal) => [
      ...legal,
      { legalRepresentativeForm }
    ])
    setLegalRepresentativeForm({})
  }

  return (
    <MoralPersonJointlyStyled>
      <Dropdown
        label={
          'Nombre del Acreditado Persona Moral / Obligado Solidario Persona Moral'
        }
      >
        <div className='form'>
          <Form
            schema={MORAL_PERSON.FORM_ONE}
            formValues={moralPersonForm}
            setFormValues={setMoralPersonForm}
          />
        </div>
      </Dropdown>
      <Dropdown label={'Acta Constitutiva'}>
        <div className='form'>
          <Form
            schema={MORAL_PERSON.FORM_TWO}
            formValues={moralPersonForm}
            setFormValues={setMoralPersonForm}
          />
        </div>
      </Dropdown>
      {legalRepresentativeArray?.map((person, index) => {
        return (
          <div className='list-person' key={`relative-${index}`}>
            <div className='icon'>
              <Icon
                name='delete'
                type='outlined'
                onClick={() =>
                  setLegalRepresentativeArray(
                    legalRepresentativeArray.filter((_, i) => index !== i)
                  )
                }
              />
            </div>
            <div>
              <p className='title'>
                <b>Nombre:</b>
              </p>
              <p>{person.legalRepresentativeForm.name}</p>
            </div>
            <div>
              <p className='title'>
                <b>Escritura Numero:</b>
              </p>
              <p>{person.legalRepresentativeForm.writingNumber}</p>
            </div>
          </div>
        )
      })}
      <Dropdown
        label={
          'Datos del Representante Legal del Acreditado o del Principal accionista persona moral'
        }
      >
        <div className='form'>
          <Form
            schema={LEGAL_REPRESENTATIVE.FORM_ONE}
            formValues={legalRepresentativeForm}
            setFormValues={setLegalRepresentativeForm}
          />
        </div>
      </Dropdown>
      <Dropdown label='Poderes y Facultades para suscribir el contrato'>
        <div className='form'>
          <Form
            schema={LEGAL_REPRESENTATIVE.FORM_TWO}
            formValues={legalRepresentativeForm}
            setFormValues={setLegalRepresentativeForm}
          />
        </div>
        <Button
          className='button-add-two'
          onClick={() => addLegalRepresentative()}
        >
          + Agregar Representante Legal
        </Button>
      </Dropdown>
      <Button className='button-add' onClick={() => addMoralPerson()}>
        + Agregar persona moral
      </Button>
    </MoralPersonJointlyStyled>
  )
}

const MoralPersonJointlyStyled = styled.div`
  .list-person {
    align-items: center;
    margin: 2rem auto 2rem 0rem;
    padding: 10px;
    background-color: #f0f0f0;
    width: 250px;
    p {
      white-space: nowrap;
      width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0.5rem;
    }
    .icon {
      float: right;
    }
  }
  .button-add-two {
    float: right;
    width: auto;
    margin-right: 2rem;
  }
  .form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 1rem auto;
    Input {
      max-width: 250px;
    }
    Select {
      max-width: 250px;
    }
    & > div {
      padding: 0;
      margin: 0.5rem 0rem;
      &:nth-child(2n + 1) {
        padding-left: 0;
      }
      &:nth-child(2n) {
        padding-right: 0;
      }
    }
  }
`

export default MoralPersonJointly
