import ProtectedRoute from 'components/protected-route'
import { Navigate, Route, Routes } from 'react-router-dom'
import OnboardingActions from 'templates/buyer-detail/actions'
import BuyerDetail from 'templates/buyer-detail/buyer-detail'
import MainTemplate from 'templates/main'
import Router from 'utils/router'
import SignIn from 'views/sign-in'
import Unauthorized from 'views/unauthorized'

const App = () => {
  return (
    <Routes>
      <Route path={Router.Root} element={<ProtectedRoute />}>
        <Route path={Router.Onboarding} element={<BuyerDetail />} />
        <Route path={Router.OnboardingAction} element={<OnboardingActions />} />
        <Route path={Router.Root} element={<MainTemplate />} />
      </Route>
      <Route path={Router.unauthorized} element={<Unauthorized />} />
      <Route path={Router.SignIn} element={<SignIn />} />
      <Route path='*' element={<Navigate to={Router.Root} />} />
    </Routes>
  )
}

export default App
