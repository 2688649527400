import { Button, Card, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import { CustomerContext } from 'providers/customer'
import { LateralBarContext, LATERAL_MENU_OPTIONS } from 'providers/lateral-bar'
import { useContext, useState } from 'react'
import { instanceTransition } from 'services/workflow'
import styled from 'styled-components'
import { getUserRoles } from 'utils/functions/role-manager'
import { useAuth } from 'utils/hooks/auth'
import { CUSTOMER_STATES } from 'utils/schemas/customer'

const Assignment = () => {
  const { user } = useAuth()
  const { customer } = useContext(CustomerContext)
  const { setAction, setSelectedOption } = useContext(LateralBarContext)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)

  const assignToMe = async () => {
    setIsLoadingSubmit(true)
    const custOrLineWfState = customer?.wfState.split('_')
    const custOrLineInstance =
      custOrLineWfState[0] === 'cust'
        ? customer?.customerInstance
        : customer?.lineInstance

    try {
      if (customer?.wfState !== custOrLineInstance.currentState)
        throw new Error(
          'The database state does not correspond to that of the workflow instance'
        )
      const transition =
        CUSTOMER_STATES[custOrLineInstance.currentState]?.transition
      let workflowId
      let principalId
      if (custOrLineWfState[0] !== 'cust') {
        workflowId = customer?.lineInstance?.workflowId
        principalId = `${customer?.id}.${customer?.lineInstance?.instanceId}`
      } else {
        workflowId = customer?.customerInstance?.workflowId
        principalId = customer?.customerInstance?.instanceId
      }
      const notificationReceiver = customer?.assignations?.find((itm) =>
        transition?.SEND?.notificationReceiverRoles?.includes(itm?.role)
      )
      await instanceTransition(workflowId, principalId, transition.SEND.event, {
        'id:assignation': customer.assignationId,
        'uuid:assignation': user.uid,
        'role:assignation': getUserRoles()[0],
        'customerId:assignation': customer.id,
        'id:status': customer.id,
        'wfState:status': transition.SEND.nextState,
        senderUidFed: user.uid,
        'receiverUidIam:notificationOne': notificationReceiver?.iamUid,
        'customerId:notificationOne': customer.id
      })
      setAction(false)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      setIsLoadingSubmit(false)
    } catch (error) {
      console.log(error)
    }
  }

  const cancelAction = () => {
    setAction(false)
  }

  return (
    <AssignMeStyled>
      <h3>¿Está seguro de que quiere asignarse a este cliente?</h3>
      <p>El líder de su area puede reasignar, en caso de cometer un error</p>
      <div className='btn-group'>
        <Button buttonType='grayButton' onClick={cancelAction}>
          Cancelar
        </Button>
        <Button disabled={isLoadingSubmit} onClick={assignToMe}>
          {isLoadingSubmit ? <LoadingAnimation /> : 'Asignarme'}
        </Button>
      </div>
    </AssignMeStyled>
  )
}

const AssignMeStyled = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;

  width: 50%;
  min-width: 400px;
  height: auto;
  padding: 20px 10px;
  margin: 100px auto 0;

  .fcDNbZ span {
    background-color: white;
  }

  .btn-group {
    /* margin: 20px auto; */
    button + button {
      margin-left: 10px;
    }
  }
`

export default Assignment
