import { Icon, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import PaginatedItems from 'components/pagination'
import CommentCard from 'containers/comment-card/comment-card'
import { AlertContext } from 'providers/alert'
import { CustomerContext } from 'providers/customer'
import { useContext, useEffect, useRef, useState } from 'react'
import { findCustomerComment } from 'services/customer'
import styled from 'styled-components'
import { getUserRoles } from 'utils/functions/role-manager'
import { useOnClickOutside } from 'utils/hooks/click-outside'

const MAX_COMMENTS = 8
const COMMENTS_FILTER = [
  {
    label: 'Comentarios de onboarding',
    value: 'onboarding',
  },
  {
    label: 'Comentarios de cartera',
    value: 'portfolio',
  },
  {
    label: 'Comentarios de pagos',
    value: 'payments',
  },
]

const CustomerComment = () => {
  const filterRef = useRef()
  const [userRole] = getUserRoles()
  const { customer } = useContext(CustomerContext)
  const { setAlert } = useContext(AlertContext)
  const [commentList, setCommentList] = useState([])
  const [totalComments, setTotalComments] = useState(0)
  const [loading, setLoading] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [filter, setFilter] = useState({
    page: 0,
    limit: MAX_COMMENTS,
    role: userRole,
    request: COMMENTS_FILTER[0].value,
  })

  useOnClickOutside(filterRef, () => setShowFilter(false))

  useEffect(() => {
    const findComments = async () => {
      try {
        setLoading(true)
        setCommentList([])
        const data = await findCustomerComment(customer.id, filter)
        const { comments, total } = data
        setCommentList(comments)
        setTotalComments(total)
      } catch (error) {
        setAlert({
          type: 'error',
          title: 'Error',
          label: 'Error al obtener los comentarios',
        })
      } finally {
        setLoading(false)
      }
    }
    if (customer?.id) findComments()
  }, [customer.id, filter, setAlert])

  const setRequestOrigin = (request) => {
    setFilter({ ...filter, page: 0, request })
    setShowFilter(false)
  }

  return (
    <CustomerCommentStyled>
      <h3>Comentarios</h3>
      <div className='filter' ref={filterRef}>
        <button
          className='filter-button'
          onClick={() => setShowFilter(!showFilter)}
        >
          <Icon name='filter_alt' />
          <span>Filtro</span>
          <Icon name='expand_more' />
        </button>
        {showFilter && (
          <div className='filter-items'>
            {COMMENTS_FILTER.map(({ label, value }) => (
              <button
                className='filter-item'
                key={`filter-${value}`}
                onClick={() => setRequestOrigin(value)}
              >
                <span>{label}</span>
              </button>
            ))}
          </div>
        )}
      </div>
      {loading && <LoadingAnimation />}
      {!loading && commentList.length === 0 && <p>No hay comentarios</p>}
      {!loading && (
        <>
          <div className='comment-list'>
            {commentList.map(
              ({ user, createdAt, message, category }, index) => {
                return (
                  <CommentCard
                    key={`comment-${index}`}
                    comment={{ title: user, createdAt, message }}
                    category={category}
                  />
                )
              }
            )}
          </div>
        </>
      )}

      {totalComments > MAX_COMMENTS && (
        <div className='pagination'>
          <PaginatedItems
            currentPage={filter.page}
            handlePageClick={(event) =>
              setFilter({ ...filter, page: event.selected })
            }
            pageCount={Math.ceil(totalComments / MAX_COMMENTS)}
          />
        </div>
      )}
    </CustomerCommentStyled>
  )
}

const CustomerCommentStyled = styled.div`
  padding: 4rem;

  .filter {
    position: relative;
    width: max-content;

    margin-top: 10px;
    margin-bottom: 10px;

    button {
      border: none;
      background: none;
      padding: 10px;
    }

    .filter-button {
      display: flex;
      align-items: center;
      padding: 10px 0;
    }

    .filter-items {
      display: grid;
      width: max-content;

      position: absolute;
      z-index: 3;

      background-color: #ffffff;
      box-shadow: 5px 5px 10px 5px #f6f8fc;
      border-radius: 4px;

      .filter-item {
        &:hover {
          background-color: #f6f8fc;
        }
      }
    }
  }

  .comment-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, 380px);
    grid-gap: 10px;
  }

  .pagination {
    margin: 1rem auto;
    ul {
      margin: auto;
    }
  }
`

export default CustomerComment
