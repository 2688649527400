/**
 * Rounds n to d decimal numbers
 * @param {number} n The number to be rounded
 * @param {number} d The decimals to be rounded
 * @returns {number} The rounded number
 */
export const gaussianRounding = (n, d = 2) => {
  n = n * Math.pow(10, d)
  let r = Math.round(n)
  let val = Math.abs(n) % 1 === 0.5 ? (r % 2 === 0 ? r : r - 1) : r
  return val / Math.pow(10, d)
}
