/**
 * @typedef CurrencyFormatOptions
 * @property {String} [currency]
 * @property {Number} [minimumFractionDigits]
 * @property {Number} [maximumFractionDigits]
 * @property {String} [style]
 */
const DEFAULT_FORMAT = {
  currency: 'MXN',
  minimumFractionDigits: 0,
  maximumFractionDigits: 4,
  style: 'currency',
}

/**
 * function to format currency
 * @param {Number} amount
 * @param {CurrencyFormatOptions} options
 * @returns
 */
export const currency = (amount, options) => {
  const defaultOptions = {
    ...DEFAULT_FORMAT,
    ...options,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }
  return new Intl.NumberFormat('es-MX', defaultOptions).format(amount)
}

/**
 * function to format percentage
 * @param {Number} value
 */
export const percentage = (value) => {
  return Intl.NumberFormat('es-MX', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 4,
    style: 'percent',
  }).format(value)
}
