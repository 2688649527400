import { Button, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import DebtCapacity from 'containers/debt-capacity/debt-capacity'
import ModalUpload from 'containers/Modal/modal-documents-upload'
import { CustomerContext } from 'providers/customer'
import { LATERAL_MENU_OPTIONS, LateralBarContext } from 'providers/lateral-bar'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiDebtCapacityRepository } from 'services/debt-capacity-suppcards/debtCapacity.repository'
import { instanceTransition } from 'services/workflow'
import styled from 'styled-components'
import { ONBOARDING_STATES } from 'utils/schemas/workflows/onboarding'

const DebtCapacityReviewByCustomer = () => {
  const [proposal, setProposal] = useState()
  const [isFetching, setIsFetching] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [openModalUpload, setOpenModalUpload] = useState(false)
  const [isApproved, setIsApproved] = useState(false)

  const { customer } = useContext(CustomerContext)
  const { setAction, setSelectedOption } = useContext(LateralBarContext)

  const navigate = useNavigate()
  const { customerId } = useParams()

  const onboardingEvents =
    ONBOARDING_STATES.DEBT_CAPACITY_REVIEW_BY_BUYER.transition

  const handleSubmit = async (isApproved) => {
    try {
      setIsSubmitting(true)
      const { approveBuyer, rejectBuyer } = onboardingEvents

      let eventName = approveBuyer.event
      const eventData = {
        'id:status': Number(customerId),
        'wfState:status': approveBuyer.nextState,
        'wfStage:status': approveBuyer.nextStage,
      }

      if (!isApproved) {
        eventName = rejectBuyer.event
        eventData['wfStage:status'] = undefined
        eventData['wfState:status'] = rejectBuyer.nextState
        eventData['status:status'] = rejectBuyer.status
      }

      await instanceTransition(
        customer?.customerInstance?.workflowId,
        customerId,
        eventName,
        eventData
      )

      setAction(false)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      navigate('/')
    } catch (error) {
      console.error(error)
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    const fetchProposal = async () => {
      if (!customerId) return
      setIsFetching(true)

      const proposal = await ApiDebtCapacityRepository.getByBuyerId(customerId)

      setProposal(proposal)

      setIsFetching(false)
    }

    fetchProposal()
  }, [customerId])

  return (
    <CreditLineStyled>
      <h3>Capacidades de endeudamiento</h3>
      {isFetching && <LoadingAnimation />}
      {!isFetching && (
        <DebtCapacity debtCapacities={proposal?.debtCapacities ?? []} />
      )}

      <section className='actions'>
        <Button
          buttonType='outline'
          disabled={isSubmitting}
          onClick={() => {
            navigate(-1)
          }}
        >
          Regresar
        </Button>
        <div>
          <Button
            buttonType='outline'
            disabled={isSubmitting}
            onClick={() => {
              setOpenModalUpload(true)
              setIsApproved(false)
            }}
          >
            {isSubmitting && <LoadingAnimation />}
            {!isSubmitting && <span>Rechazar propuesta</span>}
          </Button>
          <Button
            buttonType='blueDark'
            disabled={isSubmitting}
            onClick={() => {
              setOpenModalUpload(true)
              setIsApproved(true)
            }}
          >
            {isSubmitting && <LoadingAnimation />}
            {!isSubmitting && <span>Aprobar propuesta</span>}
          </Button>
        </div>
      </section>

      <div>
        {openModalUpload && (
          <ModalUpload
            setOpenModalUpload={setOpenModalUpload}
            messages={
              isApproved
                ? {
                    msg: '¿Estás seguro que quieres aprobar la propuesta?',
                    msgButton: 'Confirmar',
                  }
                : {
                    msg: '¿Estás seguro que NO quieres aprobar la propuesta?',
                    msgButton: 'Confirmar',
                  }
            }
            handleSave={() => handleSubmit(isApproved)}
            isLoadingSubmit={isSubmitting}
          />
        )}
      </div>
    </CreditLineStyled>
  )
}

const CreditLineStyled = styled.div`
  padding-block: 5rem;
  padding-inline: 3rem;

  .actions {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;

    div {
      display: flex;
      gap: 1rem;
    }
  }

  /* button {
    margin-top: 1rem;
    margin-right: 1rem;
    width: 188px;
    height: 35p;
  } */
`

export default DebtCapacityReviewByCustomer
