import { format, formatDistance, parseISO } from 'date-fns'
import { es } from 'date-fns/locale'

/**
 * function to display the time that has elapsed since a given date
 * @param {Date} date - date to format
 * @returns {String} - formatted date
 */
export const timeAgoToNow = (date) =>
  formatDistance(date, new Date(), { locale: es })

/**
 * function to display the date in a format.
 * If the date is undefined, it returns today's date
 * @param {Date | string} [ISOdate] - date to format
 * @param {string} [finalFormat] - format to use
 * @returns
 */
export const dateFormatFromIso = (ISOdate, finalFormat) => {
  ISOdate ??= Date.now()
  finalFormat ??= 'dd/MM/yyyy hh:mm a'
  return format(new Date(ISOdate), finalFormat, { locale: es })
}

/**
 * function to return ISO timestamp formatted for input type date-time
 * @param {string} baseDate
 * @returns {Date}
 */
export const getCurrentTimeToInput = () => {
  const current = new Date()
  return `${current.toLocaleDateString('sv')}T${current.toLocaleTimeString(
    'sv'
  )}`
}

/**
 * function to return ISO Date time
 * @param {string} [baseDateTime]
 * @returns {string} ISO date
 */
export const getUTCDateTime = (baseDateTime) => {
  const current = getDateWithOffset(
    baseDateTime ? new Date(baseDateTime) : new Date()
  )
  return `${current.toLocaleDateString('sv')}T${current.toLocaleTimeString(
    'sv'
  )}Z`
}

/**
 * function to convert local date to UTC
 * @param {Date | string} inputDate
 * @returns {Date}
 */
const getDateWithOffset = (inputDate) => {
  const date = typeof inputDate === 'string' ? parseISO(inputDate) : inputDate
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  )
}
