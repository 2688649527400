import { Button, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import axios from 'axios'
import LateralBar from 'containers/lateral-bar'
import FinanceInformation from 'containers/line/finance-information'
import LineConditionsView from 'containers/line/line-conditions-view'
import { AlertContext } from 'providers/alert'
import { CustomerContext } from 'providers/customer'
import { LateralBarContext, LATERAL_MENU_OPTIONS } from 'providers/lateral-bar'
import { useContext, useEffect, useState } from 'react'
import { getLineConditions } from 'services/lines'
import { instanceTransition } from 'services/workflow'
import styled from 'styled-components'
import { useFetchDocuments } from 'utils/hooks/fetch-documents'
import { useFetchFinance } from 'utils/hooks/fetch-finance'
import { useFetchLineConditionChanges } from 'utils/hooks/fetch-line-changes'
import { useFetchLines } from 'utils/hooks/fetch-lines'
import { CUSTOMER_STATES } from 'utils/schemas/customer'
import {
  CREDIT_ANALYSIS as RISK_DOCUMENTS,
  FILE_STAGES,
  FINANCIAL_ANALYSIS,
} from 'utils/schemas/documents'
import { LINE_STATES } from 'utils/schemas/line'
import { BuildSection } from '../document-actions/build-section'

const ANALYSIS_STAGE = { stage: FILE_STAGES.CREDIT }
const RISK_STAGE = { stage: FILE_STAGES.RISK }
const TRANSITION = CUSTOMER_STATES.line_updateReview.transition

const ApproveLineChanges = () => {
  const { customer } = useContext(CustomerContext)
  const analysisDocuments = useFetchDocuments(customer?.id, ANALYSIS_STAGE)
  const riskDocuments = useFetchDocuments(customer?.id, RISK_STAGE)
  const [files, setFiles] = useState({})
  const [line] = useFetchLines(customer?.id, LINE_STATES.REASSESSMENT)
  const [lineConditions, setLineConditions] = useState()
  const financeInformation = useFetchFinance(customer?.id)
  const lineConditionsChange = useFetchLineConditionChanges(customer?.lineId)
  const [openCommentBar, setOpenCommentBar] = useState()
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const { setSelectedOption, setAction } = useContext(LateralBarContext)
  const { setAlert } = useContext(AlertContext)

  const closeAction = () => {
    setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
    setAction(undefined)
  }

  const onRejectChanges = async () => {
    setIsLoadingSubmit(true)
    try {
      const { event, nextStage, nextState, proposalStatus } =
        TRANSITION.REJECT_REQUEST
      const instanceId = `${customer?.customerInstance?.instanceId}.${line.lineId}`
      const body = {
        'id:status': customer?.id,
        'wfState:status': nextState,
        'wfStage:status': nextStage,
        'status:proposalUpdate': proposalStatus,
        'lineConditionsChangeId:proposalUpdate': lineConditionsChange.id,
        'status:lineStatus': LINE_STATES.ACTIVE,
        'id:lineStatus': line.lineId,
      }
      await instanceTransition(
        customer?.lineInstance?.workflowId,
        instanceId,
        event,
        body
      )
      closeAction()
    } catch (err) {
      console.error('Error -> ', err?.message ?? err)
      setAlert({
        title: 'Error',
        label: 'Ocurrió un error rechazando la acción.',
        type: 'warning',
      })
    }
    setIsLoadingSubmit(false)
  }

  const onApproveChanges = async () => {
    setIsLoadingSubmit(true)
    try {
      const { event, nextStage, nextState, proposalStatus } =
        lineConditionsChange.score < lineConditions.score
          ? TRANSITION.APPROVE_DECREASE
          : TRANSITION.APPROVE_REQUEST

      const body = {
        'id:status': customer?.id,
        'wfState:status': nextState,
        'wfStage:status': nextStage,
        'status:proposalUpdate': proposalStatus,
        'lineConditionsChangeId:proposalUpdate': lineConditionsChange.id,
        'status:lineStatus': LINE_STATES.ACTIVE,
        'id:lineStatus': line.lineId,
      }
      await instanceTransition(
        customer?.lineInstance?.workflowId,
        `${customer?.customerInstance?.instanceId}.${line.lineId}`,
        event,
        body
      )
      closeAction()
    } catch (error) {
      let message =
        'Ocurrió un error al ejecutar la acción. Por favor notifique el error.'
      if (axios.isAxiosError(error)) {
        const workflowErrors = {
          'The new score is less than the line used score':
            'El nuevo monto de línea es menor al monto de la línea utilizado',
        }
        const { body } = error.response.data
        if (workflowErrors[body?.message]) {
          message = workflowErrors[body?.message]
        }
      }
      setAlert({
        title: 'Error',
        label: message,
        type: 'error',
      })
    }
    setIsLoadingSubmit(false)
  }

  useEffect(() => {
    const fetchLineConditions = async () => {
      if (customer?.lineId) {
        const lineConditions = await getLineConditions(customer.lineId)
        setLineConditions(lineConditions)
      }
    }
    fetchLineConditions()
  }, [customer])

  useEffect(() => {
    const filesUploaded = [...analysisDocuments, ...riskDocuments]
    const savedFiles = filesUploaded.reduce((acc, itm) => {
      const docs = acc[itm.typeId] || []
      const extension = itm.fileExtension ? `.${itm.fileExtension}` : ''
      docs.push({
        name: `${itm.fileName}${extension}`,
        size: itm.fileSize,
        status: itm.fileStatus,
        id: itm.fileId,
        fileExtension: itm.fileExtension,
      })
      return { ...acc, [itm.typeId]: docs }
    }, {})
    setFiles(savedFiles)
  }, [analysisDocuments, riskDocuments])

  return (
    <Styles>
      <section className='line-compare'>
        <div>
          <h5>Condiciones Actuales</h5>
          <LineConditionsView
            isLoading={isLoadingSubmit}
            lineData={lineConditions}
          />
        </div>
        <div>
          <h5>Propuesta</h5>
          <LineConditionsView
            isLoading={isLoadingSubmit}
            lineData={lineConditionsChange}
          />
        </div>
      </section>
      <section className='risk-documents'>
        <BuildSection
          allowComment
          allowUpload={false}
          files={files}
          schema={RISK_DOCUMENTS}
          setCommentBarInfo={setOpenCommentBar}
        />
      </section>
      <FinanceInformation financeInformation={financeInformation} />
      <section className='analysis-documents'>
        <BuildSection
          allowComment
          title='Documentos análisis financiero'
          allowUpload={false}
          files={files}
          schema={FINANCIAL_ANALYSIS.generalInformation.documents}
          setCommentBarInfo={setOpenCommentBar}
        />
      </section>
      <div className='actions'>
        <Button
          buttonType='grayButton'
          disabled={isLoadingSubmit}
          onClick={onRejectChanges}
        >
          {isLoadingSubmit && <LoadingAnimation className='loading' />}
          {!isLoadingSubmit && 'Rechazar'}
        </Button>
        <Button disabled={isLoadingSubmit} onClick={onApproveChanges}>
          {isLoadingSubmit && <LoadingAnimation className='loading' />}
          {!isLoadingSubmit && 'Aprobar'}
        </Button>
      </div>
      {openCommentBar && (
        <LateralBar
          fileId={openCommentBar.fileId}
          documentName={openCommentBar.fileName}
          setlateralBar={setOpenCommentBar}
        />
      )}
    </Styles>
  )
}

const Styles = styled.div`
  padding: 3rem;
  .line-compare {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    h5 {
      margin-bottom: 1rem;
    }
  }
  .finance-information {
    margin-top: 2rem;
  }
  .analysis-documents {
    margin-top: 2rem;
  }
  .actions {
    margin-top: 25px;
    button + button {
      margin-left: 28px;
    }
  }
  .loading span {
    background-color: white;
  }
`

export default ApproveLineChanges
