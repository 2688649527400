export const canBeFormatted = (number) =>
  /^(100(\.\d{0,4})?|\d{1,2}(\.\d{0,4})?)(%)?$/.test(number)

export const handleBlurPercentage = (event) => {
  const value = event.target.value
  if (!canBeFormatted(value)) return event

  let number = extractNumberPercentage(value)
  number = Math.max(0, Math.min(number, 100))

  event.target.value = formatPercentage(number)

  return event
}

export const handleFocusPercentage = (event) => {
  const value = event.target.value
  if (!canBeFormatted(value)) return
  const number = extractNumberPercentage(value)
  event.target.value = number
  return event
}

/**
 * Function to extract a number from a string with format.
 * ex: 10.00% -> 10
 * @param {String} value
 * @returns {Number}
 */
export const extractNumberPercentage = (value) => {
  if (!canBeFormatted(value)) return NaN
  return Number(`${value}`.replace(/[^0-9.]/g, ''))
}

/**
 * Function to format a number to percentage.
 * @param {Number} number
 * @returns {String}
 */
export const formatPercentage = (number) => {
  if (!canBeFormatted(number)) return NaN

  return Intl.NumberFormat('es-MX', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 4,
  }).format(number / 100)
}
