import { Button, Icon, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import GeneralComment from 'containers/general-comment'
import LateralBar from 'containers/lateral-bar'
import ModalUpload from 'containers/Modal/modal-documents-upload'
import { CustomerContext } from 'providers/customer'
import { LateralBarContext, LATERAL_MENU_OPTIONS } from 'providers/lateral-bar'
import { useContext, useEffect, useState } from 'react'
import { downloadDocument, getDocumentList } from 'services/documents'
import { instanceTransition } from 'services/workflow'
import { downloadFile } from 'utils/functions/download-file'
import { getUserRoles } from 'utils/functions/role-manager'
import { useAuth } from 'utils/hooks/auth'
import { CUSTOMER_STATES } from 'utils/schemas/customer'
import {
  FILE_STAGES,
  FILE_STATES,
  VISIT_REGISTER,
} from 'utils/schemas/documents'
import { BuildSection } from '../document-actions/build-section'
import styled from 'styled-components'
import { AlertContext } from 'providers/alert'
import { FormValidationError } from 'utils/schemas/errors'

const IMAGE_FILE_TYPE_ID = 27
const DOCUMENT_FILE_TYPE_ID = 28

const VisitInfo = () => {
  const [files, setFiles] = useState({})
  const { customer } = useContext(CustomerContext)
  const { setAlert } = useContext(AlertContext)
  const { user } = useAuth()
  const [openCommentBar, setOpenCommentBar] = useState(false)
  const [imageDownload, setImageDownload] = useState([])
  const [fileIdSaved, setFileIdSaved] = useState([])
  const { setAction, setSelectedOption } = useContext(LateralBarContext)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [comment, setComment] = useState('')
  const [isPublic, setIsPublic] = useState(false)
  const [isValidating, setIsValidating] = useState(false)
  const [openModalUpload, setOpenModalUpload] = useState(false)

  const transition =
    CUSTOMER_STATES[customer?.customerInstance?.currentState].transition

  const OPTIONS = {
    accepted: {
      event: transition.SEND.event,
      state: transition.SEND.nextState,
      fileStatus: FILE_STATES.ACCEPTED,
      notificationReceiverRoles: transition?.SEND?.notificationReceiverRoles,
      notificationReceiverRoles2: [],
    },
    rollback: {
      event: transition.RETURN.event,
      state: transition.RETURN.nextState,
      fileStatus: FILE_STATES.REFUSED,
      notificationReceiverRoles: transition?.RETURN?.notificationReceiverRoles,
      notificationReceiverRoles2: [],
    },
    refused: {
      event: transition.REJECT.event,
      state: transition.REJECT.nextState,
      fileStatus: FILE_STATES.REFUSED,
      notificationReceiverRoles: transition?.REJECT?.notificationReceiverRoles,
      notificationReceiverRoles2:
        transition?.REJECT?.notificationReceiverRoles2,
    },
  }

  const handleDownload = (id, name) => {
    try {
      if (id && name) downloadFile(downloadDocument(id), name)
    } catch (err) {
      console.error('error', err, err?.message)
    }
  }

  const validateForm = () => {
    if (!comment) {
      setIsValidating(true)
      throw new FormValidationError('error comment')
    }
  }

  const handleSubmit = async (action) => {
    try {
      validateForm()
      setIsLoadingSubmit(true)
      const {
        fileStatus,
        event,
        state,
        notificationReceiverRoles,
        notificationReceiverRoles2,
      } = OPTIONS[action]
      const [role] = getUserRoles()
      let fileList = fileIdSaved.map((file) => ({
        id: file,
        status: fileStatus,
      }))
      fileList = fileList.concat(
        imageDownload.map(({ id }) => ({
          id,
          status: fileStatus,
        }))
      )

      const notificationReceiver = customer?.assignations?.find((itm) =>
        (notificationReceiverRoles ?? []).includes(itm?.role)
      )
      const notificationReceiver2 = customer?.assignations?.find((itm) =>
        (notificationReceiverRoles2 ?? []).includes(itm?.role)
      )

      let body = {
        'fileList:multiUpdate': fileList,
        'wfState:status': state,
        'id:status': customer.id,
        customerId: customer.id,
        'wfState:comment': customer?.customerInstance?.currentState,
        'uuid:comment': user?.uid,
        'role:comment': role,
        'comment:comment': comment,
        'isPublic:comment': isPublic,
        senderUidFed: user.uid,
        'receiverUidIam:notificationOne': notificationReceiver?.iamUid,
        'customerId:notificationOne': customer.id,
        'receiverUidIam:notificationOne2': notificationReceiver2?.iamUid,
        'customerId:notificationOne2': customer.id,
      }
      if (state === 'line_sendingToCommittee') {
        body = {
          ...body,
          principalId: `${customer?.customerInstance.instanceId}.${customer?.lineInstance?.instanceId}`,
        }
      }
      await instanceTransition(
        customer?.customerInstance?.workflowId,
        customer?.customerInstance?.instanceId,
        event,
        body
      )
      setAction(false)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
    } catch (error) {
      const alert = {
        label:
          'Por favor intente nuevamente y si el problema persiste comuníquese con el área de soporte.',
        title: 'Ha ocurrido un error:',
        type: 'error',
      }

      if (error instanceof FormValidationError) {
        alert.label = 'Es necesario ingresar un comentario para continuar.'
      } else {
        console.error(error.message)
      }

      setOpenModalUpload(false)
      setAlert(alert)
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  useEffect(() => {
    const fetchFiles = async () => {
      const { data: savedFilesData } = await getDocumentList(customer.id, {
        stage: FILE_STAGES.VISIT,
      })

      let savedFiles = {}
      savedFilesData.forEach((itm) => {
        const extension = itm.fileExtension ? `.${itm.fileExtension}` : ''
        if (!savedFiles[itm.typeId]) savedFiles[itm.typeId] = []
        savedFiles[itm.typeId].push({
          name: `${itm.fileName}${extension}`,
          size: itm.fileSize,
          status: itm.fileStatus,
          id: itm.fileId,
          typeId: itm.typeId,
        })
      })
      setFiles(savedFiles)
      setFileIdSaved(
        savedFiles[DOCUMENT_FILE_TYPE_ID]?.reduce(
          (prev, acc) => [...prev, acc.id],
          []
        )
      )

      const imageArray = savedFilesData.reduce(
        (
          acc,
          { fileExtension, typeId, fileName, fileStatus, fileSize, fileId }
        ) => {
          if (typeId === IMAGE_FILE_TYPE_ID) {
            const extension = fileExtension ? `.${fileExtension}` : ''
            acc.push({
              name: `${fileName}-${fileId}${extension}`,
              size: fileSize,
              status: fileStatus,
              id: fileId,
            })
          }
          return acc
        },
        []
      )
      setImageDownload(imageArray)
    }
    if (customer.id) fetchFiles()
  }, [customer.id])
  return (
    <VisitStyled>
      <section>
        <h3>Formulario de visita</h3>
        <div className='form-container'>
          <BuildSection
            allowUpload={false}
            allowReview={false}
            allowDelete={false}
            allowUpdate={false}
            schema={VISIT_REGISTER}
            files={files}
            setFiles={setFiles}
            isValidating={isValidating}
            setCommentBarInfo={setOpenCommentBar}
          />
        </div>
      </section>
      <section>
        <h3>Fotografías</h3>
        <div className='image-flex'>
          {imageDownload.map((itm, idx) => {
            if (Boolean(itm.id !== 0)) {
              return (
                <div className='image-download' key={`img-${idx}`}>
                  <Icon
                    name='file_download'
                    type='outlined'
                    onClick={() => {
                      handleDownload(itm?.id, itm?.name)
                    }}
                  />
                  <p>{itm?.name}</p>
                </div>
              )
            } else {
              return ''
            }
          })}
        </div>
      </section>
      <section>
        <GeneralComment
          comment={comment}
          setComment={setComment}
          setIsPublic={setIsPublic}
          error={!comment && isValidating}
        />
        <div className='action-group'>
          <Button
            buttonType='outlineDark'
            onClick={() => handleSubmit('rollback')}
            disabled={isLoadingSubmit}
          >
            {isLoadingSubmit ? <LoadingAnimation /> : 'Enviar correcciones'}
          </Button>
          <Button
            buttonType='outline'
            onClick={() => setOpenModalUpload(true)}
            disabled={isLoadingSubmit}
          >
            Rechazado
          </Button>
          <Button
            buttonType='blueDark'
            className='send'
            disabled={isLoadingSubmit}
            onClick={() => handleSubmit('accepted')}
          >
            Aprobado
          </Button>
        </div>
      </section>

      {openCommentBar && (
        <LateralBar
          setlateralBar={setOpenCommentBar}
          fileId={openCommentBar?.fileId}
        />
      )}
      {openModalUpload && (
        <ModalUpload
          setOpenModalUpload={setOpenModalUpload}
          messages={{
            msg: '¿Está seguro que desea rechazar la propuesta?',
            msgButton: 'Confirmar',
          }}
          handleSave={() => handleSubmit('refused')}
          isLoadingSubmit={isLoadingSubmit}
        />
      )}
    </VisitStyled>
  )
}

const VisitStyled = styled.section`
  padding: 2rem 0rem;

  section {
    margin: 0rem 3rem;
    div.form-container {
      margin: 2rem 0rem;
      div {
        margin: 0;
      }
    }
  }

  .loading span {
    background-color: white;
  }

  .form-container {
    margin: 2rem 1rem;

    & > * {
      margin: 1rem;
    }
  }

  .image-flex {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    margin: 1rem auto;
    .image-download {
      width: 150px;
      height: auto;
      border: 1px solid;
      border-radius: 10px;
      padding: 5px;
      margin-top: 1rem;
      span {
        position: relative;
        top: 0px;
        left: 80%;
        cursor: pointer;
      }
      p {
        text-align: center;
        font-size: 12px;
        font-weight: 600;
        margin-top: 1px;
      }
    }
  }

  .image-container {
    display: flex;
    justify-content: center;
    margin: 2rem 1rem;

    & > * {
      margin: 1rem;
    }
  }

  .image-viewer {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 40%;

    span {
      cursor: pointer;
    }
  }

  .image-tile {
    width: 100px;
    height: 100px;
    background-color: grey;
    border-radius: 10px;
    margin: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;
  }

  .action-group {
    margin: 2rem 0 0;

    button + button {
      margin-left: 28px;
    }
    .send {
      float: right;
    }
  }
`

export default VisitInfo
