import { Button, Select } from '@keoworld/gbl-ui-kit'
import Form from 'containers/form'
import { AlertContext } from 'providers/alert'
import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { validateErrorsObject } from 'utils/functions/form-validate'
import { FormValidationError } from 'utils/schemas/errors'
import {
  INITIAL_VALUES_FREEZE,
  INITIAL_VALUES_UNFREEZE,
  PORTFOLIO_ACTIONS,
  PORTFOLIO_FREEZE_FORM,
  PORTFOLIO_UNFREEZE_FORM,
} from 'utils/schemas/portfolio'
import Tooltip from 'utils/tooltip'
import LoanInformation from './loan-information'
import { dateFormatFromIso } from 'utils/functions/date'

const Portfolio = (props) => {
  const { loan, setViewAction, onClose } = props
  const [action, setAction] = useState('')
  const [isValidating, setIsValidating] = useState(false)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [formValues, setFormValues] = useState({})
  const [formError, setFormError] = useState({})
  const { setAlert } = useContext(AlertContext)

  const openPopUp = (action) => {
    setViewAction(PORTFOLIO_ACTIONS.showPopUp, { action, formValues })
  }

  const validateForm = () => {
    const currentDate = dateFormatFromIso(new Date(), 'yyyy-MM-dd')

    const freezeActions = [
      PORTFOLIO_ACTIONS.freezeLoan,
      PORTFOLIO_ACTIONS.unfreezeLoan,
    ]

    if (freezeActions.includes(action) && validateErrorsObject(formError)) {
      throw new FormValidationError('missing fields in form')
    }

    if (
      action === PORTFOLIO_ACTIONS.freezeLoan &&
      formValues.unfreezingDate <= formValues.freezingDate
    ) {
      throw new FormValidationError(
        'unfreeze date less than freeze date',
        'unfreezingLowerThanFreezingDate'
      )
    }

    if (
      action === PORTFOLIO_ACTIONS.freezeLoan &&
      formValues.freezingDate < currentDate
    ) {
      throw new FormValidationError(
        'freezing date is lower than today',
        'freezingDate'
      )
    }

    if (
      freezeActions.includes(action) &&
      formValues.unfreezingDate < currentDate
    ) {
      throw new FormValidationError(
        'unfreezing date is lower than today',
        'unfreezingDate'
      )
    }

    if (
      action === PORTFOLIO_ACTIONS.cancelPayments &&
      loan.relationStatus !== 'Active'
    ) {
      throw new FormValidationError(
        'relation is not active',
        'relationNotActive'
      )
    }
  }

  const onContinue = () => {
    try {
      setIsValidating(true)
      setIsLoadingSubmit(true)

      validateForm()

      const actionsWithPopUp = [
        PORTFOLIO_ACTIONS.restructureLoan,
        PORTFOLIO_ACTIONS.writeOff,
        PORTFOLIO_ACTIONS.freezeLoan,
        PORTFOLIO_ACTIONS.unfreezeLoan,
      ]

      if (actionsWithPopUp.includes(action)) {
        openPopUp(action)
      } else {
        setViewAction(action)
      }
    } catch (error) {
      const alert = {
        label:
          'Por favor intente nuevamente y si el problema persiste comuníquese con el área de soporte.',
        title: 'Ha ocurrido un error:',
        type: 'error',
      }

      if (error instanceof FormValidationError) {
        const { fields } = error
        if (fields === 'unfreezingLowerThanFreezingDate') {
          alert.label =
            'La fecha para descongelar debe ser mayor que la fecha para congelar'
        }
        if (fields === 'freezingDate') {
          alert.label =
            'El día para congelar debe ser igual o mayor al dia actual'
        }
        if (fields === 'unfreezingDate') {
          alert.label =
            'El día para descongelar debe ser igual o mayor al dia actual'
        }
        if (fields === 'relationNotActive') {
          alert.type = 'warning'
          alert.label =
            'No es posible anular pagos mientras la relación no se encuentre activa'
        }
      } else {
        console.error(error.message)
      }

      setAlert(alert)
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  useEffect(() => {
    let errors
    if (action === 'freezeProvisions') {
      setFormValues(INITIAL_VALUES_FREEZE)
      errors = validateForm(PORTFOLIO_FREEZE_FORM, formValues)
      setFormError(errors)
    } else if (action === 'unfreezeProvisions') {
      setFormValues(INITIAL_VALUES_UNFREEZE)
      errors = validateForm(PORTFOLIO_UNFREEZE_FORM, formValues)
      setFormError(errors)
    }
    // Currently this effect needs to run only once when the action changes and not when the form values change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action])

  return (
    <Styles>
      <LoanInformation loan={loan} />
      <section className='portfolio-management'>
        <h3>Gestión de cartera</h3>
        <div className='underline'>
          Conoce los diferentes tipos de gestión de cartera
          <Tooltip />
        </div>
        <Select
          className='select-action'
          label='Acción a realizar'
          value={action}
          onChange={(e) => setAction(e.target.value)}
        >
          <option value='' disabled>
            Selecciona una opción
          </option>
          {loan.status !== 'WRO' && (
            <>
              <option value={PORTFOLIO_ACTIONS.cancelPayments}>
                Anulación pagos
              </option>
              <option value={PORTFOLIO_ACTIONS.restructureLoan}>
                Reestructuración
              </option>
              <option
                hidden={loan.status === 'WRO'}
                value={PORTFOLIO_ACTIONS.writeOff}
              >
                Write off
              </option>
            </>
          )}
          {loan.status !== 'FRZ' && loan.status !== 'WRO' && (
            <option value={PORTFOLIO_ACTIONS.freezeLoan}>
              Congelamiento de disposiciones
            </option>
          )}
          {loan.status === 'FRZ' && (
            <option value={PORTFOLIO_ACTIONS.unfreezeLoan}>
              Descongelar disposiciones
            </option>
          )}
        </Select>
        {[
          PORTFOLIO_ACTIONS.freezeLoan,
          PORTFOLIO_ACTIONS.unfreezeLoan,
        ].includes(action) && (
          <div className='form-provisions'>
            <Form
              schema={
                action === PORTFOLIO_ACTIONS.freezeLoan
                  ? PORTFOLIO_FREEZE_FORM
                  : PORTFOLIO_UNFREEZE_FORM
              }
              formValues={formValues}
              setFormValues={setFormValues}
              formErrors={formError}
              setFormErrors={setFormError}
              isValidating={isValidating}
            />
          </div>
        )}
      </section>
      <section className='actions'>
        <div className='buttons'>
          <Button
            className='btn-cancel'
            buttonType='outlineBlueDark'
            onClick={() => onClose()}
            disabled={isLoadingSubmit}
          >
            Cancelar
          </Button>
          <Button
            className='btn-accept'
            buttonType='blueDark'
            onClick={onContinue}
            disabled={isLoadingSubmit}
          >
            Continuar
          </Button>
        </div>
      </section>
    </Styles>
  )
}

const Styles = styled.div`
  padding: 0 2rem;
  height: calc(100% - 70px);
  overflow-y: auto;
  position: relative;

  .underline {
    text-decoration: underline;
    color: #000000;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 3px;
    margin: 0;
    font-size: 13px;
    cursor: pointer;
    .tooltipText {
      visibility: hidden;
      width: 400px;
      background: #ffffff;
      color: black;
      border-radius: 4pt;
      padding: 20pt;
      position: absolute;
      margin-top: 16px;
      left: 30px;
      z-index: 1;
      box-shadow: 0px 4px 12px rgba(148, 165, 174, 0.2);

      p {
        display: block;
        line-height: 28px;
        span {
          font-weight: 600;
        }
      }
    }
    &:hover .tooltipText {
      visibility: visible;
    }
  }

  .portfolio-management {
    margin-top: 3rem;

    .select-action {
      margin-top: 1rem;
    }
  }

  .actions {
    position: absolute;
    bottom: 0;
    width: calc(100% - 4rem);

    .buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 18px;
    }
  }
`

export default Portfolio
