import styled from 'styled-components'
import { LateralBarContext, LATERAL_MENU_OPTIONS } from 'providers/lateral-bar'
import { useContext } from 'react'

const Header = ({ customer, currentTab, setCurrentTab, headerOptions }) => {
  const customerName = customer?.businessName ?? ''
  const customerId = customer?.id ?? ''
  const { setSelectedOption } = useContext(LateralBarContext)

  const onClickAction = () => {
    setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_CUSTOMERS)
  }

  return (
    <HeaderStyled>
      <div className='information'>
        <div className='path'>
          <div>
            <button onClick={onClickAction}>{'<'}</button>
          </div>
          Inicio {'>'} Clientes Detalle {'>'} {customerName}
        </div>
        <div className='customer'>
          <h3>{customerName}</h3>
          <span>ID: {customerId}</span>
        </div>
      </div>

      <div className='options'>
        {headerOptions.map((option, index) => {
          const onClick = () => setCurrentTab(option)

          return (
            <button
              key={`option-${index}`}
              className={currentTab?.label === option.label ? 'selected' : ''}
              onClick={onClick}
            >
              {option.label}
            </button>
          )
        })}
      </div>
    </HeaderStyled>
  )
}

const HeaderStyled = styled.section`
  box-shadow: 0px 4px 10px rgba(21, 50, 101, 0.11);
  ${({ theme }) => theme.colors.grayShades[100]};

  .information {
    padding: 30px 70px;
    .path {
      display: flex;
      height: 50px;
      div {
        margin-top: -3pt;
        margin-right: 7pt;
        button {
          border-radius: 20px;
          border: none;
          cursor: pointer;
          background-color: #d9d9d9;
          width: 30px;
          height: 30px;
          font-size: 20px;
          color: gray;
        }
      }
    }
  }

  .options {
    padding: 0 70px;
    padding-bottom: 10px;

    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    button {
      display: inline-flex;
      align-items: flex-end;
      position: relative;

      height: 20px;

      padding: 0;
      border: none;
      background-color: transparent;
      cursor: pointer;

      &.selected {
        color: #00172d;
        font-weight: 600;
      }

      &.selected::after {
        content: '';
        height: 3px;
        width: 100%;
        position: absolute;
        bottom: -7px;
        left: 0;
        border-radius: 6px;
        background-color: #00172d;
      }
    }
  }
`

export default Header
