import { Button, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import { CustomerContext } from 'providers/customer'
import { LATERAL_MENU_OPTIONS, LateralBarContext } from 'providers/lateral-bar'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ApiBuyerRepository } from 'services/buyer-suppcards/buyer.repository'
import {
  ApiDebtCapacityRepository,
  PROPOSAL_STATUS,
} from 'services/debt-capacity-suppcards/debtCapacity.repository'
import styled from 'styled-components'
import { dateFormatFromIso } from 'utils/functions/date'
import { useAuth } from 'utils/hooks/auth'
import Router from 'utils/router'
import { ONBOARDING_STATES } from 'utils/schemas/workflows/onboarding'

const CommitteeResult = () => {
  const [evaluationResults, setEvaluationResults] = useState([])
  const [loading, setLoading] = useState(false)
  const { customer } = useContext(CustomerContext)
  const { setAction, setSelectedOption } = useContext(LateralBarContext)
  const { user } = useAuth()
  const navigate = useNavigate()

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const {
        events: { generateContract },
        rolesToNotify,
      } = ONBOARDING_STATES.COMMITTEE_RESULT

      const notificationReceiver = customer?.assignations?.find((itm) =>
        (rolesToNotify.notificationRoles1 ?? [])?.includes(itm?.role)
      )
      const notificationReceiver2 = customer?.assignations?.find((itm) =>
        (rolesToNotify.notificationRoles2 ?? [])?.includes(itm?.role)
      )

      await ApiBuyerRepository.doEvent(customer?.id, generateContract, {
        id: customer.id,
        senderUidFed: user.uid,
        'customerId:contract': customer.id,
        'receiverUidIam:notificationOne': notificationReceiver?.iamUid,
        'customerId:notificationOne': customer.id,
        'receiverUidIam:notificationOne2': notificationReceiver2?.iamUid,
        'customerId:notificationOne2': customer.id,
      })

      navigate(Router.Root)
      setAction(false)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  useEffect(() => {
    const fetchEvaluations = async () => {
      try {
        if (!customer?.id) return

        setLoading(true)

        let status

        if (customer.wfState === ONBOARDING_STATES.COMMITTEE_REJECTED.value) {
          status = PROPOSAL_STATUS.REJECTED
        } else {
          status = PROPOSAL_STATUS.APPROVED
        }

        const evaluations =
          await ApiDebtCapacityRepository.getEvaluationsByBuyerId(
            customer.id,
            status
          )
        setEvaluationResults(evaluations)
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }

    fetchEvaluations()
  }, [customer])

  return (
    <CommitteeResultStyled>
      <h3>Resultado de Comité</h3>
      {loading && (
        <div className='center-loading'>
          <LoadingAnimation />
        </div>
      )}
      {!loading && (
        <table>
          <thead>
            <tr>
              <th>Revisor</th>
              <th>Fecha</th>
              <th>Resultado</th>
              <th>Comentario</th>
            </tr>
          </thead>
          <tbody>
            {evaluationResults.length === 0 && (
              <tr>
                <td colSpan={4}>No hay resultados</td>
              </tr>
            )}
            {evaluationResults?.map((result, idx) => (
              <tr key={`evalres-${idx}-${result.id}`}>
                <td>{result.nameUser}</td>
                <td>
                  {result.createdAt &&
                    dateFormatFromIso(result.createdAt, 'dd/MM/yyyy')}
                </td>
                <td>{result.result}</td>
                <td>{result.comment}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <div className='actions'>
        <Button
          buttonType='grayButton'
          onClick={() => {
            navigate(-1)
            setAction(false)
          }}
        >
          Cancelar
        </Button>
        {customer?.wfState === ONBOARDING_STATES.COMMITTEE_RESULT.value && (
          <Button onClick={() => handleSubmit()}>Continuar</Button>
        )}
      </div>
    </CommitteeResultStyled>
  )
}

const CommitteeResultStyled = styled.div`
  padding: 3rem 2rem;

  table {
    margin-top: 30px;
    width: 80%;
    text-align: left;
    tbody {
      tr > td {
        padding-top: 15px;
      }
    }
  }

  .actions {
    margin-top: 30px;
    button + button {
      margin-left: 28px;
    }
  }
`

export default CommitteeResult
