import { Button, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import LateralBar from 'containers/lateral-bar'
import ModalUpload from 'containers/Modal/modal-documents-upload'
import { CustomerContext } from 'providers/customer'
import { LateralBarContext, LATERAL_MENU_OPTIONS } from 'providers/lateral-bar'
import { useContext, useEffect, useState } from 'react'
import { getDocumentList } from 'services/documents'
import { instanceTransition } from 'services/workflow'
import styled from 'styled-components'
import { useAuth } from 'utils/hooks/auth'
import { CUSTOMER_STATES, SHAREHOLDER_TYPES } from 'utils/schemas/customer'
import {
  ASSESSMENT,
  FILE_STAGES,
  FILE_STATES,
  PRE_ASSESSMENT,
} from 'utils/schemas/documents'
import { BuildSection } from './build-section'

const ReviewDocuments = () => {
  const [documents, setDocuments] = useState({})
  const [openModal, setOpenModal] = useState(false)
  const [lateralBar, setLateralBar] = useState(false)
  const [message, setMessage] = useState('')
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const { customer } = useContext(CustomerContext)
  const { setAction, setSelectedOption } = useContext(LateralBarContext)
  const { user } = useAuth()

  const documentInputs =
    customer.wfStage === 'assessment' || customer.status === 'Reassessment'
      ? ASSESSMENT
      : PRE_ASSESSMENT

  const process =
    customer?.type === SHAREHOLDER_TYPES.PERSON
      ? 'naturalPerson'
      : 'moralPerson'

  const validateDocs = () => {
    const generalInformation = documentInputs.generalInformation[process]
    const financialInformation = documentInputs.financialInformation[process]
    const mainAuctioneer = documentInputs.mainAuctioneer[process]

    const documentsToReview = [
      ...generalInformation,
      ...financialInformation,
      ...mainAuctioneer,
    ]

    let arrayDocuments = []

    documentsToReview.forEach((documentsReview) => {
      const documentsUploaded = documents[documentsReview.typeId] ?? []
      arrayDocuments = [...arrayDocuments, ...documentsUploaded]
    })

    arrayDocuments.forEach((document) => {
      document.status =
        document.status !== FILE_STATES.ACCEPTED
          ? FILE_STATES.REFUSED
          : FILE_STATES.ACCEPTED
    })

    const isAccepted = arrayDocuments.every(
      (itm) => itm.status === FILE_STATES.ACCEPTED
    )

    return { arrayDocuments, isAccepted }
  }

  const updateDocuments = async () => {
    setOpenModal(true)
    const { isAccepted } = validateDocs()
    let message = ''
    if (isAccepted) {
      message = `Todos los documentos fueron aceptados, el prospecto será enviado a ${CUSTOMER_STATES.cust_dossierValidated.label}`
    } else {
      message = `Algunos documentos NO fueron aceptados, el prospecto será enviado a ${CUSTOMER_STATES.cust_correctionRequest.label}`
    }
    setMessage(message)
  }

  const handleTransition = async () => {
    setIsLoadingSubmit(true)
    try {
      const { arrayDocuments, isAccepted } = validateDocs()
      const transition = CUSTOMER_STATES.cust_inOperationReview.transition
      let eventState = transition.SEND.event
      let nextState = transition.SEND.nextState

      if (!isAccepted) {
        eventState = transition.RETURN.event
        nextState = transition.RETURN.nextState
      }
      const notificationReceiver = customer?.assignations?.find((itm) =>
        transition?.SEND?.notificationReceiverRoles.includes(itm?.role)
      )

      const fileList = arrayDocuments.map((document) => ({
        id: document.id,
        status: document.status,
      }))

      await instanceTransition(
        customer.customerInstance.workflowId,
        customer.customerInstance.instanceId,
        eventState,
        {
          'fileList:multiUpdate': fileList,
          'id:status': customer.id,
          'wfState:status': nextState,
          senderUidFed: user.uid,
          'receiverUidIam:notificationOne': notificationReceiver?.iamUid,
          'customerId:notificationOne': customer.id,
        }
      )
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      setAction('')
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  const onCancel = () => {
    setAction('')
  }

  /* Fetch saved files Effect */
  useEffect(() => {
    const fetchFiles = async () => {
      const { data: savedFilesData } = await getDocumentList(customer.id, {
        status: `${FILE_STATES.PENDING},${FILE_STATES.ACCEPTED},${FILE_STATES.REFUSED},${FILE_STATES.DRAFT}`,
        stage: FILE_STAGES.ASSESSMENT,
      })
      let savedFiles = {}
      savedFilesData.forEach((itm) => {
        const extension = itm.fileExtension ? `.${itm.fileExtension}` : ''
        if (!savedFiles[itm.typeId]) savedFiles[itm.typeId] = []
        savedFiles[itm.typeId].push({
          name: `${itm.fileName}${extension}`,
          size: itm.fileSize,
          status: itm.fileStatus,
          id: itm.fileId,
        })
      })
      setDocuments(savedFiles)
    }
    if (customer.id) fetchFiles()
  }, [customer.id])

  if (!documents) {
    return <LoadingAnimation />
  }

  return (
    <ReviewDocumentsStyled>
      <h2 className='page-title'>Revision de documentos</h2>
      <BuildSection
        allowUpload={false}
        allowReview={true}
        isValidating={isLoadingSubmit}
        schema={documentInputs.generalInformation[process]}
        files={documents}
        setFiles={setDocuments}
        allowDelete={false}
        allowUpdate={false}
        title={documentInputs.generalInformation.title}
        setCommentBarInfo={setLateralBar}
      />
      {(customer.wfStage === 'assessment' ||
        customer.status === 'Reassessment') && (
        <>
          <BuildSection
            allowUpload={false}
            isValidating={isLoadingSubmit}
            allowReview={true}
            schema={documentInputs.mainAuctioneer[process]}
            files={documents}
            setFiles={setDocuments}
            allowDelete={false}
            allowUpdate={false}
            title={documentInputs.mainAuctioneer.title}
            setCommentBarInfo={setLateralBar}
          />
        </>
      )}
      <BuildSection
        allowUpload={false}
        allowReview={true}
        allowDelete={false}
        allowUpdate={false}
        isValidating={isLoadingSubmit}
        schema={documentInputs.financialInformation[process]}
        files={documents}
        setFiles={setDocuments}
        title={documentInputs.financialInformation.title}
        setCommentBarInfo={setLateralBar}
      />
      <div className='btn-actions'>
        <Button buttonType='outline' onClick={onCancel}>
          Cancelar
        </Button>
        <Button
          buttonType='blueDark'
          disabled={isLoadingSubmit}
          onClick={updateDocuments}
        >
          {isLoadingSubmit && <LoadingAnimation className='loading' />}
          {!isLoadingSubmit && 'Enviar'}
        </Button>
      </div>
      {lateralBar && (
        <LateralBar
          setlateralBar={setLateralBar}
          fileId={lateralBar.fileId}
          documentName={lateralBar.fileName}
        />
      )}
      {openModal && (
        <ModalUpload
          setOpenModalUpload={setOpenModal}
          messages={{
            msg: message,
            msgButton: 'Confirmar',
          }}
          handleSave={handleTransition}
          isLoadingSubmit={isLoadingSubmit}
        />
      )}
    </ReviewDocumentsStyled>
  )
}

const ReviewDocumentsStyled = styled.div`
  padding: 2rem 3rem;

  .page-title {
    font-size: 24pt;
    margin-bottom: 10px;
  }
  .loading span {
    background-color: white;
  }

  .row {
    display: flex;
    align-items: center;

    .button-section {
      display: flex;
      margin-left: 5%;
      button {
        width: 50px;
        border-radius: 6px;
        &.checked {
          ${({ theme }) => theme.buttons.outline.hover}
        }
      }
      button + button {
        margin-left: 15px;
      }
    }
  }

  .btn-actions {
    position: relative;
    margin-top: 3rem;

    button:nth-child(2) {
      margin-left: 15px;
    }

    button:last-child {
      position: absolute;
      right: 1rem;
    }
  }
`

export default ReviewDocuments
