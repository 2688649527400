import axios from 'axios'
import { ApiError, ErrorPrototype } from 'utils/schemas/errors'
import { ENDPOINTS } from './config'
import { getUTCDateTime } from 'utils/functions/date'

export const executeFreeze = async (body) => {
  try {
    await axios.post(ENDPOINTS.portfolio.freeze, {
      loanId: body.loanId,
      reason: body.reason,
      freezeAt: body.freezeAt,
      autoUnfreezeAt: body.autoUnfreezeAt,
      iamId: body.iamId,
    })
    return { type: 'success' }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const { data } = error.response
      return {
        type: 'error',
        title: data.title,
        detail: data.detail,
      }
    }
    return { type: 'error' }
  }
}

export const executeUnFreeze = async (body) => {
  try {
    await axios.post(ENDPOINTS.portfolio.unfreeze, {
      loanId: body.loanId,
      reason: body.reason,
      unfreezeAt: body.unfreezeAt,
      iamId: body.iamId,
    })
    return { type: 'success' }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const { data } = error.response
      return {
        type: 'error',
        title: data.title,
        detail: data.detail,
      }
    }
    return { type: 'error' }
  }
}

export const executeWriteOff = async (body) => {
  try {
    await axios.post(ENDPOINTS.portfolio.cancel, {
      loanId: body.loanId,
      reason: body.reason,
      iamId: body.iamId,
    })
    return { type: 'success' }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const { data } = error.response
      throw new ApiError(data.title, data.detail, data.status)
    }
    throw new ErrorPrototype(error.message)
  }
}

/**
 * Function to call the loan restructure endpoint
 * @param {Object} body - The request body
 * @param {string} body.loanId - The loan id
 * @param {string} body.reason - The reason of restructure
 * @param {string} body.iamId - The iam id
 * @param {boolean} body.notify - The notify flag
 */
export const restructureLoan = async (body) => {
  try {
    const { loanId, reason, iamId, notify } = body
    const response = await axios.post(ENDPOINTS.portfolio.restructure, {
      loanId,
      comment: reason,
      uidIam: iamId,
      notify,
    })

    return response
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const { data } = error.response
      throw new ApiError(data.title, data.detail, data.status)
    }
    throw new ErrorPrototype(error.message)
  }
}

/**
 * Function to obtain the concepts of a loan
 * @param {Object} query - The query params
 * @param {string} query.loanId - The loan id
 * @returns {Array} - The concepts array
 */
export const getLoanConcepts = async (query) => {
  try {
    const { loanId } = query
    const { data } = await axios.get(ENDPOINTS.loan.concepts, {
      params: { loanId },
    })
    return data.debts
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const { data } = error.response
      throw new ApiError(data.title, data.detail, data.status)
    }
    throw new ErrorPrototype(error.message)
  }
}

/**
 * @param {Object} query
 * @param {number} query.customerId - customer id
 * @param {number} query.page - page number
 * @param {number} query.numberOfItems - number of items per page
 * @param {number} query.relationId - relation id
 * @param {number} query.lineId - line id
 * @param {number} query.id - loan id
 * @param {string} query.products - product id
 * @param {string} query.relationStatus - relation status
 * @param {string} query.status - loan status
 * @param {string} query.from - start creation date
 * @param {string} query.to - end creation date
 * @param {number} query.amount - loan amount
 * @param {number} query.debt - debt amount
 */
export const getLoans = async (query) => {
  try {
    let dateFrom
    let dateTo

    if (query.from) {
      query.from.setHours(0, 0, 0, 0)
      dateFrom = getUTCDateTime(query.from)
    }

    if (query.to) {
      query.to.setHours(23, 59, 59, 999)
      dateTo = getUTCDateTime(query.to)
    }

    if (query.to && query.from && query.to < query.from) {
      throw new ApiError(
        '¡Ups, algo salió mal!',
        'La fecha de inicio no puede ser mayor a la fecha de fin',
        400
      )
    }

    const params = {
      customerId: query.customerId,
      page: query.page,
      numberOfItems: query.numberOfItems,
      dateFrom,
      dateTo,
      relationId: query.relationId,
      lineId: query.lineId,
      loanId: query.id,
      loanStatus: query.status,
      loanAmount: query.amount,
      debtAmount: query.debt,
      products: query.products,
      relationStatus: query.relationStatus,
    }

    const { data } = await axios.get(ENDPOINTS.loan.find, { params })

    return { loans: data.loans ?? [], count: data.fullCount ?? 0 }
  } catch (error) {
    if (error instanceof ApiError) throw error

    if (axios.isAxiosError(error)) {
      if (error.message === 'Network Error') {
        throw new ApiError(
          '¡Ups, algo salió mal!',
          'Error de conexión con el servidor, por favor revise su conexión a internet. ' +
            'Si el problema persiste, contacte con soporte',
          500
        )
      }
      const { data } = error.response
      throw new ApiError('¡Ups, algo salió mal!', data.detail, data.status)
    }

    throw new ApiError(
      '¡Ups, algo salió mal!',
      'Error al procesar la solicitud al servidor, por favor contacte con el soporte',
      500
    )
  }
}
