import { FILE_STATES } from 'utils/schemas/documents'

/**
 * @param {object} obj
 * @returns {FormData}
 */
export const objectToFormData = (obj) => {
  const formData = new FormData()
  Object.entries(obj).forEach(([key, value]) => {
    formData.append(key, value)
  })
  return formData
}

export const CurrencyFormatMX = (number) => {
  const exp = /(\d)(?=(\d{3})+(?!\d))/g
  const rep = '$1,'
  let arr = number.toString().split('.')
  arr[0] = arr[0].replace(exp, rep)
  return arr[1] ? arr.join('.') : arr[0]
}

/**
 * @param {File} file
 * @param {number} customerId
 * @param {number} typeFileId
 * @param {number} userUid
 * @param {string} role
 * @returns
 */
export const fileFormData = (file, customerId, typeFileId, userUid, role) => {
  const formData = new FormData()
  const fileExtension = file.name.split('.').pop()
  if (file instanceof File) formData.append('file', file)
  if (file.id) formData.append('fileId', file.id)
  if (file.fileRequestId) formData.append('fileRequestId', file.fileRequestId)
  formData.append('name', file.name)
  formData.append('size', file.size)
  formData.append('customerId', customerId)
  formData.append('extension', fileExtension)
  formData.append('typeFileId', typeFileId)
  formData.append('uuid', userUid)
  formData.append('role', role)
  formData.append('status', file.status || FILE_STATES.DRAFT)

  return formData
}
