import { Button } from '@keoworld/gbl-ui-kit'
import { CUSTOMER_DETAIL_SECTIONS, CustomerContext } from 'providers/customer'
import { LATERAL_MENU_OPTIONS, LateralBarContext } from 'providers/lateral-bar'
import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { getUserRoles, validateStateRole } from 'utils/functions/role-manager'
import { useAuth } from 'utils/hooks/auth'
import { dependentRoles } from 'utils/schemas/roles'
import { ONBOARDING_STATES } from 'utils/schemas/workflows/onboarding'
import InfoSummary from './info-summary'

const ROUTER = {
  [CUSTOMER_DETAIL_SECTIONS.INFORMATION]: {
    label: 'Información',
  },
  [CUSTOMER_DETAIL_SECTIONS.DOCUMENTS]: {
    label: 'Documentos',
  },
  [CUSTOMER_DETAIL_SECTIONS.HISTORY]: {
    label: 'Historial',
  },
  [CUSTOMER_DETAIL_SECTIONS.COMERCIAL_ACTIVITY]: {
    label: 'Actividad Comercial',
  },
  [CUSTOMER_DETAIL_SECTIONS.GENERAL_COMMENTS]: {
    label: 'Comentarios Generales',
  },
}

// const CUSTOMER_STATES_EXCEPTION = {
//   customerReady: 'cust_customerReady',
//   hubspotPendingInfo: 'cust_pendingInfo',
//   minorCommittee: 'line_minorCommittee',
//   majorCommittee: 'line_majorCommittee',
// }

const CustomerDetailHeader = ({ history }) => {
  const preCustomer = useContext(CustomerContext)
  const { setAction } = useContext(LateralBarContext)
  const { user } = useAuth()
  const [userRole] = getUserRoles()
  const location = useLocation()
  const navigate = useNavigate()

  const [showActionButton, setShowActionButton] = useState(false)

  const { currentSection, setCurrentSection, customer } = preCustomer

  const { label } = ONBOARDING_STATES[customer?.wfState] ?? {}

  const setCurrentTab = (action) => {
    if (currentSection !== action) {
      setCurrentSection(action)
    }
  }

  const onClickPendingActions = () => {
    setAction(true)
    navigate(`${location.pathname}/action`)
  }

  useEffect(() => {
    try {
      const isThereAllowedAction = async () => {
        if (!customer?.wfState || !user.iam?.id) {
          return false
        }

        // if (
        //   customer.wfState !== CUSTOMER_STATES_EXCEPTION.majorCommittee &&
        //   customer.wfState !== CUSTOMER_STATES_EXCEPTION.minorCommittee
        // ) {
        //   return

        //   const EVALUATION_PENDING_STATUS = 'Pending'
        //   const results = await evaluationFind(customer.lineId)

        //   return Boolean(
        //     results.find(
        //       ({ uidIam, result }) =>
        //         uidIam === user.iam?.id && result === EVALUATION_PENDING_STATUS
        //     )
        //   )
        // }

        return validateStateRole(customer.wfState, false)
      }

      ;(async () => setShowActionButton(await isThereAllowedAction()))()
    } catch (error) {
      console.log(error)
    }
  }, [customer, user])

  return (
    <CustomerDetailHeaderStyled>
      <div className='actions btn-group'>
        {showActionButton && (
          <Button onClick={onClickPendingActions}>
            {label ?? 'Acción Pendiente'}
          </Button>
        )}
      </div>
      <InfoSummary
        currentRoute={[ROUTER[currentSection].label]}
        labelOptions={LATERAL_MENU_OPTIONS.ONBOARDING_BUYER_DETAIL}
      />
      <div className='customers-sections'>
        {Object.entries(ROUTER)
          .filter(
            ([key]) =>
              key !== CUSTOMER_DETAIL_SECTIONS.COMERCIAL_ACTIVITY ||
              dependentRoles['bpm.mx-amex-b2b.com-hd'].includes(userRole)
          )
          .map(([key, item], index) => {
            return (
              <button
                key={`section-${index}`}
                className={`${currentSection === key ? 'selected' : ''}`}
                onClick={() => setCurrentTab(key)}
              >
                {item.label}
              </button>
            )
          })}
      </div>
    </CustomerDetailHeaderStyled>
  )
}

const CustomerDetailHeaderStyled = styled.div`
  position: relative;
  padding: 2rem;
  padding-bottom: 10px;
  box-shadow: 0px 4px 10px rgba(21, 50, 101, 0.11);
  ${({ theme }) => theme.colors.grayShades[100]};

  .clickable {
    cursor: pointer;
  }

  h3 {
    margin-top: 1rem;
  }

  .btn-group {
    position: absolute;
    right: 15px;
    button + button {
      margin-left: 15px;
    }
  }

  .customers-sections {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 20px;
    margin-top: 1rem;

    button {
      display: inline-flex;
      align-items: flex-end;
      position: relative;

      padding: 0;
      border: none;
      background-color: transparent;
      color: #515151;
      cursor: pointer;

      &.selected {
        color: #00172d;
        font-weight: 600;
      }

      &.selected::after {
        content: '';
        height: 3px;
        width: 100%;
        position: absolute;
        bottom: -7px;
        left: 0;
        border-radius: 6px;
        background-color: #00172d;
      }
    }
  }
`

export default CustomerDetailHeader
