import { useContext } from 'react'
import { Icon, Button } from '@keoworld/gbl-ui-kit'
import styled from 'styled-components'
import { LateralBarContext } from 'providers/lateral-bar'

const ContactSupport = () => {
  const { setAction } = useContext(LateralBarContext)

  return (
    <ContentHelp>
      <div className='router'>
        <span>Inicio</span>
        <span bold='true'> {'>'} </span>
        <span>Prospecto</span>
        <span bold='true'> {'>'} </span>
        <span
          className='clickable'
          onClick={() => setAction(false)}
        >
          Detalle prospecto
        </span>
        <span bold='true'> {'>'} </span>
        <span>Ayuda</span>
      </div>
      <h5>¿Necesitas ayuda?</h5>
      <div className='content-card'>
        <div className='content-icon'>
          <div>
            <Icon
              name='library_books'
              type='outlined'
            />
          </div>
        </div>
        <div className='content-text'>
          <h5>Sobre la plataforma</h5>
          <p>
            Si tienes dudas de<strong> cómo usar esta plataforma</strong> ,
            sigue la guía que encontraras en este
            <strong> manual de usuario.</strong>
          </p>
          <Button buttonType='blueDark'>Descarga el manual</Button>
        </div>
      </div>
      <div className='content-card-two'>
        <div className='content-icon'>
          <div>
            <Icon
              name='warning'
              type='outlined'
            />
          </div>
        </div>
        <div className='content-text'>
          <h5>¿Tienes un problema?</h5>
          <p>
            Si tienes algún <strong> problema de funcionamiento </strong> sigue
            estos pasos:
          </p>
          <h5>1: inicia sesión </h5>
          <p>
            Ingresando <strong>aquí</strong>
          </p>
          <h5>2: crear un ticket</h5>
          <p>dándole clic al botón con el símbolo "+"</p>
          <h5>3: llena el formulario</h5>
          <p>Sigue los pasos y dale clic a "Crear"</p>
          <p>
            ¿Sigues con dudas? descarga esta <strong>guía</strong>
          </p>
          <Button buttonType='blueDark'>Descarga guía</Button>
        </div>
      </div>
    </ContentHelp>
  )
}

const ContentHelp = styled.div`
  margin: 50px;
  h5 {
    margin-top: 2em;
    margin-bottom: 1em;
  }
  .content-card {
    width: 850px;
    display: flex;
    min-height: 195px;
    padding: 15px;
    flex-shrink: 0;

    border-radius: 10px;
    border: 2px solid #8080804d;
    background: #fff;
    margin-bottom: 2em;

    .content-icon {
      width: 25%;
      display: flex;
      justify-content: center;
      align-items: center;

      div {
        background-color: #1bc86b3b;
        margin-top: -4em;
        width: 5em;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1em;
        border-radius: 44px;
      }

      .icon {
        font-size: 40px;
        color: #269889;
      }
    }

    .content-text {
      width: 75%;
      p {
        width: 467px;
        margin-bottom: 2em;
      }
    }
  }
  .content-card-two {
    width: 850px;
    display: flex;
    min-height: 195px;
    padding: 15px;
    flex-shrink: 0;

    border-radius: 10px;
    border: 2px solid #8080804d;
    background: #fff;

    .content-icon {
      width: 25%;
      display: flex;
      justify-content: center;
      align-items: center;

      div {
        background-color: #ff000045;
        margin-top: -26em;
        width: 5em;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1em;
        border-radius: 44px;
      }

      .icon {
        font-size: 40px;
        color: red;
      }
    }

    .content-text {
      width: 75%;
      p {
        width: 467px;
        margin-bottom: 2em;
      }
    }
  }
  .clickable {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`

export default ContactSupport
