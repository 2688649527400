import { Button, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import axios from 'axios'
import LateralBar from 'containers/lateral-bar'
import LineConditions, {
  initialLineConditionsForm,
  verifyErrors,
  label,
} from 'containers/line-conditions/line-conditions'
import ModalUpload from 'containers/Modal/modal-documents-upload'
import { AlertContext } from 'providers/alert'
import { CustomerContext } from 'providers/customer'
import { LateralBarContext, LATERAL_MENU_OPTIONS } from 'providers/lateral-bar'
import { ModalContext } from 'providers/modal'
import { useContext, useEffect, useRef, useState } from 'react'
import { getDocumentList, uploadDocuments } from 'services/documents'
import { getCustomerLines, getLineConditions } from 'services/lines'
import { instanceTransition } from 'services/workflow'
import styled from 'styled-components'
import { extractNumber } from 'utils/functions/handlers-currency'
import { getUserRoles } from 'utils/functions/role-manager'
import { useAuth } from 'utils/hooks/auth'
import {
  CUSTOMER_STAGES,
  CUSTOMER_STATES,
  SHAREHOLDER_TYPES,
} from 'utils/schemas/customer'
import * as DOCUMENTS from 'utils/schemas/documents'
import { FILE_STATES } from 'utils/schemas/documents'
import { LINE_STATES } from 'utils/schemas/line'
import { BuildSection } from './build-section'
import { extractNumberPercentage } from 'utils/functions/handle-percentage'

const DocumentsUpload = () => {
  const [files, setFiles] = useState({})
  const [formValues, setFormValues] = useState(initialLineConditionsForm)
  const [formErrors, setFormErrors] = useState(initialLineConditionsForm)
  const [isValidating, setIsValidating] = useState(false)
  const [isAssessment, setIsAssessment] = useState(true)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [openCommentBar, setOpenCommentBar] = useState(false)
  const { customer } = useContext(CustomerContext)
  const { setAction, setSelectedOption } = useContext(LateralBarContext)
  const { setOpenModal, setActions, openModal, setOnClose } =
    useContext(ModalContext)
  const { setAlert } = useContext(AlertContext)
  const { user } = useAuth()
  const toResetErrorRef = useRef(null)
  const [openModalUpload, setOpenModalUpload] = useState(false)
  const [messages, setMessages] = useState('')
  const [moratorium, setMoratorium] = useState(false)

  const documentInputs = isAssessment
    ? DOCUMENTS.ASSESSMENT
    : DOCUMENTS.PRE_ASSESSMENT

  const process =
    customer?.type === SHAREHOLDER_TYPES.PERSON
      ? 'naturalPerson'
      : 'moralPerson'

  const handleUpload = async () => {
    const userRole = getUserRoles()[0]
    const response = await uploadDocuments(
      files,
      customer.id,
      user.uid,
      userRole
    )

    if (response.some((request) => request.status !== 'fulfilled')) {
      throw new Error('file-upload-error')
    }
    return response.map((request) => request.value)
  }

  const handleSave = async () => {
    try {
      setIsLoadingSubmit(true)
      await handleUpload()
      setAction(false)
      setOpenModalUpload(false)
    } catch (error) {
      console.error(error.message)
      const alert = {
        label:
          'Ha ocurrido un error al guardar los archivos, por favor intenta de nuevo',
        title: 'Error',
        type: 'error',
      }
      if (error.message === 'file-upload-error') {
        setAction(false)
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      }
      setAlert(alert)
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  const validateFormValue = () => {
    setIsValidating(true)
    if (verifyErrors(formErrors)) {
      setAlert({
        label:
          'Para crear una configuración es necesario que todos los campos sean diligenciados, por favor revise la configuración y vuelva a intentar',
        title: 'Error',
        type: 'error',
      })
      return
    }

    const documentsSchema = [
      ...documentInputs.generalInformation[process],
      ...documentInputs.financialInformation[process],
      ...documentInputs.mainAuctioneer[process],
    ]

    if (documentsSchema.some(({ typeId }) => !files[typeId]?.length > 0)) {
      setAlert({
        label:
          'Por favor sube todos los documentos marcados en rojo y vuelve a intentar',
        title: 'Error',
        type: 'error',
      })
      return
    }

    const greaterThanMaxTerm = (formValues?.ordinary).some((period) => {
      const valueOperation = (array, key) => {
        let add = 0
        array.forEach((element) => {
          add += extractNumber(element[key])
        })
        return add
      }

      const key = 'term'
      const total = valueOperation(formValues?.ordinary, key)
      return extractNumber(total) > extractNumber(formValues.line?.maxTerm)
    })

    if (extractNumber(formValues?.line?.score) <= 0) {
      setOpenModalUpload(true)
      setMessages(label.score)
      setMoratorium(false)
    } else if (extractNumber(formValues?.line?.maxTerm) <= 0) {
      setOpenModalUpload(true)
      setMessages(label.maxTerm)
      setMoratorium(false)
    } else if (greaterThanMaxTerm) {
      setOpenModalUpload(true)
      setMessages(label.ordinary)
      setMoratorium(true)
    } else if (formValues?.moratorium?.length === 0) {
      setOpenModalUpload(true)
      setMessages(label.moratorium)
      setMoratorium(true)
    } else {
      setOpenModalUpload(false)
      handleSubmit()
    }
  }

  const handleModalSection = () => {
    setOpenModalUpload(true)
    setMoratorium(false)
    setMessages(null)
  }

  const handleSubmit = async () => {
    try {
      let EVENT
      let nextState
      let nextStage

      setIsLoadingSubmit(true)
      setIsValidating(true)
      const documentsSchema = [
        ...documentInputs.generalInformation[process],
        ...documentInputs.financialInformation[process],
        ...documentInputs.mainAuctioneer[process],
      ]

      /* Check form errors */
      if (verifyErrors(formErrors)) {
        throw new Error('form-error')
      }

      /* Check documents errors */
      if (documentsSchema.some(({ typeId }) => !files[typeId]?.length > 0)) {
        throw new Error('file-error')
      }

      /* Map Form Values */

      const {
        line: lineConditions,
        ordinary,
        moratorium,
        commission,
      } = formValues

      const lineConditionsCreate = {
        'score:line_condition': extractNumber(lineConditions.score),
        'maxTerm:line_condition': Number(lineConditions.maxTerm),
        'terms:line_condition': [
          ...ordinary.map((period) => {
            Object.keys(period).forEach((key) => {
              if (key === 'rate') {
                period[key] = extractNumberPercentage(period[key])
              } else if (key !== 'name') {
                period[key] = Number(period[key])
              }
            })
            period.delinquency = false
            return period
          }),
          ...moratorium.map((period) => {
            Object.keys(period).forEach((key) => {
              if (key === 'rate') {
                period[key] = extractNumberPercentage(period[key])
              } else if (key !== 'name') {
                period[key] = Number(period[key])
              }
            })
            if (period.term === 0) period.term = undefined
            period.delinquency = true
            return period
          }),
        ],
        'commissions:line_condition': commission.map((commission) => {
          commission.amount = extractNumber(commission.amount)
          commission.days = commission.days.split(',').map(Number)
          return commission
        }),
      }

      const transition =
        CUSTOMER_STATES[customer?.customerInstance?.currentState].transition
      /*  Happens when the state is cust_uploadingAssessment */
      if (transition.SEND) {
        EVENT = transition.SEND.event
        nextState = transition.SEND.nextState
        nextStage = transition.SEND.nextStage
      } else {
        const transitionData =
          transition[isAssessment ? 'SEND_ASSESMENT' : 'SEND_PREASSESMENT']
        EVENT = transitionData.event
        nextState = transitionData.nextState
        nextStage = transitionData.nextStage
      }

      const lineStatus = isAssessment
        ? LINE_STATES.PROPOSAL
        : LINE_STATES.PREPROPOSAL

      const multiUploadIds = await handleUpload()

      await instanceTransition(
        customer?.customerInstance?.workflowId,
        customer?.customerInstance.instanceId,
        EVENT,
        {
          'customerId:contract': customer.id,
          ...lineConditionsCreate,
          'status:line': lineStatus,
          'fileList:multiUpdate': multiUploadIds,
          'id:status': customer.id,
          'wfState:status': nextState,
          'wfStage:status': nextStage,
          'role:assignation': 'bpm.mx-amex-b2b.ope-hd',
          'customerId:assignation': customer.id,
          senderUidFed: user.uid,
          'customerId:notificationMany': customer.id,
        }
      )
      setAction(false)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
    } catch (error) {
      const alert = {
        label:
          'Ha ocurrido un error al enviar la evaluación, por favor recarga la página e intenta de nuevo',
        title: 'Error',
        type: 'error',
      }
      if (error.message === 'file-error') {
        alert.label =
          'Por favor sube todos los documentos marcados en rojo y vuelve a intentar'
        setAlert(alert)
      } else if (error.message === 'form-error') {
        alert.label =
          'Para crear una configuración es necesario que todos los campos sean diligenciados, por favor revise la configuración y vuelva a intentar'
        setAlert(alert)
      } else if (error.message === 'file-upload-error') {
        alert.label =
          'Ha ocurrido un error al guardar los archivos, por favor ingresa al prospecto e intenta subir los documentos de nuevo'
        setAlert(alert)
        setAction(false)
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      } else if (axios.isAxiosError(error)) {
        const { data } = error.response
        console.error('workflow error:', data.body?.message)
        alert.label =
          'Los documentos se han guardado, pero ha ocurrido un error al enviar la  evaluación. por favor ingresa al prospecto e intenta  nuevamente'
        setAlert(alert)
        setAction(false)
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      } else {
        setAlert(alert)
      }
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  /* Show modal to select assessment or preassessment */
  useEffect(() => {
    const onCloseEvent = () => {
      setAction(false)
    }
    setOnClose(() => onCloseEvent)
    setActions([() => setIsAssessment(false), () => setIsAssessment(true)])
    /*  Pre-assessment temporarily disabled setOpenModal(modalOptions.SendEvaluation)*/
  }, [setOpenModal, setActions, setOnClose, setAction])

  /* Fetch saved files Effect */
  useEffect(() => {
    const fetchFiles = async () => {
      const { data: draftedFilesData } = await getDocumentList(customer.id, {
        status: FILE_STATES.DRAFT,
      })
      const { data: acceptedFilesData } = await getDocumentList(customer.id, {
        status: FILE_STATES.ACCEPTED,
      })
      const savedFilesData = [...draftedFilesData, ...acceptedFilesData]
      const savedFiles = savedFilesData.reduce((acc, itm) => {
        const docs = acc[itm.typeId] || []
        const extension = itm.fileExtension ? `.${itm.fileExtension}` : ''
        docs.push({
          name: `${itm.fileName}${extension}`,
          size: itm.fileSize,
          status: itm.fileStatus,
          id: itm.fileId,
          fileExtension: itm.fileExtension,
        })
        return { ...acc, [itm.typeId]: docs }
      }, {})
      setFiles(savedFiles)
    }
    if (customer?.id) fetchFiles()
  }, [customer?.id])

  useEffect(() => {
    if (
      [
        CUSTOMER_STAGES.assessment.id,
        /*  CUSTOMER_STAGES.preassessment.id, */
      ].includes(customer?.wfStage)
    ) {
      setIsAssessment(customer?.wfStage === CUSTOMER_STAGES.assessment.id)
      setOpenModal('')
    }
  }, [customer?.wfStage, setOpenModal])

  useEffect(() => {
    const lineFind = async () => {
      if (customer.id) return

      const { data: lines } = await getCustomerLines(customer.id)
      const line = lines.find(
        (line) =>
          line.status === LINE_STATES.PREOFFER ||
          line.status === LINE_STATES.PROPOSAL
      )

      if (line) {
        const lineConditions = await getLineConditions(line.id)
        setFormValues(lineConditions)
      }
    }

    lineFind()
  }, [customer.id])

  if (openModal) {
    return <></>
  }

  return (
    <DocumentsUploadStyled
      ref={toResetErrorRef}
      isMargin={messages ? true : false}
    >
      <h3>{isAssessment ? 'Assessment' : 'Pre-assessment'}</h3>

      <LineConditions
        isValidating={isValidating}
        lineForm={formValues}
        setLineForm={setFormValues}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
      />

      <h3>Documentos {isAssessment ? 'Assessment' : 'Pre-assessment'}</h3>
      <CustomerDocumentsStyled>
        <BuildSection
          allowDelete
          allowUpdate
          files={files}
          isValidating={isValidating}
          schema={documentInputs.generalInformation[process]}
          setFiles={setFiles}
          setCommentBarInfo={setOpenCommentBar}
          title={documentInputs.generalInformation.title}
        />
        {Boolean(isAssessment) && (
          <BuildSection
            allowDelete
            allowUpdate
            files={files}
            isValidating={isValidating}
            schema={documentInputs.mainAuctioneer[process]}
            setFiles={setFiles}
            setCommentBarInfo={setOpenCommentBar}
            title={documentInputs.mainAuctioneer.title}
          />
        )}
        <BuildSection
          allowDelete
          allowUpdate
          files={files}
          isValidating={isValidating}
          schema={documentInputs.financialInformation[process]}
          setFiles={setFiles}
          setCommentBarInfo={setOpenCommentBar}
          title={documentInputs.financialInformation.title}
        />
      </CustomerDocumentsStyled>
      <div className='action-group'>
        <Button
          buttonType='outlineBlueDark'
          disabled={isLoadingSubmit}
          onClick={() => setAction(false)}
        >
          {isLoadingSubmit && <LoadingAnimation className='loading' />}
          {!isLoadingSubmit && 'Cancelar'}
        </Button>
        <Button
          onClick={() => handleModalSection()}
          disabled={isLoadingSubmit}
        >
          {isLoadingSubmit && <LoadingAnimation className='loading' />}
          {!isLoadingSubmit && 'Guardar'}
        </Button>
        <Button
          buttonType='blueDark'
          className='send'
          disabled={isLoadingSubmit}
          onClick={() => validateFormValue()}
        >
          {isLoadingSubmit && <LoadingAnimation className='loading' />}
          {!isLoadingSubmit && 'Enviar'}
        </Button>
      </div>
      {openCommentBar && (
        <LateralBar
          fileId={openCommentBar.fileId}
          documentName={openCommentBar.fileName}
          setlateralBar={setOpenCommentBar}
        />
      )}
      {openModalUpload && (
        <ModalUpload
          setOpenModalUpload={setOpenModalUpload}
          isLoadingSubmit={isLoadingSubmit}
          handleSave={handleSave}
          handleSubmit={handleSubmit}
          messages={{
            mdu: messages,
            msgButton: 'Confirmar',
            moratorium: moratorium,
          }}
        />
      )}
    </DocumentsUploadStyled>
  )
}

const DocumentsUploadStyled = styled.div`
  padding: 2rem;
  padding-bottom: 1rem;
  .loading span {
    background-color: white;
  }
  h3 {
    margin-top: 1rem;
  }
  h4 {
    font-weight: bold;
    margin-top: 1rem;
  }
  .form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 1rem auto;
    & > div {
      padding: 0;
      margin: 0.5rem 0rem;
      // first column
      &:nth-child(3n - 2) {
        margin-right: 16px;
      }
      // second column
      &:nth-child(3n - 1) {
        margin-left: 8px;
        margin-right: 8px;
      }
      // last column
      &:nth-child(3n) {
        margin-left: 16px;
      }
    }
  }

  .action-group {
    margin: 2rem 42px 0;

    button + button {
      margin-left: 28px;
    }
    .send {
      float: right;
    }
  }
  .button-section {
    margin-top: ${({ isMargin }) => (isMargin ? '0' : '2.5rem')};
  }
`
const CustomerDocumentsStyled = styled.section`
  .center-loading {
    height: 50vh;
    display: flex;
    align-items: center;
    div {
      width: 150px;
    }
  }

  h5 {
    margin-top: 1rem;
  }

  section {
    width: 65%;
  }
`

export default DocumentsUpload
