import { useEffect, useState } from 'react'
import { getCustomerContracts } from 'services/customer'

/**
 * 
 * @param {number} customerId 
 * @param {string} status 
 * @returns 
 */
export const useFetchContract = ({ customerId, state }) => {
  const [contractData, setContractData] = useState()

  useEffect(() => {
		const fetchData = async () => {
			const { contracts } = await getCustomerContracts(customerId)
			const currentContract = contracts.find( itm => itm?.status === state )
			setContractData(currentContract) 
		}
    if (customerId)	fetchData()
  }, [customerId, state])

  return contractData
}
