import { INPUT_TYPES, VALIDATOR_TYPES } from 'containers/form'

export const DEBT_CAPACITY_FORM = [
  {
    label: 'Tipo de moneda',
    name: 'currency',
    inputType: INPUT_TYPES.SELECT,
    options: [
      { value: 'MXN', label: 'MXN' },
      //{ value: 'USD', label: 'USD' },
      //{ value: 'USDC', label: 'USDC' },
    ],
  },
  {
    inputType: INPUT_TYPES.CURRENCY,
    label: 'Monto',
    placeholder: 'Campo obligatorio*',
    name: 'amount',
    validatorType: VALIDATOR_TYPES.CURRENCY,
    errorMessage: 'Ingresa un monto valido',
    validatorParams: { min: 1000000, max: 9999999999.99 },
  },
]
