import axios from 'axios'
import { ENDPOINTS } from './config'

/**
 * Service to get all relations of a line
 * @param {Object} query
 * @param {number} query.lineId - The customer's line id
 * @param {string} query.status - The relation's status
 * @returns
 */
export const getLineRelations = async (query) => {
  const { lineId, status, page, size, columnFilters = [] } = query

  const filters = columnFilters.reduce((acc, filter) => {
    const { id, value } = filter
    return { ...acc, [id]: value }
  }, {})

  const { data } = await axios.get(ENDPOINTS.relation.find, {
    params: { lineId, status, page, size, ...filters },
  })

  return data
}

/**
 * Service to get relation info
 * @param {number} relationId - The relation id
 * @returns
 */
export const getRelationInfo = async (relationId) => {
  const { data } = await axios.get(ENDPOINTS.relation.info, {
    params: { relationId },
  })
  return data
}

/**
 * service to create a relation
 * @param {object} relation - The relation object
 * @returns
 */
export const createRelation = async (relation) => {
  const { data } = await axios.post(ENDPOINTS.relation.create, relation)
  return data
}

/**
 * service to edit a relation
 * @param {object} relation - The relation object
 * @returns
 */
export const editRelation = async (relation) => {
  const { data } = await axios.patch(ENDPOINTS.relation.update, relation)
  return data
}

/**
 * Service to get category list
 * @param {'PRIMARY' | 'SUPPLEMENTARY'} type
 */
export const getCategoryList = async (type) => {
  const { data } = await axios.get(ENDPOINTS.category.find, {
    params: { type },
  })
  return data
}

/**
 *
 * @param {Object} query - The query to search
 * @param {string} query.nidType - The nid type
 * @param {string} query.nid - The nid number
 * @param {string} query.name - The provider name
 * @param {string} query.status - The provider status
 * @returns
 */
export const getProviders = async (query = {}) => {
  const { nidType, nid, name, status, source } = query
  const { data } = await axios.get(ENDPOINTS.provider.find, {
    params: { nidType, nid, name, status, source },
  })
  return data
}

/**
 * Service to create a provider
 * @param {Object} provider - The provider object
 * @param {string} provider.externalId - The provider external id
 * @param {string} provider.nidType - The provider id type
 * @param {string} provider.nid - The provider id number
 * @param {string} provider.name - The provider name
 * @param {string} provider.commerceCodes - The provider commerce codes
 * @param {string} provider.source - Source of the provider
 * @returns
 */
export const createProvider = async (provider) => {
  const { data } = await axios.post(ENDPOINTS.provider.create, provider)
  return data
}
