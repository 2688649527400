import { Button, Icon, LoadingAnimation, Select } from '@keoworld/gbl-ui-kit'
import PaginatedItems from 'components/pagination'
import { CustomerContext } from 'providers/customer'
import { ModalContext, modalOptions } from 'providers/modal'
import { useCallback, useContext, useEffect, useState } from 'react'
import { getInteractionList, updateCustomer } from 'services/customer'
import styled from 'styled-components'
import { useDebounce } from 'utils/hooks/debounce'

const MAX_ITEMS_PER_PAGE = 4

const ACTIVITY_OPTIONS = [
  { label: 'EVALUACIÓN DE PROSPECCIÓN', value: 'PROSPECT EVALUATION' },
  { label: 'CALIFICAR', value: 'QUALIFY' },
  { label: 'PRIMER CONTACTO', value: 'FIRST CONTACT' },
  { label: 'EVALUACIÓN DE NECESIDADES', value: 'NEEDS ASSESSMENT' },
  { label: 'OFERTA FORMAL', value: 'FORMAL OFFER' },
  { label: 'DECISIÓN PENDIENTE', value: 'DECISION PENDING' },
  {
    label: 'GANANCIA VERBAL/CONTRATO PENDIENTE',
    value: 'VERBAL WIN/CONTRACT PENDING'
  },
  {
    label: 'CONTRATO EJECUTADO',
    value: 'CONTRACT EXECUTED'
  },
  {
    label: 'CERRADO-GANADO',
    value: 'CLOSED-WON'
  },
  {
    label: 'CERRADO-SEGUIDO',
    value: 'CLOSED-FOLLOW UP'
  },
  {
    label: 'CERRADO-DESAPARECIDO',
    value: 'CLOSED-MISS'
  },
  {
    label: 'CERRADO-DUPLICADO',
    value: 'CLOSED-WON'
  }
]

const CustomerCommercial = () => {
  const [loading, setLoading] = useState(true)
  const { setOpenModal, setModalId, setActions } = useContext(ModalContext)
  const { customer, setCustomer } = useContext(CustomerContext)
  const [interactionList, setInteractionList] = useState([])
  const [formFinal, setFormFinal] = useState(customer?.commercialStatus)
  const [totalRecords, setTotalRecords] = useState(0)
  const [offset, setOffset] = useState(0)

  const commercialStatus = useDebounce(formFinal, 1000)

  const handleChange = async (e) => {
    const { value } = e.target
    setFormFinal(value)
  }

  const fetchInteractionList = useCallback(async () => {
    try {
      if (!customer?.id) {
        return
      }
      //change page and element
      let { data } = await getInteractionList(
        customer.id,
        offset + 1,
        MAX_ITEMS_PER_PAGE
      )

      const { interactions, fullCount } = data

      const list = interactions.map(
        ({ username, action, madeAt, interactionId }) => ({
          name: username,
          action,
          createdAt: madeAt,
          interactionId
        })
      )

      setLoading(false)
      setInteractionList(list)
      setTotalRecords(fullCount)
    } catch (error) {
      console.log(error)
    }
  }, [customer?.id, offset])

  useEffect(() => {
    const update = async () => {
      try {
        await updateCustomer({ commercialStatus }, customer?.id)
        setCustomer((prev) => ({ ...prev, commercialStatus }))
      } catch (error) {
        console.log(error)
      }
    }

    if (
      customer?.id &&
      commercialStatus &&
      customer?.commercialStatus !== commercialStatus
    ) {
      update()
    }
  }, [commercialStatus, customer, setCustomer])

  useEffect(() => {
    fetchInteractionList()
  }, [fetchInteractionList])

  if (loading) {
    return (
      <CustomerCommercialStyled>
        <div className='center-loading'>
          <LoadingAnimation />
        </div>
      </CustomerCommercialStyled>
    )
  }

  return (
    <CustomerCommercialStyled>
      <div className='select-section'>
        <p>
          <b>Actividad</b>
        </p>
        <Select
          name='activity'
          value={formFinal ?? ''}
          onChange={(e) => handleChange(e)}
        >
          <option disabled value=''>
            Selecciona una opción
          </option>
          {ACTIVITY_OPTIONS.map(({ label, value }, index) => (
            <option key={`option-${index}`} value={value}>
              {label}
            </option>
          ))}
        </Select>
      </div>
      <div className='container'>
        <div>
          <h3>Lista de Actividad Comercial</h3>
          <TableStyled>
            <table>
              <thead>
                <tr>
                  <th>Usuario</th>
                  <th>Acción</th>
                  <th>Fecha</th>
                  <th>Detalle</th>
                </tr>
              </thead>
              <tbody>
                {interactionList.map((itm, index) => {
                  return (
                    <tr key={`interaction-${index}`}>
                      <td className='td-p'>
                        <img
                          src='/static/media/df.53d6cbdcbba75d916b923974f90cd4fe.svg'
                          alt=''
                        />
                        <p>{itm.name}</p>
                      </td>
                      <td>{itm.action}</td>
                      <td>{itm?.createdAt}</td>
                      <td>
                        <Button
                          className='button-icon'
                          onClick={() => {
                            setModalId(itm.interactionId)
                            setActions([undefined, fetchInteractionList])
                            setOpenModal(modalOptions.CommercialAction)
                          }}
                        >
                          <Icon name='add_circle' />
                        </Button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </TableStyled>
        </div>
        <div>
          <AddButton
            size='action'
            onClick={() => {
              setActions([undefined, fetchInteractionList])
              setOpenModal(modalOptions.CommercialAction)
            }}
          >
            <p className='label'>Agregar nueva tarea</p>
            <div className='icon-container'>
              <Icon name='add' />
            </div>
          </AddButton>
        </div>
      </div>
      {totalRecords > MAX_ITEMS_PER_PAGE && (
        <div className='pagination'>
          <PaginatedItems
            currentPage={offset}
            handlePageClick={(event) => setOffset(event.selected)}
            pageCount={Math.ceil(totalRecords / MAX_ITEMS_PER_PAGE)}
          />
        </div>
      )}
    </CustomerCommercialStyled>
  )
}

const TableStyled = styled.div`
  padding: 1rem 0;
  caption {
    font-size: 18px;
    text-align: left;
    font-weight: bold;
    margin-bottom: 2rem;
    margin-top: -2rem;
  }
  thead {
    text-align: left;
    font-size: 15px;
  }
  .row {
    display: flex;
    p {
      margin: auto 5px;
    }
  }
  th,
  td {
    width: 180px;
    padding: 12px 0rem;
  }
  .td-p {
    display: flex;
    p {
      margin-left: 5px;
    }
  }
  tbody {
    font-size: 13px;
  }
  .button-icon {
    width: 31.1px;
    height: 30px;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 15px;
    }
  }
  .comment {
    span {
      cursor: pointer;
      color: grey;
    }
  }
`

const CustomerCommercialStyled = styled.div`
  padding: 1rem;
  .select-section {
    margin: 1rem auto 1rem 1rem;
    width: 300px;
  }
  .center-loading {
    height: 50vh;
    display: flex;
    align-items: center;
    div {
      width: 150px;
    }
  }
  .container {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
  }

  .form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    & > div {
      padding: 0 1rem;
      &:nth-child(2n + 1) {
        padding-left: 0;
      }
      &:nth-child(2n) {
        padding-right: 0;
      }
    }
  }
`

const AddButton = styled(Button)`
  display: grid;
  grid-template-columns: 0px 100%;

  .label {
    color: black;
    font-weight: normal;
    font-family: 'POPPINS';
    width: fit-content;
    margin-left: -155px;
  }

  .icon-container {
    width: 3em;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export default CustomerCommercial
