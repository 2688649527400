import {
  Button,
  Icon,
  Input,
  LoadingAnimation,
  Select
} from '@keoworld/gbl-ui-kit'
import Document from 'components/document'
import { AlertContext } from 'providers/alert'
import { LateralBarContext, LATERAL_MENU_OPTIONS } from 'providers/lateral-bar'
import { useCallback, useContext, useEffect, useState } from 'react'
import { bulkFind, bulkUpload, getCollection } from 'services/lines'
import styled from 'styled-components'
import { useAuth } from 'utils/hooks/auth'
import { PAID } from 'utils/schemas/documents'
import { objectToFormData } from 'utils/functions/form-data'
import { FILE_MAX_SIZE } from 'utils/constants'

const MassivePayment = () => {
  const [file, setFile] = useState({})
  const [form, setForm] = useState({})
  const { user } = useAuth()
  const { setAlert } = useContext(AlertContext)
  const [warning, setWarning] = useState(false)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [options, setOptions] = useState({})
  const [collection, setCollection] = useState([])
  const { setSelectedOption } = useContext(LateralBarContext)

  const onHandleSubmit = async () => {
    try {
      setIsLoadingSubmit(true)
      if (!file) {
        throw new Error()
      }
      const formData = objectToFormData({
        file: file[0],
        extension: file[0]?.name?.split('.').pop(),
        documentName: file[0]?.name,
        uploadedAt: new Date().toISOString(),
        uuid: user?.uid
      })
      await bulkUpload(formData)
      setWarning(false)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_CUSTOMERS)
    } catch (error) {
      setWarning(true)
      setAlert({
        title: 'Error',
        label: 'Subir un archivo.',
        type: 'warning'
      })
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  const onHandleChange = (event) => {
    const { name, value } = event.target
    setForm({ ...form, [name]: value })
  }

  const collectionFind = useCallback(async (id) => {
    try {
      const { data } = await getCollection({
        bulkId: id,
        size: 10,
        page: 1
      })
      if (data) setCollection(data.collectionList)
      setForm({})
    } catch (error) {}
  }, [])

  useEffect(() => {
    collectionFind()
  }, [collectionFind])

  useEffect(() => {
    const bulkFinder = async () => {
      const { data } = await bulkFind(
        new Date(form.dateFrom).toISOString(),
        new Date(form.dateTo).toISOString(),
        1,
        5
      )
      const finalData = data.reduce((acc, itm, index) => {
        acc[index] = {
          id: itm.id,
          date: itm.uploadedAt
        }
        return acc
      }, {})
      setOptions(finalData)
    }
    if (form.dateFrom && form.dateTo) bulkFinder()
  }, [form.dateFrom, form.dateTo])

  return (
    <MassivePaymentStyled>
      <h3>Registro de pago masivo</h3>
      <h4>Archivo de pagos</h4>
      <div className='buildSection'>
        <BuildSection
          documentsUploaded={file}
          setFiles={setFile}
          documentList={PAID}
          warningVar={warning}
          upload
        />
      </div>
      <Button
        className='button'
        onClick={onHandleSubmit}
        disabled={isLoadingSubmit}
      >
        {isLoadingSubmit ? <LoadingAnimation /> : ' Enviar Archivo'}
      </Button>
      <h4>Resultado Carga</h4>
      <div className='form'>
        <Input
          name='dateFrom'
          label='Fecha desde la carga'
          type='date'
          onChange={onHandleChange}
        />
        {form.dateFrom && (
          <Input
            name='dateTo'
            label='Fecha hasta la carga'
            type='date'
            onChange={onHandleChange}
            min={form.dateFrom}
          />
        )}
        {form.dateFrom && form.dateTo && (
          <Select
            name='hour'
            label='Hora de carga'
            value={form?.hour ?? ''}
            onChange={onHandleChange}
          >
            <option disabled value=''>
              Selecciona una opción
            </option>
            {Object.values(options).map((itm, index) => {
              const { id, date } = itm
              const time = new Date(date).toLocaleTimeString('es', {
                timeStyle: 'short',
                hour12: false,
                timeZone: 'UTC'
              })
              return (
                <option key={`option${index}`} value={id}>
                  {`ID: ${id} - Hour: ${time}`}
                </option>
              )
            })}
          </Select>
        )}
        {form.hour && (
          <Button
            className='button-search'
            onClick={() => collectionFind(form.hour)}
          >
            <Icon name='search' />
          </Button>
        )}
      </div>
      <TableStyled>
        {collection.length === 0 ? (
          <h4>No hay datos</h4>
        ) : (
          <table>
            <thead>
              <tr>
                <th>Cliente</th>
                <th>Ref. pago</th>
                <th>Resultado</th>
              </tr>
            </thead>
            <tbody>
              {collection.map((itm, index) => {
                const { id, reference } = itm
                return (
                  <tr key={`key-${index}`}>
                    <td>{id}</td>
                    <td className='wrap'>{reference}</td>
                    <td>status</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
      </TableStyled>
      <div className='action-group'>
        <Button
          buttonType='grayButton'
          onClick={() =>
            setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_CUSTOMERS)
          }
        >
          Cancelar
        </Button>
      </div>
    </MassivePaymentStyled>
  )
}

const BuildSection = ({
  documentsUploaded,
  documentList,
  setFiles = () => {},
  upload,
  warningVar
}) => {
  const { setAlert } = useContext(AlertContext)

  return (
    <>
      {documentList.map((documentInformation, index) => {
        const { name, label, typeId, documentType } = documentInformation
        let file
        const document = documentsUploaded[typeId]

        if (document && document.size) {
          file = { name: document.name, size: document.size }
        }

        const onHandleChange = (files) => {
          try {
            if (files[0].size > FILE_MAX_SIZE) throw new Error()
            setFiles((prev) => ({
              ...prev,
              [typeId]: files[0]
            }))
          } catch (error) {
            setAlert({
              title: 'Error',
              label: 'Archivo demasiado pesado.',
              type: 'warning'
            })
          }
        }

        const onClickDelete = () => {
          setFiles((prev) => ({
            ...prev,
            [typeId]: undefined
          }))
          setFiles([])
        }

        return (
          <Document
            key={`document-${index}`}
            className='document'
            documentName={name}
            allowedDocumentTypes={documentType}
            allowUpload={upload}
            file={file}
            label={label}
            onChange={onHandleChange}
            onClickDelete={onClickDelete}
            warning={warningVar}
          />
        )
      })}
    </>
  )
}

const TableStyled = styled.div`
  padding: 1rem 0;
  caption {
    font-size: 18px;
    text-align: left;
    font-weight: bold;
    margin-bottom: 2rem;
    margin-top: -2rem;
  }
  thead {
    text-align: left;
    font-size: 15px;
  }
  .row {
    display: flex;
    p {
      margin: auto 5px;
    }
  }
  th,
  td {
    width: 180px;
    padding: 12px 0rem;
  }
  .td-p {
    display: flex;
    p {
      margin-left: 5px;
    }
  }
  tbody {
    font-size: 13px;
  }
  .button-icon {
    width: 31.1px;
    height: 30px;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 15px;
    }
  }
  .comment {
    span {
      cursor: pointer;
      color: grey;
    }
  }
`

const MassivePaymentStyled = styled.div`
  padding: 2rem;
  h4 {
    margin-top: 1rem;
  }
  .button-search {
    margin: 1rem;
    width: 80px;
  }
  .buildSection {
    margin-top: 2rem;
  }
  .input-date {
    width: 200px;
  }
  .form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 1rem auto;
    Input {
      max-width: 250px;
    }
    Select {
      display: block;
      max-width: 250px;
    }
    & > div {
      padding: 0;
      margin: 0.5rem 0rem;
      &:nth-child(2n + 1) {
        padding-left: 0;
      }
      &:nth-child(2n) {
        padding-right: 0;
      }
    }
  }
  .action-group {
    .button {
      margin-top: 1rem;
    }
  }
  .button {
    margin-top: 1rem;
    span {
      background-color: white;
    }
  }
`

export default MassivePayment
