import { Button, Icon, Input } from '@keoworld/gbl-ui-kit'
import PaginatedItems from 'components/pagination'
import LoanInformation from 'containers/lateral-bar/loan-information'
import PropTypes from 'prop-types'
import { AlertContext } from 'providers/alert'
import { useContext, useEffect, useState } from 'react'
import { fetchPayments } from 'services/payments'
import styled from 'styled-components'
import { dateFormatFromIso } from 'utils/functions/date'
import { currency } from 'utils/functions/formatters'
import { useDebounce } from 'utils/hooks/debounce'
import { PAYMENT_STATUS } from 'utils/schemas/payments'

const LIMIT = 10

const CancelPayments = (props) => {
  const { loan, onClose, setAction } = props

  const [payments, setPayments] = useState([])
  const [paymentsSelected, setPaymentsSelected] = useState([])
  const [loading, setLoading] = useState(true)
  const [search, setSearch] = useState({ total: 0, offset: 1, text: '' })
  const { setAlert } = useContext(AlertContext)
  const debounce = useDebounce(search.text, 500)

  useEffect(() => {
    const obtainPayments = async () => {
      setLoading(true)
      const query = {
        loanId: loan.id,
        paymentId: debounce ? debounce : undefined,
        limit: LIMIT,
        offset: search.offset,
      }

      const { payments, total } = await fetchPayments(query)

      setPayments(payments)
      setSearch((query) => ({ ...query, total }))

      setLoading(false)
    }

    obtainPayments()
  }, [search.offset, debounce, loan])

  const onChangeChecked = (payment, value) => {
    const paymentsTemp = [...payments]
    const indexOfPayment = paymentsTemp.findIndex((p) => p.id === payment.id)
    if (indexOfPayment !== -1) {
      paymentsTemp[indexOfPayment].selected = value
      setPayments(paymentsTemp)
    }

    if (value && !paymentsSelected.some((p) => p.id === payment.id)) {
      const selectedTemp = [...paymentsSelected, payment]
      selectedTemp.sort((a, b) => a.id - b.id)
      setPaymentsSelected(selectedTemp)
    } else if (!value) {
      const paymentsSelectedTemp = [...paymentsSelected]
      const indexOfPaymentsSelected = paymentsSelectedTemp.indexOf(payment)
      paymentsSelectedTemp.splice(indexOfPaymentsSelected, 1)
      setPaymentsSelected(paymentsSelectedTemp)
    }
  }

  const onContinue = () => {
    if (paymentsSelected.length === 0) {
      setAlert({
        type: 'warning',
        title: '¡Atención!',
        label: 'Selecciona al menos un pago',
      })
      return
    }

    setAction(paymentsSelected)
  }

  return (
    <Styles>
      <div className='payments-view'>
        <LoanInformation className='information' loan={loan} />
        <h3>Pagos realizados</h3>
        <div className='table-header'>
          <div className='head-text'>
            Selecciona los pagos que deseas anular
          </div>
          <Input
            className='search'
            name='search'
            label='ID pago'
            icon={<Icon name='search' />}
            value={search.text}
            onChange={(e) =>
              setSearch((query) => ({ ...query, text: e.target.value }))
            }
          />
        </div>
        <table border='1' frame='void' rules='rows'>
          <thead>
            <tr>
              <th />
              <th>ID pago</th>
              <th>Estado</th>
              <th>Fecha</th>
              <th>Monto pagado</th>
              <th>Continua la prelación de pago del sistema</th>
            </tr>
          </thead>
          <tbody>
            {loading &&
              [...Array(8)].map((_, i) => (
                <tr key={`tr-${i}`}>
                  {[...Array(5)].map((_, j) => (
                    <td key={`skeleton-${j}`}>
                      <div className='skeleton' />
                    </td>
                  ))}
                </tr>
              ))}
            {!loading && payments.length === 0 && (
              <tr>
                <td colSpan='5'>
                  <p>No se encontraron pagos registrados</p>
                </td>
              </tr>
            )}
            {!loading &&
              payments.map((payment = {}, index) => {
                const {
                  id,
                  madeAt,
                  amount,
                  priority = true,
                  selected = false,
                  status,
                } = payment

                const paymentStatus =
                  PAYMENT_STATUS[status] || PAYMENT_STATUS.Annulled
                const isCancelled = paymentStatus === PAYMENT_STATUS.Annulled

                return (
                  <tr key={`payment-${index}`}>
                    <td>
                      <input
                        type='checkbox'
                        name='selected'
                        checked={selected}
                        onChange={(e) =>
                          !isCancelled &&
                          onChangeChecked(payment, e.target.checked)
                        }
                        disabled={isCancelled}
                      />
                    </td>
                    <td>{id}</td>
                    <td>{paymentStatus.label}</td>
                    <td>{dateFormatFromIso(madeAt, 'yyyy-MM-dd')}</td>
                    <td>{currency(amount)}</td>
                    <td>
                      <Icon
                        data-color={`${priority ? 'green' : 'red'}`}
                        name={priority ? 'check_circle' : 'cancel'}
                        type='outlined'
                      />
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
        {Math.ceil(search.total / LIMIT) > 1 && (
          <PaginatedItems
            currentPage={search.offset - 1}
            pageCount={Math.ceil(search.total / LIMIT)}
            handlePageClick={({ selected }) => {
              setSearch((query) => ({ ...query, offset: selected + 1 }))
            }}
          />
        )}
      </div>
      <div className='payments-selected'>
        <h3>Pagos seleccionados</h3>
        {paymentsSelected.length === 0 && (
          <div className='default-label'>
            Acá verás un listado de los pagos que has seleccionado para anular.
          </div>
        )}
        {paymentsSelected.length > 0 && (
          <ul>
            {paymentsSelected.map((payment, index) => {
              return (
                <li key={`payment-selected-${index}`}>
                  <div className='payment-identifier'>
                    <button onClick={() => onChangeChecked(payment, false)}>
                      <Icon name='delete' type='outlined' />
                    </button>
                    <span className='bold'>ID pago:</span>
                    <span>{payment.id}</span>
                  </div>
                  <div>{currency(payment.amount)}</div>
                </li>
              )
            })}
            <li>
              <span className='bold'>Monto total: </span>
              <span className='total'>
                {currency(
                  paymentsSelected.reduce((acc, { amount }) => acc + amount, 0)
                )}
              </span>
            </li>
          </ul>
        )}
        <div className='actions'>
          <div className='buttons'>
            <Button buttonType='outline' onClick={() => onClose()}>
              Cancelar
            </Button>
            <Button buttonType='blueDark' onClick={() => onContinue()}>
              Continuar
            </Button>
          </div>
        </div>
      </div>
    </Styles>
  )
}

const Styles = styled.div`
  display: grid;
  grid-template-columns: minmax(600px, 2fr) minmax(450px, 1fr);
  height: calc(100% - 183px);
  overflow-x: auto;

  .bold {
    font-weight: bold;
  }

  .skeleton {
    height: 50%;
  }

  .payments-view {
    padding: 3rem 2rem;

    .information .g-loan-info {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      padding: 1rem 2rem;

      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      background-color: #f6f8fc;
    }

    .table-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;

      .head-text {
        margin-top: 10px;
      }

      .search {
        width: 180px;
      }
    }

    table {
      margin-top: 2rem;

      text-align: center;
      width: 100%;

      thead {
        tr {
          border-color: transparent;
        }
        th {
          max-width: 150px;
          min-width: 1.2rem;
        }
      }

      tbody {
        td {
          height: 3rem;
          border: solid;
          border-width: 1px 0;
          border-color: #e5e5e5;
        }

        input[type='checkbox'] {
          height: 1rem;
          width: 1rem;

          accent-color: #00172d;
        }

        .icon {
          &[data-color='green'] {
            color: #2bb161;
          }
          &[data-color='red'] {
            color: #00172d;
          }
        }
      }
    }
  }

  .payments-selected {
    background-color: #f6f8fc;
    padding: 3rem 4rem;

    position: relative;

    .default-label {
      margin-top: 1.5rem;
    }

    ul {
      margin-top: 1.5rem;
      padding: 0;
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;

        height: 4rem;

        border-bottom: 1px solid #bcbcbc;

        .payment-identifier {
          display: flex;
          align-items: center;
          gap: 10px;

          button {
            background-color: transparent;
            border: none;
            color: #b7b7b7;
          }
        }
      }

      li:last-child {
        border-bottom: none;
        justify-content: flex-end;
        gap: 10px;

        .total {
          min-width: 150px;
        }
      }
    }

    .actions {
      position: absolute;
      bottom: 2rem;

      .buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
      }
    }
  }
`

CancelPayments.propTypes = {
  loan: PropTypes.object.isRequired,
  setAction: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default CancelPayments
