import { INPUT_TYPES, VALIDATOR_TYPES } from 'containers/form'

export const PAYMENT_SUMMARY = [
  {
    name: 'amount',
    label: 'Monto total',
    placeholder: 'Monto total',
    inputType: INPUT_TYPES.CURRENCY,
    validatorType: VALIDATOR_TYPES.CURRENCY,
    validatorParams: { min: 0.01 },
  },
]

export const PAYMENT_CONCEPT = [
  {
    name: 'amount',
    label: 'Monto',
    placeholder: 'Monto',
    inputType: INPUT_TYPES.CURRENCY,
    validatorType: VALIDATOR_TYPES.CURRENCY,
    validatorParams: { min: 0.01 },
  },
  {
    name: 'concept',
    label: 'Concepto de pago',
    inputType: INPUT_TYPES.SELECT,
    options: [],
    validatorType: VALIDATOR_TYPES.ANY,
  },
]

export const PAYMENT_STATUS = Object.freeze({
  Applied: {
    label: 'Aplicado',
    value: 'Applied',
  },
  Annulled: {
    label: 'Anulado',
    value: 'Annulled',
  },
})
