import { Button, Icon, Modal } from '@keoworld/gbl-ui-kit'
import { modalOptions } from 'providers/modal'
import React from 'react'
import styled from 'styled-components'
import ComercialActivityRegister from './comercial-activity'

const ModalContent = (props) => {
  const {
    openModal,
    setOpenModal,
    actions,
    modalId,
    onClose,
    setModalId,
    setActions,
  } = props
  const [rejectCallback, successCallback] = actions

  const closeModal = () => {
    setOpenModal(undefined)
    setModalId(undefined)
    setActions([])
  }

  const onSuccess = () => {
    if (successCallback) {
      successCallback()
    }
    closeModal()
  }

  const onReject = () => {
    if (rejectCallback) {
      rejectCallback()
    }
    closeModal()
  }

  const CreateCustomerModal = () => {
    return (
      <div className='modal-content-one'>
        <h4>Enviar a Assessment o Preassessment</h4>
        <p>Acá podrás adjuntar todos los documentos correspondientes.</p>
        <div className='button-section'>
          <Button onClick={onReject}>Preassessment</Button>
          <Button onClick={onSuccess}>Assessment</Button>
        </div>
      </div>
    )
  }

  const SaveInformation = () => {
    return (
      <div className='modal-content-one'>
        <h4>Guardar</h4>
        <p>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
          nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
        </p>
        <div className='button-section'>
          <Button onClick={onReject}>Salir sin guardar </Button>
          <Button onClick={onSuccess}>Guardar</Button>
        </div>
      </div>
    )
  }

  const SendInformation = () => {
    return (
      <div className='modal-content-one'>
        <h4>Enviar</h4>
        <p>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
          nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat{' '}
        </p>
        <div className='button-section'>
          <Button onClick={onReject}>Cancelar</Button>
          <Button onClick={onSuccess}>Enviar</Button>
        </div>
      </div>
    )
  }

  const Reject = () => {
    return (
      <div className='modal-content-three'>
        <div className='header'>
          <Icon
            name='cancel'
            type='outlined'
          />
          <h4>No aprobado</h4>
        </div>
        <p>Lorem ipsum sit adem:</p>
        <textarea
          className='input-section'
          placeholder='Comentarios'
        />
        <div className='button-section'>
          <Button onClick={onReject}>Cancelar</Button>
          <Button onClick={onSuccess}>Guardar</Button>
        </div>
      </div>
    )
  }

  const CancelAction = () => {
    return (
      <div className='modal-content-two'>
        <p>¿Estás seguro de que deseas cancelar al proveedor?</p>
        <div className='button-section'>
          <Button onClick={onReject}>Cancelar</Button>
          <Button onClick={onSuccess}>Guardar</Button>
        </div>
      </div>
    )
  }

  const ContinueModal = () => {
    return (
      <div className='modal-content-two'>
        <p>Ocurrió algo inesperado. Continuar al dashboard.</p>
        <div className='button-section'>
          <Button onClick={onSuccess}>Continuar</Button>
        </div>
      </div>
    )
  }

  const ReassessmentModal = () => {
    return (
      <div className='modal-content-two'>
        <p>¿Estás seguro de enviar este customer a Reassessment?</p>
        <div className='button-section'>
          <Button onClick={onSuccess}>Continuar</Button>
        </div>
      </div>
    )
  }

  const Modals = {
    [modalOptions.CreateCustomer]: CreateCustomerModal,
    [modalOptions.SendEvaluation]: CreateCustomerModal,
    [modalOptions.SaveInformation]: SaveInformation,
    [modalOptions.SendInformation]: SendInformation,
    [modalOptions.Reject]: Reject,
    [modalOptions.CancelAction]: CancelAction,
    [modalOptions.CommercialAction]: ComercialActivityRegister,
    [modalOptions.Continue]: ContinueModal,
    [modalOptions.Reassessment]: ReassessmentModal,
  }

  const Component = openModal ? Modals[openModal] : React.Fragment

  return (
    <ModalContentStyled>
      <Icon
        name='close'
        onClick={() => {
          if (typeof onClose === 'function') onClose()
          closeModal()
        }}
      />
      <Component
        closeModal={closeModal}
        modalId={modalId}
        onReject={onReject}
        onSuccess={onSuccess}
      />
    </ModalContentStyled>
  )
}

const ModalContentStyled = styled(Modal)`
  position: fixed;
  z-index: 1;
  .loading span {
    background-color: white;
  }
  .modal-content-four {
    width: 658px;
    p {
      width: auto;
      font-weight: bold;
    }
    .content {
      width: 506px;
      margin: 1rem auto;
      h3 {
        text-align: center;
      }
      Input {
        width: 455px;
      }
    }
    .flex-section {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      align-items: center;
      width: 506px;
      margin: auto;
      Input {
        width: 70%;
      }
      Input:required {
        color: red;
        border-color: red;
      }
      .date-section {
        width: 255px;
      }
      select {
        width: 210px;
      }
      select:required {
        color: red;
        border-color: red;
      }
    }

    .textarea {
      padding: 10px;
      font-family: 'poppins';
      font-weight: bold;
      border-radius: 21px;
      width: 500px;
      height: 108px;
      margin: 2rem 3.5rem;
      resize: none;
    }
    .button-section {
      width: 410px;
      margin: -1rem auto 1rem auto;
      display: flex;
      Button {
        width: 143px;
        height: 43px;
      }
    }
    .button-section-two {
      width: 410px;
      margin: -1rem auto 1rem 6rem;
      display: flex;
      Button {
        width: 143px;
        height: 43px;
      }
      button {
        margin: 0rem 1rem;
      }
    }
  }

  .icon {
    cursor: pointer;
    float: right;
    margin: 1rem;
  }
  h4 {
    font-size: 20px;
    font-weight: bold;
  }
  p {
    width: 339px;
    margin: 1rem auto;
  }
  .button-section {
    button {
      margin: auto 1rem;
    }
  }
  .card-modal {
    width: auto;
    height: auto;
    min-height: auto;
    border-radius: 21px;
    .modal-content-one {
      width: 500px;
      height: 230px;
      text-align: center;
      margin-top: 4rem;
    }
    .modal-content-two {
      width: 500px;
      height: 138px;
      text-align: center;
      margin-top: 4rem;
      .header {
        display: flex;
        width: 200px;
        text-align: center;
        margin: auto;
        align-items: center;
      }
      p {
        text-align: center;
      }
    }
    .modal-content-three {
      width: 500px;
      height: 300px;
      text-align: center;
      margin-top: 2rem;
      .header {
        display: flex;
        width: 200px;
        text-align: center;
        margin: auto 9rem;
        align-items: center;
      }
      p {
        text-align: left;
      }
      .input-section {
        border: 1px solid #bcbcbc;
        border-radius: 11px;
        width: 350px;
        height: 108px;
        font-family: 'poppins';
        resize: none;
        padding: 10px;
      }

      .button-section {
        button {
          margin: 1rem 1rem;
        }
      }
    }
  }
`

export default ModalContent
