import { Button, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import LateralBar from 'containers/lateral-bar'
import axios from 'axios'
import ModalUpload from 'containers/Modal/modal-documents-upload'
import { AlertContext } from 'providers/alert'
import { CustomerContext } from 'providers/customer'
import { LateralBarContext, LATERAL_MENU_OPTIONS } from 'providers/lateral-bar'
import { useContext, useEffect, useState } from 'react'
import { getFileRequest, uploadDocuments } from 'services/documents'
import { instanceTransition } from 'services/workflow'
import styled from 'styled-components'
import { getUserRoles } from 'utils/functions/role-manager'
import { useAuth } from 'utils/hooks/auth'
import { CUSTOMER_STATES } from 'utils/schemas/customer'
import { FILE_STATES } from 'utils/schemas/documents'
import { BuildSection } from './document-actions/build-section'

const LegalValidation = () => {
  const [requestedFiles, setRequestedFiles] = useState([])
  const [files, setFiles] = useState({})
  const [fileScheme, setFileScheme] = useState([])
  const { customer } = useContext(CustomerContext)
  const { user } = useAuth()
  const { setAction, setSelectedOption } = useContext(LateralBarContext)
  const [openCommentBar, setOpenCommentBar] = useState()
  const [isLoadingData, setIsLoadingData] = useState(true)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [openModalUpload, setOpenModalUpload] = useState(false)
  const [isValidating, setIsValidating] = useState(false)
  const { setAlert } = useContext(AlertContext)

  const handleUpload = async () => {
    const userRole = getUserRoles()[0]

    const filesToUpload = requestedFiles
      .map((requestedFile) => {
        const documents = files[requestedFile.fileRequestId] || []
        return documents.map((document) => {
          document.id = document.fileId
          document.fileRequestId = requestedFile.fileRequestId
          return document
        })
      })
      .flat()

    const response = await uploadDocuments(
      { 26: filesToUpload },
      customer.id,
      user.uid,
      userRole
    )
    if (response.some((request) => request.status !== 'fulfilled'))
      throw new Error('error saving files')

    return response.map((request) => request.value)
  }

  const handleSave = async () => {
    try {
      setIsLoadingSubmit(true)
      await handleUpload()
      setAction(false)
    } catch (error) {
      console.error(error.message)
      const alert = {
        label:
          'Ha ocurrido un error al guardar los archivos, por favor intenta de nuevo',
        title: 'Error',
        type: 'error',
      }
      if (error.message === 'error saving files') {
        setAction(false)
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      }
      setAlert(alert)
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  const handleSubmit = async () => {
    try {
      setIsLoadingSubmit(true)
      setIsValidating(true)

      const hasErrors = fileScheme.some((scheme) => {
        const filesByType = files[scheme.typeId] || []
        const isAllDeleted = filesByType.every(
          (file) => file.status === FILE_STATES.DELETED
        )
        const isSomeRefusedOrEmpty = filesByType.some(
          (file) => file.status === FILE_STATES.REFUSED || !file.size
        )

        return isAllDeleted || isSomeRefusedOrEmpty
      })

      if (hasErrors) {
        throw new Error('missing files')
      }

      const transition = CUSTOMER_STATES.cust_pendingExtraDocs.transition
      const uploadedIds = await handleUpload()

      const multiUploadIds = uploadedIds.map((file) => ({
        id: file.fileId || file.id,
        status:
          file.status === FILE_STATES.ACCEPTED ||
          file.status === FILE_STATES.DELETED
            ? file.status
            : FILE_STATES.PENDING,
      }))

      const notificationReceiver = customer?.assignations?.find((itm) =>
        transition?.SEND?.notificationReceiverRoles.includes(itm?.role)
      )

      await instanceTransition(
        customer?.customerInstance?.workflowId,
        customer?.customerInstance?.instanceId,
        transition.SEND.event,
        {
          'fileList:multiUpdate': multiUploadIds,
          'wfState:status': transition.SEND.nextState,
          customerId: customer.id,
          uuid: user?.uid,
          role: getUserRoles()[0],
          senderUidFed: user.uid,
          'receiverUidIam:notificationOne': notificationReceiver?.iamUid,
          'customerId:notificationOne': customer.id,
        }
      )
      setAction(false)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
    } catch (err) {
      const alert = {
        label:
          'Faltan archivos por subir o los archivos cargados deben ser corregidos',
        title: 'Error',
        type: 'error',
      }
      if (err.message === 'error saving files') {
        alert.label =
          'Ha ocurrido un error al guardar los archivos, por favor ingresa al prospecto e intenta subir los documentos de nuevo'
        setAction(false)
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      } else if (axios.isAxiosError(err)) {
        const { data } = err.response
        console.error('workflow error:', data.body?.message)
        alert.label =
          'Los documentos se han guardado, pero ha ocurrido un error al enviar. por favor ingresa al prospecto e intenta  nuevamente'
        setAction(false)
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      }
      setAlert(alert)
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  useEffect(() => {
    try {
      const fetchFiles = async () => {
        setIsLoadingData(true)
        const { data: savedFilesData } = await getFileRequest(customer.id)
        const documentsUploaded = {}
        let scheme = []

        savedFilesData.forEach((request) => {
          const { data, fileRequestId, fileRequestName, fileTypeId } = request
          scheme = [
            ...scheme,
            {
              label: fileRequestName,
              typeId: fileRequestId,
              documentType: ['.pdf', 'image/*'],
            },
          ]

          documentsUploaded[fileRequestId] = data.map((file) => {
            const { fileId, fileName, fileSize, fileStatus, fileExtension } =
              file
            const extension = fileExtension ? `.${fileExtension}` : ''
            return {
              label: fileName,
              name: `${fileName}${extension}`,
              size: fileSize,
              status: fileStatus,
              id: fileRequestId,
              fileId,
              fileExtension,
              fileTypeId,
            }
          })
        })

        setRequestedFiles(savedFilesData)
        setFiles(documentsUploaded)
        setFileScheme(scheme)
        setIsLoadingData(false)
      }
      if (customer?.id) fetchFiles()
    } catch (err) {
      console.error('err', err)
    }
  }, [customer])

  return (
    <LegalValidationStyled>
      <>
        <section className='legal-documents'>
          <h3>Validación Legal</h3>
          {isLoadingData && <LoadingAnimation />}
          <CustomerDocumentsStyled>
            <BuildSection
              allowComment
              allowDelete
              allowUpdate
              allowUpload
              files={files}
              isValidating={isValidating}
              schema={fileScheme}
              setCommentBarInfo={setOpenCommentBar}
              setFiles={setFiles}
            />
          </CustomerDocumentsStyled>
        </section>
        <div className='action-group'>
          <Button
            onClick={() => setAction(false)}
            disabled={isLoadingSubmit}
          >
            Cancelar
          </Button>
          <Button
            buttonType='outline'
            disabled={isLoadingSubmit || isLoadingData}
            onClick={() => setOpenModalUpload(true)}
          >
            {isLoadingSubmit && <LoadingAnimation className='loading' />}
            {!isLoadingSubmit && 'Guardar'}
          </Button>
          <Button
            buttonType='blueDark'
            className='send'
            disabled={isLoadingSubmit || isLoadingData}
            onClick={() => handleSubmit()}
          >
            {isLoadingSubmit && <LoadingAnimation className='loading' />}
            {!isLoadingSubmit && 'Enviar'}
          </Button>
        </div>
        {openCommentBar && (
          <LateralBar
            fileId={openCommentBar.fileId}
            documentName={openCommentBar.fileName}
            setlateralBar={setOpenCommentBar}
          />
        )}
        {openModalUpload && (
          <ModalUpload
            setOpenModalUpload={setOpenModalUpload}
            isLoadingSubmit={isLoadingSubmit}
            handleSave={handleSave}
          />
        )}
      </>
    </LegalValidationStyled>
  )
}

const CustomerDocumentsStyled = styled.section`
  width: 60%;

  ul {
    list-style: none;
    padding: 0;
  }

  section {
    width: 65%;
  }
`

const LegalValidationStyled = styled.div`
  padding: 2rem;
  .loading span {
    background-color: white;
  }
  .additional-documents {
    margin-top: 1rem;
    width: 60%;
    min-width: 600px;
    .input {
      margin: 20px 0;
      .icon {
        cursor: pointer;
      }
    }
    .documents-required {
      margin: 20px 0;
      display: flex;

      .chip {
        display: flex;
        align-items: center;
        justify-content: space-between;

        ${({ theme }) => theme.colors.grayShades[600]}
        border-radius: 20px;
        padding: 3px 15px;

        .icon {
          cursor: pointer;
          margin-left: 20px;
          margin-right: -5px;
          font-size: 15px;
        }
      }
      .chip + .chip {
        margin-left: 5px;
      }
    }
  }
  .action-group {
    margin-top: 1rem;
    button + button {
      margin-left: 28px;
    }
    .send {
      float: right;
    }
  }
`

export default LegalValidation
