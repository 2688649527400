import { Button, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import axios from 'axios'
import ModalUpload from 'containers/Modal/modal-documents-upload'
import LateralBar from 'containers/lateral-bar'
import { AlertContext } from 'providers/alert'
import { CustomerContext } from 'providers/customer'
import { LATERAL_MENU_OPTIONS, LateralBarContext } from 'providers/lateral-bar'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiBuyerRepository } from 'services/buyer-suppcards/buyer.repository'
import { getFileRequest, uploadDocuments } from 'services/documents'
import styled from 'styled-components'
import { BuildSection } from 'templates/actions/document-actions/build-section'
import { getUserRoles } from 'utils/functions/role-manager'
import { useAuth } from 'utils/hooks/auth'
import Router from 'utils/router'
import { FILE_STATES } from 'utils/schemas/documents'
import { ONBOARDING_STATES } from 'utils/schemas/workflows/onboarding'

const AdditionalPropertyDocumentsReassessment = () => {
  const navigate = useNavigate()
  const { customerId } = useParams()

  const [files, setFiles] = useState({})
  const [requestedFiles, setRequestedFiles] = useState([])
  const [fileScheme, setFileScheme] = useState([])
  const [openCommentBar, setOpenCommentBar] = useState()
  const [isLoadingData, setIsLoadingData] = useState(true)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [openModalUpload, setOpenModalUpload] = useState(false)
  const [isValidating, setIsValidating] = useState(false)

  const { customer } = useContext(CustomerContext)
  const { setAction, setSelectedOption } = useContext(LateralBarContext)
  const { setAlert } = useContext(AlertContext)

  const { user } = useAuth()

  const [userRole] = getUserRoles()

  const handleUpload = async () => {
    const filesToUpload = requestedFiles
      .map((requestedFile) => {
        const documents = files[requestedFile.fileRequestId] || []
        return documents.map((document) => {
          document.id = document.fileId
          document.fileRequestId = requestedFile.fileRequestId
          return document
        })
      })
      .flat()

    const response = await uploadDocuments(
      { 26: filesToUpload },
      customerId,
      user.uid,
      userRole
    )
    if (response.some((request) => request.status !== 'fulfilled'))
      throw new Error('error saving files')

    return response.map((request) => request.value)
  }

  const handleSave = async () => {
    try {
      setIsLoadingSubmit(true)
      await handleUpload()
      setAction(false)
      navigate(-1)
    } catch (error) {
      console.error(error.message)
      const alert = {
        label:
          'Ha ocurrido un error al guardar los archivos, por favor intenta de nuevo',
        title: 'Error',
        type: 'error',
      }
      if (error.message === 'error saving files') {
        setAction(false)
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
        navigate(Router.Root)
      }
      setAlert(alert)
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  const handleSubmit = async () => {
    try {
      setIsLoadingSubmit(true)
      setIsValidating(true)

      const hasErrors = fileScheme.some((scheme) => {
        const filesByType = files[scheme.typeId] || []
        const isAllDeleted = filesByType.every(
          (file) => file.status === FILE_STATES.DELETED
        )
        const isSomeRefusedOrEmpty = filesByType.some(
          (file) => file.status === FILE_STATES.REFUSED || !file.size
        )

        return isAllDeleted || isSomeRefusedOrEmpty
      })

      if (hasErrors) {
        throw new Error('missing files')
      }

      const [sendAdditionalDocuments] =
        ONBOARDING_STATES.ADDITIONAL_DOCUMENTS_REASSESSMENT.actions
      const { event, nextState } = sendAdditionalDocuments

      const uploadedIds = await handleUpload()

      const multiUploadIds = uploadedIds.map((file) => ({
        id: file.fileId || file.id,
        status:
          file.status === FILE_STATES.ACCEPTED ||
          file.status === FILE_STATES.DELETED
            ? file.status
            : FILE_STATES.PENDING,
      }))

      let eventData = {
        'fileList:multiUpdate': multiUploadIds,
        'wfState:status': nextState,
        customerId: Number(customerId),
        uuid: user?.uid,
        role: userRole,
        'id:assignation': customer.assignationId,
        'uuid:assignation': user.uid,
        'role:assignation': userRole,
        'customerId:assignation': customer.id,
      }

      await ApiBuyerRepository.doReassessmentEvent(
        customer.externalId,
        event,
        eventData
      )

      navigate(Router.Root)
      setAction(false)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
    } catch (err) {
      const alert = {
        label:
          'Faltan archivos por subir o los archivos cargados deben ser corregidos',
        title: 'Error',
        type: 'error',
      }
      if (err.message === 'error saving files') {
        alert.label = `Ha ocurrido un error al guardar los archivos,
          por favor ingresa al prospecto e intenta subir los documentos de nuevo`
        setAction(false)
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
        navigate(Router.Root)
      } else if (axios.isAxiosError(err)) {
        const { data } = err.response
        console.error('workflow error:', data.body?.message)
        alert.label = `Los documentos se han guardado, pero ha ocurrido un error al enviar.
          Por favor ingresa al prospecto e intenta  nuevamente`
        navigate(Router.Root)
        setAction(false)
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      }
      setAlert(alert)
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  useEffect(() => {
    try {
      const fetchFiles = async () => {
        if (!customerId) return

        setIsLoadingData(true)
        const { data: savedFilesData } = await getFileRequest(customerId)
        const documentsUploaded = {}
        let scheme = []

        savedFilesData.forEach((request) => {
          const { data, fileRequestId, fileRequestName, fileTypeId } = request
          scheme = [
            ...scheme,
            {
              label: fileRequestName,
              typeId: fileRequestId,
              documentType: ['.pdf', 'image/*'],
            },
          ]

          documentsUploaded[fileRequestId] = data.map((file) => {
            const { fileId, fileName, fileSize, fileStatus, fileExtension } =
              file
            const extension = fileExtension ? `.${fileExtension}` : ''
            return {
              label: fileName,
              name: `${fileName}${extension}`,
              size: fileSize,
              status: fileStatus,
              id: fileRequestId,
              fileId,
              fileExtension,
              fileTypeId,
            }
          })
        })

        setRequestedFiles(savedFilesData)
        setFiles(documentsUploaded)
        setFileScheme(scheme)
        setIsLoadingData(false)
      }

      fetchFiles()
    } catch (error) {
      console.error('fetching documents')
      console.log(error)
    }
  }, [customerId])

  return (
    <LegalValidationStyled>
      <section className='legal-documents'>
        <h3>Validación Legal</h3>
        {isLoadingData && <LoadingAnimation />}
        {!isLoadingData && (
          <BuildSection
            className='additional-documents'
            allowComment
            allowDelete
            allowUpdate
            allowUpload
            files={files}
            isValidating={isValidating}
            schema={fileScheme}
            setCommentBarInfo={setOpenCommentBar}
            setFiles={setFiles}
          />
        )}
      </section>
      <div className='action-group'>
        <Button
          onClick={() => {
            navigate(-1)
            setAction(false)
          }}
          disabled={isLoadingSubmit}
        >
          Cancelar
        </Button>
        <div>
          <Button
            buttonType='outline'
            disabled={isLoadingSubmit || isLoadingData}
            onClick={() => setOpenModalUpload(true)}
          >
            {isLoadingSubmit && <LoadingAnimation className='loading' />}
            {!isLoadingSubmit && 'Guardar'}
          </Button>
          <Button
            buttonType='blueDark'
            className='send'
            disabled={isLoadingSubmit || isLoadingData}
            onClick={() => handleSubmit()}
          >
            {isLoadingSubmit && <LoadingAnimation className='loading' />}
            {!isLoadingSubmit && 'Enviar'}
          </Button>
        </div>
      </div>
      {openCommentBar && (
        <LateralBar
          fileId={openCommentBar.fileId}
          documentName={openCommentBar.fileName}
          setLateralBar={setOpenCommentBar}
        />
      )}
      {openModalUpload && (
        <ModalUpload
          setOpenModalUpload={setOpenModalUpload}
          isLoadingSubmit={isLoadingSubmit}
          handleSave={handleSave}
        />
      )}
    </LegalValidationStyled>
  )
}

const LegalValidationStyled = styled.div`
  padding: 2rem;

  .additional-documents {
    max-width: 60%;
  }

  .action-group {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;

    button + button {
      margin-left: 28px;
    }
    .send {
      float: right;
    }
  }
`

export default AdditionalPropertyDocumentsReassessment
