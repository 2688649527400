import { Button, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import LateralBar from 'containers/lateral-bar'
import ModalUpload from 'containers/Modal/modal-documents-upload'
import { AlertContext } from 'providers/alert'
import axios from 'axios'
import { CustomerContext } from 'providers/customer'
import { LateralBarContext, LATERAL_MENU_OPTIONS } from 'providers/lateral-bar'
import { useContext, useEffect, useRef, useState } from 'react'
import { getDocumentList, uploadDocuments } from 'services/documents'
import { instanceTransition } from 'services/workflow'
import styled from 'styled-components'
import { getUserRoles } from 'utils/functions/role-manager'
import { useAuth } from 'utils/hooks/auth'
import {
  CUSTOMER_DB_STATUS,
  CUSTOMER_STATES,
  SHAREHOLDER_TYPES,
} from 'utils/schemas/customer'
import {
  ASSESSMENT,
  FILE_STAGES,
  FILE_STATES,
  PRE_ASSESSMENT,
} from 'utils/schemas/documents'
import { BuildSection } from './build-section'

const DocumentsCorrection = () => {
  const [files, setFiles] = useState({})
  const [isValidating, setIsValidating] = useState(false)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [commentBarInfo, setCommentBarInfo] = useState(false)
  const { customer } = useContext(CustomerContext)
  const { setAction, setSelectedOption } = useContext(LateralBarContext)
  const { user } = useAuth()
  const { setAlert } = useContext(AlertContext)
  const toResetErrorRef = useRef(null)
  const [openModalUpload, setOpenModalUpload] = useState(false)

  const isAssessment =
    customer.wfStage ===
    CUSTOMER_STATES.cust_infoProvided.transition.SEND_ASSESMENT.nextStage

  const isReassessment = customer?.status === CUSTOMER_DB_STATUS.REASSESSMENT

  const documentInputs =
    isAssessment || isReassessment ? ASSESSMENT : PRE_ASSESSMENT
  const process =
    customer?.type === SHAREHOLDER_TYPES.PERSON
      ? 'naturalPerson'
      : 'moralPerson'

  const handleUpload = async () => {
    const userRole = getUserRoles()[0]
    const response = await uploadDocuments(
      files,
      customer.id,
      user.uid,
      userRole
    )
    if (response.some((request) => request.status !== 'fulfilled')) {
      throw new Error('error saving files')
    }
    return response.map((request) => request.value)
  }

  const handleSave = async () => {
    try {
      setIsLoadingSubmit(true)
      await handleUpload()
      setAction(false)
      setOpenModalUpload(false)
    } catch (error) {
      console.error(error.message)
      const alert = {
        label:
          'Ha ocurrido un error al guardar los archivos, por favor intenta de nuevo',
        title: 'Error',
        type: 'error',
      }
      if (error.message === 'error saving files') {
        setAction(false)
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      }
      setAlert(alert)
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  const validateFilesUploaded = () => {
    const documentsSchema = [
      ...documentInputs.generalInformation[process],
      ...documentInputs.financialInformation[process],
      ...documentInputs.mainAuctioneer[process],
    ]

    /* Check form errors */
    const areAllDocumentsUploaded = documentsSchema.every(({ typeId }) => {
      const length = files[typeId]?.length ?? 0
      return length > 0
    })

    if (!areAllDocumentsUploaded) {
      throw new Error('missing-files')
    }

    const isThereSomeRefused = documentsSchema.some(({ typeId }) => {
      const filesUploaded = files[typeId]
      const isSomeRefused = filesUploaded.some(
        (file) => file.status === FILE_STATES.REFUSED
      )
      return isSomeRefused
    })

    if (isThereSomeRefused) {
      throw new Error('required-corrections')
    }
  }

  const handleSubmit = async () => {
    try {
      setIsLoadingSubmit(true)
      setIsValidating(true)

      /* Check form errors */

      validateFilesUploaded()

      /* Execute transition */

      const transition = CUSTOMER_STATES.cust_correctionRequest.transition.SEND
      const EVENT = transition.event
      const nextState = transition.nextState
      const uploadedIds = await handleUpload()
      const unchangeableStates = [FILE_STATES.DELETED, FILE_STATES.ACCEPTED]
      const multiUploadIds = uploadedIds.map((itm) => {
        return {
          id: itm.id,
          status: unchangeableStates.includes(itm.status)
            ? itm.status
            : FILE_STATES.PENDING,
        }
      })
      const notificationReceiver = customer?.assignations?.find((itm) =>
        transition?.notificationReceiverRoles.includes(itm?.role)
      )

      await instanceTransition(
        customer.customerInstance.workflowId,
        customer.customerInstance.instanceId,
        EVENT,
        {
          'fileList:multiUpdate': multiUploadIds,
          'id:status': customer.id,
          'wfState:status': nextState,
          senderUidFed: user.uid,
          'receiverUidIam:notificationOne': notificationReceiver?.iamUid,
          'customerId:notificationOne': customer.id,
        }
      )
      setAction(false)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
    } catch (err) {
      const errors = {
        'missing-files': {
          close: false,
          title: `¡Error! Faltan documentos`,
          label: `Por favor carga todos los documentos solicitados`,
        },
        'required-corrections': {
          close: false,
          title: `¡Error! Correcciones requeridas`,
          label: `Por favor revisa los documentos que fueron rechazados
            y cargue los documentos corregidos`,
        },
        'error saving files': {
          close: true,
          label:
            'Ha ocurrido un error al guardar los archivos, por favor ingresa e intenta subir los documentos de nuevo',
        },
        workflow: {
          close: true,
          label:
            'Los documentos se han guardado, pero ha ocurrido un error al enviar. Por favor contacta a soporte',
        },
        default: {
          close: true,
          label:
            'Ha ocurrido un error al enviar. Intenta nuevamente y si el problema persiste, contacta a soporte',
        },
      }

      if (axios.isAxiosError(err)) {
        const { body } = err.response.data
        console.error('workflow error:', body?.message)
        err.message = 'workflow'
      }

      const { title, label, close } = errors[err.message] || errors.default

      if (close) {
        setAction(false)
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      }

      setAlert({
        label,
        title: title ?? 'Error',
        type: 'error',
      })
      console.error('send error:', err)
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  useEffect(() => {
    const fetchFiles = async () => {
      const { data: savedFilesData } = await getDocumentList(customer.id, {
        status: `${FILE_STATES.ACCEPTED},${FILE_STATES.REFUSED},${FILE_STATES.PENDING},${FILE_STATES.DRAFT}`,
        stage: FILE_STAGES.ASSESSMENT,
      })
      let savedFiles = {}
      savedFilesData.forEach((itm) => {
        const extension = itm.fileExtension ? `.${itm.fileExtension}` : ''
        if (!savedFiles[itm.typeId]) savedFiles[itm.typeId] = []
        savedFiles[itm.typeId].push({
          name: `${itm.fileName}${extension}`,
          size: itm.fileSize,
          status: itm.fileStatus,
          id: itm.fileId,
          typeId: itm.typeId,
        })
      })
      setFiles(savedFiles)
    }
    if (customer.id) fetchFiles()
  }, [customer.id])

  return (
    <DocumentsUploadStyled ref={toResetErrorRef}>
      <h3>
        {isAssessment
          ? 'Assessment'
          : isReassessment
          ? 'Reassessment'
          : 'Pre-assessment'}
      </h3>

      <h3>
        Documentos{' '}
        {isAssessment
          ? 'Assessment'
          : isReassessment
          ? 'Reassessment'
          : 'Pre-assessment'}
      </h3>
      <CustomerDocumentsStyled>
        <BuildSection
          allowUpload={true}
          allowReview={false}
          allowDelete={true}
          allowUpdate={true}
          schema={documentInputs.generalInformation[process]}
          title={documentInputs.generalInformation.title}
          files={files}
          setFiles={setFiles}
          isValidating={isValidating}
          setCommentBarInfo={setCommentBarInfo}
        />
        {Boolean(isAssessment || isReassessment) && (
          <BuildSection
            allowUpload={true}
            allowReview={false}
            allowDelete={true}
            allowUpdate={true}
            schema={documentInputs.mainAuctioneer[process]}
            title={documentInputs.mainAuctioneer.title}
            files={files}
            setFiles={setFiles}
            isValidating={isValidating}
            setCommentBarInfo={setCommentBarInfo}
          />
        )}
        <BuildSection
          allowUpload={true}
          allowReview={false}
          allowDelete={true}
          allowUpdate={true}
          schema={documentInputs.financialInformation[process]}
          title={documentInputs.financialInformation.title}
          files={files}
          setFiles={setFiles}
          isValidating={isValidating}
          setCommentBarInfo={setCommentBarInfo}
        />
      </CustomerDocumentsStyled>
      <div className='action-group'>
        <Button
          buttonType='grayButton'
          disabled={isLoadingSubmit}
          onClick={() => {
            setAction(false)
          }}
        >
          Cancelar
        </Button>
        <div className='actions-flex-end'>
          <Button
            disabled={isLoadingSubmit}
            className='loading'
            onClick={() => {
              setOpenModalUpload(true)
            }}
          >
            {isLoadingSubmit ? <LoadingAnimation /> : 'Guardar'}
          </Button>
          <Button
            buttonType='green'
            className='loading'
            disabled={isLoadingSubmit}
            onClick={() => handleSubmit()}
          >
            {isLoadingSubmit ? <LoadingAnimation /> : 'Enviar'}
          </Button>
        </div>
      </div>
      {commentBarInfo && (
        <LateralBar
          setLateralBar={setCommentBarInfo}
          documentName={commentBarInfo?.fileLabel}
          fileId={commentBarInfo?.fileId}
        />
      )}
      {openModalUpload && (
        <ModalUpload
          setOpenModalUpload={setOpenModalUpload}
          isLoadingSubmit={isLoadingSubmit}
          handleSave={handleSave}
        />
      )}
    </DocumentsUploadStyled>
  )
}

/**
 *
 * @param {object} file
 * @param {number} customerId
 * @returns
 */
export const fileFormData = (file, customerId, typeFileId, userUid, role) => {
  const formData = new FormData()
  const fileExtension = file.name.split('.').pop()
  formData.append('file', file)
  formData.append('customerId', customerId)
  formData.append('extension', fileExtension)
  formData.append('size', file.size)
  formData.append('typeFileId', typeFileId)
  formData.append('uuid', userUid)
  formData.append('role', role)
  return formData
}

const DocumentsUploadStyled = styled.div`
  padding: 2rem;
  padding-bottom: 1rem;

  .loading span {
    background-color: white;
  }
  h3 {
    margin-top: 1rem;
  }
  h4 {
    font-weight: bold;
    margin-top: 1rem;
  }
  .form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 1rem auto;
    Input {
      max-width: 250px;
    }
    Select {
      max-width: 250px;
    }
    & > div {
      padding: 0;
      margin: 0.5rem 0rem;
      &:nth-child(2n + 1) {
        padding-left: 0;
      }
      &:nth-child(2n) {
        padding-right: 0;
      }
    }
  }
  .action-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;

    min-width: 300px;

    .actions-flex-end {
      display: flex;
      gap: 10px;
    }
  }
`

const CustomerDocumentsStyled = styled.section`
  .center-loading {
    height: 50vh;
    display: flex;
    align-items: center;
    div {
      width: 150px;
    }
  }

  h5 {
    margin-top: 1rem;
  }

  section {
    width: 65%;
  }
`

export default DocumentsCorrection
