import { Button } from '@keoworld/gbl-ui-kit'
import { useId, useRef, useState } from 'react'
import styled from 'styled-components'

/**
 *
 * @param {object} props
 * @param {Boolean} props.error - Show red border when error is true
 * @param {function} props.handleFiles - Receive uploaded files
 * @returns
 */
const DragDropFile = (props) => {
  const { handleFiles, error } = props
  const inputId = useId()
  const inputRef = useRef(null)
  const [dragActive, setDragActive] = useState(false)
  const [value, setValue] = useState()

  const onDragging = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }
  const onDropFiles = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files)
    }
  }

  const onSelectFiles = (e) => {
    e.preventDefault()
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files)
    }
    inputRef.current.value = ''
  }

  const onButtonClick = () => {
    setValue(undefined)
    inputRef.current.click()
  }

  return (
    <DragDropStyled
      onDragEnter={onDragging}
      onDragLeave={onDragging}
      onDragOver={onDragging}
      onDrop={onDropFiles}
      error={error}
      dragActive={dragActive}
    >
      <input
        accept={['image/*']}
        hidden
        id={inputId}
        multiple
        onInput={onSelectFiles}
        ref={inputRef}
        type='file'
        value={value}
      />
      <label id={inputId}>
        <p>Arrastra o selecciona las imágenes</p>
        <Button
          buttonType='blueDark'
          className='upload-button'
          onClick={onButtonClick}
        >
          Seleccionar
        </Button>
      </label>
    </DragDropStyled>
  )
}

const DragDropStyled = styled.div`
  border: ${({ error }) => (error ? '2px dashed red' : '2px dashed grey')};
  border-radius: 10px;
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${({ dragActive }) =>
    dragActive ? 'gray' : 'transparent'};
  opacity: ${({ dragActive }) => (dragActive ? '0.3' : '1')};
`

export default DragDropFile
