import { createContext, useState } from 'react'

export const CUSTOMER_DETAIL_SECTIONS = {
  INFORMATION: 'information',
  DOCUMENTS: 'documents',
  HISTORY: 'history',
  COMERCIAL_ACTIVITY: 'comercialActivity',
  GENERAL_COMMENTS: 'comments',
  INFORMATION_OFFER: 'informationOffer',
}

const initialContext = {
  customer: {},
  currentSection: CUSTOMER_DETAIL_SECTIONS.INFORMATION,
  setCustomer: undefined,
  setCurrentSection: undefined,
}

export const CustomerContext = createContext(initialContext)

export const CustomerProvider = ({ children }) => {
  const [customer, setCustomer] = useState(initialContext.customer)
  const [section, setSection] = useState(initialContext.currentSection)

  return (
    <CustomerContext.Provider
      value={{
        customer,
        setCustomer,
        currentSection: section,
        setCurrentSection: setSection,
      }}
    >
      {children}
    </CustomerContext.Provider>
  )
}
