import { CustomerContext } from 'providers/customer'
import { LateralBarContext } from 'providers/lateral-bar'
import { Fragment, useContext } from 'react'
import styled from 'styled-components'
import { CUSTOMER_STATES } from 'utils/schemas/customer'

const InfoSummary = ({ currentRoute = [], title, labelOptions }) => {
  const { customer } = useContext(CustomerContext)
  const { setAction, setSelectedOption } = useContext(LateralBarContext)

  const customerStatusInfo =
    CUSTOMER_STATES[customer?.wfState ?? customer.state]

  const onClickAction = () => {
    setAction(false)
    setSelectedOption(labelOptions)
  }

  return (
    <InfoSummaryStyled>
      <div className='router'>
        <div className='button-back'>
          <button onClick={onClickAction}>{'<'}</button>
        </div>
        <div className='span-nav'>
          <span>Inicio</span>
          <span bold='true'> {'>'} </span>
          <span
            className='clickable'
            onClick={() => setAction(false)}
          >
            Detalle prospecto
          </span>
        </div>
        {currentRoute.map((itm, idx) => (
          <Fragment key={`breadcumb-itm-${idx}`}>
            <span bold='true'> {'>'} </span>
            <span>{itm}</span>
          </Fragment>
        ))}
      </div>
      {title && <h2>{title}</h2>}
      <h3>{customer?.name}</h3>
      <div body1='true'>ID {customer?.id}</div>
      <div body1='true'>
        <span bold='true'>Estado:</span> {customerStatusInfo?.label}
      </div>
    </InfoSummaryStyled>
  )
}

const InfoSummaryStyled = styled.div`
  .clickable {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
  .router {
    display: flex;
    .button-back {
      margin-top: -3pt;
      margin-right: 7pt;
      button {
        border-radius: 20px;
        border: none;
        cursor: pointer;
        background-color: #d9d9d9;
        width: 30px;
        height: 30px;
        font-size: 20px;
        color: gray;
      }
    }
    .span-nav {
      width: 180px;
    }
    span {
      margin-left: 1px;
    }
  }
`

export default InfoSummary
