import { Icon } from '@keoworld/gbl-ui-kit'
import React, { useEffect, useRef, useState } from 'react'
import { assignationUpdate } from 'services/customer'
import { getUserProfile, getUsers } from 'services/user'
import styled from 'styled-components'
import { getUserRoles } from 'utils/functions/role-manager'
import { useAuth } from 'utils/hooks/auth'
import { useDebounce } from 'utils/hooks/debounce'
import { dependentRoles } from 'utils/schemas/roles'

const ProspectModal = ({ customer, refreshList }) => {
  const [openModal, setOpenModal] = useState(false)
  const [success, setSuccess] = useState(false)
  const [dependentName, setDependentName] = useState('')
  const [users, setUsers] = useState([])
  const debounce = useDebounce(dependentName, 600)
  const wrapperRef = useRef(null)
  const { user } = useAuth()

  const assignProspect = async (user) => {
    try {
      const [userProfile] = await getUserProfile({ userId: user.id })
      const body = {
        id: customer.assignationId,
        iamId: user.id,
        role: userProfile.roles[0].code,
        customerId: customer.id
      }
      await assignationUpdate(body)
      setSuccess(true)
      refreshList()
    } catch (error) {
      console.error('error', error.message)
    }
  }

  const getUserArray = async ({ roles, name, uuid }) => {
    const data = await getUsers({ roles, name, uuid })
    setUsers(data)
  }

  useEffect(() => {
    const roles = getUserRoles(dependentRoles)
    const isLead = dependentRoles[roles[0]]
    if (openModal && !isLead) {
      getUserArray({ uuid: user.uid })
    }
  }, [openModal, user.uid])

  useEffect(() => {
    const roles = getUserRoles(dependentRoles)
    const isLead = dependentRoles[roles[0]]
    if (openModal && isLead) {
      getUserArray({ name: debounce, roles: isLead })
    }
  }, [debounce, openModal])

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setOpenModal(false)
        setSuccess(false)
      }, 2000)
      return () => clearTimeout(timer)
    }
  }, [success])

  useOutsideAlerter(wrapperRef, setOpenModal)

  const currentRole = getUserRoles()[0]

  const isHeadRole = () =>
    Object.keys(dependentRoles).find((role) => role === currentRole)

  return (
    <ProspectModalStyled>
      <span className='profile'>{customer.name.substring(0, 2)}</span>
      {isHeadRole() && (
        <button className='open-modal' onClick={() => setOpenModal(true)}>
          <Icon name='add_circle' />
        </button>
      )}
      {openModal && (
        <div className='modal' ref={wrapperRef}>
          {success && <div>Asignado correctamente</div>}
          {!success && (
            <>
              <p>
                <b>Reasignar prospectos</b>
              </p>
              <div className='input-icon'>
                <input
                  name='dependent'
                  value={dependentName}
                  onChange={(e) => setDependentName(e.target.value)}
                />
                <Icon name='search' />
              </div>
              <ul className='profile-div'>
                {users.map((user, index) => (
                  <li key={`user-${index}`}>
                    <div className='profile'>{user.name.substring(0, 2)}</div>
                    <p className='p-two'>{user.name}</p>
                    <Icon
                      name='add_circle'
                      onClick={() => assignProspect(user)}
                    />
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      )}
    </ProspectModalStyled>
  )
}

function useOutsideAlerter(ref, setState) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setState(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, setState])
}

const ProspectModalStyled = styled.div`
  .profile {
    text-transform: uppercase;
    color:white;
  }
  button.open-modal {
    cursor: pointer;

    position: absolute;
    bottom: -5px;
    right: -10px;

    background-color: white;
    border: none;
    border-radius: 50%;
    padding: 0;
    margin: auto;
    height: 24px;
    width: 24px;
    line-height: 24px;
  }
  .modal {
    z-index: 1;
    position: absolute;
    top: 10px;
    left: 68px;
    min-width: 260px;
    background-color: #f6f8fc;
    border: 1px solid;
    border-radius: 9px;
    padding: 1px 20px;
    p {
      text-align: left;
      font-size: 12px;
    }
    .profile-div {
      margin: -1rem auto 0rem auto;
      padding: 15px 0;

      width: 100%;
      float: left;

      list-style: none;

      li {
        display: flex;
        align-items: center;
        height: 20px;
      }

      li + li {
        margin-top: 1rem;
      }

      .icon {
        position: static;
        float: left;

        cursor: pointer;
      }

      .profile {
        border: 2px solid
          ${({ theme }) => theme.colors.primary.dark.backgroundColor};
        border-radius: 50px;
        height: 20px;
        width: 20px;
        line-height: 20px;
        font-size: 8px;
        text-transform: uppercase;
      }

      .p-two {
        margin-left: 3px;
        white-space: nowrap;
        width: 170px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: capitalize;
      }
    }

    .input-icon {
      .icon {
        position: absolute;
        top: 62px;
        left: 206px;
        width: 20px;
      }
    }

    input {
      margin: -1rem auto 1rem auto;
      float: left;
      width: 100%;
      height: 30px;
      border: none;
      border-radius: 5px;
    }
    input:hover {
      border: none;
    }
  }
`

export default ProspectModal
