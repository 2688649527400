import { Icon } from '@keoworld/gbl-ui-kit'
import CreateProvider from 'containers/customer-detail/relations/create-provider'
import CreateRelation from 'containers/customer-detail/relations/create-relation'
import CreateRelationSupplementaryCard from 'containers/customer-detail/relations/create-relation-supplementary'
import RelationDetail from 'containers/customer-detail/relations/relation-detail'
import styled from 'styled-components'
import RelationDetailSupplementary from './relation-detail-supplementary'

export const OPTIONS = {
  CREATE_RELATION: 'createRelation',
  CREATE_RELATION_SUPPLEMENTARY_CARD: 'createRelationSupplementaryCard',
  CREATE_PROVIDER: 'createProvider',
  VIEW: 'viewRelation',
  VIEW_SUPPLEMENTARY_CARD: 'viewRelationSupplementary',
  EDIT: 'editRelation',
}

const RelationActions = (props) => {
  const { line, relation, action, setAction, fetchRelations, closeLateral } =
    props

  return (
    <RelationActionsStyled className='lateral-bar'>
      <button className='close' onClick={() => closeLateral()}>
        <Icon name='close' />
      </button>
      {action === OPTIONS.CREATE_PROVIDER && (
        <CreateProvider line={line} closeLateral={closeLateral} />
      )}
      {action === OPTIONS.CREATE_RELATION && (
        <CreateRelation
          line={line}
          closeLateral={closeLateral}
          fetchRelations={fetchRelations}
        />
      )}
      {action === OPTIONS.CREATE_RELATION_SUPPLEMENTARY_CARD && (
        <CreateRelationSupplementaryCard
          line={line}
          closeLateral={closeLateral}
          fetchRelations={fetchRelations}
          isSupplementaryCard
        />
      )}
      {[OPTIONS.VIEW, OPTIONS.EDIT].includes(action) && (
        <RelationDetail
          editing={action === OPTIONS.EDIT}
          isSupplementaryCard={relation.isSupplementaryCard}
          relationId={relation.id}
          setAction={setAction}
          closeLateral={closeLateral}
          fetchRelations={fetchRelations}
        />
      )}
      {action === OPTIONS.VIEW_SUPPLEMENTARY_CARD && (
        <RelationDetailSupplementary
          relationId={relation.id}
          closeLateral={closeLateral}
          fetchRelations={fetchRelations}
        />
      )}
    </RelationActionsStyled>
  )
}

const RelationActionsStyled = styled.aside`
  width: 457px;
  height: calc(100% - 70px);

  position: absolute;
  z-index: 5;
  top: 70px;
  right: 0;

  padding: 70px 30px 20px 30px;

  border-radius: 24px 0px 0px 24px;

  overflow-y: auto;
  overflow-x: hidden;

  background-color: #f6f8fc;

  button.close {
    display: block;
    position: absolute;
    top: 1rem;
    background: none;
    border: none;
  }
`

export default RelationActions
