import axios from 'axios'
import { ENDPOINTS } from 'services/config'
import { v4 as UUIDv4 } from 'uuid'

export const PROPOSAL_STATUS = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
}

const URI = '/debt-capacities'
export class ApiDebtCapacityRepository {
  /**
   * @description Create a proposal
   * @param {Object} proposal The proposal data to send with the request
   * @param {String} proposal.customerId The customer identifier
   * @param {Object[]} proposal.debtCapacities The debt capacities to create
   * @param {String} proposal.debtCapacities[].currency The currency of the debt capacity
   * @param {Number} proposal.debtCapacities[].amount The amount of the debt capacity
   * @returns {Promise<Object>} The proposal data
   * @throws {Error} If the request fails.
   */
  static async create(proposal) {
    const event = 'create'
    const proposalId = UUIDv4()
    const endpoint = `${URI}/${proposalId}/${event}`

    await axios.post(`${URI}`, { instanceId: proposalId })

    const response = await axios.put(endpoint, {
      id: proposalId,
      customerId: proposal.customerId,
      debtCapacities: proposal.debtCapacities.map((debtCapacity) => ({
        amount: debtCapacity.amount,
        currency: debtCapacity.currency ?? 'MXN',
        id: UUIDv4(),
      })),
    })

    return response.data
  }

  /**
   * @description Update a debt capacity
   * @param {String} proposalId The proposal identifier
   * @param {String} debtCapacityId The debt capacity identifier
   * @param {Number} debtCapacityAmount The new amount of the debt capacity
   */
  static async update(proposalId, debtCapacityId, debtCapacityAmount) {
    const event = 'update-debt-capacity'
    const endpoint = `${URI}/${proposalId}/${event}`

    await axios.put(endpoint, {
      'id:debtCapacity': debtCapacityId,
      'amount:debtCapacity': debtCapacityAmount,
    })
  }

  /**
   * @description Approve a proposal
   * @param {String} proposalId The proposal identifier
   */
  static async approve(proposalId) {
    const event = 'approve'
    const endpoint = `${URI}/${proposalId}/${event}`

    await axios.put(endpoint, { id: proposalId })
  }

  /**
   * @description Reject a proposal
   * @param {String} proposalId The proposal identifier
   */
  static async reject(proposalId) {
    const event = 'reject'
    const endpoint = `${URI}/${proposalId}/${event}`

    await axios.put(endpoint, { id: proposalId })
  }

  /**
   * @description Get the debt capacities of a buyer
   * @param {Number} buyerId The buyer identifier in b2b
   * @param {String} status The proposal status
   * @returns {Promise<{ proposalId: String, debtCapacities: Object[] }>} The proposal identifier and the debt capacities
   */
  static async getByBuyerId(buyerId, status = PROPOSAL_STATUS.PENDING) {
    const { data } = await axios.get(ENDPOINTS.proposal.getByCustomer, {
      params: { customerId: buyerId, status },
    })

    if (data.status !== 200) return

    const { id, debtCapacities } = data.data
    return { proposalId: id, debtCapacities }
  }

  /**
   * @description Get the evaluations of a buyer
   * @param {Number} buyerId The buyer identifier in b2b
   * @param {String} proposalStatus The proposal status
   * @returns {Promise<Object>} The proposal evaluations
   */
  static async getEvaluationsByBuyerId(
    buyerId,
    proposalStatus = PROPOSAL_STATUS.PENDING
  ) {
    const { data } = await axios.get(
      ENDPOINTS.proposal.findEvaluationsByCustomer,
      {
        params: { customerId: buyerId, status: proposalStatus },
      }
    )
    return data
  }
}
