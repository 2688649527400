import { LoadingAnimation } from '@keoworld/gbl-ui-kit'
import PaginatedItems from 'components/pagination'
import { isValid } from 'date-fns'
import { CustomerContext } from 'providers/customer'
import { useContext, useEffect, useState } from 'react'
import { getCustomerHistory } from 'services/customer'
import styled from 'styled-components'
import { dateFormatFromIso } from 'utils/functions/date'
import { ROLE_LABEL } from 'utils/schemas/roles'
import { ONBOARDING_EVENTS_BY_STATE } from 'utils/schemas/workflows/onboarding'

const MAX_ITEMS_PER_PAGE = 5

const profileColors = [
  { backgroundColor: '#35C7C6' },
  { backgroundColor: '#FF9600' },
  { backgroundColor: '#00575B' },
  { backgroundColor: '#FF0045' },
]

export const useFetchHistory = (customer, page) => {
  const [loading, setLoading] = useState(false)
  const [totalRecords, setTotalRecords] = useState(0)
  const [history, setHistory] = useState([])

  useEffect(() => {
    const getHistory = async () => {
      setLoading(true)
      const query = {
        customerId: customer.id,
        page: page + 1,
        numberOfItems: 5,
      }
      const { history, records } = await getCustomerHistory(query, true)

      const historyList = history.map((item) => {
        const { userName, userRole, infoContext, event, createdAt } = item
        const { oldState } = infoContext ?? {}

        const stateData = ONBOARDING_EVENTS_BY_STATE[oldState] ?? {}
        const eventData = stateData[event]

        const data = {
          key: createdAt,
          username: userName,
          userRole,
          event: eventData?.label ?? event,
          createdAt: isValid(new Date(createdAt))
            ? dateFormatFromIso(createdAt)
            : 'N/A',
        }

        return data
      })

      setHistory(historyList)
      setTotalRecords(records)
      setLoading(false)
    }
    if (customer?.id) {
      getHistory()
    }
  }, [customer, page])

  return { history, loading, totalRecords }
}

const CustomerHistory = () => {
  const { customer } = useContext(CustomerContext)
  const [offset, setOffset] = useState(0)
  const { history, loading, totalRecords } = useFetchHistory(customer, offset)

  return (
    <CustomerHistoryStyled>
      <h3>Historial</h3>
      {loading && <LoadingAnimation />}
      {!loading && (
        <table>
          <thead>
            <tr>
              <th />
              <th>Nombre</th>
              <th>Rol</th>
              <th>Acción</th>
              <th>Fecha y hora</th>
            </tr>
          </thead>
          <tbody>
            {history.length === 0 && (
              <tr>
                <td />
                <td colSpan={4}>No hay registros</td>
              </tr>
            )}
            {history.map((item, index) => {
              const { username, userRole, event, createdAt } = item
              return (
                <tr key={`row-${index}`}>
                  <td>
                    <Profile
                      className='profile'
                      profileColor={profileColors[index % profileColors.length]}
                    >
                      {username
                        .split(' ')
                        .reduce((acc, name) => acc + name.charAt(0), '')}
                    </Profile>
                  </td>
                  <td>{username}</td>
                  <td>{ROLE_LABEL[userRole]}</td>
                  <td>{event}</td>
                  <td>{createdAt}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
      {totalRecords > 5 && (
        <div className='pagination'>
          <PaginatedItems
            currentPage={offset}
            handlePageClick={(event) => setOffset(event.selected)}
            pageCount={Math.ceil(totalRecords / MAX_ITEMS_PER_PAGE)}
          />
        </div>
      )}
    </CustomerHistoryStyled>
  )
}

const Profile = styled.div`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  margin: auto;
  text-transform: uppercase;
  color: white;

  ${(props) => props.profileColor}
`

const CustomerHistoryStyled = styled.div`
  padding: 5rem 5%;
  table {
    width: 90%;
    text-align: left;
    caption {
      font-size: 18px;
      text-align: left;
      font-weight: bold;
      margin-bottom: 2rem;
      margin-top: -2rem;
    }
    thead {
      text-align: left;
      font-size: 15px;
    }
    tbody {
      font-size: 13px;
      tr {
        td {
          padding: 12px 0rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .pagination {
    margin: 1rem auto;
    ul {
      margin: auto;
    }
  }
`

export default CustomerHistory
