import { Button, Card } from '@keoworld/gbl-ui-kit'
import { Navigate } from 'react-router-dom'
import styled from 'styled-components'
import { useAuth } from 'utils/hooks/auth'
import Router from 'utils/router'

const Unauthorized = () => {
  const { signOut, isAuthenticated } = useAuth()

  if (!isAuthenticated) return <Navigate to={Router.SignIn} />

  return (
    <UnauthorizedStyled>
      <Card className='card'>
        <img className='logo' src='/android-chrome-512x512.png' alt='logo' />
        <h3>Permisos insuficientes</h3>
        <p>No cuentas con los roles indicados para acceder a esta página</p>
        <Button onClick={signOut}>Cerrar sesión</Button>
      </Card>
    </UnauthorizedStyled>
  )
}

const UnauthorizedStyled = styled.div`
  .card {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 400px;
    height: auto;

    padding: 20px;

    display: flex;
    align-items: center;
    flex-direction: column;

    text-align: center;

    .logo {
      width: 120px;
      margin: 20px auto;
    }
  }
`

export default Unauthorized
