import { useEffect, useState } from 'react'
import { getCustomerLines } from 'services/lines'

export const useFetchLines = (customerId, status) => {
  const [lines, setLines] = useState([])

  useEffect(() => {
    if (customerId) {
      const fetchLine = async () => {
        const { data: lines } = await getCustomerLines(customerId, status)
        setLines(lines)
      }
      fetchLine()
    }
  }, [customerId, status])

  return lines
}
