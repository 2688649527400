import { Button, Icon, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import ModalUpload from 'containers/Modal/modal-documents-upload'
import GeneralComment from 'containers/general-comment'
import LateralBar from 'containers/lateral-bar'
import { AlertContext } from 'providers/alert'
import { CustomerContext } from 'providers/customer'
import { LATERAL_MENU_OPTIONS, LateralBarContext } from 'providers/lateral-bar'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { downloadDocument, getDocumentList } from 'services/documents'
import { instanceTransition } from 'services/workflow'
import styled from 'styled-components'
import { BuildSection } from 'templates/actions/document-actions/build-section'
import { downloadFile } from 'utils/functions/download-file'
import { getUserRoles } from 'utils/functions/role-manager'
import { useAuth } from 'utils/hooks/auth'
import Router from 'utils/router'
import { FILE_STAGES, VISIT_REGISTER } from 'utils/schemas/documents'
import { FormValidationError } from 'utils/schemas/errors'
import { ONBOARDING_STATES } from 'utils/schemas/workflows/onboarding'

const IMAGE_FILE_TYPE_ID = 27
const DOCUMENT_FILE_TYPE_ID = 28

const BuyerVisitReview = () => {
  const [files, setFiles] = useState({})
  const [imageDownload, setImageDownload] = useState([])
  const [fileIdSaved, setFileIdSaved] = useState([])
  const [comment, setComment] = useState('')
  const [openCommentBar, setOpenCommentBar] = useState(false)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [isPublic, setIsPublic] = useState(false)
  const [isValidating, setIsValidating] = useState(false)
  const [openModalUpload, setOpenModalUpload] = useState(false)

  const { customer } = useContext(CustomerContext)
  const { setAlert } = useContext(AlertContext)
  const { setAction, setSelectedOption } = useContext(LateralBarContext)
  const { user } = useAuth()

  const { customerId } = useParams()
  const navigate = useNavigate()

  const [userRole] = getUserRoles()

  const { approveBuyerVisit, rejectBuyerVisit, requestCorrections } =
    ONBOARDING_STATES[customer.wfState].transition

  const handleDownload = (id, name) => {
    try {
      if (id && name) downloadFile(downloadDocument(id), name)
    } catch (err) {
      console.error('error', err, err?.message)
    }
  }

  const validateForm = () => {
    if (!comment) {
      setIsValidating(true)
      throw new FormValidationError('error comment')
    }
  }

  const handleSubmit = async (action) => {
    try {
      validateForm()
      setIsLoadingSubmit(true)

      const { event, nextState, fileStatus, status } = action

      let fileList = fileIdSaved.map((file) => ({
        id: file,
        status: fileStatus,
      }))

      fileList = fileList.concat(
        imageDownload.map(({ id }) => ({
          id,
          status: fileStatus,
        }))
      )

      const eventData = {
        'fileList:multiUpdate': fileList,
        'wfState:status': nextState,
        'id:status': Number(customerId),
        'status:status': status,
        'wfState:comment': customer?.customerInstance?.currentState,
        'uuid:comment': user.uid,
        'role:comment': userRole,
        'comment:comment': comment,
        'isPublic:comment': isPublic,
        'uuid:assignation': user.uid,
        'customerId:assignation': Number(customerId),
        'id:assignation': customer.assignationId,
        'role:assignation': userRole,
      }

      await instanceTransition(
        customer?.customerInstance?.workflowId,
        customerId,
        event,
        eventData
      )

      setAction(undefined)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      navigate(Router.Root)
    } catch (error) {
      const alert = {
        label:
          'Por favor intente nuevamente y si el problema persiste comuníquese con el área de soporte.',
        title: 'Ha ocurrido un error:',
        type: 'error',
      }

      if (error instanceof FormValidationError) {
        alert.label = 'Es necesario ingresar un comentario para continuar.'
      } else {
        console.error(error.message)
      }

      setOpenModalUpload(false)
      setAlert(alert)
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  useEffect(() => {
    const fetchFiles = async () => {
      if (!customerId) return

      const { data: savedFilesData } = await getDocumentList(customerId, {
        stage: FILE_STAGES.VISIT,
      })

      let savedFiles = {}
      savedFilesData.forEach((itm) => {
        const extension = itm.fileExtension ? `.${itm.fileExtension}` : ''
        if (!savedFiles[itm.typeId]) savedFiles[itm.typeId] = []
        savedFiles[itm.typeId].push({
          name: `${itm.fileName}${extension}`,
          size: itm.fileSize,
          status: itm.fileStatus,
          id: itm.fileId,
          typeId: itm.typeId,
        })
      })
      setFiles(savedFiles)
      setFileIdSaved(
        savedFiles[DOCUMENT_FILE_TYPE_ID]?.reduce(
          (prev, acc) => [...prev, acc.id],
          []
        )
      )

      const imageArray = savedFilesData.reduce(
        (
          acc,
          { fileExtension, typeId, fileName, fileStatus, fileSize, fileId }
        ) => {
          if (typeId === IMAGE_FILE_TYPE_ID) {
            const extension = fileExtension ? `.${fileExtension}` : ''
            acc.push({
              name: `${fileName}-${fileId}${extension}`,
              size: fileSize,
              status: fileStatus,
              id: fileId,
            })
          }
          return acc
        },
        []
      )
      setImageDownload(imageArray)
    }

    fetchFiles()
  }, [customerId])

  return (
    <VisitStyled>
      <section>
        <h3>Formulario de visita</h3>
        <div className='form-container'>
          <BuildSection
            allowUpload={false}
            allowReview={false}
            allowDelete={false}
            allowUpdate={false}
            schema={VISIT_REGISTER}
            files={files}
            setFiles={setFiles}
            isValidating={isValidating}
            setCommentBarInfo={setOpenCommentBar}
          />
        </div>
      </section>
      <section>
        <h3>Fotografías</h3>
        <div className='image-flex'>
          {imageDownload
            .filter((itm) => Boolean(itm.id !== 0))
            .map((itm, idx) => (
              <div className='image-download' key={`img-${idx}`}>
                <Icon
                  name='file_download'
                  type='outlined'
                  onClick={() => {
                    handleDownload(itm?.id, itm?.name)
                  }}
                />
                <p>{itm?.name}</p>
              </div>
            ))}
        </div>
      </section>

      <GeneralComment
        comment={comment}
        setComment={setComment}
        setIsPublic={setIsPublic}
        error={!comment && isValidating}
      />

      <section className='action-group'>
        <Button
          onClick={() => {
            navigate(-1)
            setAction(undefined)
          }}
          disabled={isLoadingSubmit}
        >
          {isLoadingSubmit && <LoadingAnimation />}
          {!isLoadingSubmit && <span>Regresar</span>}
        </Button>
        <div>
          <Button
            buttonType='outlineBlueDark'
            onClick={() => handleSubmit(requestCorrections)}
            disabled={isLoadingSubmit}
          >
            {isLoadingSubmit && <LoadingAnimation />}
            {!isLoadingSubmit && <span>Solicitar correcciones</span>}
          </Button>
          <Button
            buttonType='outline'
            onClick={() => setOpenModalUpload(true)}
            disabled={isLoadingSubmit}
          >
            Rechazar
          </Button>
          <Button
            buttonType='blueDark'
            className='send'
            disabled={isLoadingSubmit}
            onClick={() => handleSubmit(approveBuyerVisit)}
          >
            Aprobar
          </Button>
        </div>
      </section>

      {openCommentBar && (
        <LateralBar
          setLateralBar={setOpenCommentBar}
          fileId={openCommentBar?.fileId}
        />
      )}
      {openModalUpload && (
        <ModalUpload
          setOpenModalUpload={setOpenModalUpload}
          messages={{
            msg: '¿Está seguro que desea rechazar la propuesta?',
            msgButton: 'Confirmar',
          }}
          handleSave={() => handleSubmit(rejectBuyerVisit)}
          isLoadingSubmit={isLoadingSubmit}
        />
      )}
    </VisitStyled>
  )
}

const VisitStyled = styled.section`
  padding-block: 2rem;
  padding-inline: 3rem;

  .loading span {
    background-color: white;
  }

  section {
    margin-block: 2rem;
  }

  .image-flex {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    margin: 1rem auto;
    .image-download {
      width: 150px;
      height: auto;
      border: 1px solid;
      border-radius: 10px;
      padding: 5px;
      margin-top: 1rem;
      span {
        position: relative;
        top: 0px;
        left: 80%;
        cursor: pointer;
      }
      p {
        text-align: center;
        font-size: 12px;
        font-weight: 600;
        margin-top: 1px;
      }
    }
  }

  .image-container {
    display: flex;
    justify-content: center;
    margin: 2rem 1rem;

    & > * {
      margin: 1rem;
    }
  }

  .image-viewer {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 40%;

    span {
      cursor: pointer;
    }
  }

  .image-tile {
    width: 100px;
    height: 100px;
    background-color: grey;
    border-radius: 10px;
    margin: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;
  }

  .action-group {
    /* margin: 2rem 0 0; */

    display: flex;
    justify-content: space-between;

    div {
      display: flex;
      gap: 1rem;
    }
  }
`

export default BuyerVisitReview
