import { useEffect, useState } from 'react'

/**
 * hook to debounce a value
 * @param {*} value - value to be debounced
 * @param {number} delay - delay in milliseconds
 * @returns
 */
export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [delay, value])

  return debouncedValue
}
