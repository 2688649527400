import { LoadingAnimation } from '@keoworld/gbl-ui-kit'
import CustomerDetailHeader from 'containers/prospect-detail/header'
import { CustomerContext, CUSTOMER_DETAIL_SECTIONS } from 'providers/customer'
import { useContext, useEffect } from 'react'
import { getCustomerAssignations, getCustomerInfo } from 'services/customer'
import { getShareholderInfo } from 'services/shareholder'
import { getCustomerInstance, getLineInstance } from 'services/workflow'
import styled from 'styled-components'
import CompleteInformation from 'templates/actions/hubspot/complete-information'
import CustomerCommercial from 'templates/precustomer-detail/tabs/customer-commercial'
import CustomerDocuments from 'templates/precustomer-detail/tabs/customer-documents'
import History from 'templates/precustomer-detail/tabs/customer-history'
import CustomerInformation from 'templates/precustomer-detail/tabs/customer-information'
import CustomerComment from './tabs/customer-comment'
import InformationOffer from 'templates/information-offert'

const HUBSPOT_STATES = ['cust_prospectAssignation', 'cust_pendingInfo']

const PrecustomerDetail = () => {
  const { customer, currentSection, setCustomer } = useContext(CustomerContext)
  const isHubspotOnboarding = HUBSPOT_STATES.includes(customer.wfState)

  const Content = {
    [CUSTOMER_DETAIL_SECTIONS.INFORMATION]: isHubspotOnboarding
      ? CompleteInformation
      : CustomerInformation,
    [CUSTOMER_DETAIL_SECTIONS.DOCUMENTS]: CustomerDocuments,
    [CUSTOMER_DETAIL_SECTIONS.HISTORY]: History,
    [CUSTOMER_DETAIL_SECTIONS.COMERCIAL_ACTIVITY]: CustomerCommercial,
    [CUSTOMER_DETAIL_SECTIONS.GENERAL_COMMENTS]: CustomerComment,
    [CUSTOMER_DETAIL_SECTIONS.INFORMATION_OFFER]: InformationOffer,
  }

  const Loader = () => (
    <CustomerDetailStyled>
      <div className='center-loading'>
        <LoadingAnimation />
      </div>
    </CustomerDetailStyled>
  )

  const Component = Content[currentSection] ?? Loader

  useEffect(() => {
    const fetchInstance = async () => {
      try {
        let type
        let customerInstance = {}
        let lineInstance = {}

        const { body: customerBody } = await getCustomerInstance(customer?.id)
        customerInstance = customerBody.instance

        if (customer?.lineId) {
          const { body: lineBody } = await getLineInstance(
            customer?.lineId,
            customer?.id
          )
          lineInstance = lineBody.instance
        }

        const { data: customer_data } = await getCustomerInfo(customer?.id)
        if (customer_data?.shareholderId) {
          const { data: shareholder_data } = await getShareholderInfo(
            customer_data?.shareholderId
          )
          type = shareholder_data.type
        }

        setCustomer({
          ...customer,
          ...customer_data,
          customerInstance,
          lineInstance,
          type,
        })
      } catch (err) {
        console.error('fetch error', err?.message, err)
      }
    }
    const fetchAssignations = async () => {
      const assignations = await getCustomerAssignations(customer?.id)
      setCustomer((prev) => ({
        ...prev,
        assignations,
      }))
    }
    if (!customer?.customerInstance?.currentState) fetchInstance()
    if (!customer?.assignations) fetchAssignations()
  }, [customer, setCustomer])

  return (
    <CustomerDetailStyled>
      <CustomerDetailHeader />
      <Component />
    </CustomerDetailStyled>
  )
}

const CustomerDetailStyled = styled.div`
  .center-loading {
    height: 60vh;
    display: flex;
    align-items: center;
    div {
      width: 150px;
    }
  }
`

export default PrecustomerDetail
