import { Button, Textarea } from '@keoworld/gbl-ui-kit'
import { Skeleton } from '@mui/material'
import Form from 'containers/form'
import LateralBar from 'containers/lateral-bar'
import { AlertContext } from 'providers/alert'
import { CustomerContext } from 'providers/customer'
import { LATERAL_MENU_OPTIONS, LateralBarContext } from 'providers/lateral-bar'
import { useContext, useEffect, useState } from 'react'
import { createCustomerComment } from 'services/customer'
import {
  ApiDebtCapacityRepository,
  PROPOSAL_STATUS,
} from 'services/debt-capacity-suppcards/debtCapacity.repository'
import styled from 'styled-components'
import { BuildSection } from 'templates/actions/document-actions/build-section'
import { currency } from 'utils/functions/formatters'
import * as CurrencyFormatter from 'utils/functions/handlers-currency'
import { extractNumber } from 'utils/functions/handlers-currency'
import { useAuth } from 'utils/hooks/auth'
import { useFetchDocuments } from 'utils/hooks/fetch-documents'
import { useFetchFinance } from 'utils/hooks/fetch-finance'
import { CREDIT_STUDY_HEAD, CUSTOMER_STAGES } from 'utils/schemas/customer'
import { FILE_STAGES } from 'utils/schemas/documents'
import { FormValidationError } from 'utils/schemas/errors'
import { CHANGE_SCORE_SCHEMA, FINANCIAL_INDICATORS } from 'utils/schemas/line'
import { dependentRoles } from 'utils/schemas/roles'

const FILE_FILTER = { typeId: 29, stage: FILE_STAGES.CREDIT }
const HEAD = CREDIT_STUDY_HEAD.map((item) => ({ ...item, disabled: true }))

const OPERATION_ROLE = 'bpm.mx-amex-b2b.ope-hd'
const UNDERWRITING_ROLE = 'bpm.mx-amex-b2b.rsk-hd'

const ALLOWED_ROLES = {
  operation: dependentRoles[OPERATION_ROLE],
  underwriting: dependentRoles[UNDERWRITING_ROLE],
}

const ERRORS = {
  DEBT_CAPACITIES_MISSING: {
    key: 'DEBT_CAPACITIES_MISSING',
    label: 'Por favor ingrese la capacidad de endeudamiento sugerida',
  },
  COMMENT_MISSING: {
    key: 'COMMENT_MISSING',
    label: 'Por favor ingrese un comentario',
  },
  SCORE_USED_VALIDATION: {
    key: 'SCORE_USED_VALIDATION',
    label: 'El score no puede ser menor al monto usado por el customer',
  },
}

const schema = (isReview, isAllowed) => {
  const [firstField, ...otherFields] = CHANGE_SCORE_SCHEMA

  if (!isAllowed) {
    return [firstField]
  }

  if (!isReview && isAllowed) {
    return [{ ...firstField, disabled: false }]
  }

  return [
    firstField,
    ...otherFields.map((field) => ({ ...field, disabled: false })),
  ]
}

const ChangeScore = () => {
  const { user } = useAuth()
  const { customer } = useContext(CustomerContext)
  const { setAlert } = useContext(AlertContext)
  const { setSelectedOption } = useContext(LateralBarContext)

  const [openCommentBar, setOpenCommentBar] = useState(undefined)
  const [proposalPending, setProposalPending] = useState(undefined)
  const [proposalApproved, setProposalApproved] = useState(undefined)
  const [isFetchingData, setIsFetchingData] = useState(true)
  const [validatingForm, setValidatingForm] = useState(false)
  const [isAllowedAction, setIsAllowedAction] = useState(false)
  const [isReview, setIsReview] = useState(false)
  const [loading, setLoading] = useState(false)
  const [documents, setDocuments] = useState({})
  const [formValues, setFormValues] = useState({})
  const [formErrors, setFormErrors] = useState({})
  const [financialForm, setFinancialForm] = useState({})

  const financeInformation = useFetchFinance()
  const financeAnalysis = useFetchDocuments(customer?.id, FILE_FILTER)

  const validateForm = () => {
    const formError = Object.entries(formErrors).find(([, value]) => value)

    if (formError) {
      throw new FormValidationError(
        ERRORS.DEBT_CAPACITIES_MISSING.label,
        ERRORS.DEBT_CAPACITIES_MISSING.key
      )
    }

    if (!formValues.comments) {
      throw new FormValidationError(
        ERRORS.COMMENT_MISSING.label,
        ERRORS.COMMENT_MISSING.key
      )
    }

    const scoreProposed = extractNumber(formValues.scoreProposed)
    const usedAmount = extractNumber(customer.usedAmount)
    const score = extractNumber(formValues.score)

    if (
      (!isReview && scoreProposed < usedAmount) ||
      (isReview && score < usedAmount)
    ) {
      throw new FormValidationError(
        ERRORS.SCORE_USED_VALIDATION.label,
        ERRORS.SCORE_USED_VALIDATION.key
      )
    }
  }

  const onSubmit = async () => {
    try {
      setLoading(true)
      setValidatingForm(true)

      validateForm()

      const [role] = user.iam.roles

      if (!isReview) {
        const newScore = extractNumber(formValues.scoreProposed)
        await ApiDebtCapacityRepository.create({
          customerId: customer.id,
          debtCapacities: [
            {
              currency: 'MXN',
              amount: newScore,
            },
          ],
        })
      } else {
        const { proposalId, debtCapacities } = proposalPending
        const [debtCapacity] = debtCapacities
        const newScore = extractNumber(formValues.score)

        await ApiDebtCapacityRepository.update(
          proposalId,
          debtCapacity.id,
          newScore
        )

        await ApiDebtCapacityRepository.approve(proposalId)
      }

      await createCustomerComment({
        comment: formValues.comments,
        isPublic: true,
        role,
        customerId: financeInformation.customerId,
        uuid: user.uid,
        wfState: CUSTOMER_STAGES.setup.id,
      })

      setAlert({
        type: 'success',
        title: 'Éxito',
        label: !isReview
          ? `Propuesta de capacidad de endeudamiento enviada correctamente,
            debe ser aprobada por el área de riesgo (Underwriting)`
          : `Capacidad de endeudamiento actualizada correctamente`,
      })
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_CUSTOMERS)
    } catch (error) {
      const alert = {
        type: 'error',
        title: 'Error',
        label: `Error al actualizar la capacidad de endeudamiento. Intente de nuevo.`,
      }
      if (error instanceof FormValidationError) {
        const key = error.fields
        alert.label = ERRORS[key]?.label ?? alert.label
      }
      setAlert(alert)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (financeInformation?.id) {
      const formattedObject = FINANCIAL_INDICATORS.reduce((acc, curr) => {
        const { name, validatorType } = curr
        let value = financeInformation[name]
        if (validatorType === 'currency') {
          value = currency(value).replace('$', '')
        }
        return { ...acc, [name]: value }
      }, {})

      setFinancialForm(formattedObject)
    }
  }, [financeInformation])

  useEffect(() => {
    const files = financeAnalysis.reduce((acc, file) => {
      const docs = acc[file.typeId] || []
      return {
        ...acc,
        [file.typeId]: [
          ...docs,
          {
            label: file.fileName,
            name: `${file.fileName}.${file.fileExtension}`,
            size: file.fileSize,
            status: file.fileStatus,
            id: file.fileId,
            extension: file.fileExtension,
            typeId: file.typeId,
          },
        ],
      }
    }, {})

    setDocuments(files)
  }, [financeAnalysis])

  useEffect(() => {
    const obtainProposals = async () => {
      let isReview = false
      let allowedRoles = ALLOWED_ROLES.operation

      setIsFetchingData(true)

      const proposalApproved = await ApiDebtCapacityRepository.getByBuyerId(
        customer.id,
        PROPOSAL_STATUS.APPROVED
      )

      const proposalPending = await ApiDebtCapacityRepository.getByBuyerId(
        customer.id,
        PROPOSAL_STATUS.PENDING
      )

      const changeDebtCapacityForm = {
        scoreProposed: 0,
        score: 0,
      }

      if (proposalApproved) {
        const debtCapacityApproved = proposalApproved.debtCapacities.find(
          (debtCapacity) => debtCapacity.currency === 'MXN'
        )

        changeDebtCapacityForm.score = CurrencyFormatter.formatCurrency(
          debtCapacityApproved.amount
        )

        setProposalApproved(proposalApproved)
      }

      if (proposalPending) {
        const debtCapacityProposed = proposalPending.debtCapacities.find(
          (debtCapacity) => debtCapacity.currency === 'MXN'
        )

        changeDebtCapacityForm.scoreProposed = CurrencyFormatter.formatCurrency(
          debtCapacityProposed.amount
        )

        setProposalPending(proposalPending)
        isReview = true
        allowedRoles = ALLOWED_ROLES.underwriting
      }

      const [userRole] = user.iam.roles

      setIsAllowedAction(allowedRoles.includes(userRole))
      setIsReview(isReview)
      setFormValues(changeDebtCapacityForm)
      setIsFetchingData(false)
    }

    if (customer?.id) {
      obtainProposals()
    }
  }, [customer?.id, user?.iam?.roles])

  return (
    <StyledView>
      <h3>Datos financieros</h3>
      <h5>Indicadores financieros</h5>
      <form className='finance-head'>
        <Form
          className='finance-head'
          schema={HEAD}
          formValues={financeInformation}
          isValidating={false}
        />
      </form>
      <form>
        <Form
          schema={FINANCIAL_INDICATORS}
          formValues={financialForm}
          isValidating={false}
        />
      </form>
      <BuildSection
        title='Análisis financiero'
        allowComment
        allowUpload={false}
        files={documents}
        schema={[{ label: 'Análisis financiero', typeId: 29 }]}
        setCommentBarInfo={setOpenCommentBar}
      />

      {isFetchingData && (
        <Skeleton variant='rounded' width={800} height={400} />
      )}
      {!isFetchingData && (
        <section>
          <h4>
            Capacidad de endeudamiento actual:{' '}
            {proposalApproved &&
              currency(proposalApproved.debtCapacities[0].amount)}
          </h4>

          <form>
            <Form
              schema={schema(isReview, isAllowedAction)}
              formValues={formValues}
              setFormValues={setFormValues}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              isValidating={validatingForm}
            />
          </form>
          <form className='form-score'>
            <Textarea
              className='comments'
              name='comments'
              label='Comentarios'
              disabled={!isAllowedAction}
              onChange={({ target }) => {
                setFormValues({ ...formValues, comments: target.value })
              }}
              error={validatingForm && !formValues.comments}
            />
          </form>

          {isAllowedAction && (
            <Button buttonType='blueDark' onClick={onSubmit} disabled={loading}>
              Enviar
            </Button>
          )}
        </section>
      )}

      {openCommentBar && (
        <LateralBar
          fileId={openCommentBar.fileId}
          documentName={openCommentBar.fileName}
          setLateralBar={setOpenCommentBar}
        />
      )}
    </StyledView>
  )
}

const StyledView = styled.section`
  padding: 3rem;

  h5,
  h4 {
    margin-top: 1rem;
  }

  .form-score {
    margin-top: 1em;
  }

  .finance-head {
    grid-template-columns: 1fr 1fr 1fr;
    background-color: #f6f8fc;
    border-radius: 9px;
    padding: 1rem;
    margin: 1rem 0;
    input {
      background-color: transparent;
    }
  }

  .document-container {
    margin: 2rem 0;
  }

  form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0.5rem;
    .comments {
      grid-column: 1 / span 2;
    }
  }

  .actions {
    margin-top: 2rem;
    button + button {
      margin-left: 0.5rem;
    }
  }
`

export default ChangeScore
