import CustomerDetailHeader from 'containers/buyer/detail/header/header'
import { CUSTOMER_DETAIL_SECTIONS, CustomerContext } from 'providers/customer'
import { getCustomerInfo } from 'services/customer'
import { getShareholderInfo } from 'services/shareholder'
import { getOnboardingInstance } from 'services/workflow'
import CustomerCommercial from 'templates/buyer-detail/tabs/customer-commercial'
import CustomerDocuments from 'templates/buyer-detail/tabs/customer-documents'
import History from 'templates/buyer-detail/tabs/customer-history'
import CustomerInformation from 'templates/buyer-detail/tabs/customer-information'
import CustomerComment from 'templates/buyer-detail/tabs/customer-comment'

import { LoadingAnimation } from '@keoworld/gbl-ui-kit'
import { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

const Content = {
  [CUSTOMER_DETAIL_SECTIONS.INFORMATION]: CustomerInformation,
  [CUSTOMER_DETAIL_SECTIONS.DOCUMENTS]: CustomerDocuments,
  [CUSTOMER_DETAIL_SECTIONS.HISTORY]: History,
  [CUSTOMER_DETAIL_SECTIONS.COMERCIAL_ACTIVITY]: CustomerCommercial,
  [CUSTOMER_DETAIL_SECTIONS.GENERAL_COMMENTS]: CustomerComment,
}

const Loader = () => (
  <CustomerDetailStyled>
    <div className='center-loading'>
      <LoadingAnimation />
    </div>
  </CustomerDetailStyled>
)

const BuyerDetail = () => {
  const { customerId } = useParams()
  const { currentSection, setCustomer } = useContext(CustomerContext)

  const Component = Content[currentSection] ?? Loader

  useEffect(() => {
    const fetchInstance = async () => {
      try {
        let type

        const { body: customerBody } = await getOnboardingInstance(customerId)
        const customerInstance = customerBody.instance

        const { data: customer_data } = await getCustomerInfo(customerId)
        if (customer_data?.shareholderId) {
          const { data: shareholder_data } = await getShareholderInfo(
            customer_data?.shareholderId
          )
          type = shareholder_data.type
        }

        setCustomer( prev => ({
          ...prev,
          ...customer_data,
          customerInstance,
          lineInstance: {},
          type,
        }))
      } catch (err) {
        console.error('fetch error', err?.message, err)
      }
    }
    // const fetchAssignations = async () => {
    //   const assignations = await getCustomerAssignations(customer?.id)
    //   setCustomer((prev) => ({
    //     ...prev,
    //     assignations,
    //   }))
    // }
    if (customerId) fetchInstance()
    // if (!customer?.assignations) fetchAssignations()
  }, [customerId, setCustomer])

  return (
    <CustomerDetailStyled>
      <CustomerDetailHeader />
      <Component />
    </CustomerDetailStyled>
  )
}

const CustomerDetailStyled = styled.div`
  overflow-y: scroll;
  height: calc(100vh - 70px);
  .center-loading {
    display: flex;
    align-items: center;
    div {
      width: 150px;
    }
  }
`

export default BuyerDetail
