import { gaussianRounding } from './gaussian-rounding'

export const calculateLineConditions = (line) => {
  const { score, maxTerm, terms = [] } = line

  let dailyReturn = 0
  let annualReturn = 0
  let termsConfig = []
  let totalOrdinaryTerm = 0

  if (score && maxTerm > 0) {
    const ordinary = terms.filter(({ delinquency }) => !delinquency)
    totalOrdinaryTerm = ordinary.reduce(
      (acc, curr) => acc + Number(curr.term ?? 0),
      0
    )

    termsConfig = terms.map((period, index) => {
      let { rate, term, typeId, delinquency } = period
      typeId = Number(typeId)
      let annualRate = 0

      const currentTerm = delinquency ? Number(totalOrdinaryTerm) + 1 : term

      if (rate && currentTerm && typeId) {
        if (typeId === 1 || typeId === 2) {
          annualRate = rate * 0.01 * (360 / currentTerm)
        } else {
          annualRate = rate * 0.01 * 360
        }
      }

      const lastTerm = terms
        .slice(0, index)
        .reduce((acc, curr) => acc + Number(curr.term), 0)

      const from = lastTerm + 1
      const to = !period.delinquency ? lastTerm + Number(term) : Infinity

      return { annualRate, delinquency, range: [from, to] }
    })

    const ordinaryInterest = ordinary.reduce((acc, period) => {
      const { rate, typeId, term } = period
      let periodInterestLastDay = 0
      if (typeId === 1 || typeId === 2) {
        periodInterestLastDay = rate * score * 0.01
      } else {
        periodInterestLastDay = rate * term * score * 0.01
      }
      return acc + periodInterestLastDay
    }, 0)

    const operationProfitability = ordinaryInterest / score
    dailyReturn = operationProfitability / totalOrdinaryTerm
    annualReturn = gaussianRounding(dailyReturn * 360, 6)
  }

  return {
    dailyReturn,
    annualReturn,
    termsConfig,
    totalOrdinaryTerm,
  }
}
