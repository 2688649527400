import ModalContent from 'containers/Modal/modal-content'
import { createContext, useState } from 'react'

const context = {
  openModal: '',
  setOpenModal: undefined,
  setActions: undefined,
  setOnClose: () => {}
}

export const modalOptions = {
  CreateCustomer: 'create',
  SendEvaluation: 'evaluation',
  SaveInformation: 'save',
  SendInformation: 'send',
  Reject: 'notApproved',
  CancelAction: 'cancel',
  CommercialAction: 'commercial',
  Continue: 'continue',
  Reassessment: 'reassessment' 
}

export const ModalContext = createContext(context)

export const ModalProvider = ({ children }) => {
  const [openModal, setOpenModal] = useState(context.openModal)
  const [actions, setActions] = useState([])
  const [modalId, setModalId] = useState()
  const [onClose, setOnClose] = useState(context.onClose)

  return (
    <ModalContext.Provider
      value={{
        openModal,
        setOpenModal,
        setActions,
        modalId,
        setModalId,
        setOnClose
      }}
    >
      {children}
      {openModal && (
        <ModalContent
          actions={actions}
          openModal={openModal}
          setOpenModal={setOpenModal}
          modalId={modalId}
          setModalId={setModalId}
          setActions={setActions}
          onClose={onClose}
        />
      )}
    </ModalContext.Provider>
  )
}
