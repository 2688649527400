import { Textarea } from '@keoworld/gbl-ui-kit'
import styled from 'styled-components'

const GeneralComment = (props) => {
  const { className, comment, setComment, isPublic, setIsPublic, error } = props

  return (
    <StyledComment className={className}>
      <Textarea
        className='comment-textarea'
        label='Comentario'
        onChange={(e) => setComment(e.target.value)}
        value={comment}
        error={Boolean(error)}
        rows={4}
      />
      <div className='switch-content'>
        <p className='comment'>
          <b>Tipo de comentario:</b>
        </p>
        <label className='switch-div'>
          <p>Interno</p>
          <label className='switch'>
            <input
              type='checkbox'
              onChange={(e) => {
                setIsPublic(e.target.checked)
              }}
              checked={isPublic}
            />
            <span className='slider round' />
          </label>
          <p> General</p>
        </label>
      </div>
    </StyledComment>
  )
}

const StyledComment = styled.section`
  display: flex;
  gap: 1rem;

  .switch-content {
    .comment {
      line-height: 0px;
      margin: 1rem;
    }
  }
  .switch-div {
    display: flex;
    margin: 0rem 1rem;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin: 0.5rem 0rem 0.5rem 0.5rem;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 8px;
    left: 0px;
    right: 14px;
    bottom: 11px;
    background-color: #939191;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 22px;
    width: 22px;
    left: -1px;
    bottom: -3.5px;
    background-color: #bab9b9;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #939191;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .comment-textarea {
    width: 60%;
  }
`

export default GeneralComment
