import { AlertMessage, Button } from '@keoworld/gbl-ui-kit'
import CustomerForm from 'containers/customer-form'
import { CustomerContext } from 'providers/customer'
import { LateralBarContext, LATERAL_MENU_OPTIONS } from 'providers/lateral-bar'
import { useContext, useEffect, useState } from 'react'
import {
  getAgentInfo,
  getCustomerInfo,
  getLocationInfo,
  getUserInfo,
} from 'services/customer'
import {
  createAgent,
  createBusiness,
  createLocation,
  createPerson,
  getShareholderInfo,
} from 'services/shareholder'
import { instanceTransition } from 'services/workflow'
import styled from 'styled-components'
import { getUserRoles } from 'utils/functions/role-manager'
import { useAuth } from 'utils/hooks/auth'
import {
  CUSTOMER_DB_STATUS,
  CUSTOMER_STATES,
  initialCustomerForm,
  SHAREHOLDER_TYPES,
} from 'utils/schemas/customer'

const CompleteInformation = () => {
  const { user } = useAuth()
  const { customer } = useContext(CustomerContext)
  const { setSelectedOption } = useContext(LateralBarContext)
  const [customerInformation, setCustomerInformation] =
    useState(initialCustomerForm)
  const [customerFormErrors, setCustomerFormErrors] =
    useState(initialCustomerForm)
  const [isValidatingForm, setIsValidatingForm] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showAlert, setShowAlert] = useState(true)

  const validateForms = () => {
    const customerType = customerInformation.customer?.personType
    const errors = customerFormErrors

    if (customerType === 'moralPerson') {
      errors.sh_person = undefined
    } else if (customerType === 'naturalPerson') {
      errors.sh_business = undefined
      errors.sh_agent = undefined
    }

    const isThereError = Object.values(errors).find(
      (section) =>
        typeof section === 'object' &&
        Object.entries(section).find(
          ([key, fieldValue]) => key !== 'personType' && fieldValue
        )
    )

    return Boolean(isThereError)
  }

  const onHandleSubmit = async () => {
    try {
      setIsValidatingForm(true)
      setLoading(true)

      if (!customer) return
      if (validateForms()) return

      const customerType = customerInformation.customer.personType
      const { instanceId, workflowId } = customer.customerInstance
      const { transition } = CUSTOMER_STATES.cust_pendingInfo
      const { event, nextState } = transition.COMPLETE_INFORMATION

      /* shareholder location */
      const { data: location_data } = await createLocation(
        customerInformation.sh_location
      )
      const { locationId } = location_data
      let shareholderId

      if (customerType === 'moralPerson' && locationId) {
        /* agent creation */
        const { data: agent_data } = await createAgent({
          ...customerInformation.sh_agent,
          areaCode: '',
          phone: '',
        })
        /* business creation */
        const { data: business_data } = await createBusiness(
          customerInformation.sh_business,
          agent_data[0].fn_create_agent,
          locationId
        )
        shareholderId = business_data[0].fn_create_business
      } else if (customerType === 'naturalPerson' && locationId) {
        /* person creation */
        const { data: person_data } = await createPerson(
          customerInformation.sh_person,
          locationId
        )
        shareholderId = person_data[0].fn_create_person
      }

      if (shareholderId) {
        const body = {
          ...customerInformation.customer,
          'id:location': customerInformation.customer['locationId:customer'],
          'id:agent': customerInformation.customer['agentId:customer'],
          'id:user': customerInformation.customer['userId:customer'],
          'id:customer': customer.id,
          'position:user': process.env.REACT_APP_USER_POSITION,
          'authorizedBy:user': process.env.REACT_APP_USER_AUTHORIZED_BY,
          'wfState:customer': nextState,
          'status:customer': CUSTOMER_DB_STATUS.PROSPECT,
          'uuid:assignation': user.uid,
          'role:assignation': getUserRoles()[0],
          'shareholderId:customer': shareholderId,
        }
        await instanceTransition(workflowId, instanceId, event, body)
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const mapFormData = (data, form_type) => {
      const user_data = {}
      Object.entries(data).forEach(([key, value]) => {
        user_data[`${key}:${form_type}`] = value
      })
      return user_data
    }

    const fetchCustomerInfo = async () => {
      try {
        let customerData = {}
        /* fetch customer data */
        const { data: customer_data } = await getCustomerInfo(customer?.id)
        const { agentId, locationId, shareholderId, userId } = customer_data

        customerData = {
          ...customerData,
          ...mapFormData(customer_data, 'customer'),
          personType: 'naturalPerson',
          satIndustriesFilter: customer_data['satIndustries'],
        }
        if (userId) {
          const { data: user_data } = await getUserInfo(userId)
          customerData = {
            ...customerData,
            ...mapFormData(user_data, 'user'),
          }
        }
        if (agentId) {
          const { data: agent_data } = await getAgentInfo(agentId)
          customerData = {
            ...customerData,
            ...mapFormData(agent_data, 'agent'),
          }
        }
        if (locationId) {
          const { data: location_data } = await getLocationInfo(locationId)
          customerData = {
            ...customerData,
            ...mapFormData(location_data, 'location'),
          }
        }
        if (shareholderId) {
          /* fetch shareholder data */
          const { data: sh_data } = await getShareholderInfo(shareholderId)
          const shType = sh_data?.type

          const uiPersonType =
            shType === SHAREHOLDER_TYPES.PERSON
              ? 'naturalPerson'
              : 'moralPerson'

          customerData = {
            ...customerData,
            personType: uiPersonType,
          }
        }

        customer_data.satIndustries =
          customer_data.satIndustries.split(' - ')[0]

        setCustomerInformation((prev) => ({
          ...prev,
          customer: customerData,
        }))
      } catch (err) {
        // setError((prev) => ({ ...prev, fetch: err?.message ?? '' }))
        console.error('fetchError', err?.message, err)
      }
      setLoading(false)
    }
    fetchCustomerInfo()
  }, [customer])

  return (
    <Styles>
      {showAlert && (
        <AlertMessage
          type='info'
          label='Complete la información antes de continuar'
          onClick={() => setShowAlert(false)}
        />
      )}
      <CustomerForm
        formValues={customerInformation}
        setFormValues={setCustomerInformation}
        formErrors={customerFormErrors}
        setFormErrors={setCustomerFormErrors}
        isValidating={isValidatingForm}
        isBlocked={
          loading ||
          customer.wfState === 'cust_prospectAssignation' ||
          !isAssigned(customer?.assignations, user?.iam?.id)
        }
        createProspect
      />
      {customer.wfState !== 'cust_prospectAssignation' &&
        isAssigned(customer?.assignations, user?.iam?.id) && (
          <Button className='action' onClick={onHandleSubmit}>
            Enviar
          </Button>
        )}
    </Styles>
  )
}

const isAssigned = (assignations, userId) => {
  const commercialAssignation = assignations?.find(
    (itm) =>
      itm?.role === 'bpm.mx-amex-b2b.com-hd' ||
      itm?.role === 'bpm.mx-amex-b2b.com-ej'
  )
  return (
    commercialAssignation && userId && commercialAssignation?.iamUid === userId
  )
}

const Styles = styled.div`
  padding: 3rem;

  .action {
  }
`

export default CompleteInformation
