import { Button, Select, Switch } from '@keoworld/gbl-ui-kit'
import axios from 'axios'
import Form from 'containers/form'
import { AlertContext } from 'providers/alert'
import { CustomerContext } from 'providers/customer'
import { useContext, useEffect, useMemo, useState } from 'react'
import { RelationRepository } from 'services/relation-supp-cards/relation.repository'
import { getCategoryList, getProviders } from 'services/relations'
import styled from 'styled-components'
import { PROVIDER_STATUS } from 'utils/constants'
import { currency } from 'utils/functions/formatters'
import { CUSTOMER_SUPPLIER_SOURCES } from 'utils/schemas/customer'
import {
  RELATION_TS_CALLAO_SCHEMA,
  RELATION_TS_SCHEMA,
} from 'utils/schemas/relations'
import { v4 as UUIDv4 } from 'uuid'

const initialForm = {
  days: '',
  dueFromApproval: '',
  percentageReceiver: '',
  providerId: '',
  source: CUSTOMER_SUPPLIER_SOURCES.KEO,
  paymentMethodId: '',
  cardProgramName: '',
}

const CreateRelationSupplementaryCard = ({
  line,
  closeLateral,
  fetchRelations,
}) => {
  const { setAlert } = useContext(AlertContext)
  const { customer } = useContext(CustomerContext)
  const [bppSelector, setBppSelector] = useState(false)
  const [cardPrograms, setCardPrograms] = useState([])
  const [suppliers, setSuppliers] = useState([])
  const [formError, setFormError] = useState({})
  const [validating, setValidating] = useState(false)
  const [loading, setLoading] = useState(false)
  const [relationFormSchema, setRelationFormSchema] =
    useState(RELATION_TS_SCHEMA)
  const [paymentMethods, setPaymentMethods] = useState([])
  const [relation, setRelation] = useState({
    ...initialForm,
    lineId: `${line.lineId}`,
  })

  const initialFormSchema = useMemo(
    () =>
      getFormSchemaByCustomerSource(
        RELATION_TS_SCHEMA,
        customer?.tradePlatform
      ),
    [customer?.tradePlatform]
  )

  const getCommerceCodes = (providerId) => {
    const commerceCodes = suppliers.find(
      (provider) => provider.id === Number(providerId)
    )?.commerceCodes
    return commerceCodes || []
  }

  const onHandleChange = (e) => {
    const { name, value } = e.target
    if (name === 'providerId') {
      relation.commerceCode = ''
    }
    setRelation((prev) => ({ ...prev, [name]: value }))
  }

  const onHandleSubmit = async () => {
    try {
      setLoading(true)
      setValidating(true)

      const supplierSelected = suppliers.find(
        (supplier) => Number(relation.providerId) === supplier.id
      )

      const newRelation = {
        source: relation.source,
        id: UUIDv4(),
        supplementaryCardId: UUIDv4(),
        lineId: line.externalId,
        paymentMethodId: relation.paymentMethodId,
        supplierId: supplierSelected?.externalProviderId,
        supplierCommerceCode: relation.commerceCode,
        buyerId: customer.externalId,
        cardProgramName: relation.cardProgramName,
        days: relation.days !== '' ? relation.days : undefined,
        dueFromApproval:
          relation.dueFromApproval !== ''
            ? Number(relation.dueFromApproval)
            : undefined,
        percentageReceiver:
          relation.percentageReceiver !== ''
            ? relation.percentageReceiver
            : undefined,
        isBpp: bppSelector,
      }

      const error =
        Object.entries(formError).find(([, value]) => value) ||
        !newRelation.lineId ||
        !newRelation.cardProgramName ||
        !newRelation.supplierCommerceCode ||
        !newRelation.buyerId

      if (!error && relation.source === CUSTOMER_SUPPLIER_SOURCES.CALLAO) {
        await RelationRepository.createCallaoRelation(newRelation)
      }
      if (!error && relation.source === CUSTOMER_SUPPLIER_SOURCES.KEO) {
        await RelationRepository.createKeoRelation(newRelation)
      }
      if (!error) {
        fetchRelations(line.lineId)
        closeLateral()
      }
    } catch (error) {
      const alert = {
        /* TODO: Handle Error messaging that must come from the services */
        default: 'Ocurrió un error al crear la relación',
      }
      const existsError = 'The relation exists with the provider with id'
      const existsAlert = 'Ya existe una relación con este proveedor'
      let label = alert.default
      if (axios.isAxiosError(error)) {
        const { response } = error
        if (response?.data) {
          const detail = response.data?.title ?? response.data?.detail
          label =
            alert[detail] || response.data?.detail?.includes(existsError)
              ? existsAlert
              : null || alert.default
        }
      }
      const isDefault = label === alert.default
      setAlert({
        label,
        title: isDefault ? 'Alerta' : 'Error',
        type: isDefault ? 'warning' : 'error',
      })
      if (isDefault) {
        fetchRelations(line.lineId)
        closeLateral()
      }
    } finally {
      setLoading(false)
    }
  }

  /* Card Programs fetch effect*/
  useEffect(() => {
    const obtainCardPrograms = async () => {
      const categories = await getCategoryList('SUPPLEMENTARY')
      setCardPrograms(
        categories.map(({ id, cardProgram }) => ({ id, name: cardProgram }))
      )
    }
    if (customer?.id) {
      obtainCardPrograms()
    }
  }, [customer?.id])

  /* Suppliers fetch effect */
  useEffect(() => {
    const obtainProviders = async () => {
      const data = await getProviders({
        status: PROVIDER_STATUS.active,
        source: relation.source,
      })
      setSuppliers(data)
    }
    if (customer?.id) {
      obtainProviders()
    }
  }, [customer?.id, relation.source])

  /* Payment Methods fetch effect */
  useEffect(() => {
    const fetchPaymentMethods = async () => {
      setPaymentMethods([
        {
          name: 'Traditional',
          id: process.env.REACT_APP_PAYMENT_METHOD_ID_TRADITIONAL,
        },
        {
          name: 'GrossPayment',
          id: process.env.REACT_APP_PAYMENT_METHOD_ID_GROSSPAYMENT,
        },
        {
          name: 'InPay',
          id: process.env.REACT_APP_PAYMENT_METHOD_ID_INPAY,
        },
      ])
    }
    fetchPaymentMethods()
  }, [])

  /* Form by relation source selection effect*/
  useEffect(() => {
    if (relation?.source === CUSTOMER_SUPPLIER_SOURCES.CALLAO) {
      setRelationFormSchema([
        ...initialFormSchema,
        ...RELATION_TS_CALLAO_SCHEMA,
      ])
    } else {
      setRelationFormSchema(initialFormSchema)
    }
  }, [initialFormSchema, relation?.source])

  return (
    <RelationModalStyled>
      <form>
        <h3>Crear Relación</h3>
        <div className='available-amount'>
          Monto disponible: {currency(line.score)}
        </div>
        <Form
          schema={relationFormSchema}
          formValues={relation}
          setFormValues={setRelation}
          formErrors={formError}
          setFormErrors={setFormError}
          isValidating={validating}
        />
        <Select
          className='input'
          name='paymentMethodId'
          label='Método de Pago'
          value={relation.paymentMethodId}
          onChange={onHandleChange}
          error={
            validating &&
            !relation.paymentMethodId &&
            'Seleccione un método de pago'
          }
        >
          <option value='' disabled>
            Seleccione una opción
          </option>
          {paymentMethods.map((itm, idx) => (
            <option key={`payment-method-${idx}`} value={itm?.id}>
              {itm?.name}
            </option>
          ))}
        </Select>
        <Select
          className='input'
          name='providerId'
          label='Proveedor'
          value={relation.providerId}
          onChange={onHandleChange}
          error={
            validating && !relation.providerId && 'Seleccione un proveedor'
          }
        >
          <option value='' disabled>
            Seleccione una opción
          </option>
          {suppliers.map(({ id, name }) => (
            <option key={`key-${id}`} value={id}>
              {name}
            </option>
          ))}
        </Select>
        <Select
          className='input'
          name='commerceCode'
          label='Actividad comercial'
          value={relation.commerceCode}
          disabled={!relation.providerId}
          onChange={onHandleChange}
          error={validating && !relation.commerceCode && 'Seleccione un código'}
        >
          <option value='' disabled>
            Seleccione una opción
          </option>
          {getCommerceCodes(relation.providerId).map((commerceCode) => (
            <option key={commerceCode} value={commerceCode}>
              {commerceCode}
            </option>
          ))}
        </Select>
        <Select
          className='input'
          name='cardProgramName'
          label='Card Program'
          value={relation.cardProgramName}
          onChange={onHandleChange}
          error={
            validating &&
            !relation.cardProgramName &&
            'Seleccione un card program'
          }
        >
          <option value='' disabled>
            Seleccione una opción
          </option>
          {cardPrograms.map((cardProgram) => (
            <option key={cardProgram.id} value={cardProgram.name}>
              {cardProgram.name}
            </option>
          ))}
        </Select>

        {relation?.source === CUSTOMER_SUPPLIER_SOURCES.CALLAO && (
          <article className='switch'>
            <label bold='true'>BPP</label>
            <Switch
              checked={bppSelector}
              onChange={() => setBppSelector((prev) => !prev)}
              firstOption='No'
              secondOption='Sí'
            />
          </article>
        )}
      </form>

      <div className='btn-group'>
        <Button buttonType='grayButton' onClick={closeLateral}>
          Cancelar
        </Button>
        <Button disabled={loading} onClick={onHandleSubmit}>
          Crear
        </Button>
      </div>
    </RelationModalStyled>
  )
}

const getFormSchemaByCustomerSource = (
  initialSchema,
  customerTradePlatform
) => {
  const schema = [...initialSchema]
  const sourceFieldIdx = schema.findIndex((itm) => itm.name === 'source')
  if (customerTradePlatform === CUSTOMER_SUPPLIER_SOURCES.KEO) {
    const options = schema[sourceFieldIdx].options.filter(
      (itm) => itm.value === CUSTOMER_SUPPLIER_SOURCES.KEO
    )
    schema[sourceFieldIdx] = { ...schema[sourceFieldIdx], options }
  }
  return schema
}

const RelationModalStyled = styled.section`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  form {
    padding: 0 10px;
    h3 {
      margin-bottom: 15px;
    }

    .input {
      input,
      select {
        background-color: transparent;
      }
    }

    .available-amount {
      margin-bottom: 20px;
    }

    article.switch {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .btn-group {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    button {
      height: 40px;
    }
  }
`

export default CreateRelationSupplementaryCard
