import { INPUT_TYPES, VALIDATOR_TYPES } from 'containers/form'

export const LINE_STATES = {
  PREPROPOSAL: 'PreProposal',
  PROPOSAL: 'Proposal',
  PREOFFER: 'PreOffer',
  OFFER: 'Offer',
  ACTIVE: 'Active',
  FROZEN: 'Frozen',
  REASSESSMENT: 'Reassessment',
  CANCELLED: 'Cancelled',
}

export const LINE_STATES_SPANISH = {
  PreProposal: 'Prepropuesta',
  Proposal: 'Propuesta',
  PreOffer: 'Preoferta',
  Offer: 'Oferta',
  Active: 'Activo',
  Frozen: 'Congelada',
  Cancelled: 'Cancelado',
  Reassessment: 'Reassessment',
}

export const FINANCIAL_INDICATORS = [
  {
    name: 'patrimony',
    label: 'Patrimonio',
    disabled: true,
    placeholder: 'Ejemplo: Texto',
    validatorType: VALIDATOR_TYPES.CURRENCY,
  },
  {
    name: 'assets',
    label: 'Activos',
    disabled: true,
    placeholder: 'Ejemplo: Texto',
    validatorType: VALIDATOR_TYPES.CURRENCY,
  },
  {
    name: 'grossMargin',
    label: 'Margen Bruto de utilidad',
    disabled: true,
    placeholder: 'Ejemplo: Texto',
    validatorType: VALIDATOR_TYPES.CURRENCY,
  },
  {
    name: 'utility',
    label: 'Utilidad',
    disabled: true,
    placeholder: 'Ejemplo: Texto',
    validatorType: VALIDATOR_TYPES.CURRENCY,
  },
  {
    name: 'annualSales',
    label: 'Ventas Anuales',
    disabled: true,
    placeholder: 'Ejemplo: Texto',
    validatorType: VALIDATOR_TYPES.CURRENCY,
  },
  {
    name: 'accountsReceivable',
    label: 'Cuentas por cobrar',
    disabled: true,
    placeholder: 'Ejemplo: Texto',
    validatorType: VALIDATOR_TYPES.CURRENCY,
  },
  {
    name: 'financialDebt',
    label: 'Deuda financiera',
    disabled: true,
    placeholder: 'Ejemplo: Texto',
    validatorType: VALIDATOR_TYPES.CURRENCY,
  },
  {
    name: 'debsToPay',
    label: 'Cuentas por pagar',
    disabled: true,
    placeholder: 'Ejemplo: Texto',
    validatorType: VALIDATOR_TYPES.CURRENCY,
  },
]

export const CHANGE_SCORE_SCHEMA = [
  {
    inputType: INPUT_TYPES.CURRENCY,
    label: 'Capacidad de endeudamiento sugerida',
    placeholder: 'Ex. $xx, xxx.xx',
    name: 'scoreProposed',
    disabled: true,
    validatorType: VALIDATOR_TYPES.CURRENCY,
  },
  {
    inputType: INPUT_TYPES.CURRENCY,
    label: 'Capacidad de endeudamiento',
    placeholder: 'Ex. $xx, xxx.xx',
    name: 'score',
    disabled: true,
    validatorType: VALIDATOR_TYPES.CURRENCY,
  },
]

export const LINE_DETAILS_FORM = [
  {
    inputType: INPUT_TYPES.CURRENCY,
    label: 'Monto',
    placeholder: 'Campo obligatorio*',
    name: 'score',
    validatorType: VALIDATOR_TYPES.CURRENCY,
    errorMessage: 'Coloca un monto mayor a $1',
  },
  {
    label: 'Días de plazo máximo',
    placeholder: 'XX Días',
    name: 'maxTerm',
    type: 'number',
    min: 0,
    validatorType: VALIDATOR_TYPES.INTEGER_NUMBER,
    errorMessage: 'Coloca el plazo máximo de la linea ',
  },
  {
    label: 'Tipo de moneda',
    name: 'currency',
    inputType: INPUT_TYPES.SELECT,
    options: [{ value: 'MXN', label: 'MXN' }],
  },
]

export const ORDINARY_TERM_FORM = [
  {
    label: 'Etiqueta',
    placeholder: 'XXXXXXXX',
    name: 'name',
    validatorType: VALIDATOR_TYPES.SPECIAL_SYMBOLS,
  },
  {
    label: 'Tipo de interés',
    name: 'typeId',
    inputType: INPUT_TYPES.SELECT,
    options: [
      {
        label: 'Fija de aplicación única sobre disposición',
        value: '1',
      },
      {
        label: 'Fija de aplicación única sobre saldo insoluto',
        value: '2',
      },
      {
        label: 'Fija de aplicación diaria sobre saldo insoluto',
        value: '3',
      },
    ],
  },
  {
    inputType: INPUT_TYPES.PERCENTAGE,
    label: 'Tasa de interés',
    placeholder: 'XX %',
    name: 'rate',
    validatorType: VALIDATOR_TYPES.PERCENTAGE,
  },
  {
    label: 'Plazo',
    placeholder: 'XX Días',
    name: 'term',
    type: 'number',
    min: 0,
    validatorType: VALIDATOR_TYPES.INTEGER_NUMBER,
    errorMessage: 'valor no valido',
  },
]

export const MORATORIUM_TERM_FORM = [
  {
    label: 'Etiqueta',
    placeholder: 'XXXXXXXX',
    name: 'name',
    validatorType: VALIDATOR_TYPES.SPECIAL_SYMBOLS,
  },
  {
    label: 'Tipo de interés',
    name: 'typeId',
    inputType: INPUT_TYPES.SELECT,
    options: [
      {
        label: 'Fija de aplicación única sobre disposición',
        value: '1',
      },
      {
        label: 'Fija de aplicación única sobre saldo insoluto',
        value: '2',
      },
      {
        label: 'Fija de aplicación diaria sobre saldo insoluto',
        value: '3',
      },
    ],
  },
  {
    inputType: INPUT_TYPES.PERCENTAGE,
    label: 'Tasa de interés',
    placeholder: 'XX %',
    name: 'rate',
    min: 0,
    validatorType: VALIDATOR_TYPES.PERCENTAGE,
  },
]
export const COMMISSION_TERM_FORM = [
  {
    label: 'Etiqueta',
    placeholder: 'XXXXXXXX',
    name: 'name',
    validatorType: VALIDATOR_TYPES.SPECIAL_SYMBOLS,
  },
  {
    inputType: INPUT_TYPES.CURRENCY,
    label: 'Monto',
    placeholder: 'XXXXXXXX',
    name: 'amount',

    validatorType: VALIDATOR_TYPES.CURRENCY,
  },
  {
    label: 'Día de aplicación',
    placeholder: 'XX',
    name: 'days',
    validatorType: VALIDATOR_TYPES.COMMA_SEPARATED_NUMBERS,
  },
]
