/**
 * @class
 * @extends Error
 * @description Error class to create custom errors and don't show the stack trace in production
 * @param {string} name - Error name
 * @param {string} message - Error message
 * @example
 * throw new ClientError('Error message')
 */
export class ErrorPrototype extends Error {
  /**
   * @constructor
   * @param {string} name - Error name
   * @param {string} message - Error message
   */
  constructor(name, message) {
    super(message)
    this.name = name
    if (process.env.NODE_ENV !== 'development') {
      this.stack = undefined
    }
  }
}

/**
 * @class
 * @extends ErrorPrototype
 * @description Error to throw when a form field is invalid or is empty
 * @param {string} message - Error message
 * @param {string | string[]} [fields] - List of fields with errors
 * @example
 * throw new FormValidationError('Error message', ['field1', 'field2'])
 */
export class FormValidationError extends ErrorPrototype {
  /**
   * @constructor
   * @param {string} message - Error message
   * @param {string | string[]} [fields] - List of fields with errors
   */
  constructor(message, fields) {
    super('FormValidationError', message)
    this.fields = fields
  }
}

/**
 * @class
 * @extends ErrorPrototype
 * @description Error to throw when an API request fails
 * @param {string} title - error title
 * @param {string} message - detailed description
 * @param {number} statusCode - HTTP error code
 * @example
 * throw new ApiError('error-title', 'Error message detailed', 400)
 */
export class ApiError extends ErrorPrototype {
  /**
   * @constructor
   * @param {string} title - error title
   * @param {string} message - detailed description
   * @param {number} statusCode - HTTP error code
   */
  constructor(title, message, statusCode) {
    super('ApiError', message)
    this.statusCode = statusCode
    this.title = title
  }
}
