/* eslint-disable react-hooks/exhaustive-deps */
import { Input, Select, Textarea } from '@keoworld/gbl-ui-kit'
import { Fragment, useEffect, useState } from 'react'
import {
  handleFocusPercentage,
  handleBlurPercentage,
} from '../utils/functions/handle-percentage'
import {
  handleBlurCurrency,
  handleFocusCurrency,
} from 'utils/functions/handlers-currency'
import { validateForm } from 'utils/functions/form-validate'
import { useDebounce } from 'utils/hooks/debounce'

export const INPUT_TYPES = {
  TEXT_FIELD: 'textField',
  SELECT: 'select',
  TEXT_AREA: 'textArea',
  CURRENCY: 'currency',
  PERCENTAGE: 'percentage',
}

export const VALIDATOR_TYPES = {
  STRING: 'string',
  NUMBER: 'numbers',
  POSITIVE_NUMBER: 'positivesNumbers',
  INTEGER_NUMBER: 'integerNumbers',
  EMAIL: 'email',
  ALPHANUMERIC: 'alphaNum',
  ANY: 'any',
  ALPHANUMERIC_SPACE: 'alphaNumSpace',
  STRING_SPACE: 'stringSpace',
  STRING_SPACE_OPTIONAL: 'stringSpaceOptional',
  PHONE: 'phone',
  ZIP_CODE: 'zipCode',
  PERCENTAGE: 'percentage',
  LADA: 'lada',
  RFC: 'rfc',
  RFC_MORAL_PERSON: 'rfcMoralPerson',
  PAST_DATES: 'pastDates',
  PAST_DATETIME: 'pastDateTime',
  NUMBER_INTERIOR: 'numberInterior',
  NUMBER_EXTERIOR: 'numberExterior',
  SCORE: 'score',
  TERM: 'term',
  DECIMAL: 'decimal',
  OPTIONAL_NUMBER: 'optionalNumber',
  ALPHANUM_LENGTH: 'alphaNumLength',
  ALPHA_LENGTH: 'alphaLenght',
  NUMBER_VALIDATION: 'numberValidation',
  CURRENCY: 'currency',
  SPECIAL_SYMBOLS: 'specialSymbols',
  COMMA_SEPARATED_NUMBERS: 'commaSeparatedNumbers',
  ADDRESS: 'address',
}

const FormInputs = {
  [INPUT_TYPES.TEXT_FIELD]: (props) => <Input {...props} />,
  [INPUT_TYPES.CURRENCY]: (props) => (
    <Input
      {...props}
      onFocus={(e) => {
        const event = handleFocusCurrency(e)
        props.onChange(event)
        return event
      }}
      onBlur={(e) => props.onChange(handleBlurCurrency(e))}
    />
  ),
  [INPUT_TYPES.SELECT]: ({ options, value = '', ...props }) => {
    return (
      <Select value={value} {...props}>
        <option disabled value=''>
          Selecciona una opción
        </option>
        {options.map(({ label, value }, index) => (
          <option key={`option-${index}`} value={value}>
            {label}
          </option>
        ))}
      </Select>
    )
  },
  [INPUT_TYPES.TEXT_AREA]: (props) => <Textarea {...props} />,
  [INPUT_TYPES.PERCENTAGE]: (props) => (
    <Input
      {...props}
      onFocus={(e) => {
        const event = handleFocusPercentage(e)
        props.onChange(event)
        return event
      }}
      onBlur={(e) => props.onChange(handleBlurPercentage(e))}
    />
  ),
}

const Form = (params) => {
  const {
    schema = [],
    formValues = {},
    setFormValues,
    formErrors = {},
    setFormErrors,
    isValidating = true,
  } = params
  const [hasModified, setHasModified] = useState({})
  const debounce = useDebounce(formValues, 2000)

  const onHandleChange = (event) => {
    const { name, value } = event?.target ?? {}
    if (typeof setFormValues === 'function') {
      setFormValues({ ...formValues, [name]: value })
    }
    setHasModified({ ...hasModified, [name]: Boolean(value) })
  }

  useEffect(() => {
    const validateErrors = () => {
      const errors = validateForm(schema, formValues)
      if (typeof setFormErrors === 'function') {
        setFormErrors(errors)
      }
    }
    validateErrors()
  }, [formValues])

  useEffect(() => {
    const objectWithoutBlanks = removeBlanks(formValues)
    if (
      typeof setFormValues === 'function' &&
      !validateEqualObjects(debounce, objectWithoutBlanks)
    ) {
      setFormValues(objectWithoutBlanks)
    }
  }, [debounce])

  return (
    <Fragment>
      {schema.map(
        (
          {
            inputType = INPUT_TYPES.TEXT_FIELD,
            className = '',
            children,
            errorMessage,
            ...props
          },
          index
        ) => {
          const { name } = props
          const InputElement = inputType && FormInputs[inputType]
          const value = formValues[name] ?? ''
          const error =
            formErrors &&
            (isValidating || Boolean(hasModified[name])) &&
            Boolean(formErrors[name])

          return (
            <InputElement
              key={`input-element-${index}`}
              onChange={onHandleChange}
              value={value}
              error={error ? errorMessage || 'Campo Requerido' : false}
              className={`form-input ${className}`}
              {...props}
            />
          )
        }
      )}
    </Fragment>
  )
}

/**
 * @param {{ [key: string]: boolean }} formErrors
 * @returns {boolean}
 */
export const validateFormErrors = (formErrors) => {
  const isThereError = Object.entries(formErrors).some(([, value]) => value)
  return isThereError
}

/**
 * Function to validate if two objects are equal
 * @param {Object} firstObject - First object to compare
 * @param {Object} secondObject - Second object to compare
 * @returns returns true if the objects are equal, otherwise returns false
 */
const validateEqualObjects = (firstObject, secondObject) => {
  return Object.entries(firstObject).every(([key, value]) => {
    return value === secondObject[key]
  })
}

/**
 * Function to remove blanks from all properties of an object.
 * @param {Object} object - Object to remove blanks
 * @returns returns an object without whitespace at the beginning and end of the string in all its properties
 */
const removeBlanks = (object = {}) => {
  return Object.entries(object).reduce((acc, [key, value]) => {
    if (typeof value !== 'string') return { ...acc, [key]: value }
    return { ...acc, [key]: value.trim() }
  }, {})
}

export default Form
