import { Button, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import LateralBar from 'containers/lateral-bar'
import ModalUpload from 'containers/Modal/modal-documents-upload'
import { AlertContext } from 'providers/alert'
import axios from 'axios'
import { CustomerContext } from 'providers/customer'
import { LateralBarContext, LATERAL_MENU_OPTIONS } from 'providers/lateral-bar'
import { useContext, useEffect, useRef, useState } from 'react'
import { getDocumentList, uploadDocuments } from 'services/documents'
import { instanceTransition } from 'services/workflow'
import styled from 'styled-components'
import { getUserRoles } from 'utils/functions/role-manager'
import { useAuth } from 'utils/hooks/auth'
import { CUSTOMER_STATES, SHAREHOLDER_TYPES } from 'utils/schemas/customer'
import * as DOCUMENTS from 'utils/schemas/documents'
import { FILE_STATES } from 'utils/schemas/documents'
import { BuildSection } from './build-section'

const documentInputs = DOCUMENTS.ASSESSMENT

const DocumentsReassessment = () => {
  const [files, setFiles] = useState({})
  const [isValidating, setIsValidating] = useState(false)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [openCommentBar, setOpenCommentBar] = useState(false)
  const [openModalUpload, setOpenModalUpload] = useState(false)
  const { customer } = useContext(CustomerContext)
  const { setAlert } = useContext(AlertContext)
  const { setAction, setSelectedOption } = useContext(LateralBarContext)
  const { user } = useAuth()
  const toResetErrorRef = useRef(null)

  const process =
    customer?.type === SHAREHOLDER_TYPES.PERSON
      ? 'naturalPerson'
      : 'moralPerson'

  const handleUpload = async () => {
    const userRole = getUserRoles()[0]
    const response = await uploadDocuments(
      files,
      customer.id,
      user.uid,
      userRole
    )
    if (response.some((request) => request.status !== 'fulfilled')) {
      throw new Error('error saving files')
    }
    return response.map((request) => request.value)
  }

  const handleSave = async () => {
    try {
      setIsLoadingSubmit(true)
      await handleUpload()
      setAction(false)
      setOpenModalUpload(false)
    } catch (error) {
      console.error(error.message)
      const alert = {
        label:
          'Ha ocurrido un error al guardar los archivos, por favor intenta de nuevo',
        title: 'Error',
        type: 'error',
      }
      if (error.message === 'error saving files') {
        setAction(false)
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      }
      setAlert(alert)
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  const handleSubmit = async () => {
    setIsLoadingSubmit(true)
    setIsValidating(true)
    try {
      const DOC_LIST = [
        ...documentInputs.generalInformation[process],
        ...documentInputs.financialInformation[process],
        ...documentInputs.mainAuctioneer[process],
      ]

      let hasErrors
      hasErrors = DOC_LIST.some(
        ({ typeId }) => !Object.keys(files).includes(String(typeId))
      )
      const errorUndeletedFiles = Object.values(files).some(
        (filesArray) =>
          !filesArray.some((file) => file.status !== FILE_STATES.DELETED) ||
          filesArray.some((file) => file.status === FILE_STATES.REFUSED)
      )
      hasErrors = hasErrors || errorUndeletedFiles

      /* Execute transition */
      if (hasErrors) {
        throw new Error('missing files')
      }

      const transition =
        CUSTOMER_STATES[customer?.customerInstance?.currentState].transition
      /*  happens when the state is cust_uploadingAssessment */
      const EVENT = transition.SEND.event
      const nextState = transition.SEND.nextState
      const nextStage = transition.SEND.nextStage

      const uploadedIds = await handleUpload()
      const unchangeableStates = [FILE_STATES.DELETED, FILE_STATES.ACCEPTED]
      const multiUploadIds = uploadedIds.map((itm) => {
        return {
          id: itm.id,
          status: unchangeableStates.includes(itm.status)
            ? itm.status
            : FILE_STATES.PENDING,
        }
      })

      await instanceTransition(
        customer?.customerInstance?.workflowId,
        customer?.customerInstance.instanceId,
        EVENT,
        {
          'customerId:contract': customer.id,
          'fileList:multiUpdate': multiUploadIds,
          'id:status': customer.id,
          'wfState:status': nextState,
          'wfStage:status': nextStage,
          'role:assignation': 'bpm.mx-amex-b2b.ope-hd',
          'customerId:assignation': customer.id,
          senderUidFed: user.uid,
          'customerId:notificationMany': customer.id,
        }
      )

      setAction(false)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
    } catch (err) {
      const errors = {
        'missing files': {
          close: false,
          label: 'Por favor sube todos los documentos solicitados',
        },
        'error saving files': {
          close: true,
          label:
            'Ha ocurrido un error al guardar los archivos, por favor ingresa al prospecto e intenta subir los documentos de nuevo',
        },
        workflow: {
          close: true,
          label:
            'Los documentos se han guardado, pero ha ocurrido un error al enviar. Por favor contacta a soporte',
        },
        default: {
          close: true,
          label: 'Ha ocurrido un error al enviar. Por favor recarga la pagina',
        },
      }

      if (axios.isAxiosError(err)) {
        const { body } = err.response.data
        console.error(`Workflow Error ${body?.message} `)
        err.message = 'workflow'
      }

      const { close, label } = errors[err.message] || errors.default

      if (close) {
        setAction(false)
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      }

      setAlert({ label, title: 'Error', type: 'error' })
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  /* Fetch saved files Effect */
  useEffect(() => {
    const fetchFiles = async () => {
      const { data: savedFilesData } = await getDocumentList(customer.id, {
        status: `${FILE_STATES.DRAFT},${FILE_STATES.PENDING},${FILE_STATES.REFUSED},${FILE_STATES.ACCEPTED}`,
        stage: DOCUMENTS.FILE_STAGES.ASSESSMENT,
      })

      let savedFiles = {}
      savedFilesData.forEach((itm) => {
        const extension = itm.fileExtension ? `.${itm.fileExtension}` : ''
        if (!savedFiles[itm.typeId]) savedFiles[itm.typeId] = []
        savedFiles[itm.typeId].push({
          name: `${itm.fileName}${extension}`,
          size: itm.fileSize,
          status: itm.fileStatus,
          id: itm.fileId,
          typeId: itm.typeId,
        })
      })
      setFiles(savedFiles)
    }
    if (customer?.id) fetchFiles()
  }, [customer?.id])

  return (
    <DocumentsUploadStyled ref={toResetErrorRef}>
      <h3>Documentos Reassessment</h3>
      <CustomerDocumentsStyled>
        <BuildSection
          allowUpload={true}
          allowReview={false}
          allowDelete={true}
          allowUpdate={true}
          schema={documentInputs.generalInformation[process]}
          title={documentInputs.generalInformation.title}
          files={files}
          setFiles={setFiles}
          isValidating={isValidating}
          setCommentBarInfo={setOpenCommentBar}
        />
        <BuildSection
          allowUpload={true}
          allowReview={false}
          allowDelete={true}
          allowUpdate={true}
          schema={documentInputs.mainAuctioneer[process]}
          title={documentInputs.mainAuctioneer.title}
          files={files}
          setFiles={setFiles}
          isValidating={isValidating}
          setCommentBarInfo={setOpenCommentBar}
        />
        <BuildSection
          allowUpload={true}
          allowReview={false}
          allowDelete={true}
          allowUpdate={true}
          schema={documentInputs.financialInformation[process]}
          title={documentInputs.financialInformation.title}
          files={files}
          setFiles={setFiles}
          isValidating={isValidating}
          setCommentBarInfo={setOpenCommentBar}
        />
      </CustomerDocumentsStyled>
      <div className='action-group'>
        <Button
          buttonType='grayButton'
          disabled={isLoadingSubmit}
          onClick={() => {
            setAction(false)
          }}
        >
          {isLoadingSubmit && <LoadingAnimation className='loading' />}
          {!isLoadingSubmit && 'Cancelar'}
        </Button>
        <Button
          onClick={() => {
            setOpenModalUpload(true)
          }}
          disabled={isLoadingSubmit}
        >
          {isLoadingSubmit ? (
            <LoadingAnimation className='loading' />
          ) : (
            'Guardar'
          )}
        </Button>
        <Button
          buttonType='green'
          className='send'
          disabled={isLoadingSubmit}
          onClick={() => handleSubmit()}
        >
          {isLoadingSubmit ? (
            <LoadingAnimation className='loading' />
          ) : (
            'Enviar'
          )}
        </Button>
      </div>
      {openCommentBar && (
        <LateralBar
          setlateralBar={setOpenCommentBar}
          documentName={openCommentBar?.fileLabel}
          fileId={openCommentBar?.fileId}
        />
      )}
      {openModalUpload && (
        <ModalUpload
          setOpenModalUpload={setOpenModalUpload}
          isLoadingSubmit={isLoadingSubmit}
          handleSave={handleSave}
        />
      )}
    </DocumentsUploadStyled>
  )
}

const DocumentsUploadStyled = styled.div`
  padding: 2rem;
  padding-bottom: 1rem;
  .loading span {
    background-color: white;
  }
  h3 {
    margin-top: 1rem;
  }
  h4 {
    font-weight: bold;
    margin-top: 1rem;
  }
  .form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 1rem auto;
    & > div {
      padding: 0;
      margin: 0.5rem 0rem;
      // first column
      &:nth-child(3n - 2) {
        margin-right: 16px;
      }
      // second column
      &:nth-child(3n - 1) {
        margin-left: 8px;
        margin-right: 8px;
      }
      // last column
      &:nth-child(3n) {
        margin-left: 16px;
      }
    }
  }

  .action-group {
    margin: 2rem 42px 0;

    button + button {
      margin-left: 28px;
    }
    .send {
      float: right;
    }
  }
`

const CustomerDocumentsStyled = styled.section`
  .center-loading {
    height: 50vh;
    display: flex;
    align-items: center;
    div {
      width: 150px;
    }
  }

  h5 {
    margin-top: 1rem;
  }

  section {
    width: 65%;
  }
`

export default DocumentsReassessment
