import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { getLineConditions } from 'services/lines'
import LineConditionsView from 'containers/line/line-conditions-view'
import { CustomerContext } from 'providers/customer'

const InformationOffer = () => {
  const { customer } = useContext(CustomerContext)
  const [lineInformation, setLineInformation] = useState()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      if (!customer?.lineId) return
      setIsLoading(true)
      const lineConditions = await getLineConditions(customer.lineId)
      setLineInformation(lineConditions)
      setIsLoading(false)
    }
    fetchData()
  }, [customer?.lineId])

  return (
    <ContentLine>
      {lineInformation && (
        <LineConditionsView
          lineData={lineInformation}
          isLoading={isLoading}
        />
      )}
      {!lineInformation && 'No hay información de la oferta'}
    </ContentLine>
  )
}

const ContentLine = styled.div`
  display: grid;
  justify-content: center;
  margin-top: 3em;
`

export default InformationOffer
