import PropTypes from 'prop-types'
import styled from 'styled-components'
import { dateFormatFromIso } from 'utils/functions/date'
import { currency } from 'utils/functions/formatters'

const LoanInformation = (props) => {
  const { loan, className } = props

  return (
    <Styles className={className}>
      <h3>Detalle del préstamo</h3>
      <div className='g-loan-info'>
        <div>
          <span className='bold'>ID préstamo: </span>
          <span>{loan?.id}</span>
        </div>
        <div>
          <span className='bold'>ID línea: </span>
          <span>{loan?.lineId}</span>
        </div>
        <div>
          <span className='bold'>Monto: </span>
          <span>{loan?.amount && currency(loan?.amount)}</span>
        </div>
        <div>
          <span className='bold'>ID relación: </span>
          <span>{loan?.relationId}</span>
        </div>
        <div>
          <span className='bold'>Deuda: </span>
          <span>{loan?.debt && currency(loan?.debt)}</span>
        </div>
        <div>
          <span className='bold'>Fecha de solicitud: </span>
          <span>
            {loan?.createdAt &&
              dateFormatFromIso(loan?.createdAt, 'dd/MM/yyyy')}
          </span>
        </div>
        <div>
          <span className='bold'>Saldo a favor: </span>
          <span>
            {loan?.positiveBalance && currency(loan?.positiveBalance)}
          </span>
        </div>
      </div>
    </Styles>
  )
}

const Styles = styled.section`
  .bold {
    font-weight: bold;
  }

  .underline {
    text-decoration: underline;
  }

  .g-loan-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
    row-gap: 5px;
  }
`

LoanInformation.propTypes = {
  loan: PropTypes.object.isRequired,
  className: PropTypes.string,
}

export default LoanInformation
