import { Icon, Input, Select } from '@keoworld/gbl-ui-kit'
import { useRef, useState } from 'react'
import styled from 'styled-components'
import { useOnClickOutside } from 'utils/hooks/click-outside'
import { CUSTOMER_STATES } from 'utils/schemas/customer'
import { ONBOARDING_STATES } from 'utils/schemas/workflows/onboarding'

export const ONBOARDING_FILTERS = {
  name: 'Denominación / Razón Social',
  state: 'Estado',
}

const statesOptions = {
  oldFlow: CUSTOMER_STATES,
  supplementaryCards: ONBOARDING_STATES,
}

const DashboardFilter = ({ setQuerySearch, buyerFlow }) => {
  const [filterSelected, setFilterSelected] = useState()
  const [filterValue, setFilterValue] = useState('')
  const filterRef = useRef()

  const search = (e) => {
    e.preventDefault()
    setQuerySearch({ name: filterSelected, value: filterValue })
    clearFilter()
  }

  const clearFilter = () => {
    setFilterSelected(undefined)
    setFilterValue(undefined)
  }

  useOnClickOutside(filterRef, clearFilter)

  const filterOptions = statesOptions[buyerFlow]

  return (
    <CustomerFilterStyled>
      <button disabled>
        <Icon name='filter_alt' />
        Filtrar
      </button>
      {Object.entries(ONBOARDING_FILTERS).map((filter, index) => {
        const [name, label] = filter

        const onClick = () => setFilterSelected(name)

        return (
          <button key={`filter-${index}`} className='filter' onClick={onClick}>
            {label}
            <Icon name='expand_more' />
          </button>
        )
      })}
      {filterSelected && (
        <form className='dropdown-filter' onSubmit={search} ref={filterRef}>
          {filterSelected !== 'state' && (
            <Input
              className='filter'
              label={ONBOARDING_FILTERS[filterSelected]}
              placeholder='ex: xxxx'
              icon={<Icon name='search' onClick={search} />}
              name={filterSelected?.name}
              value={filterValue ?? ''}
              onChange={(e) => setFilterValue(e.target.value)}
            />
          )}
          {filterSelected === 'state' && (
            <div className='filter-select'>
              <Select
                label={ONBOARDING_FILTERS[filterSelected]}
                placeholder='ex: xxxx'
                name={filterSelected}
                value={filterValue ?? ''}
                onChange={(e) => setFilterValue(e.target.value)}
              >
                <option disabled value=''>
                  Selecciona una opción
                </option>
                {Object.entries(filterOptions).map(
                  ([key, { label }], index) => (
                    <option key={`option-${index}`} value={key}>
                      {label}
                    </option>
                  )
                )}
              </Select>
              <Icon name='search' onClick={search} />
            </div>
          )}
        </form>
      )}
    </CustomerFilterStyled>
  )
}

const CustomerFilterStyled = styled.div`
  display: flex;
  margin-top: 1rem;
  margin-left: 9rem;
  position: relative;

  .dropdown-filter {
    position: absolute;
    bottom: -60px;

    padding: 0 25px 10px;
    width: 400px;

    border-radius: 30px;

    ${({ theme }) => theme.colors.grayShades[500]}

    label {
      text-transform: uppercase;
    }

    input {
      ${({ theme }) => theme.colors.grayShades[500]}
    }
    .filter-select {
      display: flex;
      align-items: center;
      justify-content: center;
      select {
        ${({ theme }) => theme.colors.grayShades[500]}
      }
      .icon {
        margin: 1em 0.5em 0 0.5em;
      }
    }
    .icon {
      cursor: pointer;
    }
  }

  button {
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    text-transform: capitalize;
  }
`

export default DashboardFilter
