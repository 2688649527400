import { Button } from '@keoworld/gbl-ui-kit'
import { AlertContext } from 'providers/alert'
import { LateralBarContext, LATERAL_MENU_OPTIONS } from 'providers/lateral-bar'
import { ModalContext, modalOptions } from 'providers/modal'
import { useContext, useState } from 'react'
import { ApiBuyerRepository } from 'services/buyer-suppcards/buyer.repository'
import {
  getCustomerInstance,
  getInstance,
  instanceTransition,
} from 'services/workflow'
import styled from 'styled-components'
import { useReassessmentValidation } from 'utils/hooks/reassessment-validation'
import { CUSTOMER_DB_STATUS } from 'utils/schemas/customer'
import { ApiError } from 'utils/schemas/errors'
import { dependentRoles } from 'utils/schemas/roles'

const REASSESSMENT_ROLES = [...dependentRoles['bpm.mx-amex-b2b.ope-hd']]

const CustomerActions = ({ customer, userRoles = [] }) => {
  const [isLoading, setIsLoading] = useState(false)

  const { setOpenModal, setActions } = useContext(ModalContext)
  const { setSelectedOption } = useContext(LateralBarContext)
  const { setAlert } = useContext(AlertContext)
  const hasReassessmentInProgress = useReassessmentValidation()

  const [userRole] = userRoles

  const handleSendReassessment = async () => {
    try {
      if (!customer) return

      setIsLoading(true)

      if (customer.service === 'supplementaryCards') {
        return await sendToReassessment()
      }

      return await oldReassessment()
    } catch (error) {
      console.error(error)
      if (error instanceof ApiError) {
        return setAlert({
          type: 'error',
          title: `¡Ups! ha ocurrido un error`,
          label: error.message,
        })
      }
      setAlert({
        type: 'error',
        title: `¡Ups! ha ocurrido un error`,
        label: 'No se ha podido enviar a reassessment',
      })
    } finally {
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_CUSTOMERS)
      setIsLoading(false)
    }
  }

  const oldReassessment = async () => {
    if (!customer) return

    const { body: customerInstanceInfo } = await getCustomerInstance(
      customer.id
    )

    await instanceTransition(
      customerInstanceInfo?.instance?.workflowId,
      customerInstanceInfo?.instance?.instanceId,
      'SEND_TO_REASSESSMENT'
    )
  }

  const sendToReassessment = async () => {
    if (!customer?.externalId) return

    const hasReassessmentFlow = await getInstance(
      customer.externalId,
      'buyer-reassessment'
    ).catch((_error) => false)

    if (hasReassessmentFlow) {
      await ApiBuyerRepository.doReassessmentEvent(
        customer.externalId,
        'send-to-reassessment',
        {
          'id:status': customer.id,
          'status:status': 'Reassessment',
          'wfState:status': 'REASSESSMENT',
          'wfStage:status': 'reassessment',
        }
      )
    } else {
      await ApiBuyerRepository.sendToReassessment(
        customer.externalId,
        customer.id
      )
    }
  }

  const handleAction = () => {
    setActions([() => {}, () => handleSendReassessment()])
    setOpenModal(modalOptions.Reassessment)
  }

  return (
    <StyledActions>
      {customer &&
        (customer.service !== 'supplementaryCards' || customer.externalId) &&
        isValidToReassessment(userRole, customer?.status) &&
        !hasReassessmentInProgress && (
          <Button disabled={isLoading} onClick={handleAction}>
            Reassessment
          </Button>
        )}
    </StyledActions>
  )
}

const isValidToReassessment = (userRole, customerStatus) =>
  REASSESSMENT_ROLES.includes(userRole) &&
  customerStatus !== CUSTOMER_DB_STATUS.REASSESSMENT

const StyledActions = styled.div`
  height: 0;
  position: relative;
  display: flex;
  justify-content: flex-end;
  top: 5em;
  right: 4em;
`

export default CustomerActions
