import { Icon } from '@keoworld/gbl-ui-kit'
import { LateralBarContext, LATERAL_MENU_OPTIONS } from 'providers/lateral-bar'
import { useContext } from 'react'
import styled from 'styled-components'
import { getUserRoles } from 'utils/functions/role-manager'
import { dependentRoles } from 'utils/schemas/roles'
import { useNavigate, useLocation } from 'react-router-dom'

const LateralBarMenu = () => {
  const [userRole] = getUserRoles()
  const { selectedOption, setSelectedOption, setAction } =
    useContext(LateralBarContext)

  const location = useLocation()
  const navigate = useNavigate()

  const clearDashboardFilter = (dashboard) => {
    sessionStorage.removeItem(`${dashboard}Filter`)
  }

  const lateralBarOptions = [
    {
      condition: !dependentRoles['bpm.mx-amex-b2b.fin-an'].includes(userRole),
      mark:
        selectedOption === LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING ||
        selectedOption === LATERAL_MENU_OPTIONS.ONBOARDING ||
        selectedOption === LATERAL_MENU_OPTIONS.PRECUSTOMER_DETAIL,
      action: () => {
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
        clearDashboardFilter('onboarding')
      },
      icon: <Icon name='person_search' type='outlined' />,
    },
    {
      condition: true,
      mark:
        selectedOption === LATERAL_MENU_OPTIONS.DASHBOARD_CUSTOMERS ||
        selectedOption === LATERAL_MENU_OPTIONS.CUSTOMER_DETAIL,
      action: () => {
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_CUSTOMERS)
        clearDashboardFilter('customers')
      },
      icon: <Icon name='folder_shared' type='outlined' />,
    },
    {
      condition: true,
      mark: selectedOption === LATERAL_MENU_OPTIONS.HELP,
      action: () => setSelectedOption(LATERAL_MENU_OPTIONS.HELP),
      icon: <Icon name='contact_support' type='outlined' />,
    },
  ]

  return (
    <LateralBarMenuStyled>
      {lateralBarOptions
        .filter((option) => option.condition)
        .map((option, index) => {
          const { mark, icon, action } = option
          const onHandleClick = () => {
            if (location.pathname !== '/') {
              navigate('/')
            }
            action()
            setAction('')
          }

          return (
            <button
              key={`btn-${index}`}
              className={mark ? 'option-selected' : ''}
              onClick={onHandleClick}
            >
              {icon}
            </button>
          )
        })}
    </LateralBarMenuStyled>
  )
}

const LateralBarMenuStyled = styled.aside`
  width: 70px;
  min-height: 380px;
  text-align: center;
  background-color: #f6f8fc;

  display: flex;
  justify-content: center;
  flex-direction: column;

  button {
    height: 70px;
    width: 100%;
    background-color: transparent;
    color: #00172d;
    border: none;
    cursor: pointer;

    &.option-selected {
      position: relative;
      background-color: #00172d14;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;

        background-color: #00172d;
        width: 8px;
        height: 100%;

        border-radius: 0 9px 9px 0;
      }
    }

    &:first-child,
    &:last-child {
      margin-top: auto;
    }
  }
`

export default LateralBarMenu
