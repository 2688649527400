import { INPUT_TYPES, VALIDATOR_TYPES } from 'containers/form'

export const COMMERCIAL_INFO = [
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'name',
    label: 'Nombre',
    placeholder: 'Nombre'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'phone',
    label: 'Teléfono',
    placeholder: '0000000000'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'email',
    label: 'Correo Electronico',
    placeholder: 'customer@mail.com'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'industry',
    label: 'Industria',
    placeholder: 'xxxxx'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'company',
    label: 'Razón Social',
    placeholder: 'xxxxx'
  }
]

export const TABLE_HISTORY = {
  headers: [
    {
      id: 'name',
      label: 'Nombre',
      type: 'withIcon'
    },
    {
      id: 'role',
      label: 'Rol'
    },
    {
      id: 'event',
      label: 'Acción'
    },
    {
      id: 'stage',
      label: 'Etapa'
    },
    {
      id: 'createdAt',
      label: 'Fecha'
    }
  ]
}

export const TABLE_ACTIVITY = {
  headers: [
    {
      id: 'name',
      label: 'Usuario',
      type: 'withIcon'
    },
    {
      id: 'action',
      label: 'Acción'
    },
    {
      id: 'createdAt',
      label: 'Fecha'
    },
    {
      id: 'detail',
      label: 'Detalle',
      type: 'action'
    }
  ]
}

export const TABLE_COMMITTEE = {
  headers: [
    {
      id: 'name',
      label: '',
      type: 'withIcon'
    },
    {
      id: 'state',
      label: ''
    },
    {
      id: 'detail',
      label: '',
      type: 'comment'
    }
  ]
}

export const INDICATOR_FORM = [
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'amount',
    label: 'Corte Información Financiera ',
    type: 'date'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'dataSource',
    label: 'Fuente de datos',
    placeholder: 'XXXXX'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'heritage',
    label: 'Patrimonio',
    placeholder: '$ XXXXX'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'assets',
    label: 'Activos',
    placeholder: '$ XXXXX'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'annualSales',
    label: 'Ventas anuales',
    placeholder: '$ XXXXX'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'financialDebt',
    label: 'Deuda financiera',
    placeholder: '$ XXXXX'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'grossMargin',
    label: 'Margen Bruto',
    placeholder: '$ XXXXX'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'utility',
    label: 'Utilidad',
    placeholder: '$ XXXXX'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'accountsReceivable',
    label: 'Cuentas por cobrar',
    placeholder: '$ XXXXX'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'debtsPay',
    label: 'Cuentas por pagar',
    placeholder: '$ XXXXX'
  }
]

export const LEGAL_REPRESENTATIVE_KEO_FORM = [
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'name',
    label: 'Nombre'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'lastName',
    label: 'Apellido Paterno'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'motherLastName',
    label: 'Apellido Materno'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'writingNumber',
    label: 'Número de escritura'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'date',
    label: 'Fecha',
    type: 'date'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'givenBy',
    label: 'Otorgada por'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'granted',
    label: 'Número de Notario Público'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'city',
    label: 'Ciudad (Notario)'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'state',
    label: 'Estado (Notario)'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'publicRecordStatus',
    label: 'Estado del Registro Público de Comercio del Estado de:'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'businessFolio',
    label: 'Folio Mercantil'
  }
]

export const MORAL_PERSON = {
  FORM_ONE: [
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'businessName',
      label: 'Razón Social'
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'rfc',
      label: 'RFC (CON HOMOCLAVE)',
      validatorType: VALIDATOR_TYPES.RFC
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'street',
      label: 'Calle'
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'outdoorNumber',
      label: 'Número exterior',
      validatorType: VALIDATOR_TYPES.NUMBER_EXTERIOR
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'interiorNumber',
      label: 'Número Interior',
      validatorType: VALIDATOR_TYPES.NUMBER_INTERIOR
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'colony',
      label: 'Coloniaㅤㅤㅤㅤㅤㅤㅤㅤㅤ'
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'municipality',
      label: 'Municipioㅤㅤㅤㅤㅤㅤㅤㅤㅤ'
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'city',
      label: 'Ciudadㅤㅤㅤㅤㅤㅤㅤㅤㅤ'
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'state',
      label: 'Estadoㅤㅤㅤㅤㅤㅤㅤㅤㅤ'
    }
  ],
  FORM_TWO: [
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'writingNumber',
      label: 'Escritura número '
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'date',
      label: 'Fecha ',
      type: 'date'
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'givenBy',
      label: 'Otorgada por '
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'notaryNumber',
      label: 'Número de Notario Público'
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'city',
      label: 'Ciudad (Notario)'
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'state',
      label: 'Estado (Notario)'
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'registrationStatus',
      label: 'Estado del Registro Público de Comercio del Estado de: '
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'businessFolio',
      label: 'Folio Mercantil'
    }
  ]
}

export const LEGAL_REPRESENTATIVE = {
  FORM_ONE: [
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'name',
      label: 'Nombre'
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'lastName',
      label: 'Apellido Paterno'
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'motherLastName',
      label: 'Apellido Materno'
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'rfc',
      label: 'RFC (con homoclave)',
      validatorType: VALIDATOR_TYPES.RFC
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'electorKey',
      label: 'Clave de elector'
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'curp',
      label: 'CURP'
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'street',
      label: 'Calle'
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'outdoorNumber',
      label: 'Número exterior',
      validatorType: VALIDATOR_TYPES.NUMBER_EXTERIOR
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'interiorNumber',
      label: 'Número interior',
      validatorType: VALIDATOR_TYPES.NUMBER_INTERIOR
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'postalCode',
      label: 'Código Postal'
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'colony',
      label: 'Coloniaㅤㅤㅤㅤㅤㅤㅤㅤㅤ'
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'municipality',
      label: 'Delegación o municipio'
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'city',
      label: 'Ciudad/Población'
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'state',
      label: 'Estado/Departamento'
    }
  ],
  FORM_TWO: [
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'writingNumber',
      label: 'Escritura número '
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'date',
      label: 'Fecha ',
      type: 'date'
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'givenBy',
      label: 'Otorgada por '
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'notaryNumber',
      label: 'Número de Notario Público'
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'city',
      label: 'Ciudad (Notario)'
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'state',
      label: 'Estado (Notario)'
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'registrationStatus',
      label: 'Estado del Registro Público de Comercio del Estado de: '
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'businessFolio',
      label: 'Folio Mercantil'
    }
  ]
}

export const PHYSICAL_PERSON = [
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'name',
    label: 'Nombre'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'lastName',
    label: 'Apellido Paterno '
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'motherLastName',
    label: 'Apellido Materno'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'rfc',
    label: 'R.F.C. (CON HOMOCLAVE)',
    validatorType: VALIDATOR_TYPES.RFC
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'electorKey',
    label: 'Clave de elector'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'curp',
    label: 'CURP'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'street',
    label: 'Calle'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'outdoorNumber',
    label: 'Número Exterior',
    validatorType: VALIDATOR_TYPES.NUMBER_EXTERIOR
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'interiorNumber',
    label: 'Número Interior',
    validatorType: VALIDATOR_TYPES.NUMBER_INTERIOR
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'postalCode',
    label: 'Código Postal',
    validatorType: VALIDATOR_TYPES.ZIP_CODE
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'colony',
    label: 'Colonia'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'municipality',
    label: 'Delegación o municipio'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'city',
    label: 'Ciudad/Población'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'state',
    label: 'Estado/Departamento'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'código de país',
    label: 'Lada',
    validatorType: VALIDATOR_TYPES.LADA
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'phone',
    label: 'Teléfono'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'civilStatus',
    label: 'Estado Civil'
  }
]
