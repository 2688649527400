import { Icon } from '@keoworld/gbl-ui-kit';
import { useState } from 'react';
import styled from 'styled-components';

const Dropdown = ({ children, label }) => {
  const [state, setState] = useState(false);

  return (
    <DropdownStyled>
      <div
        className='dropdown-div'
        onClick={() => (state ? setState(false) : setState(true))}
      >
        <h5>{label}</h5>
        <Icon name='expand_more' />
      </div>
      {state && children}
    </DropdownStyled>
  );
};

const DropdownStyled = styled.div`
  margin: 2rem auto;
  .dropdown-div {
    display: flex;
    align-items: center;
  }
  h5{
    font-weight: normal;
  }
`;

export default Dropdown;
