import { Icon } from '@keoworld/gbl-ui-kit'
import styled from 'styled-components'

/**
 * Component to display a chip
 * @param {Props} props - Component props
 */
const Chip = (props) => {
  const { className, icon, label, onClick } = props

  return (
    <ChipStyled className={className}>
      <span className='label'>{label}</span>
      <Icon name={icon} onClick={onClick} />
    </ChipStyled>
  )
}

const ChipStyled = styled.div`
  border-radius: 30px;

  padding: 5px 10px;

  display: flex;
  align-items: center;

  ${({ theme }) => theme.colors.grayShades[700]};

  text-transform: capitalize;

  .label {
    max-width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .icon {
    margin-left: 5px;
    cursor: pointer;
  }
`

export default Chip

/**
 * @typedef {Object} Props
 * @property {string} [className]
 * @property {string} [label]
 * @property {string} [icon]
 * @property {Function} [onClick]
 */
