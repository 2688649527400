export const canBeFormatted = (number) =>
  /^\$?[0-9]+(,[0-9]{3})*(\.\d{1,2})?$/.test(number)

export const handleBlurCurrency = (event) => {
  const value = event.target.value

  if (!canBeFormatted(value)) return event

  const number = extractNumber(value)

  const formattedValue = formatCurrency(number)

  event.target.value = formattedValue

  return event
}

export const handleFocusCurrency = (event) => {
  const value = event.target.value

  if (!canBeFormatted(value)) return

  const number = extractNumber(value)

  event.target.value = number

  return event
}

/**
 * Function to extract a number from a string with format.
 * ex: 1,000.00 -> 1000
 * @param {String} value
 * @returns {Number}
 */
export const extractNumber = (value) => {
  if (!canBeFormatted(value)) return NaN
  return Number(`${value}`.replace(/[^0-9.]/g, ''))
}

/**
 * Function to format a number to currency.
 * @param {Number | String} number
 * @returns {String}
 */
export const formatCurrency = (number) => {
  if (!canBeFormatted(number)) return NaN

  number = extractNumber(number)

  return Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number)
}
