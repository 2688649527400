import { Button } from '@keoworld/gbl-ui-kit'
import LineConditionsView from 'containers/line/line-conditions-view'

const LineDetail = (props) => {
  const { line, setLateralBar } = props

  const execute = (action) => {
    setLateralBar(action)
  }

  return (
    <div className='create-line'>
      <div className='scroll'>
        {/* <h3>ID Línea {formValues.lineId}</h3> */}
        <LineConditionsView lineData={line} />

        <br />
        <h5 className='p-title'>Cedido a fideicomiso</h5>
        <label className='switch-div'>
          <p>No</p>
          <label className='switch'>
            <input type='checkbox' checked={line.gaveUp} disabled={true} />
            <span className='slider round' />
          </label>
          <p>Sí</p>
        </label>
        <div className='buttons'>
          <Button buttonType='grayButton' onClick={() => execute(undefined)}>
            Cancelar
          </Button>
          <Button onClick={() => execute('EDIT')}>Editar</Button>
        </div>
      </div>
    </div>
  )
}

export default LineDetail
