import styled from 'styled-components'

export const GeneratedFilesStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  margin: 1rem;
  .file-download {
    border: 1px solid;
    border-radius: 10px;
    cursor: pointer;
    padding: 5px;
    margin: 1em;
    span {
      position: relative;
      top: 0px;
      left: 80%;
    }
    p {
      text-align: center;
      font-size: 12px;
      font-weight: 600;
      margin-top: 1px;
    }
  }
`
