import styled from 'styled-components'
import CustomerDashboard from 'containers/dashboard/onboarding/customer-dashboard'

const CustomerList = ({ customers, refreshList }) => {
  return (
    <CustomerListStyled>
      <thead>
        <tr>
          <th />
          <th>Denominación o razón social</th>
          <th>Etapa</th>
          <th>Estado</th>
          <th>Semáforo</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {customers?.length === 0 && (
          <tr>
            <td colSpan={8}>No se han encontrado clientes</td>
          </tr>
        )}
        {customers?.length > 0 &&
          customers.map((customer, index) => (
            <CustomerDashboard
              key={`customer-${index}`}
              index={index}
              customer={customer}
              refreshList={refreshList}
            />
          ))}
      </tbody>
    </CustomerListStyled>
  )
}

const CustomerListStyled = styled.table`
  text-align: center;
  width: 100%;
  tbody > tr {
    height: 70px;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      height: 1px;
      width: 100%;
      bottom: 0;
      left: 0;
      background-color: ${({ theme }) =>
        theme.colors.grayShades[400].backgroundColor};
    }
  }
`

export default CustomerList
