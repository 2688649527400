/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Icon, Input, Select, Switch } from '@keoworld/gbl-ui-kit'
import Form from 'containers/form'
import { add } from 'date-fns'
import { AlertContext } from 'providers/alert'
import { useContext, useEffect, useState } from 'react'
import { collectionCreate } from 'services/lines'
import { getLoanConcepts } from 'services/loan'
import styled from 'styled-components'
import { dateFormatFromIso, getUTCDateTime } from 'utils/functions/date'
import { currency } from 'utils/functions/formatters'
import { gaussianRounding } from 'utils/functions/gaussian-rounding'
import { extractNumber } from 'utils/functions/handlers-currency'
import { FormValidationError } from 'utils/schemas/errors'
import { LOAN_STATUS } from 'utils/schemas/loan'
import { PAYMENT_CONCEPT, PAYMENT_SUMMARY } from 'utils/schemas/payments'

const INPUT_DATE_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss"

const verifyErrors = (data) => {
  const formErrors = Object.values(data)
  return formErrors.some((isThereError) => isThereError)
}

const hasElementsRepeated = (array, property) => {
  for (const element of array) {
    const repeated = array.filter(
      (item) => item[property] === element[property]
    )
    if (repeated.length > 1) {
      return true
    }
  }
  return false
}

const PaymentRegister = (props) => {
  const { loan, onHandleClose, showCondonationModal } = props
  const [[MIN_DATE_PAY, MAX_DATE_PAY]] = useState([
    dateFormatFromIso(loan.createdAt, INPUT_DATE_TIME_FORMAT),
    dateFormatFromIso(new Date(), INPUT_DATE_TIME_FORMAT),
  ])
  const [paymentSummary, setPaymentSummary] = useState({
    paymentType: '',
    reference: '',
    date: MAX_DATE_PAY,
    amount: '',
    priority: true,
  })
  const [paymentSummaryErrors, setPaymentSummaryErrors] = useState({
    amount: true,
  })
  const [validating, setValidating] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [paymentConcepts, setPaymentConcepts] = useState([])
  const [errorsPaymentConcepts, setErrorsPaymentConcepts] = useState([])
  const [paymentSchema, setPaymentSchema] = useState(PAYMENT_SUMMARY)
  const [conceptsSchema, setConceptsSchema] = useState(PAYMENT_CONCEPT)
  const [concepts, setConcepts] = useState([])
  const [calculator, setCalculator] = useState({
    totalAmount: 0,
    concepts: 0,
    missingAmount: 0,
  })

  const { setAlert } = useContext(AlertContext)

  const validateForm = () => {
    if (!paymentSummary.paymentType) {
      throw new FormValidationError('select a payment type', 'paymentType')
    }

    if (
      paymentSummary.date < MIN_DATE_PAY ||
      paymentSummary.date > MAX_DATE_PAY
    ) {
      throw new FormValidationError(
        'the date cannot be greater than the current date and less than the creation date of the loan',
        'date'
      )
    }

    if (verifyErrors(paymentSummaryErrors)) {
      throw new FormValidationError('complete correctly the form')
    }

    if (
      !paymentSummary.priority &&
      errorsPaymentConcepts.some((concept) => verifyErrors(concept))
    ) {
      throw new FormValidationError('enter the concepts correctly', 'concepts')
    }

    if (!paymentSummary.priority) {
      const amount = extractNumber(paymentSummary.amount)
      const total = paymentConcepts
        .map((concept) => extractNumber(concept.amount))
        .reduce((acc, amount) => gaussianRounding(acc + amount), 0)

      if (hasElementsRepeated(paymentConcepts, 'concept')) {
        throw new FormValidationError(
          'there are repeated concepts',
          'repeatedConcepts'
        )
      }

      const validateConceptAmounts = paymentConcepts.some(
        ({ concept, amount }) => {
          const debt = concepts.find((debt) => debt.debtId === Number(concept))
          return debt && debt.amount < extractNumber(amount)
        }
      )

      if (validateConceptAmounts) {
        throw new FormValidationError(
          'the amount of the concept cannot be greater than the debt',
          'conceptsAmount'
        )
      }

      if (total !== amount) {
        throw new FormValidationError(
          'the total amount is not equal to the sum of the concepts',
          'amount'
        )
      }
    }
  }

  const onHandleSubmit = async () => {
    try {
      setValidating(true)
      setSubmitLoading(true)

      validateForm()

      const payment = {
        amount: extractNumber(paymentSummary.amount),
        loanId: loan.id,
        madeAt: getUTCDateTime(new Date(paymentSummary.date)),
        priority: paymentSummary.priority,
        reference: paymentSummary.reference,
        source: 'Manual',
        type: paymentSummary.paymentType,
      }

      if (!paymentSummary.priority) {
        payment.concepts = paymentConcepts.map(({ concept, amount }) => ({
          debtId: Number(concept),
          amount: extractNumber(amount),
        }))
      }

      if (paymentSummary.paymentType === 'Condonation') {
        showCondonationModal(payment)
      } else {
        await collectionCreate(payment)

        setAlert({
          type: 'success',
          title: '¡Listo!',
          label: 'Tu pago fue procesado exitosamente.',
        })

        onHandleClose()
      }
    } catch (error) {
      const alertMessage = { title: 'Error', type: 'error', label: '' }
      if (!(error instanceof FormValidationError)) {
        alertMessage.label =
          'Ocurrió un error al procesar el pago. Por favor verifique si ve el cambio, de lo contrario comuníquese con el area de soporte'
        onHandleClose()
      } else if (error.fields === 'paymentType') {
        alertMessage.label = 'Seleccione un tipo de pago'
      } else if (error.fields === 'concepts') {
        alertMessage.label =
          'Complete los campos de los conceptos y vuelva a intentar'
      } else if (error.fields === 'amount') {
        alertMessage.label =
          'La suma de los conceptos debe ser igual al monto total'
      } else if (error.fields === 'date') {
        alertMessage.label =
          'La fecha y hora de pago no puede ser menor a la fecha y hora de creación del préstamo, ni mayor a la fecha y hora actual'
      } else if (error.fields === 'repeatedConcepts') {
        alertMessage.label =
          'Hay conceptos repetidos, por favor seleccione uno solo'
      } else if (error.fields === 'conceptsAmount') {
        alertMessage.label =
          'Uno de los montos es mayor a la deuda de su concepto'
      } else {
        alertMessage.label =
          'Complete los campos obligatorios del formulario y vuelva a intentar'
      }
      setAlert(alertMessage)
    } finally {
      setSubmitLoading(false)
    }
  }

  const addPaymentConcept = () => {
    setPaymentConcepts([...paymentConcepts, { amount: '', concept: '' }])
    setErrorsPaymentConcepts([
      ...errorsPaymentConcepts,
      { amount: true, concept: true },
    ])
  }

  const onRemovePaymentConcept = (index) => {
    setPaymentConcepts((prev) => {
      const array = [...prev]
      array.splice(index, 1)
      return array
    })
    setErrorsPaymentConcepts((prev) => {
      const array = [...prev]
      array.splice(index, 1)
      return array
    })
  }

  useEffect(() => {
    const setForm = () => {
      if (!loan) return
      const { debt, paymentReference } = loan
      setPaymentSummary((prev) => ({
        ...prev,
        amount: currency(debt, { style: undefined, maximumFractionDigits: 2 }),
        date: MAX_DATE_PAY,
        reference: paymentReference,
      }))
    }
    setForm()
  }, [loan])

  useEffect(() => {
    const fetchConcepts = async () => {
      if (!loan?.id) return

      const concepts = await getLoanConcepts({ loanId: loan.id })
      setConcepts(concepts)
    }
    fetchConcepts()
  }, [loan])

  useEffect(() => {
    const mapConceptSchema = async () => {
      const schema = [...PAYMENT_CONCEPT]
      const indexOfConcept = schema.findIndex(
        (field) => field.name === 'concept'
      )
      schema[indexOfConcept].options = concepts
        .filter(({ amount }) => amount > 0)
        .filter((option) => {
          const loanCreatedAtUTC = new Date(loan.createdAt)
          const paymentDateUTC = new Date(paymentSummary.date)
          paymentDateUTC.setMilliseconds(59)

          const conditionDateUTC = add(loanCreatedAtUTC, {
            days: Number(option.initialConceptDay) - 1,
          })

          const loanCreatedAt = new Date(loanCreatedAtUTC.toDateString())
          const paymentDate = new Date(paymentDateUTC.toDateString())
          const conditionDate = new Date(conditionDateUTC.toDateString())

          return loanCreatedAt <= paymentDate && conditionDate <= paymentDate
        })
        .sort((a, b) => a.initialConceptDay - b.initialConceptDay)
        .map((debt) => {
          const { debtId, lineConditionName, amount } = debt
          return {
            value: debtId,
            label: `${lineConditionName} (${currency(amount)})`,
          }
        })

      const missingAmount = concepts.reduce(
        (prev, curr) => prev + curr.amount,
        0
      )
      setConceptsSchema(schema)
      setCalculator((prev) => ({
        ...prev,
        concepts: 0,
        missingAmount,
      }))
    }

    mapConceptSchema()
  }, [paymentSummary.date, loan.createdAt, concepts])

  useEffect(() => {
    if (paymentSummary.paymentType === 'Overpayment') {
      const schema = PAYMENT_SUMMARY.map((field) => ({
        ...field,
        disabled: true,
      }))
      setPaymentSchema(schema)
      setPaymentSummary((prev) => ({
        ...prev,
        date: MAX_DATE_PAY,
        amount: currency(loan.positiveBalance, {
          style: undefined,
          maximumFractionDigits: 2,
        }),
      }))
    } else {
      const schema = PAYMENT_SUMMARY.map((field) => ({
        ...field,
        disabled: field.name === 'reference',
      }))
      setPaymentSchema(schema)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loan.positiveBalance, paymentSummary.paymentType])

  useEffect(() => {
    const totalAmount = extractNumber(paymentSummary.amount) || 0
    setCalculator((prev) => ({
      ...prev,
      totalAmount,
      missingAmount: gaussianRounding(totalAmount - prev.concepts),
    }))
  }, [paymentSummary.amount])

  useEffect(() => {
    if (paymentConcepts.length === 0) return
    const concepts = paymentConcepts.reduce(
      (prev, curr) => prev + extractNumber(curr.amount || 0),
      0
    )
    setCalculator((prev) => ({
      ...prev,
      concepts,
      missingAmount: gaussianRounding(prev.totalAmount - concepts),
    }))
  }, [paymentConcepts])

  return (
    <Styles>
      <h3 className='title'>Detalles del préstamo</h3>
      <div className='loan-information'>
        <div>
          <span className='bold'>Id Préstamo:</span> <span>{loan.id}</span>
        </div>
        <div>
          <span className='bold'>Id Linea:</span> <span>{loan.lineId}</span>
        </div>
        <div>
          <span className='bold'>Id relación:</span>{' '}
          <span>{loan.relationId}</span>
        </div>
        <div>
          <span className='bold'>Monto:</span>{' '}
          <span>{currency(loan.amount)}</span>
        </div>
        <div>
          <span className='bold'>Deuda:</span>{' '}
          <span>{currency(loan.debt)}</span>
        </div>
        <div>
          <span className='bold'>Fecha de solicitud:</span>{' '}
          <span>
            {dateFormatFromIso(loan.createdAt, 'dd/MM/yyyy HH:mm:ss')}
          </span>
        </div>
        <div>
          <span className='bold'>Saldo a favor:</span>{' '}
          <span>{currency(loan.positiveBalance)}</span>
        </div>
        <div>
          <span className='bold'>Nombre de proveedor:</span>{' '}
          <span>{loan.providerName}</span>
        </div>
        <div>
          <span className='bold'>CRN:</span> <span>{loan.crn}</span>
        </div>
      </div>

      <div className='payment-summary'>
        <h3 className='title'>Resumen de pago</h3>
        <form className='form-payment'>
          <Select
            className='form-input'
            label='Tipo de pago'
            name='paymentType'
            value={paymentSummary.paymentType}
            onChange={(e) =>
              setPaymentSummary({
                ...paymentSummary,
                paymentType: e.target.value,
              })
            }
            error={
              validating &&
              paymentSummary.paymentType === '' &&
              'Campo Requerido'
            }
          >
            <option value='' disabled>
              Seleccione un tipo de pago
            </option>
            {loan.status !== LOAN_STATUS.WRITE_OFF && (
              <>
                {/* TODO: Add this option when back fix errors related with overpayment */}
                {/* <option value='Overpayment'>Sobre pago</option> */}
                <option value='Voluntary'>Pago voluntario</option>
                <option value='Condonation'>Condonación de la deuda</option>
              </>
            )}
            {loan.status === LOAN_STATUS.WRITE_OFF && (
              <option value='Recovery'>Recupero</option>
            )}
          </Select>
          <Input
            className='form-input'
            label='Referencia'
            name='reference'
            value={paymentSummary.reference}
            disabled
          />
          <Input
            className='form-input'
            label='Fecha de pago'
            name='date'
            type='datetime-local'
            value={paymentSummary.date}
            onChange={(e) =>
              setPaymentSummary({
                ...paymentSummary,
                date: e.target.value,
              })
            }
            error={
              validating &&
              (paymentSummary.date < MIN_DATE_PAY ||
                paymentSummary.date > MAX_DATE_PAY) &&
              'Campo Requerido'
            }
            min={MIN_DATE_PAY}
            max={MAX_DATE_PAY}
          />
          <Form
            schema={paymentSchema}
            formValues={paymentSummary}
            setFormValues={setPaymentSummary}
            formErrors={paymentSummaryErrors}
            setFormErrors={setPaymentSummaryErrors}
            isValidating={validating}
          />
        </form>
        <div className='priority'>
          <label>¿Seguir la prelación de pago del sistema?</label>
          <Switch
            firstOption='No'
            secondOption='Si'
            checked={paymentSummary.priority}
            onChange={(e) =>
              setPaymentSummary({
                ...paymentSummary,
                priority: e.target.checked,
              })
            }
          />
        </div>
      </div>
      {!paymentSummary.priority && (
        <>
          <div className='add-concept'>
            Agregar conceptos
            <Button
              className='action'
              size='action'
              onClick={() => addPaymentConcept()}
            >
              <Icon name='add' />
            </Button>
          </div>
          <section className='payment-concepts'>
            {paymentConcepts.length > 0 && (
              <>
                <h3 className='title'>Conceptos de pago</h3>
                <div className='payment-concepts-container'>
                  <ul className='payment-concepts-list'>
                    {paymentConcepts.map((concept, index) => (
                      <li key={`concept-${index}`}>
                        <div className='form-concept'>
                          <Form
                            schema={conceptsSchema}
                            formValues={concept}
                            setFormValues={(e) => {
                              setPaymentConcepts((prev) => {
                                prev[index] = e
                                return [...prev]
                              })
                            }}
                            formErrors={errorsPaymentConcepts[index]}
                            setFormErrors={(errors) => {
                              setErrorsPaymentConcepts((prev) => {
                                prev[index] = errors
                                return [...prev]
                              })
                            }}
                            isValidating={validating}
                          />
                          <div className='remove-concept'>
                            <Button
                              className='action'
                              size='action'
                              onClick={() => onRemovePaymentConcept(index)}
                            >
                              <Icon name='delete' type='outlined' />
                            </Button>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <div
                    className='calculator'
                    data-error={calculator.missingAmount !== 0}
                  >
                    <h4 className='bold'>Calculadora</h4>
                    <div className='calculator-values'>
                      <div className='calculator-value'>
                        <span className='bold'>Monto total:</span>{' '}
                        <span>{currency(calculator.totalAmount)}</span>
                      </div>
                      <div className='calculator-value'>
                        <span className='bold'>Suma de conceptos:</span>{' '}
                        <span>{currency(calculator.concepts)}</span>
                      </div>
                      <div className='calculator-value'>
                        <span className='bold'>
                          {calculator.missingAmount < 0
                            ? 'Excedente'
                            : 'Faltante'}
                          :
                        </span>{' '}
                        <span>
                          {currency(Math.abs(calculator.missingAmount))}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </section>
        </>
      )}
      <div className='button-container'>
        <Button
          buttonType='outline'
          disabled={submitLoading}
          onClick={() => onHandleClose()}
        >
          Salir
        </Button>
        <Button
          buttonType='blueDark'
          disabled={submitLoading}
          onClick={() => onHandleSubmit()}
        >
          Realizar Pago
        </Button>
      </div>
    </Styles>
  )
}

const Styles = styled.div`
  position: relative;
  padding: 1rem 70px;
  min-height: calc(100vh - 260px);
  overflow-y: auto;

  .bold {
    font-weight: bold;
  }

  .title {
    margin-bottom: 1rem;
  }

  .loan-information {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    width: 760px;
    padding: 1rem;

    border-radius: 9px;
    background-color: #f6f8fc;

    & > div {
      min-width: 360px;
    }
  }

  .payment-summary {
    margin-top: 3rem;
    margin-bottom: 1rem;
    .form-payment {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(min(200px, 100%), 1fr));
      column-gap: 1rem;
    }
    .priority {
      margin-top: 1rem;
    }
  }

  .add-concept {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 2rem 0;

    .action {
      height: 30px;
      width: 30px;

      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        font-size: 1rem;
      }
    }
  }

  .payment-concepts {
    margin: 2rem 0;

    .payment-concepts-container {
      display: flex;
      justify-content: space-between;
      gap: 1rem;

      .payment-concepts-list {
        list-style: none;
        padding: 0;

        li + li {
          margin-top: 1.5rem;
        }

        .form-concept {
          display: flex;
          gap: 1rem;

          .form-input {
            width: max-content;
            flex: 1;
          }
        }
      }
    }

    .remove-concept {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 15px;

      button.action {
        height: 40px;
        width: 40px;

        color: #b7b7b7;
        background-color: transparent;
        border: none;
      }
    }
  }

  .calculator {
    min-width: 380px;
    height: 180px;
    padding: 30px;

    background-color: white;
    border: 1px solid #00172d;
    border-radius: 9px;

    &[data-error='true'] {
      color: #ff0045;
      border-color: #ff0045;
    }

    .calculator-values {
      border-top: 1px solid #e2e2e2;
      padding-top: 10px;

      .calculator-value {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1rem;
        margin-top: 5px;
      }
    }
  }

  .button-container {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
`

export default PaymentRegister
