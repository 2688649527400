import InfoSummary from 'containers/prospect-detail/info-summary'
import { CustomerContext } from 'providers/customer'
import { LATERAL_MENU_OPTIONS } from 'providers/lateral-bar'
import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import Assignment from 'templates/actions/assignment'
import CommitteeResult from 'templates/actions/committee-actions/committee-result'
import CommitteeSend from 'templates/actions/committee-actions/committee-send'
import ContractReview from 'templates/actions/contract-actions/contract-review'
import ContractUpload from 'templates/actions/contract-actions/contract-upload'
import LoadSignatories from 'templates/actions/contract-actions/load-signatories'
import CreditAnalysis from 'templates/actions/credit-analysis'
import CreditLine from 'templates/actions/credit-line'
import DocumentsCorrection from 'templates/actions/document-actions/document-correction'
import DocumentsReassessment from 'templates/actions/document-actions/documents-reassessment'
import DocumentsUpload from 'templates/actions/document-actions/documents-upload'
import ReviewDocuments from 'templates/actions/document-actions/review-documents'
import LegalRisk from 'templates/actions/legal-risk'
import LegalValidation from 'templates/actions/legal-validation'
import ApproveLineChanges from 'templates/actions/reassessment/approve-line-changes'
import RedistributeRelationScores from 'templates/actions/reassessment/redistribute-relations-scores'
import CustomerCreditStudy from 'templates/actions/underwriter-review/customer-credit-study'
import VisitInfo from 'templates/actions/visit/visit-info'
import VisitRegister from 'templates/actions/visit/visit-register'
import { useWorkflowStateValidation } from 'utils/hooks/workflow-state-validation'
import { CUSTOMER_STATES } from 'utils/schemas/customer'

const ACTIONS_BY_STATE = {
  cust_prospectCreated: DocumentsUpload,
  cust_prospectAssignation: Assignment,
  cust_infoProvided: DocumentsUpload,
  cust_inEvaluation: Assignment,
  cust_inPreEvaluation: Assignment,
  cust_inOperationReview: ReviewDocuments,
  cust_correctionRequest: DocumentsCorrection,
  cust_dossierValidated: CreditAnalysis,
  line_underwriterAssignment: Assignment,
  line_creditReview: CustomerCreditStudy,
  line_evaluationComplete: CreditLine,
  line_preEvaluationComplete: CreditLine,
  cust_uploadingAssessment: DocumentsUpload,
  cust_proposalAccepted: LegalRisk,
  cust_pendingExtraDocs: LegalValidation,
  cust_extraDocsProvided: LegalRisk,
  cust_legalReviewCompleted: VisitRegister,
  cust_pendingVisitApprobation: VisitInfo,
  cust_visitApproved: VisitInfo,
  line_sendingToCommittee: CommitteeSend,
  line_majorCommittee: CommitteeSend,
  line_minorCommittee: CommitteeSend,
  line_committeeResult: CommitteeResult,
  line_committeeRejectedResult: CommitteeResult,
  line_instrumentation: LoadSignatories,
  line_contractGenerated: ContractUpload,
  line_signedDocsProvided: ContractReview,
  line_contractCorrectionRequested: ContractUpload,
  line_updateReview: ApproveLineChanges,
  line_amountReassigment: RedistributeRelationScores,
  line_proposalCreated: Assignment,
  cust_reassesment: DocumentsReassessment,
}

const Action = ({ customerStatus }) => {
  if (!customerStatus) {
    throw new Error('customerStatus is required')
  }
  const Component = ACTIONS_BY_STATE[customerStatus]

  if (!Component) return <></>
  return <Component />
}

const Actions = () => {
  const { customer } = useContext(CustomerContext)
  const workflowValidation = useWorkflowStateValidation()
  const [currentRoute, setCurrentRoute] = useState([])

  const customerStatus = customer?.wfState
  useEffect(() => {
    if (customerStatus && CUSTOMER_STATES[customerStatus].actionName) {
      setCurrentRoute((prev) => [
        ...prev,
        CUSTOMER_STATES[customerStatus].actionName,
      ])
    }
  }, [customerStatus])

  return (
    <StyledAction>
      <div className='info'>
        <InfoSummary
          currentRoute={currentRoute}
          labelOptions={LATERAL_MENU_OPTIONS.PRECUSTOMER_DETAIL}
        />
      </div>
      {workflowValidation && customerStatus && (
        <Action customerStatus={customerStatus} />
      )}
    </StyledAction>
  )
}

const StyledAction = styled.main`
  .info {
    padding: 2rem 2rem 0 2rem;
  }
`

export default Actions
