export const dependentRoles = {
  'bpm.mx-amex-b2b.com-hd': [
    'bpm.mx-amex-b2b.com-hd',
    'bpm.mx-amex-b2b.com-ej',
  ],
  'bpm.mx-amex-b2b.ope-hd': [
    'bpm.mx-amex-b2b.ope-hd',
    'bpm.mx-amex-b2b.ope-jr',
  ],
  'bpm.mx-amex-b2b.rsk-hd': [
    'bpm.mx-amex-b2b.rsk-hd',
    'bpm.mx-amex-b2b.rsk-vp',
    'bpm.mx-amex-b2b.rsk-jr',
  ],
  'bpm.mx-amex-b2b.rsk-vp': [
    'bpm.mx-amex-b2b.rsk-vp',
    'bpm.mx-amex-b2b.rsk-hd',
    'bpm.mx-amex-b2b.rsk-jr',
  ],
  'bpm.mx-amex-b2b.fin-an': ['bpm.mx-amex-b2b.fin-an'],
}

export const STATE_ROLES = {
  createCustomer: ['bpm.mx-amex-b2b.com-hd', 'bpm.mx-amex-b2b.com-ej'],
  cust_prospectAssignation: [
    'bpm.mx-amex-b2b.com-hd',
    'bpm.mx-amex-b2b.com-ej',
  ],
  cust_pendingInfo: ['bpm.mx-amex-b2b.com-hd', 'bpm.mx-amex-b2b.com-ej'],
  cust_prospectCreated: ['bpm.mx-amex-b2b.com-hd', 'bpm.mx-amex-b2b.com-ej'],
  cust_infoProvided: ['bpm.mx-amex-b2b.com-hd', 'bpm.mx-amex-b2b.com-ej'],
  cust_inEvaluation: ['bpm.mx-amex-b2b.ope-hd', 'bpm.mx-amex-b2b.ope-jr'],
  cust_inPreEvaluation: ['bpm.mx-amex-b2b.ope-hd', 'bpm.mx-amex-b2b.ope-jr'],
  cust_uploadingAssessment: [
    'bpm.mx-amex-b2b.com-hd',
    'bpm.mx-amex-b2b.com-ej',
  ],
  cust_inOperationReview: ['bpm.mx-amex-b2b.ope-hd', 'bpm.mx-amex-b2b.ope-jr'],
  cust_correctionRequest: ['bpm.mx-amex-b2b.com-hd', 'bpm.mx-amex-b2b.com-ej'],
  cust_dossierValidated: ['bpm.mx-amex-b2b.ope-hd', 'bpm.mx-amex-b2b.ope-jr'],
  blockedByPLD: ['bpm.mx-amex-b2b.ope-hd', 'bpm.mx-amex-b2b.ope-jr'],
  blockedByBuro: ['bpm.mx-amex-b2b.ope-hd', 'bpm.mx-amex-b2b.ope-jr'],
  line_underwriterAssignment: [
    'bpm.mx-amex-b2b.rsk-vp',
    'bpm.mx-amex-b2b.rsk-hd',
    'bpm.mx-amex-b2b.rsk-jr',
  ],
  line_creditReview: [
    'bpm.mx-amex-b2b.rsk-vp',
    'bpm.mx-amex-b2b.rsk-hd',
    'bpm.mx-amex-b2b.rsk-jr',
  ],
  preEvaluationRejected: [
    'bpm.mx-amex-b2b.rsk-vp',
    'bpm.mx-amex-b2b.rsk-hd',
    'bpm.mx-amex-b2b.rsk-jr',
  ],
  line_preEvaluationComplete: [
    'bpm.mx-amex-b2b.com-hd',
    'bpm.mx-amex-b2b.com-ej',
  ],
  preProposalRejectedByCustomer: [
    'bpm.mx-amex-b2b.rsk-vp',
    'bpm.mx-amex-b2b.rsk-hd',
    'bpm.mx-amex-b2b.rsk-jr',
  ],
  cust_pendingExtraDocs: ['bpm.mx-amex-b2b.com-hd', 'bpm.mx-amex-b2b.com-ej'],
  line_evaluationComplete: ['bpm.mx-amex-b2b.com-hd', 'bpm.mx-amex-b2b.com-ej'],
  proposalRejected: ['bpm.mx-amex-b2b.com-hd', 'bpm.mx-amex-b2b.com-ej'],
  rejectedByCredit: ['bpm.mx-amex-b2b.com-hd', 'bpm.mx-amex-b2b.com-ej'],
  proposalRejectedByCustomer: [
    'bpm.mx-amex-b2b.rsk-vp',
    'bpm.mx-amex-b2b.rsk-hd',
    'bpm.mx-amex-b2b.rsk-jr',
  ],
  cust_proposalAccepted: ['bpm.mx-amex-b2b.ope-hd', 'bpm.mx-amex-b2b.ope-jr'],
  cust_extraDocsProvided: ['bpm.mx-amex-b2b.ope-hd', 'bpm.mx-amex-b2b.ope-jr'],
  cust_legalReviewCompleted: [
    'bpm.mx-amex-b2b.com-hd',
    'bpm.mx-amex-b2b.com-ej',
  ],
  cust_pendingVisitApprobation: ['bpm.mx-amex-b2b.com-hd'],
  cust_visitApproved: ['bpm.mx-amex-b2b.ope-hd'],
  line_sendingToCommittee: [
    'bpm.mx-amex-b2b.rsk-vp',
    'bpm.mx-amex-b2b.rsk-hd',
    'bpm.mx-amex-b2b.rsk-jr',
  ],
  line_majorCommittee: [
    'bpm.mx-amex-b2b.rsk-vp',
    'bpm.mx-amex-b2b.rsk-hd',
    'bpm.mx-amex-b2b.clvl-cm',
  ],
  line_minorCommittee: [
    'bpm.mx-amex-b2b.rsk-vp',
    'bpm.mx-amex-b2b.rsk-hd',
    'bpm.mx-amex-b2b.clvl-cm',
    'bpm.mx-amex-b2b.clvl-ceo',
    'bpm.mx-amex-b2b.clvl-cfo',
  ],
  line_committeeResult: [
    'bpm.mx-amex-b2b.rsk-vp',
    'bpm.mx-amex-b2b.rsk-hd',
    'bpm.mx-amex-b2b.rsk-jr',
  ],
  line_committeeRejectedResult: [
    'bpm.mx-amex-b2b.rsk-vp',
    'bpm.mx-amex-b2b.rsk-hd',
    'bpm.mx-amex-b2b.rsk-jr',
  ],
  line_instrumentation: ['bpm.mx-amex-b2b.ope-hd', 'bpm.mx-amex-b2b.ope-jr'],
  line_contractGenerated: ['bpm.mx-amex-b2b.com-hd', 'bpm.mx-amex-b2b.com-ej'],
  line_signedDocsProvided: ['bpm.mx-amex-b2b.ope-hd', 'bpm.mx-amex-b2b.ope-jr'],
  line_contractCorrectionRequested: [
    'bpm.mx-amex-b2b.com-hd',
    'bpm.mx-amex-b2b.com-ej',
  ],
  line_updateReview: [
    'bpm.mx-amex-b2b.rsk-vp',
    'bpm.mx-amex-b2b.rsk-hd',
    'bpm.mx-amex-b2b.rsk-jr',
  ],
  line_amountReassigment: ['bpm.mx-amex-b2b.ope-hd', 'bpm.mx-amex-b2b.ope-jr'],
  line_proposalCreated: [
    'bpm.mx-amex-b2b.rsk-vp',
    'bpm.mx-amex-b2b.rsk-hd',
    'bpm.mx-amex-b2b.rsk-jr',
  ],
  cust_reassesment: ['bpm.mx-amex-b2b.com-hd', 'bpm.mx-amex-b2b.com-ej'],
  rejected: [],
}

export const ONBOARDING_ROLES_BY_STATE = {
  CREATE_PROPOSAL: ['bpm.mx-amex-b2b.com-hd', 'bpm.mx-amex-b2b.com-ej'],
  ONBOARDING_DOCUMENTS_REVIEW: [
    'bpm.mx-amex-b2b.ope-hd',
    'bpm.mx-amex-b2b.ope-jr',
  ],
  UPLOAD_CORRECTION_DOCUMENTS: [
    'bpm.mx-amex-b2b.com-hd',
    'bpm.mx-amex-b2b.com-ej',
  ],
  BACKGROUND_REVIEW: ['bpm.mx-amex-b2b.ope-hd', 'bpm.mx-amex-b2b.ope-jr'],
  FINANCE_ANALYSIS: [
    'bpm.mx-amex-b2b.rsk-hd',
    'bpm.mx-amex-b2b.rsk-vp',
    'bpm.mx-amex-b2b.rsk-jr',
  ],
  DEBT_CAPACITY_REVIEW_BY_BUYER: [
    'bpm.mx-amex-b2b.com-hd',
    'bpm.mx-amex-b2b.com-ej',
  ],
  PROPERTY_REVIEW: ['bpm.mx-amex-b2b.ope-hd', 'bpm.mx-amex-b2b.ope-jr'],
  ADDITIONAL_DOCUMENTS: ['bpm.mx-amex-b2b.com-hd', 'bpm.mx-amex-b2b.com-ej'],
  BUYER_VISIT: ['bpm.mx-amex-b2b.com-hd', 'bpm.mx-amex-b2b.com-ej'],
  REVIEW_BUYER_VISIT_BY_COMERCIAL: ['bpm.mx-amex-b2b.com-hd'],
  REVIEW_BUYER_VISIT_BY_OPERATION: ['bpm.mx-amex-b2b.ope-hd'],
  REVIEW_BEFORE_COMMITTEE: [
    'bpm.mx-amex-b2b.rsk-hd',
    'bpm.mx-amex-b2b.rsk-vp',
    'bpm.mx-amex-b2b.rsk-jr',
  ],
  MENOR_COMMITTEE: [
    'bpm.mx-amex-b2b.rsk-vp',
    'bpm.mx-amex-b2b.rsk-hd',
    'bpm.mx-amex-b2b.clvl-cm',
  ],
  MAJOR_COMMITTEE: [
    'bpm.mx-amex-b2b.rsk-vp',
    'bpm.mx-amex-b2b.rsk-hd',
    'bpm.mx-amex-b2b.clvl-cm',
    'bpm.mx-amex-b2b.clvl-ceo',
    'bpm.mx-amex-b2b.clvl-cfo',
  ],
  COMMITTEE_RESULT: [
    'bpm.mx-amex-b2b.rsk-vp',
    'bpm.mx-amex-b2b.rsk-hd',
    'bpm.mx-amex-b2b.rsk-jr',
  ],
  COMMITTEE_REJECTED: [
    'bpm.mx-amex-b2b.rsk-vp',
    'bpm.mx-amex-b2b.rsk-hd',
    'bpm.mx-amex-b2b.rsk-jr',
  ],
  CONTRACT_GENERATED: ['bpm.mx-amex-b2b.com-hd', 'bpm.mx-amex-b2b.com-ej'],
  CONTRACT_SIGNED: ['bpm.mx-amex-b2b.ope-hd', 'bpm.mx-amex-b2b.ope-jr'],
  REASSESSMENT: ['bpm.mx-amex-b2b.com-hd', 'bpm.mx-amex-b2b.com-ej'],
  REASSESSMENT_DOCUMENTS_REVIEW: [
    'bpm.mx-amex-b2b.ope-hd',
    'bpm.mx-amex-b2b.ope-jr',
  ],
  BACKGROUND_REVIEW_REASSESSMENT: [
    'bpm.mx-amex-b2b.ope-hd',
    'bpm.mx-amex-b2b.ope-jr',
  ],
  PROPERTY_REVIEW_REASSESSMENT: [
    'bpm.mx-amex-b2b.ope-hd',
    'bpm.mx-amex-b2b.ope-jr',
  ],
  ADDITIONAL_DOCUMENTS_REASSESSMENT: [
    'bpm.mx-amex-b2b.com-hd',
    'bpm.mx-amex-b2b.com-ej',
  ],
}

export const ROLE_LABEL = {
  'bpm.mx-amex-b2b.com-hd': 'Head Comercial',
  'bpm.mx-amex-b2b.com-ej': 'Ejecutivo Comercial',
  'bpm.mx-amex-b2b.ope-hd': 'Head Operaciones',
  'bpm.mx-amex-b2b.ope-jr': 'Operaciones Jr',
  'bpm.mx-amex-b2b.rsk-hd': 'Head of risk',
  'bpm.mx-amex-b2b.rsk-vp': 'VP risk',
  'bpm.mx-amex-b2b.rsk-jr': 'Risk jr',
  'bpm.mx-amex-b2b.clvl-cfo': 'CFO',
  'bpm.mx-amex-b2b.clvl-ceo': 'CEO',
  'bpm.mx-amex-b2b.clvl-cm': 'Country Manager',
}
