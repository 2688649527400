import { CustomerContext } from 'providers/customer'
import { useContext, useEffect, useState } from 'react'
import { CUSTOMER_DB_STATUS } from 'utils/schemas/customer'
import { LINE_STATES } from 'utils/schemas/line'
import { useFetchLines } from './fetch-lines'

export const useReassessmentValidation = () => {
  const { customer } = useContext(CustomerContext)
  const lines = useFetchLines(customer?.id, LINE_STATES.REASSESSMENT)
  const [hasReassessmentInProgress, setHasReassessmentInProgress] =
    useState(false)

  useEffect(() => {
    setHasReassessmentInProgress(
      lines?.length > 0 || customer.status === CUSTOMER_DB_STATUS.REASSESSMENT
    )
  }, [lines, customer.status])

  return hasReassessmentInProgress
}
