import { Button, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import LineConditionsView from 'containers/line/line-conditions-view'
import ModalUpload from 'containers/Modal/modal-documents-upload'
import { CustomerContext } from 'providers/customer'
import { LateralBarContext, LATERAL_MENU_OPTIONS } from 'providers/lateral-bar'
import { useContext, useEffect, useState } from 'react'
import { getLineConditions } from 'services/lines'
import { instanceTransition } from 'services/workflow'
import styled from 'styled-components'
import { CUSTOMER_STAGES, CUSTOMER_STATES } from 'utils/schemas/customer'

const CreditLine = () => {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [openModalUpload, setOpenModalUpload] = useState(false)
  const [lineInformation, setLineInformation] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [isApproved, setIsApproved] = useState(false)
  const { customer } = useContext(CustomerContext)
  const { setAction, setSelectedOption } = useContext(LateralBarContext)

  const handleSubmit = async (approved) => {
    /* const transitionPreEval =
       CUSTOMER_STATES.line_preEvaluationComplete.transition */
    const transitionEval = CUSTOMER_STATES.line_evaluationComplete.transition
    const reassessment = {
      ...transitionEval,
      SEND: {
        event: 'APPROVE_REASSESSMENT',
        nextState: 'cust_customerReady',
        nextStage: 'setup',
      },
    }

    setIsLoadingSubmit(true)
    try {
      const transition = {
        [CUSTOMER_STAGES.assessment.id]: transitionEval,
        [CUSTOMER_STAGES.reassessment.id]: reassessment,
        /* [CUSTOMER_STAGES.preassessment.id]: transitionPreEval */
      }
      const { REJECT, SEND } = transition[customer?.wfStage]
      const {
        event,
        nextState,
        nextStage,
        notificationReceiverRoles,
        notificationReceiverRoles2,
      } = approved ? SEND : REJECT
      const notificationReceiver = customer?.assignations?.find((itm) =>
        (notificationReceiverRoles ?? []).includes(itm?.role)
      )
      const notificationReceiver2 = customer?.assignations?.find((itm) =>
        (notificationReceiverRoles2 ?? []).includes(itm?.role)
      )

      await instanceTransition(
        customer?.lineInstance?.workflowId,
        `${customer?.id}.${customer?.lineInstance?.instanceId}`,
        event,
        {
          'id:status': customer?.id,
          'wfState:status': nextState,
          'wfStage:status': nextStage,
          principalId: `${customer.customerInstance.instanceId}`,
          'receiverUidIam:notificationOne': notificationReceiver?.iamUid,
          'customerId:notificationOne': customer.id,
          'receiverUidIam:notificationOne2': notificationReceiver2?.iamUid,
          'customerId:notificationOne2': customer.id,
        }
      )
      setAction(false)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
    } catch (err) {
      console.error(err)
    }
    setIsLoadingSubmit(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      if (!customer?.lineId) return
      const lineConditions = await getLineConditions(customer.lineId)
      setLineInformation(lineConditions)
      setIsLoading(false)
    }
    fetchData()
  }, [customer?.lineId])

  return (
    <CreditLineStyled>
      <h3>Línea de crédito pre-aprobada</h3>
      <LineConditionsView
        isLoading={isLoading}
        lineData={lineInformation}
      />
      <Button
        buttonType='outline'
        disabled={isLoadingSubmit}
        onClick={() => {
          setOpenModalUpload(true)
          setIsApproved(false)
        }}
      >
        {isLoadingSubmit ? <LoadingAnimation /> : 'Propuesta NO aprobada'}
      </Button>
      <Button
        buttonType='blueDark'
        disabled={isLoadingSubmit}
        onClick={() => {
          setOpenModalUpload(true)
          setIsApproved(true)
        }}
      >
        {isLoadingSubmit ? <LoadingAnimation /> : 'Aprobar Propuesta'}
      </Button>
      <div>
        {openModalUpload && (
          <ModalUpload
            setOpenModalUpload={setOpenModalUpload}
            messages={
              isApproved
                ? {
                    msg: '¿Estás seguro que quieres aprobar la propuesta?',
                    msgButton: 'Confirmar',
                  }
                : {
                    msg: '¿Estás seguro que NO quieres aprobar la propuesta?',
                    msgButton: 'Confirmar',
                  }
            }
            handleSave={() => handleSubmit(isApproved)}
            isLoadingSubmit={isLoadingSubmit}
          />
        )}
      </div>
    </CreditLineStyled>
  )
}

const CreditLineStyled = styled.div`
  padding: 5rem 5%;
  .fcDNbZ span {
    background-color: white;
  }
  .amount-div {
    display: grid;
    margin-top: 2rem;
    grid-template-columns: 1fr 1fr;
    width: 745px;
    height: 170px;
    background: ${({ theme }) => theme.colors.grayShades[300].backgroundColor};
    border-radius: 8px;

    .amount {
      margin-left: 1rem;
      .text-one {
        margin: 2rem auto -2rem auto;
      }
      .text {
        font-size: 29px;
      }
    }
    .detail {
      margin-left: 1rem;
      font-size: 18px;
      .grid {
        margin-top: -0.5rem;
        margin-left: 0.5rem;
        font-size: 14px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        & > div {
          margin: -0.5rem;
        }
        p {
          margin: 0.5rem auto;
        }
      }
    }
  }
  Button {
    margin-top: 1rem;
    margin-right: 1rem;
    width: 188px;
    height: 35p;
  }
  .info {
    span {
      font-size: 15px;
    }
  }
`

export default CreditLine
