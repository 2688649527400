import { Button, Input } from '@keoworld/gbl-ui-kit'
import axios from 'axios'
import Chip from 'components/chip'
import Form from 'containers/form'
import { AlertContext } from 'providers/alert'
import { useContext, useState } from 'react'
import { createProvider } from 'services/relations'
import styled from 'styled-components'
import { PROVIDER_SCHEMA } from 'utils/schemas/relations'

const CreateProvider = ({ closeLateral }) => {
  const { setAlert } = useContext(AlertContext)
  const [provider, setProvider] = useState({})
  const [formError, setFormError] = useState({})
  const [validating, setValidating] = useState(false)
  const [commerceCode, setCommerceCode] = useState('')
  const [commerceCodes, setCommerceCodes] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const validateForm = () => {
    let errors = formError
    if (commerceCodes.length === 0) {
      errors = { ...errors, commerceCode: 'Este campo es requerido' }
      setFormError(errors)
      throw new Error('Commerce code is required')
    }
    const isThereError = Object.values(errors).find((error) => error)
    if (isThereError) {
      throw new Error('Form has errors')
    }
  }

  const onSubmit = async (event) => {
    try {
      event?.preventDefault()
      setValidating(true)
      setIsLoading(true)
      validateForm()
      await createProvider({
        ...provider,
        commerceCodes,
      })
      await closeLateral()
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setAlert({
          label: 'Error al crear el proveedor',
          title: 'Error',
          type: 'error',
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const addCommerceCode = (event) => {
    event?.preventDefault()

    let commerceCodeToAdd = commerceCode.toLocaleLowerCase()

    if (commerceCodes.includes(commerceCodeToAdd)) {
      setFormError({ commerceCode: 'El código de comercio ya fue ingresado' })
      return
    }

    setCommerceCodes([...commerceCodes, commerceCodeToAdd])
    setFormError({ ...formError, commerceCode: '' })
    setCommerceCode('')
  }

  return (
    <ProviderStyled>
      <div className='content'>
        <h3>Crear Proveedor</h3>
        <Form
          schema={PROVIDER_SCHEMA}
          formValues={provider}
          setFormValues={setProvider}
          formErrors={formError}
          setFormErrors={setFormError}
          isValidating={validating}
        />
        <form onSubmit={addCommerceCode}>
          <Input
            label='Código de comercio (presione enter para agregar)'
            name='commerceCode'
            onChange={({ target }) => {
              const value = target.value
              setCommerceCode(value)
              setFormError({ ...formError, commerceCode: value.length === 0 })
            }}
            value={commerceCode}
            error={validating && formError.commerceCode && 'Campo requerido'}
          />
          <div className='commerce-codes'>
            {commerceCodes.map((code, index) => (
              <Chip
                key={`commerce-code-${index}`}
                className='code'
                icon='close'
                label={code}
                onClick={() =>
                  setCommerceCodes(commerceCodes.filter((c) => c !== code))
                }
              />
            ))}
          </div>
        </form>

        <div className='actions'>
          <Button
            className='cancel'
            device='mobileLight'
            buttonType='grayButton'
            onClick={closeLateral}
            disabled={isLoading}
          >
            Cancelar
          </Button>
          <Button
            className='save'
            device='mobileLight'
            onClick={onSubmit}
            disabled={isLoading}
          >
            Crear
          </Button>
        </div>
      </div>
    </ProviderStyled>
  )
}

const ProviderStyled = styled.section`
  .commerce-codes {
    margin-top: 1rem;

    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .code {
      max-width: 100%;
    }
  }

  .actions {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
`

export default CreateProvider
