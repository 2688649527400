import axios from 'axios'
import { commentAdapter } from 'utils/schemas/comment'
import { ENDPOINTS } from './config'

/**
 *
 * @param {number} customerId
 * @returns
 */
export const getCustomerInfo = (customerId) =>
  axios.get(ENDPOINTS.customer.getInfo, { params: { id: customerId } })

/**
 *
 * @param {number} agentId
 * @returns
 */
export const getAgentInfo = (agentId) =>
  axios.get(ENDPOINTS.agent.getInfo, { params: { id: agentId } })

/**
 *
 * @param {number} locationId
 * @returns
 */
export const getLocationInfo = (locationId) =>
  axios.get(ENDPOINTS.location.getInfo, { params: { id: locationId } })

/**
 *
 * @param {number} userId
 * @returns
 */
export const getUserInfo = (userId) =>
  axios.get(ENDPOINTS.user.getInfo, { params: { id: userId } })

/**
 *
 * @param {object} form
 * @param {number} customerId
 * @returns
 */
export const updateCustomer = (form, customerId) =>
  axios.patch(ENDPOINTS.customer.update, { ...form, id: customerId })

/**
 *
 * @param {object} form
 * @param {number} userId
 * @returns
 */
export const updateUser = (form, userId) =>
  axios.patch(ENDPOINTS.user.update, { ...form, id: userId })

/**
 *
 * @param {object} form
 * @param {string} form.address
 * @param {string} form.externalNumber
 * @param {string} form.internalNumber
 * @param {string} form.city
 * @param {string} form.state
 * @param {string} form.municipality
 * @param {string} form.colony
 * @param {number} locationId
 * @returns
 */
export const updateLocation = (form, locationId) =>
  axios.patch(ENDPOINTS.location.update, { ...form, id: locationId })

/**
 *
 * @param {object} form
 * @param {string} form.areaCode
 * @param {string} form.phone
 * @param {string} form.name
 * @param {string} form.firstSurname
 * @param {string} form.secondSurname
 * @param {number} agentId
 * @returns
 */
export const updateAgent = (form, agentId) =>
  axios.patch(ENDPOINTS.agent.update, { ...form, id: agentId })

/**
 * @param {Object} query
 * @param {string} query.stage
 * @param {string} query.status
 * @param {string} query.industry
 * @param {string} query.page
 * @param {string} query.numberOfItems
 * @returns
 */
export const getCustomers = async (query, supplementaryCards) => {
  query.page ??= 0
  query.numberOfItems ??= 8

  if (supplementaryCards === 'supplementaryCards') {
    const { data } = await axios.get(ENDPOINTS.dashboard.onboarding, {
      params: {
        name: query.name,
        stage: query.stage,
        state: query.state,
        role: query.role,
        page: query.page + 1,
        numberOfItems: query.numberOfItems,
      },
    })

    return { customers: data.buyers, fullCount: data.total }
  } else {
    const { data } = await axios.get(ENDPOINTS.customer.list, {
      params: {
        name: query.name,
        industry: query.industry,
        stage: query.stage,
        state: query.state,
        page: query.page + 1,
        role: query.role,
        numberOfItems: query.numberOfItems,
      },
    })

    const customers = data.customerList.map((customer) => {
      if (customer.semaphore === 'Disabled' || !customer.semaphore) {
        return {
          ...customer,
          stateTimeConfig: { color: 'color.disabled', name: 'Por defecto' },
        }
      }

      return {
        ...customer,
        stateTimeConfig: {
          color: customer.semaphore.color,
          name: customer.semaphore.label,
        },
      }
    })
    return { customers, fullCount: data.fullCount }
  }
}

/**
 * function to update user assignation
 * @param {Object} params
 * @param {string} params.id - assignation id
 * @param {string} params.iamId - IAM user id
 * @param {string} params.role - user role
 * @param {string} params.customerId - customer id
 * @returns {Object}
 */
export const assignationUpdate = async (props) => {
  const { id, iamId, uuid, role, customerId } = props
  const { data } = await axios.patch(ENDPOINTS.assign.update, {
    id,
    iamId,
    uuid,
    role,
    customerId,
  })
  return data
}

/**
 *
 * @param {object} form
 * @param {string} form.referrerId
 * @param {string} form.type
 * @returns
 */
export const createReferrer = (form) =>
  axios.post(ENDPOINTS.customer.referrer, form)

/**
 *
 * @param {string} filterWord
 * @returns
 */
export const getIndustries = (filterWord) =>
  axios.get(ENDPOINTS.customer.getIndustries, { params: { filterWord } })

/**
 *
 * @param {number} customerId
 * @param {number} page
 * @param {number} elements
 * @returns
 */
export const getInteractionList = (customerId, page, elements) =>
  axios.get(ENDPOINTS.interaction.list, {
    params: { customerId, page, elements },
  })

/**
 *
 * @param {object} form
 * @returns
 */
export const createInteraction = (form) =>
  axios.post(ENDPOINTS.interaction.create, form)

/**
 *
 * @param {number} interactionId
 * @returns
 */
export const infoInteraction = (interactionId) =>
  axios.get(ENDPOINTS.interaction.info, { params: { interactionId } })

/**
 *
 * @param {object} formData
 * @returns
 */
export const updateInteraction = (formData) =>
  axios.patch(ENDPOINTS.interaction.update, formData)

/**
 *
 * @param {number} customerId
 * @param {Object} query
 * @param {string} query.role
 * @param {number} query.page
 * @param {number} query.limit
 * @param {string} query.request
 */
export const findCustomerComment = async (customerId, query) => {
  const { role, page, limit, request } = query

  const endpoints = {
    onboarding: ENDPOINTS.customerComment.find,
    portfolio: ENDPOINTS.portfolio.comments,
    payments: ENDPOINTS.payment.comments,
  }

  let params = {}

  if (request === 'onboarding') {
    params = { role, page: page + 1, numberOfItems: limit }
  } else {
    params = { offset: page * limit, limit }
  }

  const { data } = await axios.get(endpoints[request], {
    params: { customerId, ...params },
  })

  return commentAdapter(data, request)
}

/**
 * Service to obtain the history of a customer
 * @param {Object} query
 * @param {number} query.customerId - customer id
 * @param {number} query.workflowId - workflow id
 * @param {number} query.page - page number
 * @param {number} query.numberOfItems - number of items per page
 * @param {boolean} [isSupplementaryOnboarding] - flag to determine if the service is for supplementary onboarding
 * @returns {Object}
 */
export const getCustomerHistory = async (query, isSupplementaryOnboarding) => {
  const { customerId, page = 1, numberOfItems = 8 } = query

  const endpoint = isSupplementaryOnboarding
    ? ENDPOINTS.customer.onboardingHistory
    : ENDPOINTS.customer.history

  const { data } = await axios.get(endpoint, {
    params: {
      customerId,
      page,
      numberOfItems,
    },
  })

  return data
}

/**
 *
 * @param {Object} query
 * @param {string} query.state
 * @param {string} query.industry
 * @param {number} query.page
 * @param {number} query.numberOfItems
 * @returns
 */
export const getCustomerStatements = async (query) => {
  const { state, industry, name: customerName, page, limit } = query

  const { data: customerStatements } = await axios.get(
    ENDPOINTS.customer.statement,
    {
      params: { state, industry, customerName, page, elements: limit },
    }
  )

  return customerStatements
}

/**
 * @description Get the list of buyers
 * @param {Object} query
 * @param {string} query.service
 * @param {string} query.role
 * @param {string} query.state
 * @param {string} query.industry
 * @param {number} query.page
 * @param {number} query.elements
 * @returns {{ customers: Array, fullCount: number}}
 */
export const getBuyers = async ({ service, ...params }) => {
  if (service === 'supplementaryCards') {
    const { data: response } = await axios.get(ENDPOINTS.dashboard.buyers, {
      params,
    })
    return response
  } else {
    const { data: customerStatements } = await axios.get(
      ENDPOINTS.customer.statement,
      { params }
    )
    return customerStatements
  }
}

/**
 * @param {object} form
 * @param {string} form.comment
 * @param {number} form.customerId
 * @param {boolean} form.isPublic
 * @param {string} form.role
 * @param {string} form.uuid
 * @param {string} form.wfState
 * @returns
 */
export const createCustomerComment = async (form) => {
  const { data } = await axios.post(ENDPOINTS.customerComment.create, form)
  return data
}

/**
 *
 * @param {number} customerId
 * @param {object} pagination
 * @param {number} pagination.page
 * @param {number} pagination.size
 * @returns
 */
export const getCustomerContracts = async (
  customerId,
  pagination = { page: 1, size: 100 }
) => {
  const { data } = await axios.get(ENDPOINTS.customer.contractFind, {
    params: { customerId, ...pagination },
  })
  return data
}

/**
 *
 * @param {number} customerId
 */
export const getCustomerAssignations = async (customerId) => {
  const { data } = await axios.get(ENDPOINTS.assign.find, {
    params: { customerId },
  })
  return data
}
