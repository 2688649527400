import axios from 'axios'
import { ApiError } from 'utils/schemas/errors'

const REASSESSMENT_URI = 'buyer-reassessment'
const URI = '/buyers'

export class ApiBuyerRepository {
  /**
   * Sends a POST request to the `/api/buyers` endpoint with the given buyer data.
   * @param {Object} buyer - The buyer data to send with the request.
   * @returns {Promise<AxiosResponse>} A promise that resolves to the Axios response object.
   */
  static create(buyer) {
    return axios.post(URI, buyer)
  }

  /**
   * Sends a POST request to the specified buyer's endpoint with the given event and data.
   * @param {string} id - The ID of the buyer.
   * @param {string} event - The event to trigger for the buyer.
   * @param {Object} data - The data to send with the request.
   * @returns {Promise<AxiosResponse>} A promise that resolves to the Axios response object.
   */
  static async doEvent(id, event, data) {
    const response = await axios.put(`${URI}/${id}/${event}`, data)
    const { actionResults } = response.data.body
    return actionResults
  }

  /**
   * Fetches the amounts assigned to the specified customer.
   * @param {Number} customerId - The customer identifier
   * @param {String} [currency] - The currency ISO code
   * @returns {Promise<{ authorizedAmount: Number, requestedAmount: Number, availableAmount: Number }>} The buyer data
   */
  static async obtainAssignedAmountsByCustomer(customerId, currency = 'MXN') {
    try {
      const { data } = await axios.get(`/customer.assignedAmounts`, {
        params: { customerId, currency },
      })

      return data
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        throw error
      }

      const { response } = error
      throw new ApiError(response.data.message, response.status)
    }
  }

  /**
   * Send buyer to reassessment.
   * @param {string} instanceId The ID of the instance.
   * @param {string} customerId The ID of the customer
   * @returns {Promise<AxiosResponse>} A promise that resolves to the Axios response object.
   */
  static async sendToReassessment(instanceId, customerId) {
    try {
      const endpoint = `/${REASSESSMENT_URI}`

      const body = {
        instanceId,
        'id:status': customerId,
        'status:status': 'Reassessment',
        'wfState:status': 'REASSESSMENT',
        'wfStage:status': 'reassessment',
      }

      const { data } = await axios.post(endpoint, body)

      return { error: false, ...data }
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        throw error
      }

      const { response } = error
      throw new ApiError(response.data.message, response.status)
    }
  }

  /**
   * @description Send event into buyer's reassessment
   * @param {String} buyerId - The UID of the buyer.
   * @param {String} event - The event to trigger for the buyer.
   * @param {Object} data - The data to send with the request.
   * @returns {Promise<Object>} The response data.
   */
  static async doReassessmentEvent(buyerId, event, data) {
    try {
      const endpoint = `/buyer-reassessment/${buyerId}/${event}`

      const response = await axios.put(endpoint, data)

      return { error: false, data: response.data }
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        throw error
      }

      const { response } = error
      throw new ApiError(
        'Workflow Error Transition',
        response.data.message,
        response.status
      )
    }
  }
}
