import { useEffect, useState } from 'react'
import { getDocumentList } from 'services/documents'

export const useFetchDocuments = (customerId, filter) => {
  const [documents, setDocuments] = useState([])

  useEffect(() => {
    const fetchDocuments = async () => {
      const { data } = await getDocumentList(customerId, filter)
      setDocuments(data)
    }

    if (customerId) fetchDocuments()
  }, [customerId, filter])

  return documents
}
