import { Button } from '@keoworld/gbl-ui-kit'
import { useState } from 'react'
import styled from 'styled-components'
import { PHYSICAL_PERSON } from 'utils/schemas/commercial'
import Dropdown from './dropdown'
import Form from './form'

const NaturePersonJointly = ({ listPerson, setListPerson }) => {
  const [naturePersonJointly, setNaturePersonJointly] = useState({
    spouse: false
  })

  const addPerson = () => {
    setListPerson((listPerson) => [
      ...listPerson,
      { naturePersonJointly: naturePersonJointly }
    ])
    setNaturePersonJointly({ spouse: false })
  }

  const handleChangeNaturePerson = (e) => {
    const { name, checked } = e.target
    setNaturePersonJointly({ ...naturePersonJointly, [name]: checked })
  }

  return (
    <NaturePersonJointlyStyled>
      <Dropdown
        label={
          'Datos del Acreditado Persona Física/ Principal accionista persona física/ Cónyuge del acreditado o del Principal accionista persona física'
        }
      >
        <div className='form'>
          <Form
            schema={PHYSICAL_PERSON}
            formValues={naturePersonJointly}
            setFormValues={setNaturePersonJointly}
          />
        </div>
        <div>
          <p>Cónyuge</p>
          <label className='switch-div'>
            <p> No</p>
            <label className='switch'>
              <input
                type='checkbox'
                name='spouse'
                onChange={(e) => {
                  handleChangeNaturePerson(e)
                }}
              />
              <span className='slider round' />
            </label>
            <p> Sí</p>
          </label>
        </div>
        <Button
          className='button-add'
          buttonType='grayButton'
          onClick={() => addPerson()}
        >
          + Agregar Persona Física
        </Button>
      </Dropdown>
    </NaturePersonJointlyStyled>
  )
}

const NaturePersonJointlyStyled = styled.div`
  .list-person {
    align-items: center;
    margin: 2rem auto 2rem 0rem;
    padding: 10px;
    background-color: #f0f0f0;
    width: 300px;
    p {
      white-space: nowrap;
      width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0.5rem;
    }
    .icon {
      float: right;
    }
  }
  .div-checkbox {
    display: flex;
    margin: -1rem auto 1rem 0rem;
    align-items: center;
    width: 100px;
    div.input {
      width: 30%;
    }
  }
  .form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 1rem auto;
    Input {
      max-width: 250px;
    }
    Select {
      max-width: 250px;
    }
    & > div {
      padding: 0;
      margin: 0.5rem 0rem;
      &:nth-child(2n + 1) {
        padding-left: 0;
      }
      &:nth-child(2n) {
        padding-right: 0;
      }
    }
  }
`

export default NaturePersonJointly
