import { Button, Select, Switch } from '@keoworld/gbl-ui-kit'
import axios from 'axios'
import Form from 'containers/form'
import { AlertContext } from 'providers/alert'
import { CustomerContext } from 'providers/customer'
import { useContext, useEffect, useState } from 'react'
import {
  createRelation,
  getCategoryList,
  getProviders,
} from 'services/relations'
import styled from 'styled-components'
import { PROVIDER_STATUS } from 'utils/constants'
import { currency } from 'utils/functions/formatters'
import { extractNumberPercentage } from 'utils/functions/handle-percentage'
import { extractNumber } from 'utils/functions/handlers-currency'
import { CUSTOMER_SUPPLIER_SOURCES } from 'utils/schemas/customer'
import { RELATION_SCHEMA } from 'utils/schemas/relations'

const initialForm = {
  score: '',
  days: '',
  dueFromApproval: '',
  percentageReceiver: '',
  categoryId: '',
  providerId: '',
  commerceCode: '',
  source: 'Keo',
}

const CreateRelation = ({ line, closeLateral, fetchRelations }) => {
  const { setAlert } = useContext(AlertContext)
  const { customer } = useContext(CustomerContext)
  const [bppSelector, setBppSelector] = useState(false)
  const [cardPrograms, setCardPrograms] = useState([])
  const [providers, setProviders] = useState([])
  const [relation, setRelation] = useState({
    ...initialForm,
    lineId: `${line.lineId}`,
  })
  const [formError, setFormError] = useState({})
  const [validating, setValidating] = useState(false)
  const [loading, setLoading] = useState(false)
  const [relationFormSchema, setRelationFormSchema] = useState(RELATION_SCHEMA)

  const onHandleChange = (e) => {
    const { name, value } = e.target
    const form = { ...relation, [name]: value }

    if (name === 'providerId') {
      form.commerceCode = ''
    }
    setRelation(form)
  }

  const onHandleSubmit = async () => {
    try {
      setLoading(true)
      setValidating(true)
      const body = {
        source: relation.source,
        score: extractNumber(relation.score),
        days: relation.days !== '' ? Number(relation.days) : undefined,
        dueFromApproval:
          relation.dueFromApproval !== ''
            ? Number(relation.dueFromApproval)
            : undefined,
        percentageReceiver:
          relation.percentageReceiver !== ''
            ? extractNumberPercentage(relation.percentageReceiver)
            : undefined,
        categoryId: Number(relation.categoryId),
        providerId: Number(relation.providerId),
        commerceCode: relation.commerceCode,
        lineId: line.lineId,
        customerId: customer.id,
        bpp: bppSelector,
      }

      if (body.score === 0) {
        setFormError((prev) => ({ ...prev, score: true }))
        return
      }

      const error =
        Object.entries(formError).find(([, value]) => value) ||
        !body.categoryId ||
        !body.providerId ||
        !body.commerceCode

      if (!error) {
        await createRelation(body)
        fetchRelations(line.lineId)
        closeLateral()
      }
    } catch (error) {
      const alert = {
        'The category does not exist': 'La categoría no existe',
        'The i2c service returned an error':
          'El servicio de i2c devolvió un error',
        'Request validation error':
          'Error los campos ingresados de la solicitud',
        'The provider and customer do not match':
          'El cliente fue creado en Keo y el proveedor en callao',
        'The provider has a relation':
          'Ya existe una relación con un proveedor',
        'There are pending relationships': 'Hay relaciones pendientes',
        'The line score is full':
          'El score sobrepasa el monto asignado en la linea',
        'The commerce code does not exist':
          'No se ha creado el proveedor, o el código de comercio no se ha asociado al proveedor',
        default: 'Ocurrió un error al crear la relación',
      }
      const existsError = 'The relation exists with the provider with id'
      const existsAlert = 'Ya existe una relación con este proveedor'
      let label = alert.default
      if (axios.isAxiosError(error)) {
        const { response } = error
        if (response?.data) {
          const detail = response.data?.title ?? response.data?.detail
          label =
            alert[detail] || response.data?.detail?.includes(existsError)
              ? existsAlert
              : null || alert.default
        }
      }
      const isDefault = label === alert.default
      setAlert({
        label,
        title: isDefault ? 'Alerta' : 'Error',
        type: isDefault ? 'warning' : 'error',
      })
      if (isDefault)
        setTimeout(() => {
          fetchRelations(line.lineId)
          closeLateral()
        }, 4000)
    } finally {
      setLoading(false)
    }
  }

  const getCommerceCodes = (providerId) => {
    const commerceCodes = providers.find(
      (provider) => provider.id === Number(providerId)
    )?.commerceCodes
    return commerceCodes || []
  }

  useEffect(() => {
    setRelationFormSchema(
      getFormSchemaByCustomerSource(RELATION_SCHEMA, customer?.tradePlatform)
    )
  }, [customer?.tradePlatform])

  useEffect(() => {
    const obtainCardPrograms = async () => {
      const categories = await getCategoryList('SUPPLEMENTARY')
      setCardPrograms(
        categories.map(({ id, cardProgram }) => ({ id, name: cardProgram }))
      )
    }
    if (customer?.id) {
      obtainCardPrograms()
    }
  }, [customer?.id])

  useEffect(() => {
    const obtainProviders = async () => {
      setProviders([])
      const data = await getProviders({
        status: PROVIDER_STATUS.active,
        source: relation.source,
      })
      setProviders(
        data.map(({ id, name, commerceCodes }) => ({ id, name, commerceCodes }))
      )
    }
    if (customer?.id) {
      obtainProviders()
    }
  }, [customer?.id, relation.source])

  return (
    <RelationModalStyled>
      <form>
        <h3>Crear Relación</h3>
        <div className='available-amount'>
          Monto disponible: {currency(line.score)}
        </div>
        <Form
          schema={relationFormSchema}
          formValues={relation}
          setFormValues={setRelation}
          formErrors={formError}
          setFormErrors={setFormError}
          isValidating={validating}
        />
        <Select
          className='input'
          name='providerId'
          label='Proveedor'
          value={relation.providerId}
          onChange={onHandleChange}
          error={
            validating && !relation.providerId && 'Seleccione un proveedor'
          }
        >
          <option value='' disabled>
            Seleccione una opción
          </option>
          {providers.map(({ id, name }) => (
            <option key={`key-${id}`} value={id}>
              {name}
            </option>
          ))}
        </Select>
        <Select
          className='input'
          name='commerceCode'
          label='Actividad comercial'
          value={relation.commerceCode}
          disabled={!relation.providerId}
          onChange={onHandleChange}
          error={validating && !relation.commerceCode && 'Seleccione un código'}
        >
          <option value='' disabled>
            Seleccione una opción
          </option>
          {getCommerceCodes(relation.providerId).map((commerceCode) => (
            <option key={commerceCode} value={commerceCode}>
              {commerceCode}
            </option>
          ))}
        </Select>
        <Select
          className='input'
          name='categoryId'
          label='Card Program'
          value={relation.categoryId}
          onChange={onHandleChange}
          error={
            validating && !relation.categoryId && 'Seleccione un card program'
          }
        >
          <option value='' disabled>
            Seleccione una opción
          </option>
          {cardPrograms.map((cardProgram) => (
            <option key={cardProgram.id} value={cardProgram.id}>
              {cardProgram.name}
            </option>
          ))}
        </Select>
      </form>

      {relation?.source === CUSTOMER_SUPPLIER_SOURCES.CALLAO && (
        <article className='item-inline'>
          <label bold='true'>BPP</label>
          <Switch
            checked={bppSelector}
            onChange={() => setBppSelector((prev) => !prev)}
            firstOption='No'
            secondOption='Sí'
          />
        </article>
      )}
      <div className='btn-group'>
        <Button buttonType='grayButton' onClick={closeLateral}>
          Cancelar
        </Button>
        <Button disabled={loading} onClick={onHandleSubmit}>
          Crear
        </Button>
      </div>
    </RelationModalStyled>
  )
}

const getFormSchemaByCustomerSource = (
  initialSchema,
  customerTradePlatform
) => {
  const schema = [...initialSchema]
  const sourceFieldIdx = schema.findIndex((itm) => itm.name === 'source')
  if (customerTradePlatform === CUSTOMER_SUPPLIER_SOURCES.KEO) {
    const options = schema[sourceFieldIdx].options.filter(
      (itm) => itm.value === CUSTOMER_SUPPLIER_SOURCES.KEO
    )
    schema[sourceFieldIdx] = { ...schema[sourceFieldIdx], options }
  }
  return schema
}

const RelationModalStyled = styled.section`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  form {
    padding: 0 10px;
    h3 {
      margin-bottom: 15px;
    }

    .input {
      input,
      select {
        background-color: transparent;
      }
    }

    .available-amount {
      margin-bottom: 20px;
    }
  }

  .btn-group {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    button {
      height: 40px;
    }
  }

  .item-inline {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: 5px;

    p {
      margin: 0;
      max-width: 50%;
      text-wrap: balance;
    }

    p + * {
      text-align: end;
    }
  }
`

export default CreateRelation
