import {
  Button,
  Icon,
  Input,
  LoadingAnimation,
  Select,
} from '@keoworld/gbl-ui-kit'
import Chip from 'components/chip'
import PaginatedItems from 'components/pagination'
import { Tabs } from 'components/tabs'
import { CustomerContext } from 'providers/customer'
import { LateralBarContext, LATERAL_MENU_OPTIONS } from 'providers/lateral-bar'
import { useContext, useEffect, useRef, useState, useCallback } from 'react'
import { getBuyers } from 'services/customer'
import styled from 'styled-components'
import { currency } from 'utils/functions/formatters'
import { getUserRoles } from 'utils/functions/role-manager'
import { useOnClickOutside } from 'utils/hooks/click-outside'
import { CUSTOMER_STATES } from 'utils/schemas/customer'

const DASHBOARD_FILTERS = [
  { label: 'Denominación / Razón Social', name: 'name' },
  { label: 'Industria', name: 'industry' },
]
const DASHBOARD_COLUMNS = [
  {
    label: 'Denominación o Razón Social',
    name: 'businessName',
    show: (_state) => true,
  },
  {
    label: 'Industria',
    name: 'industry',
    show: (_state) => true,
  },
  {
    label: 'Monto autorizado',
    name: 'authorizedAmount',
    show: (state) => state === 'customersStatement',
    format: currency,
  },
  {
    label: 'Monto disponible',
    name: 'availableAmount',
    show: (state) => state === 'customersStatement',
    format: currency,
  },
  {
    label: 'Monto Asignado',
    name: 'usedAmount',
    show: (state) => state === 'customersStatement',
    format: currency,
  },
]
const MAX_ITEMS_PER_PAGE = 8

const TAB_FILTER = [
  { label: 'Antiguo flujo', value: 'customersStatement' },
  { label: 'Tarjetas suplementarias', value: 'supplementaryCards' },
]

const filters = {
  page: 0,
  state: undefined,
  industry: undefined,
  name: undefined,
  service: TAB_FILTER[1].value,
}

const Filter = ({ name, label, setFilterSelected }) => {
  const selectFilter = () => {
    setFilterSelected({ name, label })
  }

  return (
    <button onClick={selectFilter}>
      {label} <Icon name='expand_more' />
    </button>
  )
}

const FilterModal = ({ setQuerySearch, filterSelected, setFilterSelected }) => {
  const [filterValue, setFilterValue] = useState('')
  const filterRef = useRef()

  const search = (e) => {
    e.preventDefault()
    setQuerySearch({ ...filterSelected, value: filterValue })
    clearFilter()
  }

  const clearFilter = () => {
    setFilterSelected(undefined)
    setFilterValue(undefined)
  }

  useOnClickOutside(filterRef, clearFilter)

  return (
    <form className='dropdown-filter' onSubmit={search} ref={filterRef}>
      {filterSelected?.name !== 'state' ? (
        <Input
          className='filter'
          label={filterSelected?.label}
          placeholder='ex: xxxx'
          icon={<Icon name='search' onClick={search} />}
          name={filterSelected?.name}
          value={filterValue ?? ''}
          onChange={(e) => setFilterValue(e.target.value)}
        />
      ) : (
        <div className='filter-select'>
          <Select
            label={filterSelected?.label}
            placeholder='ex: xxxx'
            name={filterSelected?.name}
            value={filterValue ?? ''}
            onChange={(e) => setFilterValue(e.target.value)}
          >
            <option disabled value=''>
              Selecciona una opción
            </option>
            {Object.values(CUSTOMER_STATES).map(({ label }, index) => (
              <option
                key={`option-${index}`}
                value={Object.keys(CUSTOMER_STATES)[index]}
              >
                {label}
              </option>
            ))}
          </Select>
          <Icon name='search' onClick={search} />
        </div>
      )}
    </form>
  )
}

const CustomerDashboard = () => {
  const { customer, setCustomer } = useContext(CustomerContext)
  const { setSelectedOption } = useContext(LateralBarContext)
  const [customers, setCustomers] = useState([])
  const [loading, setLoading] = useState(false)
  const [totalItems, setTotalItems] = useState(10)
  const [filter, setFilter] = useState(filters)
  const [filterSelected, setFilterSelected] = useState()

  const setQueryParameter = (name, value, label) => {
    setFilter({
      page: 0,
      ...filter,
      [name]: { label, value },
    })
  }

  const filterParameters = (filter) => {
    return Object.entries(filter).reduce((previous, current) => {
      if (current[1]) {
        const [name, { value, label }] = current
        if (name !== 'stage' && value) {
          return [...previous, { name, value, label }]
        }
      }
      return previous
    }, [])
  }
  const chips = filterParameters(filter)

  const handlePage = (page) => {
    setFilter({ ...filter, page })
  }

  const fetchCustomers = useCallback(async () => {
    setLoading(true)

    const [userRole] = getUserRoles()

    const query = {
      service: filter.service,
      industry: filter.industry?.value,
      state: filter.state?.value,
      customerName: filter.name?.value,
      role: userRole,
      page: filter.page + 1,
      elements: MAX_ITEMS_PER_PAGE,
    }

    const { fullCount, customerList } = await getBuyers(query)

    setCustomers(
      customerList.map((customerStatement) => ({
        id: customerStatement.id,
        businessName: customerStatement.name,
        industry: customerStatement.satIndustries,
        authorizedAmount: customerStatement.authorizedAmount,
        availableAmount: customerStatement.balance,
        usedAmount: customerStatement.usedAmount,
        state: customerStatement.wfState,
        service: filter.service,
      }))
    )

    setTotalItems(fullCount)
    setLoading(false)
  }, [filter])

  useEffect(() => {
    fetchCustomers()
  }, [fetchCustomers])

  useEffect(() => {
    if (customer) setCustomer(undefined)
  }, [customer, setCustomer])

  return (
    <CustomerDashboardStyled>
      <Tabs
        className='flowTabs'
        options={TAB_FILTER}
        initialSelected={TAB_FILTER[1].value}
        onClick={(service) => setFilter({ ...filters, service })}
      />
      <section className='filters'>
        <button disabled>
          Filtro <Icon name='filter_alt' />
        </button>
        {DASHBOARD_FILTERS?.map((filter, index) => {
          return (
            <Filter
              key={`filter-${index}`}
              label={filter.label}
              name={filter.name}
              setFilterSelected={setFilterSelected}
            />
          )
        })}
        {filterSelected && (
          <FilterModal
            filterSelected={filterSelected}
            setFilterSelected={setFilterSelected}
            setQuerySearch={(filter) => {
              const { name, value, label } = filter
              setQueryParameter(name, value, label)
            }}
          />
        )}
      </section>

      {Boolean(chips?.length) && (
        <div className='chips'>
          {chips.map(({ name, value, label }, index) => {
            const removeFilter = () =>
              setFilter({ ...filter, [name]: undefined })

            return (
              <Chip
                key={`chip-${index}`}
                className='chip'
                icon='close'
                label={
                  label === 'Estado' ? CUSTOMER_STATES[value]?.label : value
                }
                onClick={removeFilter}
              />
            )
          })}
        </div>
      )}
      {loading && <LoadingAnimation />}
      {!loading && (
        <table>
          <thead>
            <tr>
              <th />
              {DASHBOARD_COLUMNS.map((column, index) => {
                const { label, name, show } = column
                if (!show(filter.service)) return null
                return <th key={`column-${name}-${index}`}>{label}</th>
              })}
              <th />
            </tr>
          </thead>
          <tbody>
            {customers.map((customer, index) => {
              const onClickAction = () => {
                setCustomer(customer)
                sessionStorage.setItem('dashboardService', filter.service)
                setSelectedOption(LATERAL_MENU_OPTIONS.CUSTOMER_DETAIL)
              }

              return (
                <tr key={`customer-${index}`}>
                  <td>
                    <div className='profile-picture'>
                      <span>
                        {customer.businessName.slice(0, 2).toUpperCase()}
                      </span>
                    </div>
                  </td>
                  {DASHBOARD_COLUMNS.map((column) => {
                    const { name, show, format = (value) => value } = column
                    if (!show(filter.service)) return null
                    return <td>{format(customer[name])}</td>
                  })}
                  <td>
                    <Button
                      buttonType='blueDark'
                      className='action'
                      onClick={onClickAction}
                    >
                      Ver
                    </Button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
      {!loading && totalItems > 8 && (
        <div className='pagination'>
          <PaginatedItems
            currentPage={filter.page}
            handlePageClick={(event) => handlePage(event.selected)}
            pageCount={Math.ceil(totalItems / MAX_ITEMS_PER_PAGE)}
          />
        </div>
      )}
    </CustomerDashboardStyled>
  )
}

const CustomerDashboardStyled = styled.div`
  .filters {
    position: relative;
    width: 100%;
    height: 50px;

    display: flex;
    padding-left: calc(10% - 5px);

    .dropdown-filter {
      position: absolute;
      bottom: -60px;

      padding: 0 25px 10px;
      width: 400px;

      border-radius: 30px;

      ${({ theme }) => theme.colors.grayShades[500]}

      label {
        text-transform: uppercase;
      }

      input {
        ${({ theme }) => theme.colors.grayShades[500]}
      }

      .icon {
        cursor: pointer;
      }
    }
    .filter-select {
      display: flex;
      align-items: center;
      justify-content: center;
      select {
        ${({ theme }) => theme.colors.grayShades[500]}
      }
      .icon {
        margin: 1em 0.5em 0 0.5em;
      }
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;

      border: none;
      background-color: transparent;

      min-width: 120px;
      position: relative;
      padding: 0 0.8rem;
      padding-top: 1rem;

      cursor: pointer;

      &:disabled {
        color: ${({ theme }) => theme.colors.grayShades[600].backgroundColor};
        cursor: default;
      }

      &.filter.selected::after {
        content: '';
        position: absolute;
        left: 11px;
        bottom: 0.1rem;

        background-color: ${({ theme }) =>
          theme.colors.grayShades[700].backgroundColor};

        width: 30%;
        height: 3px;
      }
    }
  }
  .onboarding {
    position: absolute;
    right: 1rem;
    top: 5rem;
  }

  .chips {
    display: flex;
    flex-wrap: wrap;

    gap: 10px;

    margin-top: 10px;
    padding: 0 15px;
  }

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 300px;
  }

  table {
    width: 90%;
    margin: 20px auto;

    text-align: center;

    tbody > tr {
      height: 70px;
      position: relative;

      td {
        .profile-picture {
          width: 50px;
          height: 50px;

          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          border-radius: 50%;
          border: 2px solid
            ${({ theme }) => theme.colors.grayShades[700].backgroundColor};
          ${({ theme }) => theme.colors.grayShades[300]};
        }

        button.action {
          width: 120px;
          height: 30px;
        }
      }

      &::after {
        content: '';
        position: absolute;
        height: 1px;
        width: 100%;
        bottom: 0;
        left: 0;
        background-color: ${({ theme }) =>
          theme.colors.grayShades[400].backgroundColor};
      }
    }
  }

  .pagination {
    margin: 1rem auto;
    ul {
      margin: auto;
    }
  }
`

export default CustomerDashboard
