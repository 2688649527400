import { Icon } from '@keoworld/gbl-ui-kit'
import { useEffect, useRef, useState } from 'react'
import { getPendingNotifications } from 'services/notifications'
import styled from 'styled-components'
import { useAuth } from 'utils/hooks/auth'
import NotificationBar from './notification-bar'
import logo from '../assets/images/keo-core-bmp-logo.svg'
import { useOnClickOutside } from 'utils/hooks/click-outside'

const AppBar = () => {
  const { signOut, user } = useAuth()
  const [openDropdown, setOpenDropdown] = useState(false)
  const [openNotifications, setOpenNotifications] = useState(false)
  const [pendingNotifications, setPendingNotifications] = useState(0)
  const dropdownRef = useRef()

  useOnClickOutside(dropdownRef, () => setOpenDropdown(false))

  const email = user?.email
  const profile = email && email.substring(0, 2)

  useEffect(() => {
    if (user?.iam?.id) {
      const unsubscribe = getPendingNotifications(
        setPendingNotifications,
        user?.iam?.id
      )
      return () => unsubscribe()
    }
  }, [user?.iam?.id])

  return (
    <AppBarStyled className='app-bar'>
      <div className='content-image'>
        <img src={logo} alt='logo' />
      </div>
      <button
        className='notifications'
        onClick={() => setOpenNotifications(true)}
      >
        <Icon name='notifications' type='outlined' />
        {pendingNotifications !== 0 && (
          <span className='pending-notifications'>
            {pendingNotifications < 9 ? pendingNotifications : '+'}
          </span>
        )}
      </button>
      <div className='profile' ref={dropdownRef}>
        <button
          className='profile-picture'
          onClick={() => setOpenDropdown(!openDropdown)}
        >
          {profile}
        </button>
        {openDropdown && (
          <div className='profile-dropdown'>
            <div className='email'>{email}</div>
            <button onClick={signOut}>Cerrar sesión</button>
          </div>
        )}
      </div>
      {openNotifications && (
        <NotificationBar onClose={() => setOpenNotifications(false)} />
      )}
    </AppBarStyled>
  )
}

const AppBarStyled = styled.header`
  width: 100%;
  height: 70px;
  position: relative;
  background-color: #00172d;
  display: flex;
  align-items: center;
  font-weight: 500;

  .content-image {
    display: grid;
    place-content: center;

    margin-left: 2rem;

    img {
      width: 176px;
    }
  }

  .profile {
    position: relative;

    button {
      border: none;
      background-color: transparent;
    }

    .profile-picture {
      position: relative;

      text-transform: uppercase;
      color: white;

      width: 40px;
      height: 40px;
      border-radius: 50px;

      background-color: #ff0045;
      margin-right: 2rem;
      cursor: pointer;

      display: grid;
      place-content: center;
    }

    .profile-dropdown {
      border-radius: 20px;

      position: absolute;
      z-index: 5;
      top: 3rem;
      right: 10px;

      ${({ theme }) => theme.colors.grayShades[800]};

      padding: 15px 0;
      display: grid;

      div.email {
        padding: 5px 15px;
      }

      button {
        text-align: center;
        color: white;
        padding: 5px 15px;
        &:hover {
          ${({ theme }) => theme.colors.grayShades[700]};
        }
      }
    }
  }

  .notifications {
    position: relative;
    background-color: transparent;
    display: grid;
    place-content: center;
    height: max-content;
    margin-left: auto;
    margin-right: 2rem;

    color: white;
    border: none;

    .icon {
      font-size: 25px;
    }

    .pending-notifications {
      width: 10px;
      height: 10px;
      border-radius: 50%;

      position: absolute;
      top: 5px;
      right: 5px;

      ${({ theme }) => theme.colors.primary.main};
      font-size: 7pt;
      font-weight: bold;

      display: grid;
      place-content: center;
    }
  }
`

export default AppBar
