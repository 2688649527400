import { Button, LoadingAnimation, Select } from '@keoworld/gbl-ui-kit'
import ModalUpload from 'containers/Modal/modal-documents-upload'
import LateralBar from 'containers/lateral-bar'
import { AlertContext } from 'providers/alert'
import { LATERAL_MENU_OPTIONS, LateralBarContext } from 'providers/lateral-bar'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiBuyerRepository } from 'services/buyer-suppcards/buyer.repository'
import { getDocumentList } from 'services/documents'
import { getContract } from 'services/lines'
import styled from 'styled-components'
import { BuildSection } from 'templates/actions/document-actions/build-section'
import Router from 'utils/router'
import {
  CUSTOMER_STATES,
  CUSTOMER_SUPPLIER_SOURCES,
  CUSTOMER_SUPPLIER_SOURCE_OPTIONS,
} from 'utils/schemas/customer'
import {
  CONTRACT_DOCUMENTS,
  CONTRACT_STATES,
  FILE_STAGES,
  FILE_STATES,
} from 'utils/schemas/documents'
import { ONBOARDING_STATES } from 'utils/schemas/workflows/onboarding'

const ContractReview = () => {
  const { customerId } = useParams()
  const navigate = useNavigate()

  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [files, setFiles] = useState({})
  const [commentInfo, setCommentInfo] = useState(false)
  const [isValidating, setIsValidating] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [messageModal, setMessageModal] = useState('')
  const [supplierSourceInput, setSupplierSourceInput] = useState(
    CUSTOMER_SUPPLIER_SOURCES.KEO
  )
  const { setAction, setSelectedOption } = useContext(LateralBarContext)
  const { setAlert } = useContext(AlertContext)

  const handleSupplierInputSwitch = ({ target }) =>
    setSupplierSourceInput(target?.value)

  const validateDocs = () => {
    const arrayDocuments = Object.values(files).flat()
    arrayDocuments.forEach((document) => {
      document.status =
        document.status !== FILE_STATES.ACCEPTED
          ? FILE_STATES.REFUSED
          : FILE_STATES.ACCEPTED
    })
    const allAccepted = arrayDocuments.every(
      (itm) => itm.status === FILE_STATES.ACCEPTED
    )
    return { arrayDocuments, allAccepted }
  }

  const handleShowModal = async () => {
    const msgs = {
      ACCEPTED: `Todos los documentos fueron aceptados, el prospecto será enviado a ${CUSTOMER_STATES.line_approved.label}`,
      NOT_ACCEPTED: `Algunos documentos NO fueron aceptados, el prospecto será enviado a ${CUSTOMER_STATES.line_contractCorrectionRequested.label}`,
    }
    const { allAccepted } = validateDocs()
    setOpenModal(true)
    if (allAccepted) setMessageModal(msgs.ACCEPTED)
    else setMessageModal(msgs.NOT_ACCEPTED)
  }

  const handleSubmit = async () => {
    setIsLoadingSubmit(true)
    setIsValidating(true)

    const { completeOnboarding, completeOnboardingWithCallao, requestCorrections } =
      ONBOARDING_STATES.CONTRACT_SIGNED.transition

    try {
      const { arrayDocuments, allAccepted } = validateDocs()

      const { contracts } = await getContract(
        customerId,
        CONTRACT_STATES.Review
      )
      const [contractInfo] = contracts

      const multiUpdateIds = arrayDocuments.map((file) => ({
        id: file.id,
        status: file.status,
        typeId: file.typeId,
      }))

      let event = ''
      let eventData = {
        'fileList:multiUpdate': multiUpdateIds,
        'id:status': Number(customerId),
        'tradePlatform:status': supplierSourceInput,
      }

      if (allAccepted) {
        if (supplierSourceInput === CUSTOMER_SUPPLIER_SOURCES.KEO) {
          event = completeOnboarding.event
        } else {
          event = completeOnboardingWithCallao.event
        }
        eventData = {
          ...eventData,
          'status:status': 'Ready',
          'wfState:status': completeOnboarding.nextState,
          'wfStage:status': completeOnboarding.nextStage,
          'contractId:contractUpdate': contractInfo.id,
          'status:contractUpdate': CONTRACT_STATES.Active,
        }
      } else {
        event = requestCorrections.event
        eventData = {
          ...eventData,
          'wfState:status': requestCorrections.nextState,
          'wfStage:status': requestCorrections.nextStage,
          'contractId:contractUpdate': contractInfo.id,
          'status:contractUpdate': CONTRACT_STATES.Draft,
        }
      }

      await ApiBuyerRepository.doEvent(customerId, event, eventData)
    } catch (err) {
      console.error('SubmitError', err, err?.status)
      setAlert({
        title: 'Error',
        label:
          'Ocurrió un error al activar el prospecto. Por favor, ingrese al dashboard de clientes y verifique el alta del mismo. De no ver el cambio, comuníquese con el Area de Soporte',
        type: 'error',
      })
    } finally {
      setIsLoadingSubmit(false)
      setAction(undefined)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      navigate(Router.Root)
    }
  }

  /* Fetch files effect */
  useEffect(() => {
    const fetchFiles = async () => {
      if (!customerId) return

      const { data: filesData } = await getDocumentList(customerId, {
        stage: FILE_STAGES.INSTRUMENTATION,
      })
      let contractFiles = {}
      filesData.forEach((itm) => {
        const extension = itm.fileExtension ? `.${itm.fileExtension}` : ''
        if (!contractFiles[itm.typeId]) contractFiles[itm.typeId] = []
        contractFiles[itm.typeId].push({
          name: `${itm.fileName}${extension}`,
          size: itm.fileSize,
          status: itm.fileStatus,
          id: itm.fileId,
          typeId: itm.typeId,
        })
      })
      setFiles(contractFiles)
    }
    fetchFiles()
  }, [customerId])

  return (
    <ContractContainerStyled>
      <div>
        <h5>Documentos de contrato</h5>
        <BuildSection
          allowUpload={false}
          allowReview={true}
          allowDelete={false}
          allowUpdate={false}
          schema={CONTRACT_DOCUMENTS.generalInformation.documents}
          files={files}
          setFiles={setFiles}
          isValidating={isValidating}
          setCommentBarInfo={setCommentInfo}
        />
      </div>
      <div className='container'>
        <h4>Seleccionar flujo de customer:</h4>
        <label className='source-selector'>
          <Select
            name='supplierSource'
            label='Flujo de customer'
            value={supplierSourceInput}
            onChange={(e) => handleSupplierInputSwitch(e)}
          >
            {CUSTOMER_SUPPLIER_SOURCE_OPTIONS.map((itm, idx) => (
              <option value={itm.value} key={`opt-src-${idx}`}>
                {itm.label}
              </option>
            ))}
          </Select>
        </label>
      </div>
      <div className='action-group'>
        <Button
          buttonType='grayButton'
          onClick={() => {
            navigate(-1)
            setAction(false)
          }}
        >
          Cancelar
        </Button>
        <Button
          buttonType='green'
          className='send'
          disabled={isLoadingSubmit}
          onClick={() => handleShowModal()}
        >
          {isLoadingSubmit ? (
            <LoadingAnimation className='loading' />
          ) : (
            'Enviar'
          )}
        </Button>
      </div>
      {commentInfo && (
        <LateralBar
          setLateralBar={setCommentInfo}
          fileId={commentInfo.fileId}
          documentName={commentInfo.fileName}
        />
      )}
      {openModal && (
        <ModalUpload
          setOpenModalUpload={setOpenModal}
          messages={{
            msg: messageModal,
            msgButton: 'Confirmar',
          }}
          handleSave={handleSubmit}
          isLoadingSubmit={isLoadingSubmit}
        />
      )}
    </ContractContainerStyled>
  )
}

const ContractContainerStyled = styled.div`
  padding: 2rem;
  padding-bottom: 1rem;
  .loading span {
    background-color: white;
  }
  h3 {
    margin-top: 1rem;
  }
  h4 {
    font-weight: bold;
    margin-top: 1rem;
  }
  .container {
    margin: 0 0.5rem;
  }
  .proposal {
    display: flex;
    justify-content: space-between;
    p {
      font-weight: bold;
    }
    .input-section {
      display: flex;
      justify-content: center;
      align-items: center;
      .grey {
        ${({ theme }) => theme.colors.grayShades[300]};
        border-radius: 6px;
        height: 39px;
        margin-right: 1rem;
        text-align: center;
        width: 50px;
        p {
          margin: 0.5rem auto;
        }
      }
      .input {
        max-width: 200px;
      }
    }
  }
  .action-group {
    margin: 2rem 0.5rem 0;

    button + button {
      margin-left: 28px;
    }
    .send {
      float: right;
    }
  }
  .file-row {
    display: flex;
    align-items: center;

    .button-section {
      display: flex;
      margin-left: 5%;
      button {
        width: 50px;
        border-radius: 6px;
        &.checked {
          ${({ theme }) => theme.buttons.outline.hover}
        }
      }
      button + button {
        margin-left: 15px;
      }
    }
  }
  .source-selector {
    display: flex;
  }
`

export default ContractReview
