import axios from 'axios'
import { ENDPOINTS } from './config'

/**
 *
 * @param {object} form
 * @param {string} form.address
 * @param {string} form.city
 * @param {string} form.state
 * @param {string} form.municipality
 * @param {string} form.colony
 * @param {string} form.externalNumber
 * @param {string} form.internalNumber
 * @returns
 */
export const createLocation = (form) =>
  axios.post(ENDPOINTS.location.create, form)

/**
 *
 * @param {object} form
 * @param {string} form.name
 * @param {string} form.firstSurname
 * @param {string} form.secondSurname
 * @param {number} form.sharePercentage
 * @param {string} form.tin
 * @param {string} form.zipCode (QUIT FROM DB)
 * @param {string} form.areaCode
 * @param {string} form.phone
 * @param {string} form.civilStatus
 * @param {string} form.email
 * @param {number} locationId
 * @returns
 */
export const createPerson = (form, locationId) =>
  axios.post(ENDPOINTS.person.create, { ...form, locationId })

/**
 *
 * @param {object} form
 * @param {string} form.areaCode - not for business
 * @param {string} form.phone - not for business
 * @param {string} form.name
 * @param {string} form.firstSurname
 * @param {string} form.secondSurname
 * @returns
 */
export const createAgent = (form) => axios.post(ENDPOINTS.agent.create, form)

/**
 *
 * @param {object} form
 * @param {string} form.name
 * @param {string} form.tin
 * @param {string} form.mainActivity
 * @param {string} form.zipCode (QUIT FROM DB)
 * @param {string} form.areaCode
 * @param {string} form.phone
 * @param {string} form.email
 * @param {number} agentId
 * @param {number} locationId
 * @returns
 */
export const createBusiness = (form, agentId, locationId) =>
  axios.post(ENDPOINTS.business.create, { ...form, agentId, locationId })

/**
 *
 * @param {object} form
 * @param {number} personId
 * @returns
 */
export const updateBusiness = (form, businessId) =>
  axios.patch(ENDPOINTS.business.update, { ...form, id: businessId })

/**
 *
 * @param {number} shareholderId
 * @returns
 */
export const getShareholderInfo = (shareholderId) =>
  axios.get(ENDPOINTS.shareholder.getInfo, { params: { id: shareholderId } })

/**
 *
 * @param {number} personId
 * @returns
 */
export const getPersonInfo = (personId) =>
  axios.get(ENDPOINTS.person.getInfo, { params: { id: personId } })

/**
 *
 * @param {number} businessId
 * @returns
 */
export const getBusinessInfo = (businessId) =>
  axios.get(ENDPOINTS.business.getInfo, { params: { id: businessId } })

/**
 *
 * @param {object} form
 * @param {number} shareholderId
 * @returns
 */
export const updateShareholder = (form, shareholderId) =>
  axios.patch(ENDPOINTS.shareholder.update, { ...form, id: shareholderId })

/**
 *
 * @param {object} form
 * @param {number} personId
 * @returns
 */
export const updatePerson = (form, personId) => {
  axios.patch(ENDPOINTS.person.update, { ...form, id: personId })
}
