import { Button, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import axios from 'axios'
import LateralBar from 'containers/lateral-bar'
import ModalUpload from 'containers/Modal/modal-documents-upload'
import { AlertContext } from 'providers/alert'
import { CustomerContext } from 'providers/customer'
import { LateralBarContext, LATERAL_MENU_OPTIONS } from 'providers/lateral-bar'
import { useContext, useEffect, useState } from 'react'
import { getDocumentList, uploadDocuments } from 'services/documents'
import { instanceTransition } from 'services/workflow'
import styled from 'styled-components'
import { getUserRoles } from 'utils/functions/role-manager'
import { useAuth } from 'utils/hooks/auth'
import { CUSTOMER_STATES } from 'utils/schemas/customer'
import {
  CREDIT_ANALYSIS,
  FILE_STAGES,
  FILE_STATES,
} from 'utils/schemas/documents'
import { BuildSection } from './document-actions/build-section'

const pldTypeId = CREDIT_ANALYSIS.find((itm) => itm?.name === 'pld')?.typeId

const CreditAnalysis = () => {
  const [files, setFiles] = useState({})
  const { setAlert } = useContext(AlertContext)
  const [isPublic, setIsPublic] = useState(false)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [isValidating, setIsValidating] = useState(false)
  const [comment, setComment] = useState('')
  const [commentBarInfo, setCommentBarInfo] = useState(false)
  const { setAction, setSelectedOption } = useContext(LateralBarContext)
  const { customer } = useContext(CustomerContext)
  const { user } = useAuth()
  const [openModalUpload, setOpenModalUpload] = useState(false)
  const [isRejectedProspect, setIsRejectedProspect] = useState(false)
  const [isValidationSuccessful, setIsValidationSuccessful] = useState(false)

  const handleUpload = async (defaultState = FILE_STATES.DRAFT) => {
    const userRole = getUserRoles()[0]
    const filesToUpload = Object.entries(files).reduce((acc, curr) => {
      const [typeFileId, documents] = curr
      return {
        ...acc,
        [typeFileId]: documents.map((document) => {
          document.status ??= defaultState
          return document
        }),
      }
    }, {})
    const response = await uploadDocuments(
      filesToUpload,
      customer.id,
      user.uid,
      userRole
    )
    if (response.some((request) => request.status !== 'fulfilled')) {
      throw new Error('error saving files')
    }
    return response.map((request) => request.value)
  }

  const validateFields = () => {
    setIsValidating(true)
    let hasErrorFiles
    const hasErrorComment = !Boolean(comment && comment !== '')
    hasErrorFiles = CREDIT_ANALYSIS.some(
      ({ typeId }) => !Object.keys(files).includes(String(typeId))
    )
    const errorUndeletedFiles = Object.values(files).some(
      (filesArray) =>
        !filesArray.some((file) => file.status !== FILE_STATES.DELETED)
    )
    hasErrorFiles = hasErrorFiles || errorUndeletedFiles
    const validateIsCorrect = !(hasErrorComment || hasErrorFiles)
    setIsValidationSuccessful(validateIsCorrect)
    if (!validateIsCorrect)
      setAlert({
        title: 'Completa el formulario',
        label: 'Por favor completa el formulario',
        type: 'info',
      })
    return validateIsCorrect
  }

  const isProspectRejected = () => {
    const validate = validateFields()

    /* Valid if there is at least one rejected document */
    const hasInvalidDocument = Object.values(files).some((filesArray) =>
      filesArray.some(
        (file) =>
          file.status !== FILE_STATES.ACCEPTED &&
          file.status !== FILE_STATES.DELETED
      )
    )
    if (hasInvalidDocument) setIsRejectedProspect(true)
    else handleSend(validate)
  }

  const handleSend = async (validate) => {
    try {
      setIsLoadingSubmit(true)
      if (!validate) {
        throw new Error('missing fields')
      }
      const result = await handleUpload(FILE_STATES.REFUSED)
      const fileList = result.map(({ id, status }) => ({ id, status }))
      let updateStatusInfo
      let roleHeadAssignation = 'bpm.mx-amex-b2b.rsk-hd'
      const transition = CUSTOMER_STATES.cust_dossierValidated.transition
      const fileRejected = fileList.find(
        ({ status }) => status !== FILE_STATES.ACCEPTED
      )
      const isReassessment = customer?.status === 'Reassessment'

      if (!fileRejected && !isReassessment) {
        updateStatusInfo =
          customer?.lineInstance?.currentState === 'line_proposalCreated'
            ? transition.SEND_TO_ASSIGNMENT
            : transition.SEND_TO_CREDIT_REVIEW
      } else if (!fileRejected && isReassessment) {
        updateStatusInfo = transition.SEND_TO_LEGAL
        roleHeadAssignation = 'bpm.mx-amex-b2b.ope-hd'
      } else {
        updateStatusInfo =
          fileRejected.typeId === pldTypeId
            ? transition.BLOCK
            : transition.REJECT
      }

      const notificationReceiver = customer?.assignations?.find((itm) =>
        (updateStatusInfo?.notificationReceiverRoles ?? []).includes(itm?.role)
      )

      let body = {
        'fileList:multiUpdate': fileList,
        'wfState:status': updateStatusInfo.nextState,
        'comment:comment': comment,
        'wfState:comment': customer?.wfState,
        'isPublic:comment': isPublic,
        'uuid:comment': user?.uid,
        'role:comment': getUserRoles()[0],
        customerId: customer.id,
        'role:assignation': roleHeadAssignation,
        principalId: `${customer.id}.${customer.lineId}`,
        'customerId:notificationOne': customer.id,
        'receiverUidIam:notificationOne': notificationReceiver?.iamUid,
        'customerId:notificationMany': customer.id,
      }

      await instanceTransition(
        customer?.customerInstance?.workflowId,
        customer?.customerInstance?.instanceId,
        updateStatusInfo.event,
        body
      )

      setAction(false)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
    } catch (error) {
      const ERRORS = {
        'error saving files': {
          close: true,
          label: 'Ha ocurrido un error al guardar los archivos',
        },
        'workflow error': {
          close: true,
          label:
            'Los documentos se han guardado, pero ha ocurrido un error al enviar. Por favor ingresa al prospecto e intenta nuevamente',
        },
        'missing fields': {
          close: false,
          label: 'Por favor completa los campos requeridos',
        },
        default: {
          close: true,
          label:
            'Ha ocurrido un error, si el problema persiste contacta a soporte',
        },
      }

      if (axios.isAxiosError(error)) {
        const { data } = error.response
        console.error('workflow error:', data.body?.message)
        error.message = 'workflow error'
      }

      const { label, close } = ERRORS[error.message] || ERRORS.default
      setAlert({ label, title: 'Error', type: 'error' })
      if (close) {
        setAction(false)
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      }
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  const handleSave = async () => {
    try {
      setIsLoadingSubmit(true)
      await handleUpload()
      setAction(false)
      setOpenModalUpload(false)
    } catch (error) {
      console.error(error.message)
      const alert = {
        label:
          'Ha ocurrido un error al guardar los archivos, por favor intenta de nuevo',
        title: 'Error',
        type: 'error',
      }
      if (error.message === 'error saving files') {
        setAction(false)
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      }
      setAlert(alert)
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  /* Fetch saved files Effect */
  useEffect(() => {
    try {
      const fetchFiles = async () => {
        const { data: savedFilesData } = await getDocumentList(customer.id, {
          stage: FILE_STAGES.RISK,
          status: [
            FILE_STATES.DRAFT,
            FILE_STATES.ACCEPTED,
            FILE_STATES.REFUSED,
          ].join(','),
        })
        let savedFiles = {}
        CREDIT_ANALYSIS.forEach((schema) => {
          let documents = savedFilesData
            .filter((itm) => itm.typeId === schema.typeId)
            .map((itm) => {
              const extension = itm.fileExtension ? `.${itm.fileExtension}` : ''
              return {
                name: `${itm.fileName}${extension}`,
                size: itm.fileSize,
                status: itm.fileStatus,
                id: itm.fileId,
                typeId: itm.typeId,
              }
            })

          savedFiles[schema.typeId] = documents
        })
        setFiles(savedFiles)
      }
      if (customer?.id) fetchFiles()
    } catch (err) {
      console.error('err', err)
    }
  }, [customer?.id])

  return (
    <CreditAnalysisStyled
      commentWarning={isValidating && (!comment || comment === '')}
    >
      <h3>Análisis de riesgo</h3>
      <CustomerDocumentsStyled>
        <BuildSection
          allowUpload={true}
          allowReview={true}
          allowDelete={true}
          allowUpdate={true}
          schema={CREDIT_ANALYSIS}
          title={'Documentos'}
          files={files}
          setFiles={setFiles}
          isValidating={isValidating}
          setCommentBarInfo={setCommentBarInfo}
        />
      </CustomerDocumentsStyled>
      <h3>Comentario sobre el prospecto</h3>
      <div className='content'>
        <textarea
          className='textarea'
          placeholder='Comentarios'
          onChange={(e) => setComment(e.target.value)}
          value={comment}
        />
        <div className='switch-content'>
          <p className='comment'>
            <b>Comentario:</b>
          </p>
          <label className='switch-div'>
            <p> Interno</p>
            <label className='switch'>
              <input
                type='checkbox'
                onChange={(e) => {
                  setIsPublic(e.target.checked)
                }}
              />
              <span className='slider round' />
            </label>
            <p> General</p>
          </label>
        </div>
      </div>
      <div className='action-group'>
        <Button
           buttonType='outline'
          disabled={isLoadingSubmit}
          onClick={() => {
            setOpenModalUpload(true)
          }}
        >
          {isLoadingSubmit && <LoadingAnimation className='loading' />}
          {!isLoadingSubmit && 'Guardar'}
        </Button>
        <Button
          buttonType='blueDark'
          disabled={isLoadingSubmit}
          onClick={() => isProspectRejected()}
        >
          {isLoadingSubmit && <LoadingAnimation className='loading' />}
          {!isLoadingSubmit && 'Enviar'}
        </Button>
      </div>
      {commentBarInfo && (
        <LateralBar
          setlateralBar={setCommentBarInfo}
          documentName={commentBarInfo?.fileLabel}
          fileId={commentBarInfo?.fileId}
        />
      )}
      {openModalUpload && (
        <ModalUpload
          setOpenModalUpload={setOpenModalUpload}
          isLoadingSubmit={isLoadingSubmit}
          handleSave={handleSave}
        />
      )}
      {isRejectedProspect && isValidationSuccessful && (
        <ModalUpload
          setOpenModalUpload={setIsRejectedProspect}
          messages={{
            msg: 'Algunos documentos no fueron aceptados. ¿Esta seguro que desea rechazar al prospecto? Si rechaza al prospecto ya no podrá retomar el proceso.',
            msgButton: 'Aceptar',
          }}
          isLoadingSubmit={isLoadingSubmit}
          handleSave={() => handleSend(isValidationSuccessful)}
        />
      )}
    </CreditAnalysisStyled>
  )
}

const CreditAnalysisStyled = styled.div`
  padding: 1rem 2rem;
  .loading span {
    background-color: white;
  }
  .content {
    display: flex;
  }
  .switch-content {
    .comment {
      line-height: 0px;
      margin: 1rem;
    }
  }
  .switch-div {
    display: flex;
    margin: 0rem 1rem;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin: 0.5rem 0rem 0.5rem 0.5rem;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 8px;
    left: 0px;
    right: 14px;
    bottom: 11px;
    background-color: #939191;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 22px;
    width: 22px;
    left: -1px;
    bottom: -3.5px;
    background-color: #bab9b9;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #939191;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
  F .documents-risk {
    width: 80%;
  }
  .blBNhs {
    min-width: 60%;
  }
  .textarea {
    padding: 10px;
    font-family: 'Poppins';
    font-weight: bold;
    border-radius: 21px;
    width: 55%;
    height: 108px;
    margin: 1rem 0rem;
    resize: none;
    border-color: ${({ theme, commentWarning }) =>
      commentWarning ? theme.alertTheme.error.backgroundColor : 'none'};
  }
  h3 {
    margin: 1rem auto;
  }
  .action-group {
    margin: 2rem 42px 0;
    button + button {
      margin-left: 28px;
    }
  }

  .row {
    display: flex;
    align-items: center;
    .iScOtA {
      width: 500px;
    }
    .button-section {
      display: flex;
      margin-left: 5%;
      button {
        width: 50px;
        border-radius: 6px;
      }
      button + button {
        margin-left: 15px;
      }
    }
  }
`
const CustomerDocumentsStyled = styled.section`
  .center-loading {
    height: 50vh;
    display: flex;
    align-items: center;
    div {
      width: 150px;
    }
  }
  .checked {
    ${({ theme }) => theme.buttons.outline.hover}
  }
  h3 {
    margin: 1rem auto;
  }

  section {
    width: 65%;
  }
`

export default CreditAnalysis
