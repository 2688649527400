import { validateValue } from './validators'

export const validateForm = (schema, formValues) => {
  return schema.reduce((acc, curr) => {
    const { name, validatorType, validatorParams, required = true } = curr

    if (!required && !formValues[name]) {
      return { ...acc, [name]: false }
    }

    const isRight = validateValue(formValues[name], {
      type: validatorType,
      params: validatorParams,
    })
    const errorMessage = schema.errorMessage || true
    return { ...acc, [name]: !isRight ? errorMessage : false }
  }, {})
}

export const validateErrorsObject = (errorsObject = {}) => {
  return Object.values(errorsObject).some((value) => value)
}
