const Tooltip = () => {
  return (
    <div className='tooltipText'>
      <p>
        <span>Anulación de pagos: </span>
         Permite revertir uno o varios pagos que
        fueron aplicados a las disposiciones que aún no se encuentran cerradas.
        <br/>
        <span> Reestructuración: </span>
         Permite cerrar una disposición que aún
        tiene deuda y abrir una nueva disposición con condiciones diferentes.
        <br/>
        <span> Write Off:  </span>
        Permite cerrar una disposición y enviar la
        deuda pendiente a Cartera castigada.
        <br/>
        <span> Congelamiento:  </span>
         Permite detener la generación de intereses
        y/o comisiones de una disposición.
      </p>
    </div>
  )
}
export default Tooltip
