/**
 * @param {number} bytes
 * @param {'KB' | 'MB' | 'GB'} to
 * @returns
 */
export const bytesConverter = (bytes, to = 'MB') => {
  const units = {
    KB: 1000,
    MB: 1e6,
    GB: 1e9,
  }
  const total = Number(bytes) / units[to]
  return Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  }).format(total)
}
