import {
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth'
import { SessionContext } from 'providers/session-manager'
import { useContext } from 'react'
import { auth, microsoftProvider } from 'services/firebase.controller'

export const useAuth = () => {
  const { userAuth, setUserAuth } = useContext(SessionContext)

  /**
   * function to sign in with email and password
   * @param {string} email
   * @param {string} password
   */
  const signInWithEmail = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password)
    } catch (error) {
      console.log('error', error)
    }
  }

  /**
   * function to sign in with microsoft account
   */
  const signInWithMicrosoft = async () => {
    try {
      const { _tokenResponse } = await signInWithPopup(auth, microsoftProvider)
      window.localStorage.setItem('oauthIdToken', _tokenResponse.oauthIdToken)
    } catch (error) {
      console.log('error', error)
    }
  }

  /**
   * function to sign out
   */
  const signOutController = () => {
    signOut(auth)
    setUserAuth()
    localStorage.clear()
    sessionStorage.clear()
  }

  /**
   * @constant {boolean} isSignedIn - true if user is signed in
   */
  const isAuthenticated = Boolean(userAuth)

  return {
    isAuthenticated,
    signInWithEmail,
    signInWithMicrosoft,
    signOut: signOutController,
    user: userAuth,
  }
}
