import {
  Button,
  Input,
  LoadingAnimation,
  Select,
  Textarea,
} from '@keoworld/gbl-ui-kit'
import { CustomerContext } from 'providers/customer'
import { useContext, useEffect, useState } from 'react'
import {
  createInteraction,
  infoInteraction,
  updateInteraction,
} from 'services/customer'
import styled from 'styled-components'
import { dateFormatFromIso } from 'utils/functions/date'
import { useAuth } from 'utils/hooks/auth'

const SELECT_OPTIONS = [
  { label: 'Llamada', value: 'Llamada' },
  { label: 'E-Mail', value: 'E-Mail' },
  { label: 'Visita', value: 'Visita' },
  { label: 'Otro', value: 'Otro' },
]

const initialFormValues = {
  action: '',
  checkAt: '',
  comment: '',
  madeAt: '',
}

const ComercialActivityRegister = (props) => {
  const { closeModal, modalId, onSuccess } = props
  const [formFinal, setFormFinal] = useState(initialFormValues)
  const [isLoading, setIsLoading] = useState(false)
  const [validating, setValidating] = useState(false)
  const { customer } = useContext(CustomerContext)
  const { user } = useAuth()
  const today = dateFormatFromIso(new Date(), 'yyyy-MM-dd')

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormFinal({ ...formFinal, [name]: value })
  }

  const handleSubmit = async () => {
    try {
      setValidating(true)
      setIsLoading(true)

      const { action, checkAt, comment, madeAt } = formFinal

      if (!user || !customer || !action || !checkAt || !comment || !madeAt) {
        return
      }
      if (checkAt < today || checkAt < madeAt) {
        return
      }

      const body = {
        customerId: customer.id,
        checkAt,
        madeAt,
        uuid: user.uid,
        action,
        comment,
      }

      if (!modalId) {
        await createInteraction(body)
      } else {
        body.interactionId = modalId
        await updateInteraction(body)
      }
      await onSuccess()
      setFormFinal({})
      closeModal()
    } catch (error) {
      console.error('Error:', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const getInfo = async () => {
      try {
        setIsLoading(true)
        const { data } = await infoInteraction(modalId)
        setFormFinal(data)
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    if (modalId) {
      getInfo()
    }
  }, [modalId])

  return (
    <ComercialActivityStyles>
      <h3>Agregar actividad comercial</h3>
      <form>
        <Select
          className='input'
          label='Acción'
          name='action'
          error={validating && !formFinal.action && 'Campo requerido'}
          value={formFinal.action ?? ''}
          onChange={(e) => handleChange(e)}
        >
          <option
            disabled
            value=''
          >
            Selecciona una opción
          </option>
          {SELECT_OPTIONS.map(({ label, value }, index) => (
            <option
              key={`option-${index}`}
              value={value}
            >
              {label}
            </option>
          ))}
        </Select>

        <Input
          className='input'
          label='Fecha de actividad'
          placeholder='XXXXXX'
          type='date'
          name='madeAt'
          error={validating && !formFinal.madeAt && 'Campo requerido'}
          value={formFinal.madeAt ?? ''}
          onChange={(e) => handleChange(e)}
        />

        <Input
          className='input'
          label='Fecha de seguimiento'
          placeholder='XXXXXX'
          type='date'
          name='checkAt'
          value={formFinal.checkAt ?? ''}
          onChange={(e) => handleChange(e)}
          min={today}
          error={
            validating &&
            (!formFinal.checkAt ||
              formFinal.checkAt < today ||
              formFinal.checkAt < formFinal.madeAt) &&
            'Campo requerido'
          }
        />
      </form>
      <Textarea
        className='textarea'
        placeholder='Comentarios'
        name='comment'
        value={formFinal?.comment ?? ''}
        onChange={(e) => handleChange(e)}
        error={validating && !formFinal?.comment}
      />

      <div className='button-section'>
        <Button
          buttonType='outline'
          onClick={closeModal}
          disabled={isLoading}
        >
          Cancelar
        </Button>
        <Button
          buttonType='blueDark'
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? <LoadingAnimation className='loading' /> : 'Crear'}
        </Button>
      </div>
    </ComercialActivityStyles>
  )
}

const ComercialActivityStyles = styled.div`
  padding: 2rem;
  form {
    width: 100%;
    margin-bottom: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

    .input {
      .icon {
        margin: 0;
      }
    }

    .input,
    input,
    select {
      min-width: auto;
      width: 250px;
    }
  }
  .button-section {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export default ComercialActivityRegister
