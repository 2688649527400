import axios from 'axios'
import { ENDPOINTS } from './config'

/**
 * function to get users
 * @param {Object} params
 * @param {string} params.name - user's name
 * @param {string[]} params.roles - user's roles
 * @param {string} params.uuid - user's uuid
 * @returns {Object[]>}
 */
export const getUsers = async ({ roles, name, uuid }) => {
  const { data } = await axios.post(ENDPOINTS.user.find, {
    projectCode: process.env.REACT_APP_PROJECT_ID,
    name,
    roles,
    uuid
  })
  return data
}

/**
 * function to get user profile
 * @param {Object} params
 * @param {string} params.uuid - user's uuid
 * @param {string} params.userId - user's id
 * @returns {Object}
 */
export const getUserProfile = async ({ uuid, userId }) => {
  const { data } = await axios.post(ENDPOINTS.profile.find, {
    uuid,
    userId,
    projectId: process.env.REACT_APP_IAM_PROJECT_ID
  })

  return data
}
