import styled from 'styled-components'
import { dateFormatFromIso } from 'utils/functions/date'

const CommentCard = (props) => {
  const { comment = {}, category = {} } = props
  const { label: categoryName, color: categoryColor } = category
  const { title, createdAt, message } = comment

  return (
    <CardStyled>
      <div className='category' style={{ color: categoryColor }}>
        {categoryName || 'Sin titulo'}
      </div>
      <div className='card-header'>
        <div className='comment-title'>{title}</div>
        <div className='comment-created-at'>
          {createdAt && dateFormatFromIso(createdAt, 'dd/MM/yyyy')}
          {!createdAt && 'DD/MM/YYYY'}
        </div>
      </div>
      <div className='card-content'>
        <p className='comment-message'>{message || 'sin comentario'}</p>
      </div>
    </CardStyled>
  )
}

const CardStyled = styled.div`
  width: 380px;
  min-height: 195px;
  padding: 15px;
  flex-shrink: 0;

  border-radius: 10px;
  border: 1px solid #d9d9d9;
  background: #fff;

  .category {
    width: max-content;
    height: 21px;

    font-size: 9px;
    font-weight: 400;

    border-radius: 10px;
    border: 1px solid currentColor;
    background: color-mix(in srgb, currentColor 20%, transparent);

    display: grid;
    place-items: center;
    flex-shrink: 0;

    padding: 0 10px;
    margin-bottom: 10px;

    text-transform: capitalize;
  }

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    .comment-title {
      font-weight: bold;
      font-size: 14px;
      font-weight: 600;
      text-transform: capitalize;
    }
    .comment-created-at {
      color: #979797;
      font-size: 12px;
      font-weight: 400;
    }
  }

  .card-content .comment-message {
    overflow-wrap: break-word;
    text-transform: lowercase;
    &:first-letter {
      text-transform: capitalize;
    }
  }
`

export default CommentCard
