import { dateFormatFromIso } from 'utils/functions/date'

export const PORTFOLIO_FREEZE_FORM = [
  {
    name: 'freezingDate',
    label: 'Fecha de inicio de congelamiento',
    type: 'date',
    min: dateFormatFromIso(undefined, 'yyyy-MM-dd'),
  },
  {
    name: 'unfreezingDate',
    label: 'Fecha para descongelar automáticamente (Opcional)',
    type: 'date',
    required: false,
    min: dateFormatFromIso(undefined, 'yyyy-MM-dd'),
  },
]

export const PORTFOLIO_UNFREEZE_FORM = [
  {
    name: 'unfreezingDate',
    label: 'Fecha de descongelamiento',
    type: 'date',
    min: dateFormatFromIso(undefined, 'yyyy-MM-dd'),
  },
]

export const INITIAL_VALUES_FREEZE = {
  freezingDate: '',
  unfreezingDate: '',
}

export const INITIAL_VALUES_UNFREEZE = {
  unfreezingDate: '',
}

export const PORTFOLIO_ACTIONS = {
  cancelPayments: 'CANCEL_PAYMENTS',
  restructureLoan: 'RESTRUCTURE_LOAN',
  writeOff: 'WRITE_OFF',
  freezeLoan: 'FREEZE_LOAN',
  unfreezeLoan: 'UNFREEZE_LOAN',
  paymentRegister: 'PAYMENT_REGISTER',
  loanList: 'LOAN_LIST',
  openLateralBar: 'OPEN_LATERAL_BAR',
  showPopUp: 'SHOW_POP_UP',
  paymentCondonation: 'PAYMENT_CONDONATION',
}

export const POPUP_CONFIG = {
  [PORTFOLIO_ACTIONS.cancelPayments]: {
    title: 'Anular Pago',
    icon: 'paid',
    mark: true,
    inputLabel: 'Explica brevemente el motivo de la anulación',
    showMessage: false,
    successMessage:
      'Los pagos anulados de la disposición {loanId} fueron procesados exitosamente',
    errorMessage:
      'La anulación de pagos no se pudo realizar, por favor revisa el estado de la disposición e intenta nuevamente',
  },
  [PORTFOLIO_ACTIONS.restructureLoan]: {
    title: 'Reestructurar disposición',
    icon: 'paid',
    mark: true,
    inputLabel: 'Explica brevemente el motivo de la reestructuración',
    showMessage: true,
    successMessage:
      'Disposición reestructurada exitosamente, se creó una nueva disposición con ID {loanId}',
    errorMessage:
      'No se pudo completar la reestructuración, por favor revisa el estado de la disposición e intenta nuevamente',
  },
  [PORTFOLIO_ACTIONS.writeOff]: {
    title: 'Write Off',
    icon: 'paid',
    mark: true,
    inputLabel: 'Explica brevemente el motivo del write off',
    showMessage: true,
    successMessage: 'Disposición {loanId} enviada a Write Off exitosamente',
    errorMessage:
      'No se pudo enviar a Write off la disposición {loanId} por favor revisa el estado de la disposición e intenta nuevamente',
  },
  [PORTFOLIO_ACTIONS.freezeLoan]: {
    title: 'Congelar disposición',
    icon: 'paid',
    mark: true,
    inputLabel: 'Explica brevemente el motivo del congelamiento',
    showMessage: false,
    successMessage: 'Disposición {loanId} congelada exitosamente',
    errorMessage:
      'No se pudo congelar la disposición {loanId}, por favor revisa el estado de la disposición e intenta nuevamente',
  },
  [PORTFOLIO_ACTIONS.unfreezeLoan]: {
    title: 'Descongelar disposición',
    icon: 'paid',
    mark: true,
    inputLabel: 'Explica brevemente el motivo del descongelamiento',
    showMessage: false,
    successMessage: 'Disposición {loanId} descongelada exitosamente',
    errorMessage:
      'No se pudo descongelar la disposición {loanId}, por favor revisa el estado de la disposición e intenta nuevamente',
  },
  [PORTFOLIO_ACTIONS.paymentCondonation]: {
    title: 'Condonación de pagos',
    icon: 'paid',
    mark: false,
    inputLabel: 'Explica brevemente el motivo de la condonación',
    showMessage: false,
    successMessage:
      'Los pagos de la disposición {loanId} fueron condonados exitosamente',
    errorMessage:
      'No se pudo realizar la condonación, por favor revisa el estado de la disposición e intenta nuevamente',
  },
  default: {
    title: '',
    icon: 'paid',
    mark: false,
    inputLabel: '',
    showMessage: false,
  },
}

export const LOAN_ACTION_CATEGORIES = {
  Freeze: { label: 'Congelar', color: '#00575B' },
  Unfreeze: { label: 'Descongelar', color: '#751644' },
  WriteOff: { label: 'Write Off', color: '#00172d' },
}
