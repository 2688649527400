import axios from 'axios'
import { ApiError } from 'utils/schemas/errors'
import { v4 as UUIDv4 } from 'uuid'

const URI = `lines`

/**
 * @class ApiLineRepository
 * @classdesc A class for making requests to the Line API.
 * @exports ApiLineRepository
 * @static {Function} buildHeader - Create the headers required to the request.
 * @static {Function} create - Create Line
 * @static {Function} sendToReassessment - Update line conditions
 */
export class ApiLineRepository {
  /**
   * Create the headers required to the request.
   * @returns {Object} The headers to use for the request.
   */
  static buildHeader = () => {
    return {
      headers: {
        'x-auth-workflow': localStorage.getItem('oauthIdToken'),
      },
    }
  }

  /**
   * Create Line
   * @param {Object} line - The line data to send with the request.
   * @param {string} line.id - The ID of the line.
   * @param {number} line.balance - The balance of the line.
   * @param {string} line.contractId - The ID of the contract.
   * @param {string} line.primaryCardId - The ID of the primary card.
   * @param {string} line.productId - The ID of the product.
   * @param {string} line.cardProgram - The name of the card program.
   * @param {boolean} line.isAssignedToTrust - Whether
   * @param {number} line.amount - The amount of the line.
   * @param {string} line.lineConditionId - The ID of the line condition.
   * @param {string} line.startDate - The start date of the line.
   * @param {string} line.endingDate - The ending date of the line.
   * @param {number} line.maxTerm - The maximum term of the line.
   * @param {Array<Object>} line.commissions - The commissions of the line.
   * @param {Array<Object>} line.terms - The terms of the line.
   * @returns {Promise<AxiosResponse>} A promise that resolves to the Axios response object.
   * @throws {Error} If the request fails.
   */
  static async create(line) {
    await axios.post(
      `/${URI}`,
      { instanceId: line.id },
      ApiLineRepository.buildHeader()
    )

    await axios.put(
      `/${URI}/${line.id}/create`,
      {
        'id:line': line.id,
        'id:lineTDC': line.id,
        balance: line.amount,
        contractId: line.contractId,
        productId: process.env.REACT_APP_TDC_PRODUCT_ID,
        isAssignedToTrust: line.isAssignedToTrust ?? false,
        'id:primaryCard': line.primaryCardId,
        'id:lineCond': line.lineConditionId,
        'id:lineCondTDC': line.lineConditionId,
        'buyerId:primaryCard': line.buyerId,
        'cardProgramName:primaryCard': line.cardProgram,
        amount: line.amount,
        startDate: line.startDate,
        endingDate: line.endingDate,
        maxTerm: line.maxTerm,
        commissions: line.commissions,
        terms: line.terms,
        lineId: line.id,
        pathParams: {
          lineId: line.id,
        },
      },
      ApiLineRepository.buildHeader()
    )

    return line.id
  }

  /**
   * @description Update line conditions
   * @param {string} instanceId - The workflow identifier.
   * @param {Object} line - The line data to send with the request.
   * @param {string} line.id - The ID of the line.
   * @param {number} line.balance - The balance of the line.
   * @param {string} line.contractId - The ID of the contract.
   * @param {string} line.primaryCardId - The ID of the primary card.
   * @param {string} line.productId - The ID of the product.
   * @param {string} line.cardProgram - The name of the card program.
   * @param {number} line.amount - The amount of the line.
   * @param {string} line.lineConditionId - The ID of the line condition.
   * @param {string} line.startDate - The start date of the line.
   * @param {string} line.endingDate - The ending date of the line.
   * @param {number} line.maxTerm - The maximum term of the line.
   * @param {Array<Object>} line.commissions - The commissions of the line.
   * @param {Array<Object>} line.terms - The terms of the line.
   */
  static async updateConditions(instanceId, line) {
    try {
      const event = 'update-conditions'
      const endpoint = `/${URI}/${instanceId}/${event}`

      const lineConditionId = UUIDv4()

      const terms = line.terms.map((term) => {
        return {
          ...term,
          lineConditionId,
          id: UUIDv4(),
        }
      })

      const commissions = line.commissions.map((commission) => {
        return {
          ...commission,
          lineConditionId,
          id: UUIDv4(),
        }
      })

      const body = {
        'id:lineConditionChange': lineConditionId,
        'id:lineConditionChangeTDC': lineConditionId,
        lineId: line.id,
        maxTerm: line.maxTerm,
        amount: line.amount,
        terms,
        commissions,
        'terms:lineConditionChangeTDC': [],
        'commissions:lineConditionChangeTDC': [],
      }

      const { data } = await axios.put(endpoint, body)

      const TDC_SERVICE = 1
      const actionResults = data.body.actionResults
      const TDCResult = actionResults[TDC_SERVICE]

      return {
        error: false,
        data: TDCResult?.response?.data,
      }
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        throw error
      }

      const { response } = error
      const errorData = response.data.body

      throw new ApiError('Error: Workflow', errorData.message, errorData.status)
    }
  }

  /**
   * @description Increase line amount assigned
   * @param {String} lineId - The ID of the line.
   * @param {String} crn - The card reference number.
   * @param {Number} amount - The amount to increase.
   */
  static async increaseAmount(lineId, crn, amount) {
    try {
      const event = 'increase-amount'
      const endpoint = `/${URI}/${lineId}/${event}`

      const body = {
        id: lineId,
        crn,
        amount,
      }

      const { data } = await axios.put(endpoint, body)

      return { error: false, data }
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        throw error
      }

      const { response } = error
      const errorData = response.data.body

      throw new ApiError('Error: Workflow', errorData.message, errorData.status)
    }
  }

  /**
   * @description Decrease line amount assigned
   * @param {string} lineId - The ID of the line.
   * @param {String} crn - The card reference number.
   * @param {number} amount - The amount to decrease.
   */
  static async decreaseAmount(lineId, crn, amount) {
    try {
      const event = 'decrease-amount'
      const endpoint = `/${URI}/${lineId}/${event}`

      const body = {
        id: lineId,
        crn,
        amount,
      }

      const { data } = await axios.put(endpoint, body)

      return { error: false, data }
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        throw error
      }

      const { response } = error
      const errorData = response.data.body

      throw new ApiError('Error: Workflow', errorData.message, errorData.status)
    }
  }

  /**
   * @description Assign a line to trust
   * @param {String} lineId - The ID of the line.
   * @param {Boolean} isAssignedToTrust - Whether the line is assigned to trust.
   * @returns {Promise<Object>} The response data.
   */
  static async assignToTrust(lineId, isAssignedToTrust) {
    try {
      const event = 'assigned-to-trust'
      const endpoint = `/${URI}/${lineId}/${event}`

      const body = {
        pathParams: { lineId },
        isAssignedToTrust: isAssignedToTrust,
      }

      const { data } = await axios.put(endpoint, body)

      return { error: false, data }
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        throw error
      }

      const { response } = error
      const errorData = response.data.body

      throw new ApiError('Error: Workflow', errorData.message, errorData.status)
    }
  }

  /**
   * Freeze line
   * @param {string} id - The ID of the line.
   * @param {Object} data - The data to send with the request.
   * @returns {Promise<AxiosResponse>} A promise that resolves to the Axios response object.
   * @throws {Error} If the request fails.
   */
  static async freeze(id, data) {
    const event = 'frozen'
    const response = await axios.put(
      `/${URI}/${id}/${event}`,
      data,
      ApiLineRepository.buildHeader()
    )
    const { actionResults } = response.data.body
    return actionResults
  }

  /**
   * Unfreeze line
   * @param {string} id - The ID of the line.
   * @param {Object} data - The data to send with the request.
   * @returns {Promise<AxiosResponse>} A promise that resolves to the Axios response object.
   * @throws {Error} If the request fails.
   */
  static async unfreeze(id, data) {
    const event = 'reactive'
    const response = await axios.put(
      `/${URI}/${id}/${event}`,
      data,
      ApiLineRepository.buildHeader()
    )
    const { actionResults } = response.data.body
    return actionResults
  }

  /**
   * Cancel line
   * @param {string} id - The ID of the line.
   * @param {Object} data - The data to send with the request.
   * @returns {Promise<AxiosResponse>} A promise that resolves to the Axios response object.
   * @throws {Error} If the request fails.
   */
  static async cancel(id, data) {
    const event = 'cancel'
    const response = await axios.put(
      `/${URI}/${id}/${event}`,
      data,
      ApiLineRepository.buildHeader()
    )
    const { actionResults } = response.data.body
    return actionResults
  }
}
