import CommitteeResult from 'templates/onboarding-actions/committee-actions/committee-result'
import CommitteeReview from 'templates/onboarding-actions/committee-actions/committee-review'
import BuyerVisit from 'templates/onboarding-actions/prospecting/buyer-visit'
import BuyerVisitReview from 'templates/onboarding-actions/prospecting/buyer-visit-review'
import CreditAnalysis from 'templates/onboarding-actions/prospecting/credit-analysis'
import DebtCapacityReviewByCustomer from 'templates/onboarding-actions/prospecting/debt-capacity-review-by-customer'
import DocumentsCorrection from 'templates/onboarding-actions/prospecting/document-correction'
import FinancialAnalysis from 'templates/onboarding-actions/prospecting/financial-analysis'
import ReviewDocuments from 'templates/onboarding-actions/prospecting/review-documents'
import UploadProposal from 'templates/onboarding-actions/prospecting/upload-proposal'
import AdditionalPropertyDocuments from 'templates/onboarding-actions/setup/additional-property-documents'
import PropertyReview from 'templates/onboarding-actions/setup/property-review'

import ContractGenerated from 'templates/onboarding-actions/setup/contract-generated'
import ContractReview from 'templates/onboarding-actions/setup/contract-review'
import DocumentsReassessment from 'templates/onboarding-actions/reassessment/documents-reassessment'
import ReviewDocumentsReassessment from 'templates/onboarding-actions/reassessment/review-documents-reassessment'
import BackgroundReviewReassessment from 'templates/onboarding-actions/reassessment/background-review-reassessment'
import PropertyReviewReassessment from 'templates/onboarding-actions/reassessment/property-review-reassessment'
import AdditionalPropertyDocumentsReassessment from 'templates/onboarding-actions/reassessment/additional-property-review-reassessment'

export const ONBOARDING_STATES = {
  CREATE_PROPOSAL: {
    action: 'Crear propuesta',
    label: 'Crear propuesta',
    value: 'CREATE_PROPOSAL',
    /*
      transition maybe is a workflow-api concept
      nextState and nextStage are not necessary if we use constants on workflow
      I propose replace transition field with events
      ex. { ..., events: { uploadProposal: 'upload-proposal' } }
    */
    transition: {
      uploadProposal: {
        event: 'upload-proposal',
        nextState: 'ONBOARDING_DOCUMENTS_REVIEW',
        nextStage: 'assessment',
        notificationReceiverRoles: [
          'bpm.mx-amex-b2b.ope-hd',
          'bpm.mx-amex-b2b.ope-jr',
        ],
      },
    },
    component: UploadProposal,
  },
  ONBOARDING_DOCUMENTS_REVIEW: {
    label: 'Revisión de documentos',
    value: 'ONBOARDING_DOCUMENTS_REVIEW',
    transition: {
      requestCorrections: {
        event: 'request-documents',
        nextState: 'UPLOAD_CORRECTION_DOCUMENTS',
        notificationReceiverRoles: [
          'bpm.mx-amex-b2b.com-hd',
          'bpm.mx-amex-b2b.com-ej',
        ],
      },
      approveDocuments: {
        event: 'approve-documents',
        nextState: 'BACKGROUND_REVIEW',
        notificationReceiverRoles: [
          'bpm.mx-amex-b2b.ope-hd',
          'bpm.mx-amex-b2b.ope-jr',
        ],
      },
    },
    component: ReviewDocuments,
  },
  UPLOAD_CORRECTION_DOCUMENTS: {
    label: 'Subir documentos corregidos',
    value: 'UPLOAD_CORRECTION_DOCUMENTS',
    transition: {
      uploadCorrectionDocuments: {
        event: 'send-corrections',
        nextState: 'ONBOARDING_DOCUMENTS_REVIEW',
      },
    },
    component: DocumentsCorrection,
  },
  BACKGROUND_REVIEW: {
    label: 'Revisión de antecedentes',
    value: 'BACKGROUND_REVIEW',
    transition: {
      rejectBuyer: {
        event: 'reject-finance',
        nextState: 'BUYER_REJECTED',
        status: 'Rejected',
      },
      approveBuyer: {
        event: 'approve-buyer',
        nextState: 'FINANCE_ANALYSIS',
        roleAssign: 'bpm.mx-amex-b2b.rsk-hd',
      },
    },
    component: CreditAnalysis,
  },
  FINANCE_ANALYSIS: {
    label: 'Análisis financiero',
    value: 'FINANCE_ANALYSIS',
    transition: {
      rejectFinance: {
        event: 'reject-finance',
        nextState: 'BUYER_REJECTED',
        status: 'Rejected',
      },
      approveFinance: {
        event: 'approve-finance',
        nextState: 'DEBT_CAPACITY_REVIEW_BY_BUYER',
      },
      addDebtCapacity: {
        event: 'add-debt-capacity',
      },
      deleteDebtCapacity: {
        event: 'delete-debt-capacity',
      },
      updateDebtCapacity: {
        event: 'update-debt-capacity',
      },
    },
    component: FinancialAnalysis,
  },
  DEBT_CAPACITY_REVIEW_BY_BUYER: {
    label: 'Revisión de cliente',
    value: 'CUSTOMER_REVIEW',
    transition: {
      rejectBuyer: {
        event: 'reject-buyer',
        nextState: 'BUYER_REJECTED',
        status: 'Rejected',
      },
      approveBuyer: {
        event: 'approve-buyer',
        nextState: 'PROPERTY_REVIEW',
        nextStage: 'setup',
      },
    },
    component: DebtCapacityReviewByCustomer,
  },
  PROPERTY_REVIEW: {
    label: 'Revisión de RPP y Dictamen',
    value: 'PROPERTY_REVIEW',
    transition: {
      requestAdditionalDocuments: {
        event: 'request-additional-documents',
        nextState: 'ADDITIONAL_DOCUMENTS',
        fileTypeId: 26,
      },
      rejectPropertyDocuments: {
        event: 'reject-buyer',
        nextState: 'BUYER_REJECTED',
        status: 'Rejected',
      },
      requestCorrectedPropertyDocuments: {
        event: 'request-corrected-documents',
        nextState: 'ADDITIONAL_DOCUMENTS',
      },
      approvePropertyDocuments: {
        event: 'approve-property-documents',
        nextState: 'BUYER_VISIT',
      },
    },
    component: PropertyReview,
  },
  ADDITIONAL_DOCUMENTS: {
    label: 'Documentos adicionales',
    value: 'ADDITIONAL_DOCUMENTS',
    transition: {
      uploadAdditionalDocuments: {
        event: 'upload-additional-documents',
        nextState: 'PROPERTY_REVIEW',
      },
    },
    component: AdditionalPropertyDocuments,
  },
  BUYER_VISIT: {
    label: 'Visita al cliente',
    value: 'BUYER_VISIT',
    transition: {
      uploadEvidence: {
        event: 'upload-evidence',
        nextState: 'REVIEW_BUYER_VISIT_BY_COMERCIAL',
      },
    },
    component: BuyerVisit,
  },
  REVIEW_BUYER_VISIT_BY_COMERCIAL: {
    label: 'Revisión de visita al cliente (Comercial)',
    value: 'REVIEW_BUYER_VISIT_BY_COMERCIAL',
    transition: {
      rejectBuyerVisit: {
        event: 'reject-buyer',
        nextState: 'BUYER_REJECTED',
        status: 'Rejected',
        fileStatus: 'Refused',
      },
      approveBuyerVisit: {
        event: 'approve-buyer',
        nextState: 'REVIEW_BUYER_VISIT_BY_OPERATION',
        fileStatus: 'Accepted',
      },
      requestCorrections: {
        event: 'request-corrections',
        nextState: 'BUYER_VISIT',
        fileStatus: 'Refused',
      },
    },
    component: BuyerVisitReview,
  },
  REVIEW_BUYER_VISIT_BY_OPERATION: {
    label: 'Revisión de visita al cliente (Operación)',
    value: 'REVIEW_BUYER_VISIT_BY_OPERATION',
    transition: {
      rejectBuyerVisit: {
        event: 'reject-buyer',
        nextState: 'BUYER_REJECTED',
        fileStatus: 'Refused',
        status: 'Rejected',
      },
      approveBuyerVisit: {
        event: 'approve-buyer',
        nextState: 'REVIEW_BEFORE_COMMITTEE',
        fileStatus: 'Accepted',
      },
      requestCorrections: {
        event: 'request-corrections',
        nextState: 'BUYER_VISIT',
        fileStatus: 'Refused',
      },
    },
    component: BuyerVisitReview,
  },
  REVIEW_BEFORE_COMMITTEE: {
    label: 'Revisión antes de comité',
    value: 'REVIEW_BEFORE_COMMITTEE',
    transition: {
      sendToMenorCommittee: {
        event: 'send-to-menor-committee',
        nextState: 'COMMITTEE_REVIEW',
      },
      sendToMajorCommittee: {
        event: 'send-to-major-committee',
        nextState: 'COMMITTEE_REVIEW',
      },
      rejectBuyer: {
        event: 'reject-buyer',
        nextState: 'BUYER_REJECTED',
        status: 'Rejected',
      },
    },
    /* proposal */
    events: {
      assign: 'assign-proposal',
      sendToMenorCommittee: 'send-to-menor-committee',
      sendToMajorCommittee: 'send-to-major-committee',
      rejectBuyer: 'reject-buyer',
    },
    component: CommitteeReview,
  },
  MENOR_COMMITTEE: {
    label: 'Comité menor',
    value: 'MENOR_COMMITTEE',
    events: {
      uploadReview: 'evaluate-proposal',
      completeReview: 'complete-review',
      rejectBuyer: 'reject-by-committee',
    },
    component: CommitteeReview,
  },
  MAJOR_COMMITTEE: {
    label: 'Comité mayor',
    value: 'MAJOR_COMMITTEE',
    events: {
      uploadReview: 'evaluate-proposal',
      completeReview: 'complete-review',
      rejectBuyer: 'reject-by-committee',
    },
    component: CommitteeReview,
  },
  COMMITTEE_RESULT: {
    label: 'Resultado de comité',
    value: 'COMMITTEE_RESULT',
    events: {
      generateContract: 'generate-contract',
    },
    rolesToNotify: {
      notificationRoles1: ['bpm.mx-amex-b2b.ope-hd', 'bpm.mx-amex-b2b.ope-jr'],
      notificationRoles2: ['bpm.mx-amex-b2b.com-hd', 'bpm.mx-amex-b2b.com-ej'],
    },
    component: CommitteeResult,
  },
  COMMITTEE_REJECTED: {
    label: 'Resultado de comité rechazado',
    value: 'COMMITTEE_REJECTED',
    component: CommitteeResult,
  },
  CONTRACT_GENERATED: {
    label: 'Contrato generado',
    value: 'CONTRACT_GENERATED',
    transition: {
      uploadContractSigned: {
        event: 'upload-contract-signed',
        nextState: 'CONTRACT_SIGNED',
      },
    },
    component: ContractGenerated,
  },
  CONTRACT_SIGNED: {
    label: 'Contrato firmado',
    value: 'CONTRACT_SIGNED',
    transition: {
      requestCorrections: {
        event: 'request-corrections',
        nextState: 'CONTRACT_GENERATED',
      },
      completeOnboarding: {
        event: 'complete-onboarding',
        nextState: 'BUYER_ACTIVE',
      },
      completeOnboardingWithCallao: {
        event: 'complete-onboarding-callao',
        nextState: 'BUYER_ACTIVE',
      },
    },
    component: ContractReview,
  },
  BUYER_ACTIVE: {
    label: 'Cliente activo',
    value: 'BUYER_ACTIVE',
  },
  BUYER_REJECTED: {
    label: 'Cliente rechazado',
    value: 'BUYER_REJECTED',
  },

  // Reassessment States
  REASSESSMENT: {
    label: 'Reassessment - Carga de documentos',
    value: 'REASSESSMENT',
    actions: [
      {
        event: 'send-documents',
        label: 'Subir documentos',
        value: 'UPLOAD_DOCUMENTS',
        nextState: 'REASSESSMENT_DOCUMENTS_REVIEW',
      },
    ],
    component: DocumentsReassessment,
  },
  REASSESSMENT_DOCUMENTS_REVIEW: {
    label: 'Reassessment - Revisión de documentos',
    value: 'REASSESSMENT_DOCUMENTS_REVIEW',
    actions: [
      {
        event: 'request-documents',
        label: 'Solicitar correcciones',
        value: 'REQUEST_CORRECTIONS',
        nextState: 'REASSESSMENT',
      },
      {
        event: 'approve-documents',
        label: 'Aprobar documentos',
        value: 'APPROVE_DOCUMENTS',
        nextState: 'BACKGROUND_REVIEW_REASSESSMENT',
      },
    ],
    component: ReviewDocumentsReassessment,
  },
  BACKGROUND_REVIEW_REASSESSMENT: {
    label: 'Reassessment - Revisión de antecedentes',
    value: 'BACKGROUND_REVIEW_REASSESSMENT',
    actions: [
      {
        event: 'reject-buyer',
        label: 'Rechazar cliente',
        value: 'REJECT_BUYER',
        nextState: 'BUYER_REJECTED',
        status: 'Rejected',
      },
      {
        event: 'approve-buyer',
        label: 'Aprobar cliente',
        value: 'APPROVE_BUYER',
        nextState: 'PROPERTY_REVIEW_REASSESSMENT',
      },
    ],
    component: BackgroundReviewReassessment,
  },
  PROPERTY_REVIEW_REASSESSMENT: {
    label: 'Reassessment - Revisión de RPP y Dictamen',
    value: 'PROPERTY_REVIEW_REASSESSMENT',
    actions: [
      {
        event: 'request-additional-documents',
        label: 'Solicitar documentos adicionales',
        value: 'REQUEST_ADDITIONAL_DOCUMENTS',
        nextState: 'ADDITIONAL_DOCUMENTS_REASSESSMENT',
        fileTypeId: 26,
      },
      {
        event: 'request-corrected-documents',
        label: 'Solicitar correcciones',
        value: 'REQUEST_CORRECTED_DOCUMENTS',
        nextState: 'ADDITIONAL_DOCUMENTS_REASSESSMENT',
      },
      {
        event: 'approve-property-documents',
        label: 'Aprobar documentos',
        value: 'APPROVE_PROPERTY_DOCUMENTS',
        nextState: 'BUYER_ACTIVE',
        nextStage: 'setup',
      },
      {
        event: 'reject-buyer',
        label: 'Rechazar documentos',
        value: 'REJECT_BUYER',
        nextState: 'BUYER_REJECTED',
      },
    ],
    component: PropertyReviewReassessment,
  },
  ADDITIONAL_DOCUMENTS_REASSESSMENT: {
    label: 'Reassessment - Documentos adicionales',
    value: 'ADDITIONAL_DOCUMENTS_REASSESSMENT',
    actions: [
      {
        event: 'upload-additional-documents',
        label: 'Subir documentos adicionales',
        value: 'UPLOAD_ADDITIONAL_DOCUMENTS',
        nextState: 'PROPERTY_REVIEW_REASSESSMENT',
      },
    ],
    component: AdditionalPropertyDocumentsReassessment,
  },
}

export const ONBOARDING_EVENTS_BY_STATE = {
  CREATE_PROPOSAL: {
    'upload-proposal': {
      label: 'Subir propuesta',
      key: 'upload-proposal',
    },
  },
  ONBOARDING_DOCUMENTS_REVIEW: {
    'request-documents': {
      label: 'Solicitar documentos',
      key: 'request-documents',
    },
    'approve-documents': {
      label: 'Aprobar documentos',
      key: 'approve-documents',
    },
  },
  UPLOAD_CORRECTION_DOCUMENTS: {
    'send-corrections': {
      label: 'Enviar correcciones',
      key: 'send-corrections',
    },
  },
  BACKGROUND_REVIEW: {
    'reject-finance': {
      label: 'Rechazar financiamiento',
      key: 'reject-finance',
    },
    'approve-buyer': {
      label: 'Aprobar cliente',
      key: 'approve-buyer',
    },
  },
  FINANCE_ANALYSIS: {
    'reject-finance': {
      label: 'Rechazar financiamiento',
      key: 'reject-finance',
    },
    'approve-finance': {
      label: 'Aprobar financiamiento',
      key: 'approve-finance',
    },
    'add-debt-capacity': {
      label: 'Subir documentos adicionales',
      key: 'add-debt-capacity',
    },
    'delete-debt-capacity': {
      label: 'Eliminar capacidad de deuda',
      key: 'delete-debt-capacity',
    },
    'update-debt-capacity': {
      label: 'Actualizar capacidad de deuda',
      key: 'update-debt-capacity',
    },
  },
  DEBT_CAPACITY_REVIEW_BY_BUYER: {
    'reject-buyer': {
      label: 'Rechazar cliente',
      key: 'reject-buyer',
    },
    'approve-buyer': {
      label: 'Aprobar cliente',
      key: 'approve-buyer',
    },
  },
  PROPERTY_REVIEW: {
    'request-additional-documents': {
      label: 'Solicitar documentos adicionales',
      key: 'request-additional-documents',
    },
    'reject-buyer': {
      label: 'Rechazar documentos',
      key: 'reject-buyer',
    },
    'request-corrected-documents': {
      label: 'Solicitar correcciones',
      key: 'request-corrected-documents',
    },
    'approve-property-documents': {
      label: 'Aprobar documentos',
      key: 'approve-property-documents',
    },
  },
  ADDITIONAL_DOCUMENTS: {
    'upload-additional-documents': {
      label: 'Subir documentos adicionales',
      key: 'upload-additional-documents',
    },
  },
  BUYER_VISIT: {
    'upload-evidence': {
      label: 'Subir evidencia',
      key: 'upload-evidence',
    },
  },
  REVIEW_BUYER_VISIT_BY_COMERCIAL: {
    'reject-buyer': {
      label: 'Rechazar cliente',
      key: 'reject-buyer',
    },
    'approve-buyer': {
      label: 'Aprobar cliente',
      key: 'approve-buyer',
    },
    'request-corrections': {
      label: 'Solicitar correcciones',
      key: 'request-corrections',
    },
  },
  REVIEW_BUYER_VISIT_BY_OPERATION: {
    'reject-buyer': {
      label: 'Rechazar cliente',
      key: 'reject-buyer',
    },
    'approve-buyer': {
      label: 'Aprobar cliente',
      key: 'approve-buyer',
    },
    'request-corrections': {
      label: 'Solicitar correcciones',
      key: 'request-corrections',
    },
  },
  REVIEW_BEFORE_COMMITTEE: {
    'assign-proposal': {
      label: 'Asignar propuesta',
      key: 'assign-proposal',
    },
    'send-to-menor-committee': {
      label: 'Enviar a comité menor',
      key: 'send-to-menor-committee',
    },
    'send-to-major-committee': {
      label: 'Enviar a comité mayor',
      key: 'send-to-major-committee',
    },
    'reject-buyer': {
      label: 'Rechazar cliente',
      key: 'reject-buyer',
    },
  },
  MENOR_COMMITTEE: {
    'evaluate-proposal': {
      label: 'Evaluar propuesta',
      key: 'evaluate-proposal',
    },
    'complete-review': {
      label: 'Completar revisión',
      key: 'complete-review',
    },
    'reject-by-committee': {
      label: 'Rechazar por comité',
      key: 'reject-by-committee',
    },
  },
  MAJOR_COMMITTEE: {
    'evaluate-proposal': {
      label: 'Evaluar propuesta',
      key: 'evaluate-proposal',
    },
    'complete-review': {
      label: 'Completar revisión',
      key: 'complete-review',
    },
    'reject-by-committee': {
      label: 'Rechazar por comité',
      key: 'reject-by-committee',
    },
  },
  COMMITTEE_RESULT: {
    'generate-contract': {
      label: 'Generar contrato',
      key: 'generate-contract',
    },
  },
  CONTRACT_GENERATED: {
    'upload-contract-signed': {
      label: 'Subir contrato firmado',
      key: 'upload-contract-signed',
    },
  },
  CONTRACT_SIGNED: {
    'request-corrections': {
      label: 'Solicitar correcciones',
      key: 'request-corrections',
    },
    'complete-onboarding': {
      label: 'Completar onboarding',
      key: 'complete-onboarding',
    },
    'complete-onboarding-callao': {
      label: 'Completar onboarding con Callao',
      key: 'complete-onboarding-callao',
    },
  },
  COMMITTEE_REJECTED: {},
  BUYER_ACTIVE: {},
  BUYER_REJECTED: {},
  REASSESSMENT: {
    'send-documents': {
      label: 'Subir documentos',
      key: 'send-documents',
    },
  },
  REASSESSMENT_DOCUMENTS_REVIEW: {
    'request-documents': {
      label: 'Solicitar documentos',
      key: 'request-documents',
    },
    'approve-documents': {
      label: 'Aprobar documentos',
      key: 'approve-documents',
    },
  },
  BACKGROUND_REVIEW_REASSESSMENT: {
    'reject-finance': {
      label: 'Rechazar financiamiento',
      key: 'reject-finance',
    },
    'approve-buyer': {
      label: 'Aprobar cliente',
      key: 'approve-buyer',
    },
  },
  PROPERTY_REVIEW_REASSESSMENT: {
    'request-additional-documents': {
      label: 'Solicitar documentos adicionales',
      key: 'request-additional-documents',
    },
    'request-corrected-documents': {
      label: 'Solicitar correcciones',
      key: 'request-corrected-documents',
    },
    'approve-property-documents': {
      label: 'Aprobar documentos',
      key: 'approve-property-documents',
    },
    'reject-buyer': {
      label: 'Rechazar documentos',
      key: 'reject-buyer',
    },
  },
  ADDITIONAL_DOCUMENTS_REASSESSMENT: {
    'upload-additional-documents': {
      label: 'Subir documentos adicionales',
      key: 'upload-additional-documents',
    },
  },
}
