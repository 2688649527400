import { jwtToJson } from 'utils/functions/json-translator'
import { ONBOARDING_ROLES_BY_STATE, STATE_ROLES } from 'utils/schemas/roles'

/**
 * function to obtain the roles from oauth token
 * @returns {Array} - Array of roles
 */
export const getUserRoles = () => {
  const oauthIdToken = window.localStorage.getItem('oauthIdToken')
  if (!oauthIdToken) return []
  const token = jwtToJson(oauthIdToken)
  return token.payload.roles ?? []
}

/**
 * function to check if the user's role has permission to access a specific state
 * @param {string} currentState - Current state
 * @param {boolean} [oldFlow] - Old flow
 * @returns {boolean}
 */
export const validateStateRole = (currentState, oldFlow = true) => {
  if (oldFlow) {
    if (!currentState) return false
    const [role] = getUserRoles()
    if (!role) return false
    if (!STATE_ROLES[currentState]) return false
    return STATE_ROLES[currentState].includes(role)
  } else {
    if (!currentState) return false
    const [role] = getUserRoles()
    if (!role || !ONBOARDING_ROLES_BY_STATE[currentState]) return false
    return ONBOARDING_ROLES_BY_STATE[currentState].includes(role)
  }
}
