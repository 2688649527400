import { MenuItem } from '@mui/material'
import { MaterialReactTable } from 'material-react-table'
import { MRT_Localization_ES } from 'material-react-table/locales/es'

const MaterialTable = (props) => {
  const {
    columns,
    dataRows = [],
    actions,
    pagination,
    setPagination,
    columnFilters,
    setColumnFilters,
    rowCount = 0,
    isLoading = false,
    isRefetching = false,
    enableGlobalFilter = false,
    enableSorting = false,
    enableDensityToggle = false,
  } = props

  return (
    <MaterialReactTable
      localization={MRT_Localization_ES}
      columns={columns}
      data={dataRows}
      enableRowActions
      renderRowActionMenuItems={({ row, closeMenu }) =>
        actions
          .filter((action) => {
            if (action.display) return action.display(row.original, action)
            return true
          })
          .map((action) => {
            return (
              <MenuItem
                key={action.key}
                disabled={
                  action.disabled && action.disabled(row.original, action)
                }
                onClick={() => {
                  action.exec(row.original, action)
                  closeMenu()
                }}
              >
                {action?.label}
              </MenuItem>
            )
          })
      }
      state={{
        columnFilters,
        pagination,
        isLoading,
        showProgressBars: isRefetching,
      }}
      onColumnFiltersChange={setColumnFilters}
      onPaginationChange={setPagination}
      rowCount={rowCount}
      paginateExpandedRows={false}
      manualPagination
      manualSorting
      manualFiltering
      enableGlobalFilter={enableGlobalFilter}
      enableSorting={enableSorting}
      enableDensityToggle={enableDensityToggle}
      positionActionsColumn='last'
    />
  )
}

export default MaterialTable
