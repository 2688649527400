import CustomerForm from 'containers/customer-form'
import { CustomerContext } from 'providers/customer'
import { LATERAL_MENU_OPTIONS, LateralBarContext } from 'providers/lateral-bar'
import {
  getAgentInfo,
  getCustomerInfo,
  getLocationInfo,
  getUserInfo,
  updateAgent,
  updateCustomer,
  updateLocation,
  updateUser,
} from 'services/customer'
import {
  getBusinessInfo,
  getPersonInfo,
  getShareholderInfo,
  updateBusiness,
  updatePerson,
  updateShareholder,
} from 'services/shareholder'
import * as Percentage from 'utils/functions/handle-percentage'
import { extractNumberPercentage } from 'utils/functions/handle-percentage'
import { getUserRoles, validateStateRole } from 'utils/functions/role-manager'
import { useAuth } from 'utils/hooks/auth'
import { SHAREHOLDER_TYPES, initialCustomerForm } from 'utils/schemas/customer'
import { dependentRoles } from 'utils/schemas/roles'

import { Button, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import { Fragment, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'

const CustomerInformation = () => {
  const [formValues, setFormValues] = useState(initialCustomerForm)
  const [isValidating, setIsValidating] = useState(false)
  const [formErrors, setFormErrors] = useState({
    ...initialCustomerForm,
    customer: { ...initialCustomerForm.customer },
  })
  const [error, setError] = useState()
  const [loading, setLoading] = useState(true)
  const { setSelectedOption } = useContext(LateralBarContext)
  const { customer } = useContext(CustomerContext)
  const { user } = useAuth()
  const [shLocationId, setShLocationId] = useState()
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)

  const { customerId } = useParams()
  const navigate = useNavigate()

  const allowedAction =
    validateStateRole(customer.wfState, false) &&
    (!customer.iamUid || customer.iamUid === user.iam?.id)

  const underwriterRoles = dependentRoles['bpm.mx-amex-b2b.rsk-hd']
  const [userRole] = getUserRoles()

  const isEditable = !underwriterRoles.includes(userRole)

  const onHandleSubmit = async () => {
    setIsLoadingSubmit(true)
    setIsValidating(true)
    /* Check for errors */
    let hasErrors = false
    const sh_type = formValues.customer?.personType
    if (formErrors.personType) delete formErrors.personType

    Object.values(formErrors).forEach((value) => {
      const sectionErrors = Object.values(value).filter(
        (field) => field === true
      )
      if (sectionErrors.length > 0) {
        hasErrors = true
      }
    })

    if (!hasErrors && hasErrors !== undefined) {
      try {
        const customer_form_data = splitFormData(formValues)
        customer_form_data.customer.tradePlatform = undefined
        customer_form_data.customer.activeAt = undefined

        await updateLocation(formValues.sh_location, shLocationId)
        await updateCustomer(customer_form_data.customer, customerId)
        await updateUser(
          customer_form_data.user,
          customer_form_data.customer.userId
        )
        await updateLocation(
          customer_form_data.location,
          customer_form_data.customer.locationId
        )
        await updateAgent(
          customer_form_data.agent,
          customer_form_data.customer.agentId
        )
        if (sh_type === 'naturalPerson') {
          await updatePerson(
            {
              name: formValues?.sh_person?.name,
              firstSurname: formValues?.sh_person?.firstSurname,
              secondSurname: formValues?.sh_person?.secondSurname,
              sharePercentage: extractNumberPercentage(
                formValues?.sh_person?.sharePercentage
              ),
              locationId: formValues?.sh_person?.locationId,
              civilStatus: formValues?.sh_person?.civilStatus,
            },
            customer_form_data.customer.shareholderId
          )
          await updateShareholder(
            {
              tin: formValues?.sh_person?.tin,
              areaCode: formValues?.sh_person?.areaCode,
              phone: formValues?.sh_person?.phone,
              email: formValues?.sh_person?.email,
            },
            customer_form_data.customer.shareholderId
          )
        } else if (sh_type === 'moralPerson') {
          await updateAgent(
            {
              name: formValues?.sh_agent?.name,
              firstSurname: formValues?.sh_agent?.firstSurname,
              secondSurname: formValues?.sh_agent?.secondSurname,
            },
            formValues?.sh_business?.agentId
          )
          await updateBusiness(
            {
              mainActivity: formValues?.sh_business?.mainActivity,
              name: formValues?.sh_business?.name,
            },
            customer_form_data.customer.shareholderId
          )
          await updateShareholder(
            {
              tin: formValues?.sh_business?.tin,
              areaCode: formValues?.sh_business?.areaCode,
              phone: formValues?.sh_business?.phone,
              email: formValues?.sh_business?.email,
            },
            customer_form_data.customer.shareholderId
          )
        }
        navigate(-1)
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      } catch (err) {
        setError((prev) => ({ ...prev, upload: err?.message ?? '' }))
        console.error(err?.message, err)
      }
    }
    setIsLoadingSubmit(false)
  }

  useEffect(() => {
    const fetchCustomerInfo = async () => {
      try {
        /* fetch customer data */
        const { data: customer_data } = await getCustomerInfo(customerId)
        const { agentId, locationId, shareholderId, userId } = customer_data
        const { data: user_data } = await getUserInfo(userId)
        const { data: agent_data } = await getAgentInfo(agentId)
        const { data: location_data } = await getLocationInfo(locationId)

        /* fetch shareholder data */
        const { data: sh_data } = await getShareholderInfo(shareholderId)
        const shType = sh_data?.type
        const uiPersonType =
          shType === SHAREHOLDER_TYPES.PERSON ? 'naturalPerson' : 'moralPerson'

        customer_data.satIndustries =
          customer_data.satIndustries.split(' - ')[0]

        setFormValues((prev) => ({
          ...prev,
          customer: {
            ...prev.customer,
            satIndustriesFilter: customer_data['satIndustries'],
            personType: uiPersonType,
            ...mapFormData(customer_data, 'customer'),
            ...mapFormData(user_data, 'user'),
            ...mapFormData(agent_data, 'agent'),
            ...mapFormData(location_data, 'location'),
          },
        }))

        if (shType === SHAREHOLDER_TYPES.PERSON) {
          /* fetch person data */
          const { data: shperson_data } = await getPersonInfo(shareholderId)
          const shLocationId = shperson_data?.location_id
          setShLocationId(shLocationId)
          const { data: shlocation_data } = await getLocationInfo(shLocationId)

          setFormValues((prev) => ({
            ...prev,
            sh_person: {
              ...sh_data,
              name: shperson_data?.name,
              firstSurname: shperson_data?.first_surname,
              secondSurname: shperson_data?.second_surname,
              sharePercentage: Percentage.formatPercentage(
                shperson_data?.share_percentage
              ),
              civilStatus: shperson_data?.civil_status,
            },
            sh_location: shlocation_data,
          }))
        } else {
          /* fetch business data */
          const { data: shbusiness_data } = await getBusinessInfo(shareholderId)
          setShLocationId(shbusiness_data?.locationId)
          const { data: shagent_data } = await getAgentInfo(
            shbusiness_data.agentId
          )
          const { data: shlocation_data } = await getLocationInfo(
            shbusiness_data.locationId
          )
          /* TO DO: fetch agent and location of business */
          setFormValues((prev) => ({
            ...prev,
            sh_business: {
              ...sh_data,
              ...shbusiness_data,
            },
            sh_agent: shagent_data,
            sh_location: shlocation_data,
          }))
        }
      } catch (err) {
        setError((prev) => ({ ...prev, fetch: err?.message ?? '' }))
        console.error('fetchError', err?.message, err)
      }
      setLoading(false)
    }
    fetchCustomerInfo()
  }, [customerId])

  if (loading) {
    return (
      <CustomerInformationStyled>
        <div className='center-loading'>
          <LoadingAnimation />
        </div>
      </CustomerInformationStyled>
    )
  }

  return (
    <CustomerInformationStyled>
      {!error?.fetch && (
        <Fragment>
          <CustomerForm
            formValues={formValues}
            setFormValues={setFormValues}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
            isValidating={isValidating}
            createProspect={false}
            savingProspect={true}
            isBlocked={!allowedAction || !isEditable}
          />

          <div className='action-group'>
            <Button
              buttonType='grayButton'
              onClick={() => {
                navigate(-1)
                setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
              }}
            >
              Cancelar
            </Button>
            {allowedAction && isEditable && (
              <Button disabled={isLoadingSubmit} onClick={onHandleSubmit}>
                {isLoadingSubmit ? <LoadingAnimation /> : 'Guardar'}
              </Button>
            )}
          </div>
        </Fragment>
      )}
      {error && <p>{`Ocurrió un error!`}</p>}
    </CustomerInformationStyled>
  )
}

const splitFormData = (formValues) => {
  const customer_form_data = {}
  Object.entries(formValues.customer).forEach(([key, value]) => {
    const [valueKey, type] = key.split(':')
    if (valueKey && type)
      customer_form_data[type] = {
        ...customer_form_data[type],
        [valueKey]: value,
      }
  })
  return customer_form_data
}

const mapFormData = (data, form_type) => {
  const user_data = {}
  Object.entries(data).forEach(([key, value]) => {
    user_data[`${key}:${form_type}`] = value
  })
  return user_data
}

const CustomerInformationStyled = styled.section`
  padding: 2rem;
  .action-group {
    margin: 2rem 42px 0;

    button + button {
      margin-left: 28px;
    }
    button span {
      background-color: white;
    }
  }
`

export default CustomerInformation
