import { LoadingAnimation } from '@keoworld/gbl-ui-kit'
import LateralBar from 'containers/lateral-bar'
import { CustomerContext } from 'providers/customer'
import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { BuildSection } from 'templates/actions/document-actions/build-section'
import { getUserRoles } from 'utils/functions/role-manager'
import { useFetchDocuments } from 'utils/hooks/fetch-documents'
import { FILE_STATES } from 'utils/schemas/documents'

const [role] = getUserRoles()
const DOCUMENT_FILTERS = {
  status: `${FILE_STATES.ACCEPTED},${FILE_STATES.EXPIRED}`,
  role,
}

const CustomerDocuments = () => {
  const { customer } = useContext(CustomerContext)
  const [loading, setLoading] = useState(true)
  const [documents, setDocuments] = useState({})
  const [documentScheme, setDocumentScheme] = useState([])
  const [lateralBar, setLateralBar] = useState(false)
  const files = useFetchDocuments(customer?.id, DOCUMENT_FILTERS)

  useEffect(() => {
    const fetchFiles = async () => {
      setLoading(true)

      const savedFiles = files.reduce((acc, document) => {
        let documentsSaved = acc[document.typeId] || []
        const extension = `.${document.fileExtension}`

        documentsSaved = [
          ...documentsSaved,
          {
            name: `${document.fileName}${extension}`,
            size: document.fileSize,
            status: document.fileStatus,
            id: document.fileId,
            fileExtension: document.fileExtension,
          },
        ]

        return { ...acc, [document.typeId]: documentsSaved }
      }, {})

      const scheme = files.reduce((acc, file) => {
        const { typeId, typeName: label, typeStage } = file
        const fileTypesByStage = acc[typeStage] || []

        const isFileType = fileTypesByStage.some((f) => f.typeId === typeId)
        if (isFileType) return acc

        return {
          ...acc,
          [typeStage]: [...fileTypesByStage, { typeId, label }],
        }
      }, {})

      setDocumentScheme(scheme)
      setDocuments(savedFiles)
      setLoading(false)
    }
    fetchFiles()
  }, [files])

  return (
    <CustomerDocumentsStyled>
      <h3>Documentos</h3>
      {loading && (
        <div className='center-loading'>
          <LoadingAnimation />
        </div>
      )}
      {!loading &&
        Object.entries(documentScheme).map(
          ([stage, documentInformation], index) => {
            return (
              <BuildSection
                key={`document-${index}`}
                title={stage}
                schema={documentInformation}
                files={documents}
                setCommentBarInfo={setLateralBar}
                allowComment
                allowUpload={false}
                allowUpdate={false}
              />
            )
          }
        )}
      {lateralBar && (
        <LateralBar
          fileId={lateralBar.fileId}
          documentName={lateralBar.fileName}
          setlateralBar={setLateralBar}
        />
      )}
    </CustomerDocumentsStyled>
  )
}

const CustomerDocumentsStyled = styled.section`
  padding: 2rem;

  .center-loading {
    height: 50vh;
    display: flex;
    align-items: center;
    div {
      width: 150px;
    }
  }

  h5 {
    margin-top: 1rem;
  }

  ul > li {
    list-style: none;
    .document {
      width: 65%;
    }
  }
`

export default CustomerDocuments
