import Form from 'containers/form'
import styled from 'styled-components'
import { CREDIT_STUDY_HEAD, CREDIT_STUDY } from 'utils/schemas/customer'

const FinanceInformation = ({ financeInformation }) => {
  const HEAD = CREDIT_STUDY_HEAD.map((item) => ({ ...item, disabled: true }))
  const SCHEMA = CREDIT_STUDY.map((item) => ({ ...item, disabled: true }))

  return (
    <Styles className='finance-information'>
      <h5>Indicadores financieros</h5>
      <form className='finance-head'>
        <Form
          schema={HEAD}
          formValues={financeInformation}
          isValidating={false}
        />
      </form>
      <form>
        <Form
          schema={SCHEMA}
          formValues={financeInformation}
          isValidating={false}
        />
      </form>
    </Styles>
  )
}

const Styles = styled.div`
  .finance-head {
    grid-template-columns: 1fr 1fr 1fr;
    background-color:#f6f8fc;
    border-radius: 9px;
    padding: 1rem;
    margin: 1rem 0;
    input {
      background-color: transparent;
    }
  }

  form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0.5rem;
    .comments {
      grid-column: 1 / span 2;
    }
  }
`

export default FinanceInformation
