import { Icon } from '@keoworld/gbl-ui-kit'
import { useState } from 'react'
import styled from 'styled-components'

export const Tabs = ({ className, options = [], initialSelected, onClick }) => {
  const [selected, setSelected] = useState(initialSelected)

  const handleTabClick = (value) => {
    setSelected(value)
    if (typeof onClick === 'function') {
      onClick(value)
    }
  }

  return (
    <Styles className={className}>
      {options.map((option, index) => {
        const { label, value, icon } = option
        return (
          <button
            key={`option-${index}`}
            className={selected === value ? 'selected' : 'not-selected'}
            onClick={() => handleTabClick(value)}
          >
            {icon && <Icon name={icon} />}
            <span>{label}</span>
          </button>
        )
      })}
    </Styles>
  )
}

const Styles = styled.section`
  width: 100%;
  height: 50px;
  display: flex;
  box-shadow: 0px 4px 10px rgba(21, 50, 101, 0.11);
  background-color: #ffffff;
  color: #515151;

  button {
    border: none;
    position: relative;
    background-color: transparent;
    cursor: pointer;
    padding: 0 0.8rem;
    padding-top: 1rem;

    &:hover {
      color: #00172d;
    }

    &.selected::after {
      content: '';
      position: absolute;
      left: 11px;
      bottom: 0.1rem;
      background-color: #9b9b9b;
      width: 30%;
      height: 3px;
    }
  }
`
