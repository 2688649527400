import { Button, Icon } from '@keoworld/gbl-ui-kit'
import Form from 'containers/form'
import styled from 'styled-components'
import { DEBT_CAPACITY_FORM } from 'utils/schemas/credit-line'

const MULTICURRENCY_ENABLED = false;

const DebtCapacityForm = ({
  debtCapacities = [],
  setDebtCapacities,
  formErrors = [],
  setFormErrors,
  isValidating,
}) => {
  const addPropose = () => {
    setDebtCapacities([...debtCapacities, { currency: '', amount: '' }])
    setFormErrors([...formErrors, { currency: true, amount: true }])
  }

  const removePropose = (index) => {
    setDebtCapacities(debtCapacities.filter((_, i) => i !== index))
    setFormErrors(formErrors.filter((_, i) => i !== index))
  }

  return (
    <Styles className='credit-line'>
      {debtCapacities.map((debtCapacity, index) => (
        <article key={`${debtCapacity.currency}-${index}`}>
          <Form
            schema={DEBT_CAPACITY_FORM}
            formValues={debtCapacities[index]}
            setFormValues={(values) => {
              const newFormValues = [...debtCapacities]
              newFormValues[index] = values
              setDebtCapacities(newFormValues)
            }}
            formErrors={formErrors[index]}
            setFormErrors={(errors) => {
              const newFormErrors = [...formErrors]
              newFormErrors[index] = errors
              setFormErrors(newFormErrors)
            }}
            isValidating={isValidating}
          />
          {debtCapacities.length > 1 && (
            <Button
              className='remove-button'
              size='action'
              onClick={() => removePropose(index)}
            >
              <Icon name='delete' />
            </Button>
          )}
        </article>
      ))}

      {MULTICURRENCY_ENABLED && 
        <Button className='add-proposal' onClick={() => addPropose()}>
          <span>Añadir propuesta</span>
          <Icon name='add' />
        </Button>
      }
    </Styles>
  )
}

const Styles = styled.section`
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 15px;

  article {
    display: grid;
    grid-template-columns: repeat(3, 300px);
    align-items: center;
    gap: 15px;

    .remove-button {
      height: 40px;
      width: 40px;
    }
  }

  .add-proposal {
    position: absolute;
    right: 0;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: fit-content;
    height: fit-content;
    padding: 5px;

    span {
      display: none;
    }
    span + span {
      display: inline-block;
    }

    &:hover {
      span {
        display: inline-block;
      }
    }
  }
`

export default DebtCapacityForm
