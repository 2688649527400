import { LoadingAnimation } from '@keoworld/gbl-ui-kit'
import ContactSupport from 'containers/contact-support/contact-support'
import MassivePayment from 'containers/customer-detail/paid/massive-payment'
import { LATERAL_MENU_OPTIONS, LateralBarContext } from 'providers/lateral-bar'
import { ModalProvider } from 'providers/modal'
import { useContext } from 'react'
import styled from 'styled-components'
import Actions from 'templates/actions'
import BuyerDetail from 'templates/buyer-detail/buyer-detail'
import CustomerDetail from 'templates/customer-detail'
import CustomerDashboard from 'templates/dashboards/customers'
import OnboardingDashboard from 'templates/dashboards/onboarding'
import Onboarding from 'templates/onboarding'
import PrecustomerDetail from 'templates/precustomer-detail'

const PrecustomerComponents = {
  [LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING]: <OnboardingDashboard />,
  [LATERAL_MENU_OPTIONS.ONBOARDING]: <Onboarding />,
  [LATERAL_MENU_OPTIONS.PRECUSTOMER_DETAIL]: <PrecustomerDetail />,
  [LATERAL_MENU_OPTIONS.ONBOARDING_BUYER_DETAIL]: <BuyerDetail />,
}

const CustomerComponents = {
  [LATERAL_MENU_OPTIONS.DASHBOARD_CUSTOMERS]: <CustomerDashboard />,
  [LATERAL_MENU_OPTIONS.CUSTOMER_DETAIL]: <CustomerDetail />,
  [LATERAL_MENU_OPTIONS.MASSIVE_PAYMENT]: <MassivePayment />,
  [LATERAL_MENU_OPTIONS.HELP]: <ContactSupport />,
}

const selectedOptionIsInSection = (section, selectedOption) => {
  const exists = Object.keys(section).find((key) => key === selectedOption)
  return exists
}

const MainTemplate = () => {
  const { selectedOption, action } = useContext(LateralBarContext)

  let Component = <LoadingAnimation className='loading-center' />
  if (action) {
    Component = <Actions />
  } else if (selectedOptionIsInSection(PrecustomerComponents, selectedOption)) {
    Component = <>{PrecustomerComponents[selectedOption]}</>
  } else if (selectedOptionIsInSection(CustomerComponents, selectedOption)) {
    Component = <>{CustomerComponents[selectedOption]}</>
  }

  return (
    <DashboardContainerStyled>
      <ModalProvider>{Component}</ModalProvider>
    </DashboardContainerStyled>
  )
}

const DashboardContainerStyled = styled.div`
  overflow-y: scroll;
  height: calc(100vh - 70px);

  .loading-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 300px;
  }
`

export default MainTemplate
